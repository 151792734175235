<template>
  <div class="body2 mr-1">{{ $t("manageWorkspaces.workspaceTypes.channel") }}:</div>
  <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
    <template v-slot:activator="{ props }">
      <div v-bind="props" role="button" class="d-flex align-center">
        <span class="font-weight-bold">
          {{ currentWorkspace.name }}
        </span>
        <v-icon class="ml-2" size="10" icon="$triangle"></v-icon>
      </div>
    </template>

    <v-card min-width="400" elevation="2" max-height="450">
      <div class="bg-white pa-4 position-sticky top-0 z-index-9999">
        <v-text-field
          density="comfortable"
          rounded
          min-width="400"
          clear-icon="$x"
          :placeholder="$t('general.search')"
          prepend-inner-icon="$search"
          clearable
          variant="filled"
          bg-color="indigo-faint"
          :hide-details="true"
          @update:model-value="workspaceSearch = $event"
        >
        </v-text-field>
      </div>

      <v-list>
        <v-list-item density="comfortable">
          <template v-slot:title>
            <div class="caption">
              <span v-text="$t('general.workspaces', { n: total }, total)"></span>
            </div>
          </template>
        </v-list-item>
        <v-list-item
          v-for="(workspace, i) in workspaces"
          :id="i.toString()"
          density="comfortable"
          variant="flat"
          height="56"
          :key="workspace.workspaceId"
          :active="workspace.workspaceId === currentWorkspace.workspaceId"
          @click="selectWorkplace(workspace)"
          v-intersect="onIntersect"
        >
          <template v-slot:title>
            <div class="d-flex align-center">
              <v-icon class="mr-2" size="40" icon="$channelWorkspace" />
              <span v-text="workspace.workspaceName"></span>
            </div>
          </template>
        </v-list-item>
        <div v-if="loading" class="d-flex align-center justify-center">
          <v-progress-circular size="40" indeterminate></v-progress-circular>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { WorkspaceAction } from "@/constants/workplaces";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import { computed, ref, watch } from "vue";
import { useAccountStore } from "@/_store";
import { handleVirtualScrollData, useResetStore } from "@/_helpers/utils.ts";
import { type MspHierarchyItem, useMspStore } from "@/_store/msp/msp.module.ts";
import type { Pagination } from "@/types.ts";

export default {
  setup() {
    const workspaceStore = useWorkspacesStore();
    const mspStore = useMspStore();
    const workspaceSearch = ref("");
    let workspaceTimer: ReturnType<typeof setTimeout>;
    const total = ref(0);
    const workspaces = ref<MspHierarchyItem[]>([]);
    const pagination = ref<Pagination>({
      page: 0,
      pageSize: 10,
    });
    const loading = ref(false);
    const accountStore = useAccountStore();
    const menu = ref(false);
    const currentWorkspace = computed(() => ({
      name: accountStore.account.customerName,
      workspaceId: accountStore.account.workplace,
    }));

    const selectWorkplace = async (workplace: MspHierarchyItem) => {
      await workspaceStore.updateWorkspaceAndPermissions(workplace.workspaceId);
      useResetStore().allExceptIgnoreList();
      await mspStore.checkMspAccess();
      menu.value = false;
    };

    const onIntersect = async (isIntersecting: boolean, entries: IntersectionObserverEntry[]) => {
      const intersectedItemId = Number(entries[0].target.id);
      if (isIntersecting && intersectedItemId === (workspaces.value ?? []).length - 1) {
        // last tab reached in backend, don't do new request
        if (intersectedItemId === total.value - 1) {
          return;
        }
        await loadMoreWorkplaces();
      }
    };

    const loadMoreWorkplaces = async () => {
      pagination.value = {
        page: pagination.value.page + 1,
        pageSize: 25,
      };
      await getItems(workspaceSearch.value);
    };

    const getItems = async (search: string = "") => {
      loading.value = true;
      try {
        const { data } = await mspStore.getHierarchies({
          search,
          pagination: pagination.value,
        });
        workspaces.value = handleVirtualScrollData(workspaces.value, data.items, "workspaceId");
        total.value = data.total;
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    watch(workspaceSearch, (newVal) => {
      // cancel pending call
      clearTimeout(workspaceTimer);
      // delay new call 500ms
      workspaceTimer = setTimeout(async () => {
        workspaces.value = [];
        pagination.value = {
          page: 0,
          pageSize: 10,
        };
        await getItems(newVal);
      }, 500);
    });

    watch(menu, async (opened) => {
      if (opened) {
        pagination.value = {
          page: 0,
          pageSize: 10,
        };
        await getItems();
      }
    });

    return {
      menu,
      loading,
      workspaceSearch,
      workplacesActions: WorkspaceAction,
      workspaces,
      total,
      pagination,
      selectWorkplace,
      onIntersect,
      currentWorkspace,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(*) {
  .v-field__outline {
    display: none !important;
  }
}
</style>
