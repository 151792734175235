import api from "@/_helpers/api";
import { defineStore } from "pinia";
import type { OpenSearchCursor, Pagination } from "@/types";
import { axiosInstance } from "@/plugins/https";
import { FilterContext, type MspUsersFilters, useFiltersStore } from "@/_store/filters.module";
import { handleVirtualScrollData, parseOpenSearchQuery } from "@/_helpers/utils";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { i18n } from "@/plugins/i18n";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useSelectorStore } from "@/_store/selector.module";
import { SubscriptionModule } from "@/constants/workplaces";
import type { AxiosResponse } from "axios";
import type { UserDetails, UserListItem } from "../users.module.ts";
import { MspUserVulnerabilityStatus } from "@/constants/msp-users.ts";
import cloneDeep from "lodash/cloneDeep";
import type { ExportDialogPayload } from "@/_store/exports.module.ts";
import { ExportChoice } from "@/_store/exports.module.ts";
import { firstPageQueryEntityActivityLogs } from "@/constants/general.ts";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions.ts";

export interface MspUserDetails extends UserDetails {
  workspaceId: string;
  workspaceName: string;
  suspended: boolean;
}

export interface MspUserListItem extends UserListItem {
  workspaceId: string;
  workspaceName: string;
  suspended: boolean;
}

interface MspUsersState {
  loading: boolean;
  items: Array<MspUserListItem>;
  total: number;
  pagination: Pagination;
  usersLoading: boolean;
  search: null | string;
  userDetailsLoading: boolean;
  activityLogsLoading: boolean;
  userDetails: MspUserDetails;
  cursor: OpenSearchCursor;
}

const defaultMspUsersState: MspUsersState = {
  loading: false,
  items: [],
  total: 0,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  usersLoading: true,
  search: null,
  userDetailsLoading: false,
  activityLogsLoading: false,
  userDetails: {
    id: null,
    name: null,
    protectionGrantedTime: 0,
    cloudServices: [],
    tickets: [],
    activityLogs: [],
    aliases: [],
    workspaceId: "",
    workspaceName: "",
    suspended: false,
  },
  cursor: {
    nextPage: firstPageQueryEntityActivityLogs,
  },
};

export const useMspUsersStore = defineStore("msp-users", {
  state: (): MspUsersState => cloneDeep({ ...defaultMspUsersState }),
  actions: {
    async getUserDetails(user: MspUserListItem): Promise<void> {
      this.userDetailsLoading = true;

      const activityLogsAccessRestricted = isMspAccessRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ACTIVITY_LOGS
      );

      const ticketsAccessRestricted = (
        [
          SubscriptionModule.USER_DATA_GOVERNANCE,
          SubscriptionModule.CLOUD_SECURITY,
          SubscriptionModule.EMAIL_SECURITY,
        ] as const
      ).every((module) => isMspAccessRestricted(RolePermissionsScope.TICKETS, module));

      const userInfoRequest = {
        ...api.getMspUserInfo(user.id),
        params: {
          workspaceId: user.workspaceId,
        },
      };
      const userTicketsRequest = {
        ...api.getMspUserTickets(user.id),
        params: {
          workspaceId: user.workspaceId,
        },
      };
      const userActivityLogsRequest = {
        ...api.getMspUserActivityLogs(user.id),
        params: {
          ...parseOpenSearchQuery(firstPageQueryEntityActivityLogs),
          workspaceId: user.workspaceId,
        },
      };

      try {
        const userInfoPromise = axiosInstance.request(userInfoRequest);
        const userTicketsPromise = ticketsAccessRestricted
          ? Promise.resolve({ data: [] })
          : axiosInstance.request(userTicketsRequest);
        const userActivityLogsPromise = activityLogsAccessRestricted
          ? Promise.resolve({ data: { items: [] } })
          : axiosInstance.request(userActivityLogsRequest);

        const [userInfoResponse, userTicketsResponse, userActivityLogsResponse] = await Promise.all(
          [userInfoPromise, userTicketsPromise, userActivityLogsPromise]
        );

        this.cursor = userActivityLogsResponse?.data.cursor;

        this.userDetails = {
          ...userInfoResponse.data,
          tickets: [...userTicketsResponse.data],
          activityLogs: [...userActivityLogsResponse.data.items],
        };
      } catch (error) {
        console.error(error);
      } finally {
        this.usersLoading = false;
        this.userDetailsLoading = false;
      }
    },
    async getItems(showSkeletonLoader: boolean = false) {
      this.loading = true;
      this.usersLoading = showSkeletonLoader;
      const filtersStore = useFiltersStore();
      const { search, workspaceIds, suspended, hasVulnerabilities, cloudServices } =
        filtersStore.filters[FilterContext.MSP_USERS];
      const request = {
        ...api.getMspUsers(),
        data: {
          hasVulnerabilities:
            hasVulnerabilities === MspUserVulnerabilityStatus.ALL
              ? null
              : hasVulnerabilities === MspUserVulnerabilityStatus.WITH_ISSUES,
          search,
          workspaceIds: workspaceIds ?? [],
          suspended,
          cloudServices: cloudServices ?? [],
        },
        params: {
          ...this.pagination,
        },
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.items = data.items;
        this.total = data.total;
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
      this.usersLoading = false;
    },
    async onPageChange(pagination: Pagination) {
      this.pagination = pagination;
      await this.getItems();
    },

    async undoActivityLog(id: string) {
      this.activityLogsLoading = true;

      try {
        const request = {
          ...api.activityLogsUndo(id),
          headers: {
            Workspace: this.userDetails.workspaceId,
          },
        };

        await axiosInstance.request(request);

        this.resetCursor();

        const userActivityLogsRequest = {
          ...api.getMspUserActivityLogs(this.userDetails.id as string),
          params: parseOpenSearchQuery(this.cursor.nextPage!),
        };

        const { data } = await axiosInstance.request(userActivityLogsRequest);
        this.cursor = data.cursor;
        this.userDetails.activityLogs = data.items;
      } catch (error) {
        console.error(error);
      }

      this.activityLogsLoading = false;
    },
    async onProtectedUsersAction(payload: { action: string; selection: MspUserListItem[] }) {
      const { deselectAllPages } = useSelectorStore();
      const request = {
        ...api.mspUserAction,
        data: {
          action: payload.action,
          userIds: payload.selection.map((v) => v.id),
        },
      };

      axiosInstance
        .request(request)
        .then(() => {
          useSnackbarStore().addGenericSuccess(
            i18n.global.t(`snackbar.messages.users.${payload.action}`)
          );
          this.getItems();
          deselectAllPages();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getActions(userIds: string[]): Promise<AxiosResponse<{ items: string[] }>> {
      const request = {
        ...api.mspUsersAvailableActions,
        data: {
          userIds,
        },
      };

      return axiosInstance.request(request);
    },
    async loadMoreMspUserActivityLogs() {
      this.activityLogsLoading = true;

      try {
        const request = {
          ...api.getMspUserActivityLogs(this.userDetails.id!),
          params: parseOpenSearchQuery(this.cursor.nextPage!),
        };

        const { data } = await axiosInstance.request(request);
        this.cursor = data.cursor;
        this.userDetails = {
          ...this.userDetails,
          activityLogs: handleVirtualScrollData(this.userDetails.activityLogs, data.items, "id"),
        };

        this.activityLogsLoading = false;
      } catch (error) {
        console.error(error);
      }

      this.activityLogsLoading = false;
    },
    async exportToCsv({ type, filters }: ExportDialogPayload<MspUsersFilters>) {
      const { cloudServices, workspaceIds } = filters;

      const filtersPayload =
        type === ExportChoice.ALL
          ? { hasVulnerabilities: null }
          : {
              hasVulnerabilities: null,
              cloudServices: cloudServices ?? [],
              workspaceIds: workspaceIds ?? [],
            };

      const request = {
        ...api.exportMspUsersToCsv(),
        data: {
          ...filtersPayload,
        },
      };
      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.general.exportCsvStarted")
        );
      } catch (err) {
        console.error(err);
      }
    },
    resetCursor() {
      this.cursor = {
        nextPage: firstPageQueryEntityActivityLogs,
      };
    },
  },
});
