<template>
  <v-card class="h-100 pa-6" rounded="lg" elevation="0">
    <div v-if="widgetData.loading">
      <v-skeleton-loader type="heading" class="mb-2 bg-transparent" />
      <v-skeleton-loader
        type="list-item-two-line@3,divider,list-item-two-line"
        class="h-100 bg-transparent"
      />
    </div>

    <div v-else-if="widgetData.data">
      <div class="d-flex justify-space-between mb-5">
        <div
          class="subtitle2 text-capitalize"
          v-text="$t('general.workspaces', { n: widgetData.data.total }, widgetData.data.total)"
        ></div>
        <router-link
          :to="{ name: RouteName.MSP_OVERVIEW_PAGE }"
          class="body2 coro-link text-decoration-none"
          aria-label="View All Workspaces"
        >
          <div class="view-all-btn body2 coro-link item-clickable d-flex align-center">
            <span>{{ $t("general.viewAll") }}</span>
            <v-icon icon="$chevronRight" color="link" size="16" />
          </div>
        </router-link>
      </div>

      <div
        v-for="(item, index) in statusItems"
        :key="index"
        class="d-flex justify-space-between align-center py-3"
        :data-testid="item.testId"
      >
        <v-divider
          v-if="'divider' in item"
          class="my-2"
          opacity="100"
          color="indigo-faint"
        ></v-divider>
        <template v-else>
          <div class="body2">
            {{ item.displayName }}
          </div>
          <div class="subtitle2" :class="item.extraClass">
            {{ item.count }}
          </div>
        </template>
      </div>
    </div>
    <div
      v-else
      class="h-100 d-flex align-center justify-center flex-column"
      data-testid="widget-error-state"
    >
      <v-icon icon="$alertTriangle" size="80"></v-icon>
      <span class="subtitle1 mb-4">
        {{ $t("mspDashboard.widgets.workspacesStatsWidget.error") }}
      </span>
      <div class="coro-link" @click="$emit('retry-fetch-data')">
        {{ $t("general.retry") }}
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { DataValue } from "@/types.ts";
import type { WorkspacesStats } from "@/_store/msp/msp-dashboard.module.ts";
import { RouteName } from "@/constants/routes.ts";
import { SubscriptionType } from "@/constants/workplaces.ts";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    widgetData: {
      type: Object as PropType<DataValue<WorkspacesStats>>,
      required: true,
    },
  },
  emits: ["retry-fetch-data"],
  setup(props) {
    const i18n = useI18n();
    const statusItems = computed(() => {
      if (!props.widgetData.data) return [];
      return [
        {
          displayName: i18n.t(
            `mspDashboard.widgets.workspacesStatsWidget.statuses.${SubscriptionType.SUBSCRIPTION}`
          ),
          count: props.widgetData.data.subscriptionCount,
          extraClass: "",
          testId: "workspaces-stats-widget-subscription-item",
        },
        {
          displayName: i18n.t(
            `mspDashboard.widgets.workspacesStatsWidget.statuses.${SubscriptionType.INITIAL}`
          ),
          count: props.widgetData.data.newCount,
          extraClass: "",
          testId: "workspaces-stats-widget-new-item",
        },
        {
          displayName: i18n.t(
            `mspDashboard.widgets.workspacesStatsWidget.statuses.${SubscriptionType.TRIAL}`
          ),
          count: props.widgetData.data.trialCount,
          extraClass: "",
          testId: "workspaces-stats-widget-trial-item",
        },
        {
          displayName: i18n.t("mspDashboard.widgets.workspacesStatsWidget.statuses.nearExpiration"),
          count: props.widgetData.data.nearExpirationCount,
          extraClass: "text-red-dark",
          testId: "workspaces-stats-widget-near-expiration-item",
        },
        { divider: true, testId: "workspaces-stats-widget-divider" },
        {
          displayName: i18n.t(
            `mspDashboard.widgets.workspacesStatsWidget.statuses.${SubscriptionType.FREEZE}`
          ),
          count: props.widgetData.data.inactiveCount,
          extraClass: "text-indigo-medium",
          testId: "workspaces-stats-widget-freeze-item",
        },
      ];
    });
    return {
      RouteName,
      SubscriptionType,
      statusItems,
    };
  },
});
</script>
<style lang="scss">
.view-all-btn {
  text-decoration: none;

  .icon-chevron-right {
    text-decoration: none;
    &:before {
      color: rgb(var(--v-theme-anchor-base));
    }
  }

  &:hover span {
    text-decoration: underline; /* Underline only the text */
  }
}

.icon-alert-triangle:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}
</style>
