import { i18n } from "@/plugins/i18n";
import {
  addEncryptDriveMetadata,
  addWorkspaceHeaderIfNeeded,
  buildTicketActionRequest,
  convertTicketFiltersForBackend,
  getTicketBulkActionRequest,
  getTicketDetailsRequest,
  getTicketsRequest,
  handleBulkActionSnackbar,
  handleTicketActionSnackbar,
} from "./adapters";
import {
  AnomalyIpThreatLevel,
  SocStatus,
  TicketAction,
  TicketActionSeverity,
  TicketTrigger,
  TicketType,
  ViolationDirection,
} from "@/constants/tickets";
import { axiosInstance } from "@/plugins/https";
import { defineStore } from "pinia";
import { useFiltersStore } from "@/_store/filters.module";
import type { Pagination } from "@/types";
import { useTicketStore } from "@/_store/tickets/ticket.module";
import api from "@/_helpers/api";
import { type ISelection, useSelectorStore } from "@/_store/selector.module";
import { useAccountStore, useSnackbarStore } from "@/_store";
import { useEmailProxyStore } from "@/_store/email-security/email-proxy.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { Service } from "@/constants/cloud-apps";
import {
  downloadFile,
  getSelectionAsStringsArray,
  handleVirtualScrollData,
  isAccessRestricted,
  parseOpenSearchQuery,
} from "@/_helpers/utils";
import type { SubscriptionModule } from "@/constants/workplaces";
import type { AxiosRequestConfig } from "axios";
import type { EdrProcessStatus } from "@/constants/edr";
import type { ProxyDirection, ProxySecurityMode } from "@/constants/email-proxy";
import type { ActivityLogItem } from "@/_store/activity-logs.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { OpenSearchCursor } from "@/types";
import { firstPageQueryEntityActivityLogs } from "@/constants/general.ts";

export interface TicketActionWithSeverity {
  name: TicketAction;
  severity: TicketActionSeverity;
}

export interface AnomalyDetection {
  type: string;
  originalTime: number;
  ip: string;
  country: string;
  regionName: string;
  suspicious: boolean;
  threatTypes: string[];
  proxyType?: string;
  vpnService: string;
  host?: string;
  threatLevel?: AnomalyIpThreatLevel;
  threatDetectionDate: number;
  orgName: string;
}

export interface AnomalyDetectionMetaData {
  records: AnomalyDetection[];
}

export interface DeviceMetaData {
  deviceId: string;
  login: string;
  userName: string;
  upn: string;
  enrollmentCode: string;
  hostname: string;
  model: string;
  osType: string;
  osVersion: string;
  osVersionDisplayName: string;
  removed: boolean;
  unencryptedFileStorage: FileStorage;
  usersWithoutPassword: DeviceUserMetaData[];
  scanId: string;
  scannedFileStorage: FileStorage;
  dlpEngineVersion: string;
}

export interface FileStorage {
  name: string;
  model: string;
  interfaceType: string;
}

export interface DeviceUserMetaData {
  login: string;
  userName: string;
  upn: string;
}

export interface EmailMetaData {
  senderName?: string;
  senderIp?: string;
  senderEmail: string;
  recipients: string[];
  detectionType: string;
  subject: string;
  attachment?: Attachment; // For legacy tickets support
  attachments: Attachment[];
  directions: string[];
  messageHash: number;
  proxyEmailMetadata: boolean;
  proxyRecipientsDomain?: string;
  emailProxyDirection?: ProxyDirection;
  internetMessageId?: string;
  content?: string;
  proxyBlockingMode?: ProxySecurityMode;
}

export interface SecurityAwarenessMetaData {
  affectedUsersList: string[];
  campaignName: string;
  emailTemplate: string;
  emailSender: string;
  attachmentType: string;
  securityTrainingContents: string[];
}

export interface MalwareMetaData {
  fileId: string;
  parentFolderId: string;
  fileName: string;
  path: string;
  virusName: string;
  threatType: string;
  threatStatus: string;
  mimeType: string;
  fileHash: string;
  detectedBy: string;
  fileSize?: number;
  owner: string;
  sharedWith: Array<string>;
}

export interface NetworkMetaData {
  ssid: string;
  bssids: string[]; // Using an array instead of a Set
  networkSecurity: string;
  indicators: string[]; // Using an array instead of a Set
}

export interface EdrMetaData {
  shortDescription: string;
  fullDescription: string;
  processHash: string;
  processName: string;
  affectedDevicesCount: number;
  showExpandedFindings: boolean;
  mitre: EdrMitreTechniqueData[];
  processStatus: EdrProcessStatus;
  allowBlockTimestamp?: number;
}

export interface SystemMetaData {
  proxyMisconfiguration: ProxyMisconfigurationData;
}

export interface ProxyMisconfigurationData {
  domain: string;
  lastFailedTestTime: number;
}

export interface EdrMitreTechniqueData {
  title: string;
  description: string;
  techniqueId: string;
  link: string;
}

export interface InfectedProcessMetaData {
  type: InfectedProcessType;
  threatType: AtcThreatType;
  processGroups: Array<ProcessGroup>;
  suspiciousCharacteristics: string;
  suspectedMaliciousProcessName: string;
}

export enum InfectedProcessType {
  PROCESS = "process",
  GROUP = "group",
}

export enum AtcThreatType {
  INFECTED_PROCESS = "infectedProcess",
  ML_INFECTED_PROCESS = "mlInfectedProcess",
  BETA_INFECTED_PROCESS = "betaInfectedProcess",
  EXTERNAL_SOURCE_INFECTED_PROCESS = "externalSourceInfectedProcess",
  INFECTED_GROUP = "infectedGroup",
  ML_INFECTED_GROUP = "mlInfectedGroup",
}

export interface ProcessGroup {
  id: string;
  path: string;
  detected: boolean;
  sha256: string;
}

export interface Attachment {
  fileName: string;
  fileSize: number;
}

export interface Finding {
  type: string;
  quote: string;
  longQuote: string;
  filePath: string;
  virusName?: string;
  typeUiView?: string;
}

export interface Location {
  ip: string;
  countryName: string;
  countryCode: string;
  host?: string;
  regionName?: string;
}

export interface UserDataAccessViolation {
  violationDirection: ViolationDirection;
  users: string[];
}

export interface Ticket {
  eventId: string;
  eventType: TicketType;
  eventTrigger: TicketTrigger;
  processed: boolean;
  eventTime: number; // timestamp
  from: string;
  to: string[];
  userDataAccessViolation: {
    violationDirection: ViolationDirection;
    users: string[];
  };
  suspiciousVoteCount: number;
  safeVoteCount: number;
  moduleName: SubscriptionModule;
  potentiallyProtectableUsers: string[];
  workspaceId?: string;
  workspaceName?: string;
  processName?: string;
  usersAffected?: number;
  socStatus?: SocStatus;
  processStatus?: EdrProcessStatus;
  lastLoggedInUsers?: string[];
  hasUnreadComment?: boolean;
}

export interface TicketDetails {
  activityLogs: ActivityLogItem[];
  activityLogsTotal: number;
  workspaceId?: string;
  processed: boolean;
  eventId: string;
  eventType: TicketType;
  eventTrigger: TicketTrigger;
  actions: TicketActionWithSeverity[];
  potentiallyProtectableUsers: string[];
  startTime: number;
  endTime: number;
  creationTime: number;
  sections: TicketSection;
  noProtectedUsersAtCreation: boolean;
  socModuleEnabled: boolean;
  sensitiveDataRestricted: boolean;
  moduleName: SubscriptionModule;
  processedTime?: number;
  workspaceName?: string;
  processName?: string;
  socStatus?: SocStatus;
  processStatus?: EdrProcessStatus;
  lastLoggedInUsers?: string[];
  serviceName?: string;
}

interface TicketsStoreState {
  allTickets: number;
  pages: number;
  pagination: Pagination;
  tickets: Ticket[];
  sorting: {
    sortBy: string;
    sortDesc: boolean;
  };
  showSkeletonLoader: boolean;
  loading: boolean;
  search: string | null;
  ticketDetailsLoading: boolean;
  activityLogsLoading: boolean;
  isSoc: boolean;
  ticketDetails: TicketDetails;
  cursor: OpenSearchCursor;
}

export interface TicketSection {
  triggeredUser: string;
  serviceName: string;
  locations: Location[];
  counter: number;
  findings: Finding[];
  additionalFindings: any[];
  filesMetaData: any[];
  securityAwarenessMetaData?: SecurityAwarenessMetaData;
  emailMetaData: EmailMetaData;
  malwareMetaData: MalwareMetaData;
  infectedProccessMetaData: InfectedProcessMetaData;
  anomalyDetectionMetaData: AnomalyDetectionMetaData;
  deviceMetaData: DeviceMetaData;
  networkMetaData: NetworkMetaData;
  cloudActivityLogsMetaData: { cloudActivityLogId: string; logType: string }[];
  userDataAccessViolation: UserDataAccessViolation;
  edrMetaData: EdrMetaData;
  systemMetaData: SystemMetaData;
}

export interface ApplyTicketBulkActionPayload {
  action: TicketAction;
  selection: ISelection<Ticket>;
  item?: Ticket;
  closeTicket?: boolean; // Used in soc
}

const defaultTicketsState = {
  allTickets: 0,
  pages: 0,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  tickets: [],
  sorting: {
    sortBy: "lastSeen",
    sortDesc: true,
  },
  showSkeletonLoader: true,
  loading: true,
  search: null,
  ticketDetailsLoading: false,
  activityLogsLoading: false,
  isSoc: false,
  ticketDetails: {
    activityLogs: [],
    activityLogsTotal: 0,
    processed: false,
    eventId: "",
    actions: [],
    potentiallyProtectableUsers: [],
    startTime: 0,
    endTime: 0,
    creationTime: 0,
    sections: {} as TicketSection,
    noProtectedUsersAtCreation: false,
    socModuleEnabled: false,
    sensitiveDataRestricted: false,
    lastLoggedInUsers: [],
    eventType: "" as TicketType,
    eventTrigger: "" as TicketTrigger,
    moduleName: "" as SubscriptionModule,
  } as TicketDetails,
  cursor: {
    nextPage: firstPageQueryEntityActivityLogs,
  },
};

export const useTicketsStore = defineStore("tickets", {
  state: (): TicketsStoreState => ({ ...defaultTicketsState }),
  actions: {
    resetState() {
      this.$reset();
    },
    setPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
    resetPagination() {
      this.pagination = {
        page: 0,
        pageSize: 25,
      };
    },
    async getTickets(showSkeletonLoader = true) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;

      const request = getTicketsRequest(this.isSoc, this.pagination);

      try {
        const tickets = await axiosInstance.request(request);
        this.tickets = tickets.data.items;
        this.allTickets = tickets.data.total;
      } catch (err) {
        console.error(err);
      }

      this.showSkeletonLoader = false;
      this.loading = false;
    },
    applyPaginationChange(payload: Pagination) {
      this.setPagination(payload);
      this.getTickets();
    },
    async getTicketDetails({ eventId, workspaceId }: { eventId: string; workspaceId?: string }) {
      this.ticketDetailsLoading = true;
      this.cursor = {
        nextPage: firstPageQueryEntityActivityLogs,
      };
      useTicketStore().$patch({ workspaceId, eventId });

      const eventInfoRequest = getTicketDetailsRequest(this.isSoc, eventId);
      const request = addWorkspaceHeaderIfNeeded(
        {
          ...api.getTicketActivityLogs(eventId),
          params: parseOpenSearchQuery(this.cursor.nextPage!),
        },
        workspaceId
      ) as AxiosRequestConfig;

      const activityLogsAccessRestricted = isAccessRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ACTIVITY_LOGS
      );

      try {
        const [eventInfoResponse, eventActivityLogsResponse] = await Promise.all([
          axiosInstance.request(eventInfoRequest),
          activityLogsAccessRestricted ? null : axiosInstance.request(request),
        ]);

        const activityLogs = eventActivityLogsResponse?.data.items ?? [];
        const activityLogsTotal = eventActivityLogsResponse?.data.total ?? 0;
        this.cursor = eventActivityLogsResponse?.data.cursor;
        const ticketDetails = {
          ...eventInfoResponse.data,
          activityLogs,
          activityLogsTotal,
        };

        if (
          ticketDetails.eventType === TicketType.EMAIL_PHISHING &&
          ticketDetails.sections?.serviceName === Service.PROXY
        ) {
          const { direction, proxyRecipientsDomain } = {
            proxyRecipientsDomain: ticketDetails.sections?.emailMetaData?.proxyRecipientsDomain,
            direction: ticketDetails.sections?.emailMetaData?.emailProxyDirection!,
          };

          await useEmailProxyStore().getEmailProxyStatus(
            {
              direction,
            },
            proxyRecipientsDomain
          );
        }

        this.ticketDetails = ticketDetails;
      } catch (error) {
        console.error(error);
      } finally {
        this.ticketDetailsLoading = false;
      }
    },
    async exportTicketsToCsv() {
      const filtersStore = useFiltersStore();

      const ticketFilters = { ...filtersStore.filters.ticketsFilters };
      const { widget, search, fromTime, toTime, protectedUsersOnly } =
        convertTicketFiltersForBackend({
          ...filtersStore.filters.ticketsFilters,
        });

      const selectorModule = useSelectorStore();
      const request = {
        ...api.exportTicketsToCsv(),
        data: {
          filters: {
            widget,
            search,
            eventTriggers: ticketFilters.eventTriggers,
            fromTime,
            toTime,
            protectedUsersOnly,
          },
          selection: getSelectionAsStringsArray(selectorModule.selection),
        },
      };
      try {
        await axiosInstance.request(request);

        const snackbarStore = useSnackbarStore();

        snackbarStore.addGenericSuccess(
          i18n.global.t("snackbar.messages.general.exportCsvStarted")
        );
      } catch (err) {
        console.error(err);
      }
    },
    async applyTicketAction(
      payload: DialogDataConfig<
        TicketDetails & { name?: string; closeTicket?: boolean },
        TicketAction
      >
    ) {
      try {
        const request = buildTicketActionRequest(payload, this.isSoc);

        if (payload.action === TicketAction.ENCRYPT_DRIVE) {
          addEncryptDriveMetadata(request, payload);
        }

        await axiosInstance.request(request);
        await this.getTickets();
        handleTicketActionSnackbar(payload, this.isSoc);
      } catch (err) {
        console.error(err);
      }
    },
    async applyTicketBulkAction(payload: ApplyTicketBulkActionPayload) {
      const { selection } = useSelectorStore();
      const requestData: ApplyTicketBulkActionPayload = {
        ...payload,
        selection,
      };
      try {
        const request = getTicketBulkActionRequest(this.isSoc, requestData);

        const response = await axiosInstance.request(request);

        await this.getTickets();

        handleBulkActionSnackbar(requestData, response.data.appliedActions, this.isSoc);

        useSelectorStore().deselectAllPages();
      } catch (e) {
        console.error(e);
      }
    },
    async applyDownloadEmailAction(payload: { item: Ticket; action: TicketAction }) {
      const request = addWorkspaceHeaderIfNeeded(
        {
          ...api.ticketDownloadAction(payload.item.eventId),
          data: {
            action: payload.action,
          },
        },
        payload.item.workspaceId
      );

      try {
        const { data, headers } = await axiosInstance.request(request);
        const blob = new Blob([data], { type: headers["content-type"] });
        const fileName = headers["x-coro-attachment-filename"];
        downloadFile(blob, fileName);

        await this.getTickets();

        const snackbarStore = useSnackbarStore();

        snackbarStore.addGenericSuccess(
          i18n.global.t(`snackbar.messages.event.${payload.action}`, {
            fileName,
          })
        );
      } catch (err) {
        console.error(err);
      }
    },
    async getTicketActivityLogs({
      eventId,
      workspaceId,
    }: {
      eventId: string;
      workspaceId?: string;
    }) {
      this.activityLogsLoading = true;

      try {
        const request = addWorkspaceHeaderIfNeeded(
          {
            ...api.getTicketActivityLogs(eventId),
            params: parseOpenSearchQuery(this.cursor.nextPage!),
          },
          workspaceId
        );

        const { data } = await axiosInstance.request(request);

        this.cursor = data.cursor;

        this.ticketDetails = {
          ...this.ticketDetails,
          activityLogs: handleVirtualScrollData(this.ticketDetails.activityLogs, data.items, "id"),
        };

        this.activityLogsLoading = false;
      } catch (error) {
        console.error(error);
      }

      this.activityLogsLoading = false;
    },
    async undoActivityLog(id: string) {
      this.activityLogsLoading = true;

      try {
        const request = addWorkspaceHeaderIfNeeded(
          { ...api.activityLogsUndo(id) },
          this.ticketDetails.workspaceId
        );

        await axiosInstance.request(request);
        this.resetCursor();
        await this.updateEventActivityLogs();
        await this.getTickets();
      } catch (error) {
        console.error(error);
      }

      this.activityLogsLoading = false;
    },
    async updateEventActivityLogs() {
      this.activityLogsLoading = true;
      try {
        const request = addWorkspaceHeaderIfNeeded(
          {
            ...api.getTicketActivityLogs(this.ticketDetails.eventId),
            params: parseOpenSearchQuery(this.cursor.nextPage!),
          },
          this.ticketDetails.workspaceId
        );
        const { data } = await axiosInstance.request(request);
        this.cursor = data.cursor;
        this.ticketDetails.activityLogs = data.items;
      } catch (e) {
        console.error(e);
      }

      this.activityLogsLoading = false;
    },
    async updateSocStatus(socStatus: SocStatus) {
      try {
        const socStatusRequest = {
          ...api.updateSocStatus(this.ticketDetails.eventId),
          data: {
            socStatus,
          },
        };
        await axiosInstance.request(socStatusRequest);
        this.getTickets();
      } catch (e) {
        console.error(e);
      }
    },
    async searchSocWorkspaces({
      search,
      page,
      pageSize,
    }: Pagination & { search: string }): Promise<{ workspaceId: string; workspaceName: string }[]> {
      try {
        const searchSocWorkspacesRequest = {
          ...api.searchSocWorkspaces({ search, page, pageSize }),
        };
        const { data } = await axiosInstance.request(searchSocWorkspacesRequest);
        return data;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async onContactUser(
      payload: DialogDataConfig<{
        includeEventDetails: boolean;
        contactMessage: string;
        recipients: string[];
        id: string;
        by: "event";
      }>
    ) {
      const accountStore = useAccountStore();
      const request = {
        ...api.contactUser,
        params: {
          id: payload.item.id,
          by: payload.item.by,
        },
        data: {
          contactMessage: payload.item.contactMessage,
          includeEventDetails: payload.item.includeEventDetails,
          recipients: payload.item.recipients,
          workspaceDisplayName: accountStore.account.customerName,
        },
      };

      try {
        await axiosInstance.request(request);

        useSnackbarStore().addGenericSuccess(i18n.global.t("snackbar.messages.users.contactUser"));

        if (payload.item.by === "event") {
          this.resetCursor();
          await this.updateEventActivityLogs();
        }
      } catch (error) {
        console.error(error);
      }
    },
    resetCursor() {
      this.cursor = {
        nextPage: firstPageQueryEntityActivityLogs,
      };
    },
  },
});
