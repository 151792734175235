<template>
  <v-card class="protected-users-widget pa-6 h-100" rounded="lg" elevation="0">
    <div v-if="widgetData.loading">
      <v-skeleton-loader
        type="list-item-avatar-two-line"
        height="100"
        class="mb-2 bg-transparent"
      />
      <v-skeleton-loader type="list-item" class="mb-2 bg-transparent" />
      <v-skeleton-loader type="list-item" class="mb-2 bg-transparent" />
      <v-skeleton-loader type="list-item" class="bg-transparent" />
    </div>

    <div v-else-if="widgetData.data">
      <div class="protected-users-widget-grid mb-4">
        <v-icon icon="$usersNoHexagon" size="72" class="icon" />
        <div class="d-flex flex-column total-number" data-testid="protected-users-widget-total">
          <div class="big-number">
            {{ widgetData.data.total }}
          </div>
          <div class="subtitle2">{{ $t("mspDashboard.widgets.protectedUsersWidget.title") }}</div>
        </div>
        <router-link
          v-if="mspUsersAccessAllowed"
          :to="{ name: RouteName.MSP_PROTECTED_USERS_PAGE }"
          class="body2 coro-link view-all text-decoration-none"
        >
          <div class="view-all-btn body2 coro-link item-clickable d-flex align-center">
            <span>{{ $t("general.viewAll") }}</span>
            <v-icon icon="$chevronRight" color="link" size="16" />
          </div>
        </router-link>
      </div>
      <div class="d-flex justify-space-between py-2" data-testid="protected-users-widget-suspended">
        <div class="body2">{{ $t("mspDashboard.widgets.protectedUsersWidget.suspended") }}</div>
        <div class="text-red-dark font-weight-bold">{{ widgetData.data.suspendedCount }}</div>
      </div>
      <v-divider class="border-opacity-100" color="indigo-faint" />
      <div
        class="d-flex justify-space-between py-2"
        data-testid="protected-users-widget-with-issues"
      >
        <div class="body2">{{ $t("mspDashboard.widgets.protectedUsersWidget.withIssues") }}</div>
        <div class="text-red-dark font-weight-bold">{{ widgetData.data.withIssuesCount }}</div>
      </div>
      <v-divider class="border-opacity-100" color="indigo-faint" />
      <div class="d-flex justify-space-between py-2" data-testid="protected-users-widget-no-issues">
        <div class="body2">{{ $t("mspDashboard.widgets.protectedUsersWidget.noIssues") }}</div>
        <div class="text-green-dark">{{ widgetData.data.noIssuesCount }}</div>
      </div>
    </div>

    <div
      v-else
      class="h-100 d-flex align-center justify-center flex-column"
      data-testid="widget-error-state"
    >
      <v-icon icon="$alertTriangle" size="80"></v-icon>
      <span class="subtitle1 mb-4">
        {{ $t("mspDashboard.widgets.protectedUsersWidget.error") }}
      </span>
      <div class="coro-link" @click="$emit('retry-fetch-data')">
        {{ $t("general.retry") }}
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { DataValue } from "@/types.ts";
import type { UsersStats } from "@/_store/msp/msp-dashboard.module.ts";
import { RouteName } from "@/constants/routes.ts";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions.ts";
import { RolePermissionsScope } from "@/_store/roles.module.ts";

export default defineComponent({
  props: {
    widgetData: {
      type: Object as PropType<DataValue<UsersStats>>,
      required: true,
    },
  },
  emits: ["retry-fetch-data"],
  setup() {
    const mspUsersAccessAllowed = computed(() => {
      return !isMspAccessRestricted(RolePermissionsScope.VIEWS, "usersView");
    });

    return {
      RouteName,
      mspUsersAccessAllowed,
    };
  },
});
</script>
<style lang="scss">
.protected-users-widget-grid {
  display: grid;
  grid-template-areas:
    "icon number viewAll"
    "icon number .";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  gap: 16px;
}

.icon {
  grid-area: icon;
}

.total-number {
  grid-area: number;
}

.view-all {
  grid-area: viewAll;
  justify-self: end;
  white-space: nowrap;
}

.view-all-btn {
  text-decoration: none !important;

  .icon-chevron-right {
    text-decoration: none !important;
    &:before {
      color: rgb(var(--v-theme-anchor-base)) !important;
    }
  }

  &:hover span {
    text-decoration: underline !important; /* Underline only the text */
  }
}

.icon-alert-triangle:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}
</style>
