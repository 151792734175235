import { defineStore } from "pinia";
import type { Paged, Pagination } from "@/types";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { RolePermissionAccessMode, RolesAction, RoleType } from "@/constants/roles";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { i18n } from "@/plugins/i18n";
import { SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";
import cloneDeep from "lodash/cloneDeep";
import { set } from "lodash";
import { TicketActionSeverity } from "@/constants/tickets";
import type { AxiosRequestConfig } from "axios";
import { FilterContext, useFiltersStore } from "@/_store/filters.module";

// Role and Permissions Interfaces
export interface RoleListItem {
  id: string;
  name: string;
  assigneesCount: number;
  customRole: boolean;
}

export interface MspRoleListItem extends RoleListItem {
  workspaceId: string;
  workspaceDisplayName: string;
}

export interface RoleAssignee {
  id: string;
  name: string;
  email: string;
}

export type RolePermissions<
  MspPermissions extends {
    [MspRolePermissionScopes.MSP_PORTAL_SCOPE]: MspPortalScope;
  } = MspPortalPermissions,
> = WorkspaceRolePermissions & MspPermissions & GlobalRolePermissions;

export type RoleDetails = RolePermissions & {
  name: string;
  workspaceId?: string;
  workspaceDisplayName?: string;
};

export interface RolesState {
  roles: (RoleListItem | MspRoleListItem)[];
  rolesTotal: number;
  roleDetails: {
    [roleId: string]: {
      permissions: RolePermissions;
      assignees: Paged<RoleAssignee>;
    };
  };
  pagination: Pagination;
  loading: boolean;
  showSkeletonLoader: boolean;
}

// Permission Types

export type NestedWorkspacePermissions = AdminUsersPermissions | TicketsScopeModulePermissions;

export type NestedMspPermissions =
  | MspWorkspacesPermissions
  | MspChannelAdminsPermissions
  | MspRolesPermissions
  | NestedWorkspacePermissions;

export type NestedGlobalPermissions =
  | GlobalAdminsAccessPermissions
  | GlobalRolesAccessPermissions
  | SocPortalAccessPermissions
  | NestedMspPermissions
  | NestedWorkspacePermissions;

export type NestedPermissions =
  | NestedWorkspacePermissions
  | NestedMspPermissions
  | NestedGlobalPermissions;

// Scopes and Sections Enums

export enum RolePermissionsScope {
  WORKSPACE_MANAGEMENT = "workspaceManagementScope",
  VIEWS = "viewsScope",
  PROTECTION = "protectionScope",
  TICKETS = "ticketsScope",
}

export enum GlobalRoleScopes {
  GLOBAL_SCOPE = "globalScope",
}

export enum GlobalRoleScopeSection {
  GLOBAL_ADMIN_USERS = "globalAdminsAccess",
  GLOBAL_ROLES = "globalRolesAccess",
  SOC_PORTAL = "socPortalAccess",
  WORKSPACE_SUBSCRIPTION_TYPE_ACCESS = "workspaceSubscriptionTypeAccess",
  WORKSPACE_TYPE_ACCESS = "workspaceTypeAccess",
  SPECIAL_PERMISSIONS = "specialPermissions",
}

export enum MspRolePermissionScopes {
  MSP_PORTAL_SCOPE = "mspPortalScope",
}

export enum MspPortalScopeSection {
  MSP_WORKSPACES = "mspPortalAccess",
  MSP_ADMIN_USERS = "channelAdminsAccess",
  MSP_ROLES = "channelRolesAccess",
  MSP_WORKSPACE_LABELS = "workspaceLabelsAccess",
}

export enum ProtectionScopeModules {
  MANAGED_SOC = "managedSoc",
}

export enum WorkspaceManagementScopeSections {
  USERS = "users",
  DEVICES = "devices",
  CLOUD_APPS = "cloudApps",
  ACTIVE_SESSIONS = "activeSessions",
  ACTIVITY_LOGS = "activityLogs",
  CONNECTORS = "connectors",
  ROLES = "roles",
  REPORTS = "reports",
  ADMIN_USERS = "adminUsers",
}

// Scope Interfaces

// Workspace Scope interfaces

export interface WorkspaceRolePermissions {
  [RolePermissionsScope.WORKSPACE_MANAGEMENT]: WorkspaceManagementScope;
  [RolePermissionsScope.VIEWS]: ViewsScope;
  [RolePermissionsScope.PROTECTION]: ProtectionScope;
  [RolePermissionsScope.TICKETS]: TicketsScope;
}

export interface WorkspaceManagementScope {
  [WorkspaceManagementScopeSections.USERS]: RolePermissionAccessMode;
  [WorkspaceManagementScopeSections.DEVICES]: RolePermissionAccessMode;
  [WorkspaceManagementScopeSections.CLOUD_APPS]: RolePermissionAccessMode;
  [WorkspaceManagementScopeSections.ACTIVE_SESSIONS]: RolePermissionAccessMode;
  [WorkspaceManagementScopeSections.ACTIVITY_LOGS]: RolePermissionAccessMode;
  [WorkspaceManagementScopeSections.CONNECTORS]: RolePermissionAccessMode;
  [WorkspaceManagementScopeSections.ROLES]: RolePermissionAccessMode;
  [WorkspaceManagementScopeSections.REPORTS]: RolePermissionAccessMode;
  [WorkspaceManagementScopeSections.ADMIN_USERS]: AdminUsersPermissions;
}

export interface ViewsScope {
  usersView: RolePermissionAccessMode;
  devicesView: RolePermissionAccessMode;
}

export interface AdminUsersPermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission?: AdminUsersEditAccessModePermission;
}

export interface AdminUsersEditAccessModePermission {
  add: boolean;
  edit: boolean;
  remove: boolean;
  removeMfaData: boolean;
  assignRoles: boolean;
  manageContentPermissions: boolean;
}

export interface ProtectionScope {
  [SubscriptionModule.CLOUD_SECURITY]: RolePermissionAccessMode;
  [SubscriptionModule.ENDPOINT_SECURITY]: RolePermissionAccessMode;
  [SubscriptionModule.EMAIL_SECURITY]: RolePermissionAccessMode;
  [SubscriptionModule.USER_DATA_GOVERNANCE]: RolePermissionAccessMode;
  [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: RolePermissionAccessMode;
  [SubscriptionModule.EDR]: RolePermissionAccessMode;
  [SubscriptionModule.NETWORK]: RolePermissionAccessMode;
  [ProtectionScopeModules.MANAGED_SOC]: RolePermissionAccessMode;
  [SubscriptionAddon.INBOUND_GATEWAY]: RolePermissionAccessMode;
  [SubscriptionModule.MDM]: RolePermissionAccessMode;
  [SubscriptionModule.SECURITY_AWARENESS]: RolePermissionAccessMode;
  [SubscriptionAddon.SWG]: RolePermissionAccessMode;
  [SubscriptionAddon.WIFI_PHISHING]: RolePermissionAccessMode;
  [SubscriptionAddon.SECURED_MESSAGES]: RolePermissionAccessMode;
}

export interface TicketsScope {
  [SubscriptionModule.CLOUD_SECURITY]: TicketsScopeModulePermissions;
  [SubscriptionModule.ENDPOINT_SECURITY]: TicketsScopeModulePermissions;
  [SubscriptionModule.EMAIL_SECURITY]: TicketsScopeModulePermissions;
  [SubscriptionModule.USER_DATA_GOVERNANCE]: TicketsScopeModulePermissions;
  [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: TicketsScopeModulePermissions;
  [SubscriptionModule.EDR]: TicketsScopeModulePermissions;
}

export interface TicketsScopeModulePermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission?: TicketsScopeAccessModePermission;
}

export interface TicketsScopeAccessModePermission {
  commentTickets: boolean;
  [TicketActionSeverity.LOW]: boolean;
  [TicketActionSeverity.MEDIUM]: boolean;
  [TicketActionSeverity.CRITICAL]: boolean;
}

// MSP Scope interfaces

export interface MspPortalScope {
  [MspPortalScopeSection.MSP_WORKSPACES]: MspWorkspacesPermissions;
  [MspPortalScopeSection.MSP_ADMIN_USERS]: MspChannelAdminsPermissions;
  [MspPortalScopeSection.MSP_ROLES]: MspRolesPermissions;
  [MspPortalScopeSection.MSP_WORKSPACE_LABELS]: RolePermissionAccessMode;
}

export interface MspWorkspacesPermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission?: MspPortalEditAccessModePermission;
}

export interface MspChannelAdminsPermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission?: MspChannelAdminsEditAccessModePermission;
}

export interface MspRolesPermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission?: MspRolesEditAccessModePermission;
}

export interface MspChannelAdminsEditAccessModePermission {
  add: boolean;
  remove: boolean;
  assignRoles: boolean;
}

export interface MspRolesEditAccessModePermission {
  add: boolean;
  remove: boolean;
  edit: boolean;
}

export interface MspPortalEditAccessModePermission {
  editWorkspace: boolean;
  startSubscription: boolean;
  stopSubscription: boolean;
  editSubscription: boolean;
  createChildWorkspace: boolean;
  createChannelWorkspace: boolean;
  archiveWorkspace: boolean;
  generateMspExport: boolean;
  generateMspSummaryReport: boolean;
  exportMspNotifications: boolean;
}

export interface MspPortalPermissions extends WorkspaceRolePermissions {
  [MspRolePermissionScopes.MSP_PORTAL_SCOPE]: MspPortalScope;
}

export interface GlobalMspPortalPermissions {
  [MspRolePermissionScopes.MSP_PORTAL_SCOPE]: GlobalMspPortalScope;
}

export interface GlobalMspPortalScope extends MspPortalScope {
  [MspPortalScopeSection.MSP_WORKSPACES]: GlobalMspWorkspacesPermissions;
}

export interface MspChannelAdminsPermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission?: MspChannelAdminsEditAccessModePermission;
}

export interface MspRolesPermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission?: MspRolesEditAccessModePermission;
}

export interface MspChannelAdminsEditAccessModePermission {
  add: boolean;
  remove: boolean;
  assignRoles: boolean;
}

export interface MspRolesEditAccessModePermission {
  add: boolean;
  remove: boolean;
  edit: boolean;
}

export interface MspWorkspacesPermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission?: MspPortalEditAccessModePermission;
}

export interface GlobalMspWorkspacesPermissions extends MspWorkspacesPermissions {
  editAccessModePermission?: GlobalMspPortalEditAccessModePermission;
}

export interface MspPortalEditAccessModePermission {
  editWorkspace: boolean;
  startSubscription: boolean;
  stopSubscription: boolean;
  editSubscription: boolean;
  createChildWorkspace: boolean;
  createChannelWorkspace: boolean;
  archiveWorkspace: boolean;
  generateMspExport: boolean;
  generateMspSummaryReport: boolean;
  exportMspNotifications: boolean;
}

export interface GlobalMspPortalEditAccessModePermission extends MspPortalEditAccessModePermission {
  extendTrial: boolean;
  convertWorkspace: boolean;
  restoreWorkspace: boolean;
  createRegularWorkspace: boolean;
}

// Global Scope interfaces

export interface GlobalRolePermissions extends MspPortalPermissions {
  [GlobalRoleScopes.GLOBAL_SCOPE]: GlobalScope;
}

export interface GlobalScope {
  [GlobalRoleScopeSection.GLOBAL_ADMIN_USERS]: GlobalAdminsAccessPermissions;
  [GlobalRoleScopeSection.GLOBAL_ROLES]: GlobalRolesAccessPermissions;
  [GlobalRoleScopeSection.SOC_PORTAL]: SocPortalAccessPermissions;
  [GlobalRoleScopeSection.WORKSPACE_SUBSCRIPTION_TYPE_ACCESS]: WorkspaceSubscriptionTypeAccess;
  [GlobalRoleScopeSection.WORKSPACE_TYPE_ACCESS]: WorkspaceTypeAccess;
  [GlobalRoleScopeSection.SPECIAL_PERMISSIONS]: SpecialPermissions;
}

export interface GlobalAdminsAccessPermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission?: GlobalAdminsAccessEditAccessModePermission;
}

export interface GlobalAdminsAccessEditAccessModePermission {
  add: boolean;
  remove: boolean;
  assignRoles: boolean;
}

export interface GlobalRolesAccessPermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission?: GlobalRolesAccessEditAccessModePermission;
}

export interface GlobalRolesAccessEditAccessModePermission {
  add: boolean;
  remove: boolean;
  edit: boolean;
}

export interface SocPortalAccessPermissions {
  accessMode: RolePermissionAccessMode;
  editAccessModePermission: SocPortalAccessEditAccessModePermission;
}

export interface SocPortalAccessEditAccessModePermission {
  lowSeverity: boolean;
  mediumSeverity: boolean;
  criticalSeverity: boolean;
  commentTickets: boolean;
  tier1Statuses: boolean;
  tier2Statuses: boolean;
}

export interface WorkspaceSubscriptionTypeAccess {
  prospects: boolean;
  customers: boolean;
}

export interface WorkspaceTypeAccess {
  regular: boolean;
  channel: boolean;
  child: boolean;
}

export interface SpecialPermissions {
  collectLogs: boolean;
  collectQuarantineData: boolean;
  showAdvancedDeviceInformation: boolean;
  backOffice: boolean;
  manageEndpointAutoUpdateGroups: boolean;
  // TODO: uncomment in future when needed again
  // manageWorkspaceCodes: boolean;
  demoMode: boolean;
  extendTrial: boolean;
  convertWorkspace: boolean;
  restoreWorkspace: boolean;
  createRegularWorkspace: boolean;
}

const defaultRolesState: RolesState = {
  roles: [],
  rolesTotal: 0,
  roleDetails: {},
  pagination: {
    page: 0,
    pageSize: 25,
  },
  loading: false,
  showSkeletonLoader: false,
};

export const useRolesStore = (roleType: RoleType) => {
  // id will be either `globalRoles`, `workspaceRoles` or `mspRoles`
  return defineStore(`${roleType.toLowerCase()}Roles`, {
    state: (): RolesState => ({ ...defaultRolesState }),
    persist: false,
    actions: {
      resetPagination(): void {
        this.pagination = { ...defaultRolesState.pagination };
      },
      setPagination(pagination: Pagination): void {
        this.pagination = pagination;
      },
      async getDetails(id: string, assigneesCount: number = 100): Promise<void> {
        const getPermissionsRequestConfig = () => {
          switch (roleType) {
            case RoleType.MSP:
              return { ...api.getMspRolePermissions(id) };
            case RoleType.GLOBAL:
              return { ...api.getGlobalRolePermissions(id) };
            case RoleType.WORKSPACE:
            default:
              return { ...api.getRolePermissions(id) };
          }
        };
        const getAssigneesRequestConfig = () => {
          switch (roleType) {
            case RoleType.MSP:
              return {
                ...api.getMspRoleAssignees(id, {
                  page: 0,
                  pageSize: assigneesCount,
                }),
              };
            case RoleType.GLOBAL:
              return {
                ...api.getGlobalRoleAssignees(id, {
                  page: 0,
                  pageSize: assigneesCount,
                }),
              };
            case RoleType.WORKSPACE:
            default:
              return {
                ...api.getRoleAssignees(id, {
                  page: 0,
                  pageSize: assigneesCount,
                }),
              };
          }
        };

        try {
          const [permissionsResponse, assignees] = await Promise.all([
            axiosInstance.request(getPermissionsRequestConfig()),
            axiosInstance.request(getAssigneesRequestConfig()),
          ]).then((responses) => responses.map((r) => r.data));
          const permissions =
            roleType === RoleType.GLOBAL
              ? convertBackendSpecialPermissionsToMspPortalPermissions(permissionsResponse)
              : permissionsResponse;
          this.roleDetails[id] = {
            permissions,
            assignees,
          };
        } catch (err) {
          console.error(err);
        }
      },
      async getRoles(showSkeletonLoader: boolean = false): Promise<void> {
        this.showSkeletonLoader = showSkeletonLoader;
        this.loading = true;
        const { page, pageSize } = this.pagination;
        const filtersStore = useFiltersStore();
        let filters = {};
        const getRequestConfig = (): AxiosRequestConfig => {
          switch (roleType) {
            case RoleType.MSP:
              filters = filtersStore.filters[FilterContext.MSP_ROLES];
              return {
                ...api.getMspRolesList(),
              };
            case RoleType.GLOBAL:
              return { ...api.getGlobalRolesList() };
            case RoleType.WORKSPACE:
            default:
              return { ...api.getRolesList() };
          }
        };
        const request = {
          ...getRequestConfig(),
          params: {
            page,
            pageSize,
            sort: "customRole,asc",
            ...filters,
          },
        };

        try {
          const { data } = await axiosInstance.request<Paged<RoleListItem>>(request);
          this.roles = data.items;
          this.rolesTotal = data.total;
        } catch (e) {
          console.error(e);
        }
        this.loading = false;
        this.showSkeletonLoader = false;
      },
      async searchRoles(
        { page, pageSize, search }: Pagination & { search: string },
        workspaceId?: string
      ) {
        const getRequestConfig = (): AxiosRequestConfig => {
          switch (roleType) {
            case RoleType.MSP:
              return {
                ...api.searchMspRoles(),
                params: {
                  workspaceId,
                  page,
                  pageSize,
                  roleName: search,
                },
              };
            case RoleType.GLOBAL:
              return {
                ...api.searchGlobalRoles(),
                params: {
                  workspaceId,
                  page,
                  pageSize,
                  roleName: search,
                },
              };
            case RoleType.WORKSPACE:
            default:
              return {
                ...api.searchRoles(),
                params: {
                  page,
                  pageSize,
                  roleName: search,
                },
              };
          }
        };

        try {
          const { data } =
            await axiosInstance.request<Paged<{ roleName: string; roleId: string }>>(
              getRequestConfig()
            );
          return data;
        } catch (e) {
          console.error(e);
          return { items: [], total: 0 };
        }
      },
      async addRole(data: DialogDataConfig<RoleDetails & { workspaceId?: string }>): Promise<void> {
        const requestPayload = removeSpecificPermissionsFromRole(data.item);
        const getRequestConfig = (): AxiosRequestConfig => {
          switch (roleType) {
            case RoleType.MSP:
              return { ...api.createNewMspRole(requestPayload) };
            case RoleType.GLOBAL:
              return {
                ...api.createNewGlobalRole(
                  convertMspPortalToBackendGlobalSpecialPermissions(
                    requestPayload as RolePermissions<GlobalMspPortalPermissions>
                  )
                ),
              };
            case RoleType.WORKSPACE:
            default:
              return { ...api.createNewRole(requestPayload) };
          }
        };

        try {
          await axiosInstance.request(getRequestConfig());
          useSnackbarStore().addGenericSuccess(
            i18n.global.t(`snackbar.messages.roles.${RolesAction.ADD}`, {
              name: data.item.name,
            })
          );
          this.resetPagination();
          await this.getRoles();
        } catch (e) {
          console.error(e);
        }
      },
      async deleteRole(data: DialogDataConfig<RoleListItem>): Promise<void> {
        const getRequestConfig = (): AxiosRequestConfig => {
          switch (roleType) {
            case RoleType.MSP:
              return { ...api.deleteMspRole(data.item.id) };
            case RoleType.GLOBAL:
              return { ...api.deleteGlobalRole(data.item.id) };
            case RoleType.WORKSPACE:
            default:
              return { ...api.deleteRole(data.item.id) };
          }
        };

        try {
          await axiosInstance.request(getRequestConfig());
          useSnackbarStore().addGenericSuccess(
            i18n.global.t(`snackbar.messages.roles.${RolesAction.DELETE}`, {
              name: data.item.name,
            })
          );
          this.resetPagination();
          await this.getRoles();
        } catch (e) {
          console.error(e);
        }
      },
      async editRole(
        payload: DialogDataConfig<RoleDetails & { id: string; workspaceId?: string }>
      ): Promise<void> {
        const requestPayload = removeSpecificPermissionsFromRole(payload.item);
        const getRequestConfig = (): AxiosRequestConfig => {
          switch (roleType) {
            case RoleType.MSP:
              return {
                ...api.editMspRole(payload.item.id, requestPayload),
              };
            case RoleType.GLOBAL:
              return {
                ...api.editGlobalRole(
                  payload.item.id,
                  convertMspPortalToBackendGlobalSpecialPermissions(
                    requestPayload as RolePermissions<GlobalMspPortalPermissions>
                  )
                ),
              };
            case RoleType.WORKSPACE:
            default:
              return {
                ...api.editRole(payload.item.id, requestPayload),
              };
          }
        };
        try {
          const { data } = await axiosInstance.request(getRequestConfig());
          useSnackbarStore().addGenericSuccess(
            i18n.global.t(`snackbar.messages.roles.${RolesAction.EDIT}`, {
              name: payload.item.name,
            })
          );
          this.resetPagination();
          await this.getRoles();
          this.roleDetails[payload.item.id].permissions =
            roleType === RoleType.GLOBAL
              ? convertBackendSpecialPermissionsToMspPortalPermissions(data)
              : data;
        } catch (e) {
          console.error(e);
        }
      },
    },
  })();
};

/**
 * Removes permission to assign roles because only `Super Admin` can assign roles.
 * @param item
 */
function removeSpecificPermissionsFromRole(item: RolePermissions): RolePermissions {
  const clonedItem = cloneDeep(item);
  if (clonedItem[GlobalRoleScopes.GLOBAL_SCOPE]) {
    set(
      clonedItem,
      `${GlobalRoleScopes.GLOBAL_SCOPE}.${GlobalRoleScopeSection.GLOBAL_ADMIN_USERS}.editAccessModePermission.assignRoles`,
      false
    );
  }
  if (clonedItem[MspRolePermissionScopes.MSP_PORTAL_SCOPE]) {
    set(
      clonedItem,
      `${MspRolePermissionScopes.MSP_PORTAL_SCOPE}.${MspPortalScopeSection.MSP_ADMIN_USERS}.editAccessModePermission.assignRoles`,
      false
    );
  }
  set(
    clonedItem,
    `${RolePermissionsScope.WORKSPACE_MANAGEMENT}.${WorkspaceManagementScopeSections.ADMIN_USERS}.editAccessModePermission.assignRoles`,
    false
  );
  return clonedItem;
}
/**
 * Converts global special permissions to MSP portal-specific permissions.
 *
 * This function transfers certain permissions from the global scope to the MSP portal scope.
 * Specifically, it moves the following permissions: 'extendTrial', 'convertWorkspace',
 * 'restoreWorkspace', and 'createRegularWorkspace'.
 *
 * @param {RolePermissions} data - The role permissions data containing both global scope and MSP
 * portal scope permissions.
 * @returns {RolePermissions<GlobalMspPortalPermissions>} - The updated role permissions with the
 * specified permissions moved to the MSP portal scope.
 */
export function convertBackendSpecialPermissionsToMspPortalPermissions(
  data: RolePermissions
): RolePermissions<GlobalMspPortalPermissions> {
  const specialPermissions = data.globalScope.specialPermissions;
  const editAccessModePermission = data.mspPortalScope.mspPortalAccess
    .editAccessModePermission as GlobalMspPortalEditAccessModePermission;
  const accessMode = data.mspPortalScope.mspPortalAccess.accessMode;

  const keysToMove = [
    "extendTrial",
    "convertWorkspace",
    "restoreWorkspace",
    "createRegularWorkspace",
  ] as const;

  keysToMove.forEach((key) => {
    if (accessMode === RolePermissionAccessMode.EDIT && editAccessModePermission) {
      editAccessModePermission[key] = specialPermissions[key];
    }
    delete specialPermissions[key];
  });

  data.mspPortalScope.mspPortalAccess.editAccessModePermission =
    accessMode === RolePermissionAccessMode.EDIT ? editAccessModePermission : undefined;
  return data as RolePermissions<GlobalMspPortalPermissions>;
}
/**
 * Converts MSP portal-specific special permissions to global special permissions.
 *
 * This function transfers certain permissions from the MSP portal scope to the global scope.
 * Specifically, it moves the following permissions: 'extendTrial', 'convertWorkspace',
 * 'restoreWorkspace', and 'createRegularWorkspace'.
 *
 * @param {RolePermissions<GlobalMspPortalPermissions>} data - The role permissions data containing
 * both global scope and MSP portal scope permissions.
 * @returns {RolePermissions} - The updated role permissions with the specified permissions
 * moved to the global scope.
 */
export function convertMspPortalToBackendGlobalSpecialPermissions(
  data: RolePermissions<GlobalMspPortalPermissions>
): RolePermissions {
  const specialPermissions = data.globalScope.specialPermissions;
  const editAccessModePermission = data.mspPortalScope.mspPortalAccess.editAccessModePermission!;
  const accessMode = data.mspPortalScope.mspPortalAccess.accessMode;

  const keysToMove = [
    "extendTrial",
    "convertWorkspace",
    "restoreWorkspace",
    "createRegularWorkspace",
  ] as const;

  keysToMove.forEach((key) => {
    specialPermissions[key] = editAccessModePermission[key];
    delete editAccessModePermission[key];
  });

  // Ensure the structure is correctly placed back into globalScope
  data.globalScope.specialPermissions = specialPermissions;
  data.mspPortalScope.mspPortalAccess.editAccessModePermission =
    accessMode === RolePermissionAccessMode.EDIT ? editAccessModePermission : undefined;

  return data as RolePermissions;
}
