<template>
  <v-card class="h-100" rounded="lg" elevation="0">
    <v-row no-gutters>
      <v-col class="pa-6" data-testid="tickets-breakdown-widget-per-modules">
        <div v-if="ticketsPerModuleData.loading">
          <v-skeleton-loader type="heading" class="mb-2 bg-transparent" />
          <v-skeleton-loader type="list-item-two-line@4" class="mb-2 bg-transparent" />
        </div>

        <div v-else-if="ticketsPerModuleData.data">
          <div class="subtitle2 mb-5">
            {{ $t("mspDashboard.widgets.ticketsBreakdownWidget.ticketsPerModule") }}
          </div>

          <template v-for="(count, module, index) in sortedTicketsPerModule" :key="module">
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex align-center pb-3 pt-4">
                <v-icon size="24" :icon="iconToModuleMap[module]"></v-icon>
                <div class="body2 ml-2">
                  {{ $t(`tickets.filters.widgets.${module}`) }}
                </div>
              </div>
              <div class="subtitle2" :class="{ 'text-red-dark': count > 0 }">
                {{ count }}
              </div>
            </div>
            <v-divider
              v-if="index !== Object.values(sortedTicketsPerModule).length - 1"
              class="border-opacity-100"
              color="indigo-faint"
            ></v-divider>
          </template>
        </div>

        <div
          v-else
          class="h-100 d-flex align-center justify-center flex-column"
          data-testid="widget-error-state"
        >
          <v-icon icon="$alertTriangle" size="80"></v-icon>
          <span class="subtitle1 mb-4">
            {{ $t("mspDashboard.widgets.ticketsBreakdownWidget.error") }}
          </span>
          <div
            class="coro-link"
            @click="$emit('retry-fetch-data', TicketsBreakdownWidgetPart.PER_MODULE)"
          >
            {{ $t("general.retry") }}
          </div>
        </div>
      </v-col>
      <v-divider color="indigo-faint" opacity="100" inset vertical class="mx-4 my-6" />
      <v-col class="pa-6" data-testid="tickets-breakdown-widget-per-workspace">
        <div v-if="ticketsPerWorkspaceData.loading">
          <v-skeleton-loader type="heading" class="mb-2 bg-transparent" />
          <v-skeleton-loader type="list-item-two-line@4" class="mb-2 bg-transparent" />
        </div>

        <div v-else-if="ticketsPerWorkspaceData.data">
          <div class="subtitle2 mb-5">
            {{ $t("mspDashboard.widgets.ticketsBreakdownWidget.ticketsPerWorkspace") }}
          </div>

          <template
            v-for="({ workspace, ticketsCount }, index) in ticketsPerWorkspaceData.data"
            :key="workspace.workspaceId"
          >
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex align-center pb-3 pt-4">
                <v-icon size="24" :icon="iconToWorkspaceMap[workspace.type]"></v-icon>
                <div class="body2 ml-2">
                  {{ workspace.name }}
                </div>
              </div>
              <div class="subtitle2" :class="{ 'text-red-dark': ticketsCount > 0 }">
                {{ ticketsCount }}
              </div>
            </div>
            <v-divider
              v-if="index !== ticketsPerWorkspaceData.data.length - 1"
              class="border-opacity-100"
              color="indigo-faint"
            ></v-divider>
          </template>
        </div>

        <div
          v-else
          class="h-100 d-flex align-center justify-center flex-column"
          data-testid="widget-error-state"
        >
          <v-icon icon="$alertTriangle" size="80"></v-icon>
          <span class="subtitle1 mb-4">
            {{ $t("mspDashboard.widgets.ticketsBreakdownWidget.error") }}</span
          >
          <div
            class="coro-link"
            @click="$emit('retry-fetch-data', TicketsBreakdownWidgetPart.PER_MODULE)"
          >
            {{ $t("general.retry") }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { DataValue } from "@/types.ts";
import type { ModuleTicketsStats, WorkspaceTicket } from "@/_store/msp/msp-dashboard.module.ts";
import { RouteName } from "@/constants/routes.ts";
import { sortObjectByValue } from "@/_helpers/utils.ts";
import { WorkspaceType } from "@/constants/workplaces.ts";
import { TicketsModuleFilter } from "@/constants/tickets.ts";

export type TicketsBreakdownWidgetData = {
  ticketsPerModuleData: DataValue<ModuleTicketsStats>;
  ticketsPerWorkspaceData: DataValue<WorkspaceTicket[]>;
};

export enum TicketsBreakdownWidgetPart {
  PER_WORKSPACE = "ticketsBreakdownPerWorkspace",
  PER_MODULE = "ticketsBreakdownPerModule",
}
const iconToModuleMap: Record<string, string> = {
  [TicketsModuleFilter.CLOUD_SECURITY_WIDGET]: `$cloudSecurityXs`,
  [TicketsModuleFilter.EMAILS_WIDGET]: `$mailUnreadXs`,
  [TicketsModuleFilter.DEVICE_WIDGET]: `$endpointXs`,
  [TicketsModuleFilter.ENDPOINT_DATA_GOVERNANCE]: `$endpointDataGovernanceXs`,
  [TicketsModuleFilter.USER_DATA_GOVERNANCE]: `$userDataGovernanceXs`,
  [TicketsModuleFilter.EDR]: `$edrXs`,
};

const iconToWorkspaceMap: Record<string, string> = {
  [WorkspaceType.REGULAR]: "icon-regular-plan",
  [WorkspaceType.CHANNEL]: "icon-parent-plan",
  [WorkspaceType.CHILD]: "icon-child-plan",
};

export default defineComponent({
  props: {
    widgetData: {
      type: Object as PropType<TicketsBreakdownWidgetData>,
      required: true,
    },
  },
  emits: ["retry-fetch-data"],
  setup(props) {
    const ticketsPerModuleData = computed(() => props.widgetData.ticketsPerModuleData);
    const ticketsPerWorkspaceData = computed(() => props.widgetData.ticketsPerWorkspaceData);
    const sortedTicketsPerModule = computed(() => {
      return ticketsPerModuleData.value.data
        ? sortObjectByValue(ticketsPerModuleData.value.data)
        : {};
    });

    return {
      ticketsPerModuleData,
      ticketsPerWorkspaceData,
      RouteName,
      sortObjectByValue,
      iconToModuleMap,
      iconToWorkspaceMap,
      sortedTicketsPerModule,
      TicketsBreakdownWidgetPart,
    };
  },
});
</script>
<style lang="scss">
.icon-alert-triangle:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}
</style>
