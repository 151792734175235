<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
    >
      <div class="headline5 mb-6 settings-header-with-btn">
        {{ $t("mspLabelsPage.title") }}
        <v-btn color="primary" data-testid="label-add-btn" rounded size="large" @click="addLabel()">
          <v-icon color="white" class="mr-2" icon="$add"> </v-icon>
          <span>{{ $t("mspLabelsPage.addLabel") }}</span>
        </v-btn>
      </div>
      <table-wrapper class="label-table">
        <div class="coronet-table--loader">
          <v-progress-linear v-if="loading" indeterminate height="2px" />
          <v-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left caption name-block">
                    {{ $t("general.name") }}
                  </th>
                  <th class="text-left caption">
                    {{ $t("mspLabelsPage.workspaceCount") }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(label, index) in labels" :key="label.name">
                  <td class="name-block">
                    <div class="d-flex align-center">
                      <div
                        class="color-preview"
                        :style="{ 'background-color': label.color }"
                        :data-testid="`labels-table-color-row-${index}`"
                      />
                      <div
                        class="coro-subtitle-2 ml-4"
                        :data-testid="`labels-table-name-row-${index}`"
                      >
                        {{ label.name }}
                      </div>
                    </div>
                  </td>
                  <td :data-testid="`labels-table-count-row-${index}`">
                    <span
                      v-if="label.workspaceCount"
                      role="button"
                      class="coro-link"
                      @click="goToWorkspacesTable(label)"
                    >
                      {{ label.workspaceCount }}
                    </span>
                    <span v-else class="text-indigo-medium">
                      {{ label.workspaceCount || 0 }}
                    </span>
                  </td>
                  <td class="text-right">
                    <v-menu offset-y location="bottom right">
                      <template #activator="{ props }">
                        <v-btn
                          :icon="true"
                          :data-testid="`labels-table-actions-btn-row-${index}`"
                          v-bind="props"
                          variant="text"
                        >
                          <v-icon icon="$dots"></v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="action in actionsList"
                          :key="action"
                          :data-testid="`labels-table-action-${action}`"
                          @click="onActionClick(action, label)"
                        >
                          <v-list-item-title>
                            <span>
                              {{ $t(`devicesSettings.labelsTab.actions.${action}`) }}
                            </span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
                <tr v-if="!labels.length">
                  <td colspan="3">
                    <div class="d-flex align-center justify-center w-100">
                      {{ $t("general.noData") }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </div>
      </table-wrapper>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { LabelAction } from "@/constants/devices.ts";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader.ts";
import { defineComponent, onMounted, watch } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { FilterContext, useFiltersStore } from "@/_store/filters.module.ts";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
} from "@/_helpers/utils.ts";
import AddLabelModal from "@/components/modals/AddLabelModal.vue";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import {
  MspPortalScopeSection,
  MspRolePermissionScopes,
  RolePermissionsScope,
} from "@/_store/roles.module.ts";
import { useI18n } from "vue-i18n";
import { RouteName } from "@/constants/routes.ts";
import { type MspLabel, useMspLabelsStore } from "@/_store/msp/msp-labels.module.ts";
import { ModalWidth } from "@/constants/modals.ts";
import { useWorkspacesStore } from "@/_store/workspaces.module.ts";
import { isMspActionRestricted } from "@/_helpers/msp-permissions.ts";

export default defineComponent({
  components: {
    TableWrapper,
  },
  setup() {
    const i18n = useI18n();
    const mspLabelsStore = useMspLabelsStore();
    const { labels, showSkeletonLoader, loading } = storeToRefs(mspLabelsStore);
    const { workspaceAndPermissionsUpdatedTimestamp } = storeToRefs(useWorkspacesStore());
    const router = useRouter();
    const filtersStore = useFiltersStore();
    const dialogsStore = useDialogsStore();

    function onActionClick(action: LabelAction, item?: MspLabel) {
      switch (action) {
        case LabelAction.EDIT_LABEL:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              component: AddLabelModal,
              item,
              action,
              width: ModalWidth.X_SMALL,
              callback: mspLabelsStore.editLabel,
              disable: isMspActionRestricted(
                MspRolePermissionScopes.MSP_PORTAL_SCOPE,
                MspPortalScopeSection.MSP_WORKSPACE_LABELS
              ),
            })
          );
          break;
        case LabelAction.DELETE_LABEL:
        default:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              item,
              callback: mspLabelsStore.deleteLabel,
              text: i18n.t(`modals.${action}.description`, { ...item }, item?.workspaceCount ?? 0),
              disable: isMspActionRestricted(
                MspRolePermissionScopes.MSP_PORTAL_SCOPE,
                MspPortalScopeSection.MSP_WORKSPACE_LABELS
              ),
            })
          );
      }
    }

    function goToWorkspacesTable(label: MspLabel) {
      filtersStore.setFilters(FilterContext.MSP, {
        labels: [label.id],
        search: null,
        types: [],
        subscriptionTypes: [],
        addons: [],
      });
      router.push({ name: RouteName.MSP_OVERVIEW_PAGE });
    }

    const addLabel = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        component: AddLabelModal,
        action: LabelAction.CREATE_LABEL,
        width: ModalWidth.X_SMALL,
        callback: mspLabelsStore.createLabel,
        disable: isMspActionRestricted(
          MspRolePermissionScopes.MSP_PORTAL_SCOPE,
          MspPortalScopeSection.MSP_WORKSPACE_LABELS
        ),
      });
      dialogsStore.openDialog(dialogConfig);
    };

    onMounted(async () => {
      await router.replace({ query: undefined });
      await mspLabelsStore.getLabels(true);
    });

    watch(workspaceAndPermissionsUpdatedTimestamp, async (newVal) => {
      if (newVal) {
        await mspLabelsStore.getLabels(true);
      }
    });

    return {
      RolePermissionsScope,
      loading,
      labels,
      showSkeletonLoader,
      LabelAction,
      coronetSkeletonLoaderTypes,
      actionsList: [LabelAction.EDIT_LABEL, LabelAction.DELETE_LABEL],
      onActionClick,
      goToWorkspacesTable,
      addLabel,
      breadCrumbsItems: [
        {
          title: `‹ ${i18n.t("mspControlPanel.title")}`,
          disabled: false,
          to: { name: RouteName.MSP_CONTROL_PANEL_PAGE },
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .color-preview {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    border-radius: 4px !important;
  }
}
</style>
