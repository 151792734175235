<template>
  <div class="page-break-avoid">
    <div class="d-flex align-center mb-3">
      <v-icon size="40" :icon="iconToModuleMap[protectionModule]"></v-icon>
      <div class="headline6" data-testid="executive-summary-report-module-overview-title">
        {{ $t(`reports.executiveSummaryReport.${protectionModule}.title`) }}
      </div>
    </div>
    <div
      class="body1 mb-6 flex-column"
      data-testid="executive-summary-report-module-overview-description"
    >
      <div>
        {{ $t(`reports.executiveSummaryReport.${protectionModule}.description`) }}
      </div>
      <div
        v-if="isModuleDisabled(protectionModule)"
        class="body1 text--semitransparent mt-2 d-flex align-center"
        data-testid="executive-summary-report-module-overview-disabled-module-notification"
      >
        <v-icon class="mr-1" icon="$warning" size="24" />
        <span>{{ $t(`reports.executiveSummaryReport.disabledModuleNotification`) }}</span>
      </div>
    </div>
  </div>

  <v-row class="d-flex" no-gutters>
    <v-col
      class="page-break-avoid pr-10"
      v-if="columnsLayout.totalTicketsColumns"
      :cols="columnsLayout.totalTicketsColumns"
    >
      <div
        class="d-flex flex-column"
        data-testid="executive-summary-report-module-overview-items-processed"
      >
        <div class="big-number mb-2 text-primary">
          {{ totalItemsProcessed }}
        </div>
        <div class="subtitle2 mb-2">
          {{ totalItemsTitle }}
        </div>
      </div>
    </v-col>
    <v-col
      v-if="columnsLayout.vulnerabilitiesColumns"
      :cols="columnsLayout.vulnerabilitiesColumns"
      class="pr-10"
    >
      <div class="subtitle1">
        {{ $t("reports.executiveSummaryReport.ticketsByType") }}
      </div>
      <div
        v-for="{ trigger, count } in alphabeticallySortedVulnerabilities"
        :key="trigger"
        class="page-break-avoid es-report-module-overview__ticket-type"
      >
        <div class="d-flex justify-space-between align-center h-100 body2 py-3">
          <span> {{ $t(`tickets.eventTriggers.${trigger}`) }} </span>
          <span :class="{ 'text-red-dark': count > 0 }">
            {{ count }}
          </span>
        </div>
        <v-divider class="border-opacity-100" color="indigo-faint" />
      </div>
    </v-col>
    <v-col v-if="columnsLayout.topViolatorsColumns" :cols="columnsLayout.topViolatorsColumns">
      <div v-if="hasViolators" class="subtitle1">
        {{ $t(`reports.executiveSummaryReport.${protectionModule}.topViolatorsTitle`) }}
      </div>
      <div
        v-for="(count, violator) in violators"
        :key="violator"
        class="page-break-avoid es-report-module-overview__violator"
      >
        <div class="d-flex justify-space-between align-center h-100 body2 py-3">
          <div class="body2 word-break-all">
            {{ violator }}
          </div>
          <div class="body2 ml-4">
            {{ count }}
          </div>
        </div>
        <v-divider class="border-opacity-100" color="indigo-faint" />
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { CoroIcons } from "@/constants/coro-icon";
import { SubscriptionModule } from "@/constants/workplaces";
import { isModuleDisabled } from "@/_helpers/utils";
import type { TicketTrigger } from "@/constants/tickets";
import { useI18n } from "vue-i18n";
import { i18n } from "@/plugins/i18n.ts";

const iconToModuleMap: Record<string, string> = {
  [SubscriptionModule.CLOUD_SECURITY]: `$${CoroIcons.CLOUD_SECURITY}`,
  [SubscriptionModule.EMAIL_SECURITY]: `$${CoroIcons.EMAILS}`,
  [SubscriptionModule.ENDPOINT_SECURITY]: `$${CoroIcons.ENDPOINT_SECURITY}`,
  [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: `$${CoroIcons.ENDPOINT_DATA_GOVERNANCE}`,
  [SubscriptionModule.USER_DATA_GOVERNANCE]: `$${CoroIcons.USER_DATA_GOVERNANCE}`,
  [SubscriptionModule.EDR]: `$${CoroIcons.EDR}`,
};

export interface ReportModuleOverviewColumns {
  totalTicketsColumns: number;
  vulnerabilitiesColumns: number;
  topViolatorsColumns: number;
}

export default defineComponent({
  props: {
    protectionModule: {
      type: String as PropType<SubscriptionModule>,
      required: true,
    },
    vulnerabilities: {
      type: Object as PropType<{ [key in TicketTrigger]: number }>,
      required: true,
    },
    violators: {
      type: Object as PropType<{ [email: string]: number }>,
      required: false,
    },
    columnsLayout: {
      type: Object as PropType<ReportModuleOverviewColumns>,
      required: true,
    },
    totalItemsProcessed: {
      type: Number,
      required: false,
    },
    totalItemsTitle: {
      type: String,
      default() {
        return i18n.global.t("reports.managedServicesSummaryReport.totalTicketsResolved");
      },
    },
    showZeroValues: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const i18n = useI18n();

    const hasViolators = computed(() =>
      props.violators ? !!Object.values(props.violators).length : false
    );

    const alphabeticallySortedVulnerabilities = computed(() => {
      return Object.entries(props.vulnerabilities)
        .map(([trigger, count]) => {
          return {
            trigger,
            count,
          };
        })
        .sort((a, b) =>
          i18n
            .t(`tickets.eventTriggers.${a.trigger}`)
            .localeCompare(i18n.t(`tickets.eventTriggers.${b.trigger}`))
        )
        .filter((v) => {
          if (props.showZeroValues) {
            return true;
          }
          return v.count > 0;
        });
    });

    return {
      iconToModuleMap,
      hasViolators,
      alphabeticallySortedVulnerabilities,
      isModuleDisabled,
    };
  },
});
</script>

<style lang="scss" scoped>
.es-report-module-overview {
  &__ticket-type {
    height: 63px;
  }

  &__violator {
    height: 63px;
  }
}

:deep(*) {
  .icon-warning:before {
    color: rgb(var(--v-theme-primary)) !important;
  }
}
</style>
