import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { Pagination } from "@/types";
import type { DeviceLabel } from "@/_store/devices-settings.module";
import { ConfigType, Protocol } from "@/constants/network";
import type { AxiosError } from "axios";
import type { ErrorPayload } from "@/_store/errors.module.ts";

export interface ZtnaRule {
  id?: string;
  name: string;
  description: string;
  configs: RuleConfig[];
  labels: Array<DeviceLabel>;
}

export interface RuleConfig {
  id?: string;
  type: ConfigType;
  value: string;
  ports: Array<string | number>;
  protocol: Protocol | null;
  domains: {
    [key: string]: string;
  };
}

interface ZtnaState {
  loading: boolean;
  rules: ZtnaRule[];
  pagination: Pagination;
  total: number;
  enabled: boolean;
}

const defaultZtnaState: ZtnaState = {
  loading: false,
  enabled: false,
  rules: [],
  pagination: {
    page: 0,
    pageSize: 25,
  },
  total: 0,
};

export const useZtnaStore = defineStore("ztna", {
  state: () => ({ ...defaultZtnaState }),
  actions: {
    async initZtna() {
      try {
        await this.getZtnaStatus();
      } catch (e) {
        console.error(e);
      }
    },

    async toggleZtnaEnabled(enable: boolean): Promise<void> {
      this.loading = true;
      const requestConfig = api.toggleZtnaEnabled(enable);
      try {
        await axiosInstance.request(requestConfig);
        await this.getZtnaStatus();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getZtnaStatus() {
      this.loading = true;
      const requestConfig = api.ztnaStatus();
      try {
        const {
          data: { enabled },
        } = await axiosInstance.request(requestConfig);
        this.enabled = enabled;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getZtnaRules() {
      this.loading = true;
      const requestConfig = api.ztnaRules();
      try {
        const { data } = await axiosInstance.request({
          ...requestConfig,
          ...this.pagination,
          params: { ...this.pagination },
        });
        this.rules = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async updateZtnaRule(rule: ZtnaRule) {
      this.loading = true;
      const requestConfig = api.ztnaRules(rule.id);
      try {
        await axiosInstance.request({
          ...requestConfig,
          method: "PUT",
          data: convertZtnaDataForBe(rule),
        });
        await this.getZtnaRules();
      } catch (e) {
        console.error(e);
        const error = e as AxiosError<{ errors: ErrorPayload[] }>;
        if (error.response?.data?.errors.length) {
          throw error.response.data?.errors;
        }
      } finally {
        this.loading = false;
      }
    },
    async deleteZtnaRule({ id }: ZtnaRule) {
      this.loading = true;
      const requestConfig = api.ztnaRules(id);
      try {
        await axiosInstance.request({ ...requestConfig, method: "DELETE" });
        await this.getZtnaRules();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async saveZtnaRule(data: ZtnaRule) {
      this.loading = true;
      const requestConfig = api.ztnaRules();
      try {
        await axiosInstance.request({
          ...requestConfig,
          method: "POST",
          data: convertZtnaDataForBe(data),
        });
        await this.getZtnaRules();
      } catch (e) {
        console.error(e);
        const error = e as AxiosError<{ errors: ErrorPayload[] }>;
        if (error.response?.data?.errors.length) {
          throw error.response.data.errors;
        }
      } finally {
        this.loading = false;
      }
    },
  },
});

// @TODO Add unit-tests for ZTNA related stuff
function convertZtnaDataForBe(data: ZtnaRule) {
  return {
    ...data,
    configs: data.configs.map((config) => ({
      ...config,
      ports: Array.isArray(config.ports) ? config.ports.map((port) => Number(port)) : [],
    })),
  };
}
