<template>
  <table-wrapper>
    <v-data-table-server
      v-model:expanded="expanded"
      class="coronet-table--highlitable mb-10"
      :items="items"
      :headers="headers"
      :loading="loading"
      :fixed-header="true"
      :show-select="false"
      :show-expand="true"
      :items-per-page="pagination.pageSize"
      :items-length="totalItems"
      item-value="id"
      @update:options="handlePageChange"
    >
      <template #item="{ item, index, isExpanded, toggleExpand, internalItem }">
        <tr :id="`${index}`" :key="index">
          <td class="w-5">
            <v-icon
              class="mr-2 cursor-pointer"
              icon="$dropdown"
              color="primary"
              :class="{ rotated: isExpanded(internalItem) }"
              @click="toggleExpand(internalItem)"
            />
          </td>
          <td>
            <span class="subtitle2">{{ item.name }}</span>
          </td>
          <td>
            <div v-if="!!item.labels.length" class="chip-container">
              <v-chip
                v-for="(label, index) in item.labels"
                variant="flat"
                size="default"
                :color="label.color"
                rounded="sm"
                :text="label.name"
                :key="index"
              />
            </div>
            <div v-else>
              <v-icon icon="$warning" />
              <span class="ml-2 text-red" v-text="$t(`network.virtualOffice.ztna.table.noLabel`)" />
            </div>
          </td>
          <td>
            <v-menu :disabled="loading" @click.stop>
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="$dots" />
              </template>
              <v-list>
                <template v-for="(action, index) in actionsList" :key="index">
                  <v-list-item @click="handleItemAction(action, item)">
                    <span v-text="$t(`network.virtualOffice.ztna.actions.${action}`)" />
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
      <template #expanded-row="{ item, columns }">
        <td :colspan="columns.length">
          <div class="pl-16 py-6 pr-4">
            <div v-if="item.description" class="mb-4">
              <div class="subtitle2 mb-2">{{ $t("general.description") }}</div>
              <div class="body2">{{ item.description }}</div>
            </div>
            <div>
              <h3 class="subtitle2 mb-2">
                {{ $t("network.virtualOffice.ztna.table.resourceConfigurations") }}
              </h3>
              <table-wrapper>
                <v-table class="mb-4 nested-table">
                  <thead>
                    <tr>
                      <th>{{ $t("network.virtualOffice.ztna.table.headers.resourceType") }}</th>
                      <th>{{ $t("general.protocol") }}</th>
                      <th>{{ $t("general.port", 0) }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(config, index) in item.configs" :key="index">
                      <td>
                        <div class="d-flex flex-column">
                          <span class="subtitle2">{{ formatResourceValue(config) }}</span>
                          <span
                            v-if="
                              config.type === ConfigType.DOMAIN &&
                              Object.keys(config.domains).length
                            "
                            class="caption"
                          >
                            IPs: {{ Object.values(config.domains).join(" / ") }}
                          </span>
                        </div>
                      </td>
                      <td>{{ formatProtocol(config.protocol) }}</td>
                      <td>{{ formatPorts(config.ports) }}</td>
                    </tr>
                  </tbody>
                </v-table>
              </table-wrapper>
            </div>
          </div>
          <v-divider />
        </td>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { ConfigType, ZtnaAction } from "@/constants/network";
import type { RuleConfig, ZtnaRule } from "@/_store/network/ztna.module";
import type { Pagination } from "@/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    loading: { type: Boolean as PropType<boolean>, default: false },
    items: { type: Array as PropType<ZtnaRule[]>, required: true },
    totalItems: { type: Number, required: true },
    pagination: { type: Object as PropType<Pagination>, required: true },
  },

  emits: ["last-item-reached", "page-change", "action-click"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const expanded = ref<string[]>([]);
    const actionsList = [ZtnaAction.DELETE_ZTNA_RULE, ZtnaAction.EDIT_ZTNA_RULE];

    const headers = [
      { title: "", key: "data-table-expand", sortable: false },
      {
        title: t("network.virtualOffice.ztna.table.headers.resourceName"),
        key: "resourceName",
        sortable: false,
      },
      {
        title: t("network.virtualOffice.ztna.table.headers.devices"),
        key: "devices",
        sortable: false,
      },
      { key: "actions", sortable: false, width: "50px" },
    ];

    const handlePageChange = (payload: Pagination): void => {
      emit("page-change", payload);
    };

    const formatResourceValue = (config: RuleConfig) => {
      let value = config.value;

      if (config.type === ConfigType.RANGE) {
        value = value.replace("-", " - ");
      } else if (config.type === ConfigType.DOMAIN) {
        value = Object.keys(config.domains)
          ? Object.keys(config.domains).join(" / ")
          : config.value;
      }

      return value;
    };

    const formatPorts = (ports: Array<string | number>) => {
      return ports?.length ? ports.join(", ") : "";
    };

    const formatProtocol = (protocol: string | null) => {
      return protocol ? t(`network.virtualOffice.ztna.protocols.${protocol}`) : "";
    };

    const handleItemAction = (action: ZtnaAction, item: ZtnaRule): void => {
      emit("action-click", { action, item });
    };

    return {
      formatResourceValue,
      formatProtocol,
      handlePageChange,
      handleItemAction,
      headers,
      actionsList,
      ConfigType,
      expanded,
      formatPorts,
    };
  },
});
</script>

<style scoped lang="scss">
.chip-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  gap: 4px;
}

.v-table {
  box-shadow: unset !important;
}

.nested-table {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}
</style>
