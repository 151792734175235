<template>
  <table-wrapper>
    <v-data-table-server
      class="coronet-table--highlightable"
      :items="items"
      v-model="selectedItems"
      :fixed-header="true"
      :page="pagination.page + 1"
      :loading="loading"
      :items-per-page="pagination.pageSize"
      :items-length="total"
      show-select
      @update:options="
        $emit('page-changed', {
          pageSize: $event.itemsPerPage,
          page: $event.page - 1,
        })
      "
      :no-data-text="$t('manageWorkspaces.noData')"
      return-object
    >
      <template #headers="{ allSelected, selectAll, someSelected }">
        <tr>
          <th>
            <v-checkbox-btn
              :indeterminate="someSelected && !allSelected"
              :model-value="allSelected"
              color="primary"
              density="compact"
              @update:model-value="selectAll(!allSelected)"
            ></v-checkbox-btn>
          </th>
          <template v-if="selectedItems.length === 0">
            <th>
              {{ $t("manageWorkspaces.table.name") }}
            </th>
            <th>
              {{ $t("manageWorkspaces.table.subscription") }}
            </th>
            <th>
              {{ $t("manageWorkspaces.table.plan") }}
            </th>
            <th>
              {{ $t("manageWorkspaces.table.createdOn") }}
            </th>
            <th>
              <div>{{ $t("manageWorkspaces.table.protectedUsers") }} /</div>
              <div>{{ $t("manageWorkspaces.table.potentialUsers") }}</div>
            </th>
            <th>
              <span v-html="$t('manageWorkspaces.table.protectedDevices')" />
            </th>
            <th>
              {{ $t("manageWorkspaces.table.tickets") }}
            </th>
            <th></th>
          </template>
          <template v-else>
            <th :colspan="8">
              <span>{{ selectedItems.length }} {{ $t("general.selected") }}</span>
              <v-menu v-if="!!selectedItems.length" offset-y bottom right>
                <template #activator="{ props }">
                  <v-btn
                    data-testid="bulk-actions-button"
                    class="ml-8"
                    rounded
                    color="primary"
                    v-bind="props"
                  >
                    {{ $t("general.actions") }}
                    <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="action in bulkActions"
                    :key="action"
                    @click="onBulkActionClick(action)"
                  >
                    <v-list-item-title>
                      {{ $t(`manageWorkspaces.actions.${action}`) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </th>
          </template>
        </tr>
      </template>
      <template #item="{ item, index, internalItem, isSelected, toggleSelect }">
        <tr :key="index" :data-testid="`table-row-${index}`" class="msp-table-row">
          <td class="checkbox-col">
            <v-checkbox-btn
              :model-value="isSelected(internalItem)"
              color="primary"
              density="compact"
              @update:model-value="toggleSelect(internalItem)"
            ></v-checkbox-btn>
          </td>
          <td class="workspace-name-col" :data-testid="`msp-table-name-col-row-${index}`">
            <div class="d-flex align-center">
              <v-icon class="workspace-icon" size="40">{{ iconsMap[item.type] }}</v-icon>
              <div class="ml-3">
                <div class="subtitle2 white-space-normal">
                  {{ item.name }}
                </div>
                <div v-if="item.parentName" class="body2 white-space-normal">
                  {{ $t(`manageWorkspaces.workspaceTypes.${item.type}`) }} /
                  {{ item.parentName }}
                </div>
                <div v-else class="body2">
                  {{ $t(`manageWorkspaces.workspaceTypes.${item.type}`) }}
                </div>
                <div class="caption text-indigo-medium white-space-normal">
                  {{ item.workspaceId }}
                </div>
                <div class="d-flex align-center w-100 mt-1">
                  <v-chip
                    v-for="label in item.labels"
                    :key="label.id"
                    variant="flat"
                    label
                    size="x-small"
                    :color="label.color"
                    class="mr-1"
                  >
                    <div class="d-flex caption align-center">
                      {{ label.name }}
                    </div>
                  </v-chip>
                </div>
              </div>
            </div>
          </td>
          <td :data-testid="`msp-table-plan-subscription-row-${index}`">
            <div class="body2 subscription-text" v-if="getSubscription(item).bundlesCount > 0">
              {{ $t(`subscriptions.bundles.${getSubscription(item).bundles[0]}`) }}
              <v-tooltip
                v-if="getSubscription(item).bundlesCount > 1"
                open-delay="300"
                location="top"
              >
                <template #activator="{ props }">
                  +<span class="item-clickable text-decoration-underline" v-bind="props">{{
                    getSubscription(item).bundlesCount - 1
                  }}</span>
                </template>
                <ul>
                  <li v-for="bundle in getSubscription(item).bundles" :key="bundle">
                    {{ $t(`subscriptions.bundles.${bundle}`) }}
                  </li>
                </ul>
              </v-tooltip>
            </div>
            <div
              class="caption text-indigo-medium subscription-text"
              v-if="getSubscription(item).modulesCount > 0"
            >
              {{ $t(`subscriptions.modules.${getSubscription(item).modules[0]}`) }}
              <v-tooltip
                v-if="getSubscription(item).modulesCount > 1"
                open-delay="300"
                location="top"
              >
                <template #activator="{ props }">
                  +<span class="item-clickable text-decoration-underline" v-bind="props">{{
                    getSubscription(item).modulesCount - 1
                  }}</span>
                </template>
                <ul>
                  <li v-for="module in getSubscription(item).modules" :key="module">
                    {{ $t(`subscriptions.modules.${module}`) }}
                  </li>
                </ul>
              </v-tooltip>
            </div>
          </td>
          <td class="subscription-col" :data-testid="`msp-table-plan-col-row-${index}`">
            <div>
              {{ $t(`subscriptions.plans.${item.subscriptionType}`) }}
            </div>
            <div v-if="item.since" class="caption text-indigo-medium">
              {{ format(item.since, "ll") }}
            </div>
          </td>
          <td class="creation-time-col" :data-testid="`msp-table-daysLeft-col-row-${index}`">
            <div class="body2 creation-text" v-if="item.creationTime">
              {{ format(item.creationTime, "ll") }}
            </div>
            <template v-else> --- </template>
          </td>
          <td class="users-col" :data-testid="`msp-table-protectedUsers-col-row-${index}`">
            <span class="body2">
              <span
                class="body2"
                :class="{ 'coro-link': item.protectedUsers > 0 }"
                @click="viewUsers(item)"
              >
                {{ item.protectedUsers }}
              </span>
              / {{ item.protectableUsers }}
            </span>
          </td>
          <td class="devices-col" :data-testid="`msp-table-protectedDevices-col-row-${index}`">
            <span
              class="body2"
              :class="{ 'coro-link': item.protectedDevices > 0 }"
              @click="viewDevices(item)"
            >
              {{ item.protectedDevices }}
            </span>
          </td>
          <td class="tickets-col" :data-testid="`msp-table-lastActivity-col-row-${index}`">
            <span class="subtitle2 text-red-dark">{{ item.ticketsCount }}</span>
          </td>
          <td class="checkbox-col" @click="$event.stopPropagation()">
            <v-menu location="bottom left">
              <template #activator="{ props }">
                <v-btn
                  icon="$dots"
                  :data-testid="`users-table-action-btn-row-${index}`"
                  v-bind="props"
                >
                </v-btn>
              </template>

              <v-list>
                <template v-for="mspAction in itemActions">
                  <v-list-item
                    v-if="showAction(mspAction, item)"
                    :key="mspAction"
                    @click="handleItemAction(mspAction, item)"
                  >
                    <v-list-item-title :data-testid="`msp-table-${mspAction}-action`">{{
                      $t(`manageWorkspaces.actions.${mspAction}`)
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref, watch } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { CoroIcons } from "@/constants/coro-icon";
import {
  MspWorkspaceAction,
  SubscriptionModule,
  SubscriptionType,
  WorkspaceAction,
  WorkspaceType,
} from "@/constants/workplaces";
import { useAccountStore } from "@/_store";
import { MfaStatus } from "@/constants/account";
import type { MspWorkspaceListItem } from "@/_store/msp/msp.module.ts";
import { useMyAccountStore } from "@/_store/my-account.module";
import type { Pagination } from "@/types";
import { useDialogsStore } from "@/_store/dialogs.module";
import {
  confirmationDialogsConfigConstructor,
  formatDistanceToNow,
  getFormattedDateTime,
  getSelectedModules,
  isModuleIncludedInBundle,
} from "@/_helpers/utils";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    items: {
      type: Array as PropType<Array<MspWorkspaceListItem>>,
      required: true,
    },
    itemActions: {
      type: Array as PropType<Array<MspWorkspaceAction>>,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    filtersUpdateTime: {
      type: Number,
      required: true,
    },
  },
  emits: ["item-action-applied", "page-changed", "bulk-action-clicked"],
  setup(props, { emit }) {
    const accountStore = useAccountStore();
    const myAccountStore = useMyAccountStore();
    const selectedItems = ref([]);
    const plansWithoutSubscription = [
      SubscriptionType.INITIAL,
      SubscriptionType.FREEZE,
      SubscriptionType.TRIAL,
    ];

    const bulkActions = computed(() => {
      const actions = [MspWorkspaceAction.EDIT_SUBSCRIPTION];
      const showStopSubscription = selectedItems.value.some((item: MspWorkspaceListItem) => {
        return !plansWithoutSubscription.includes(item.subscriptionType);
      });
      const showStartSubscription = selectedItems.value.some((item: MspWorkspaceListItem) => {
        return plansWithoutSubscription.includes(item.subscriptionType);
      });
      const showAddLabels = selectedItems.value.some((item: MspWorkspaceListItem) => {
        return item.type === WorkspaceType.CHILD;
      });

      if (showStopSubscription) {
        actions.push(MspWorkspaceAction.STOP_BULK_SUBSCRIPTION);
      }

      if (showStartSubscription) {
        actions.push(MspWorkspaceAction.START_BULK_SUBSCRIPTION);
      }

      if (showAddLabels) {
        actions.push(MspWorkspaceAction.ADD_MSP_BULK_LABEL);
      }

      return actions;
    });

    const showAction = (action: MspWorkspaceAction, item: MspWorkspaceListItem) => {
      const plansInTrial = [SubscriptionType.FREEZE, SubscriptionType.TRIAL];

      if (item.subscriptionType === SubscriptionType.ARCHIVED) {
        return action === MspWorkspaceAction.UNARCHIVE_WORKSPACE;
      }
      switch (action) {
        case MspWorkspaceAction.VIEW_WORKSPACE:
        case MspWorkspaceAction.EDIT_WORKSPACE:
        case MspWorkspaceAction.EDIT_SUBSCRIPTION:
          return true;
        case MspWorkspaceAction.ARCHIVE_WORKSPACE:
          return item.canBeArchived;
        case MspWorkspaceAction.START_SUBSCRIPTION:
          return plansWithoutSubscription.includes(item.subscriptionType);
        case MspWorkspaceAction.STOP_SUBSCRIPTION:
          return !plansWithoutSubscription.includes(item.subscriptionType);
        case MspWorkspaceAction.CONVERT:
          return accountStore.isGlobalAdmin;
        case MspWorkspaceAction.EXTEND_TRIAL:
          return accountStore.isGlobalAdmin && plansInTrial.includes(item.subscriptionType);
        case MspWorkspaceAction.EDIT_MSP_WORKSPACE_LABEL:
          return item.type === WorkspaceType.CHILD;
      }
    };

    const handleItemAction = (action: MspWorkspaceAction, item: MspWorkspaceListItem) => {
      const payload = {
        item,
        itemAction: action,
      };
      if (
        myAccountStore.myAccount.profileData.mfaConfig === MfaStatus.NONE &&
        action === MspWorkspaceAction.VIEW_WORKSPACE &&
        myAccountStore.myAccount.profileData.allowMfa &&
        item.mfaRequired &&
        !accountStore.account.socialLogin
      ) {
        useDialogsStore().openDialog(
          confirmationDialogsConfigConstructor({
            item,
            action: WorkspaceAction.LAUNCH_WITH_2FA,
            callback: () => emit("item-action-applied", payload),
            disable: false,
            disclaimer: "",
          })
        );
      } else {
        emit("item-action-applied", payload);
      }
    };

    const viewUsers = (item: MspWorkspaceListItem) => {
      if (item.protectedUsers > 0) {
        handleItemAction(MspWorkspaceAction.VIEW_PROTECTED_USERS, item);
      }
    };

    const viewDevices = (item: MspWorkspaceListItem) => {
      if (item.protectedDevices > 0) {
        handleItemAction(MspWorkspaceAction.VIEW_DEVICES, item);
      }
    };

    const getSubscription = (item: MspWorkspaceListItem) => {
      const bundles = item.subscription.bundles ?? [];
      const modules = getSelectedModules(item.subscription).filter((module: SubscriptionModule) => {
        return !isModuleIncludedInBundle(module, item.subscription);
      });
      return {
        bundles,
        modules,
        bundlesCount: bundles.length,
        modulesCount: modules.length,
      };
    };

    const onBulkActionClick = (action: MspWorkspaceAction) => {
      emit("bulk-action-clicked", {
        itemAction: action,
        selection: selectedItems.value.map((item: MspWorkspaceListItem) => item.workspaceId),
      });
    };

    watch(
      () => props.filtersUpdateTime,
      () => {
        selectedItems.value = [];
      }
    );

    return {
      formatDistanceToNow,
      format: getFormattedDateTime,
      coronetIcons: CoroIcons,
      MspWorkspacesAction: MspWorkspaceAction,
      iconsMap: {
        [WorkspaceType.REGULAR]: "icon-regular-plan",
        [WorkspaceType.CHANNEL]: "icon-parent-plan",
        [WorkspaceType.CHILD]: "icon-child-plan",
        [WorkspaceType.CORO_AGENT]: "",
      },
      selectedItems,
      showAction,
      handleItemAction,
      SubscriptionType,
      viewUsers,
      viewDevices,
      getSubscription,
      onBulkActionClick,
      bulkActions,
    };
  },
});
</script>

<style scoped lang="scss">
.checkbox-col {
  width: 30px !important;
}

.workspace-name-col {
  min-width: 300px !important;
}

.users-col {
  min-width: 125px !important;
}

.creation-time-col {
  width: 10% !important;
}

.subscription-col {
  max-width: 173px !important;
}

.tickets-col {
  min-width: 80px !important;
}

.devices-col {
  min-width: 100px !important;
}

.msp-table-row {
  height: 96px;
}

.plan-col {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 87px;
  overflow: hidden;
}

.white-space-normal {
  white-space: normal !important;
  word-break: break-all;
  min-width: 150px;
}

.subscription-text {
  max-width: 160px;
  min-width: 100px;
  word-break: break-word;
  text-wrap: wrap;
}

.creation-text {
  max-width: 90px;
  min-width: 60px;
  word-break: break-word;
  text-wrap: wrap;
}
</style>
