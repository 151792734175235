<template>
  <div class="mt-6 mb-8 content-md margin-auto">
    <v-row>
      <v-col
        v-for="(widget, index) in widgets"
        :key="widget.id"
        :cols="columns[index]"
        data-testid="dashboard-widget-column"
      >
        <component
          :data-testid="`msp-dashboard-${widget.id}`"
          :is="widget.component"
          :widget-data="widget.data"
          @retry-fetch-data="widget.retryCallback($event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { type Component, computed, defineComponent, onMounted, onUnmounted, watch } from "vue";
import { useMspDashboardStore } from "@/_store/msp/msp-dashboard.module.ts";
import { storeToRefs } from "pinia";
import GeneratedTicketsWidget from "@/components/msp/dashboard/GeneratedTicketsWidget.vue";
import ProtectedUsersWidget from "@/components/msp/dashboard/ProtectedUsersWidget.vue";
import ProtectedDevicesWidget from "@/components/msp/dashboard/ProtectedDevicesWidget.vue";
import { useDisplay } from "vuetify";
import TicketsBreakdownWidget, {
  TicketsBreakdownWidgetPart,
} from "@/components/msp/dashboard/TicketsBreakdownWidget.vue";
import WorkspacesStatsWidget from "@/components/msp/dashboard/WorkspacesStatsWidget.vue";
import { useWorkspacesStore } from "@/_store/workspaces.module.ts";
import type { GenericCallback } from "@/types.ts";

const MOBILE_COLUMNS = [12, 12, 12, 12, 12];
const SMALL_SCREEN_COLUMNS = [6, 6, 6, 12, 6];
const DEFAULT_COLUMNS = [4, 4, 4, 9, 3];

interface Widget<T = any> {
  id: string;
  component: Component;
  data: T;
  retryCallback: GenericCallback;
}

export default defineComponent({
  components: { ProtectedDevicesWidget, ProtectedUsersWidget, GeneratedTicketsWidget },
  setup() {
    const display = useDisplay({ mobileBreakpoint: 700 });
    const mspDashboardStore = useMspDashboardStore();
    const {
      generatedTickets,
      usersStats,
      devicesStats,
      moduleTickets,
      workspaceTickets,
      workspacesStats,
    } = storeToRefs(mspDashboardStore);
    const { workspaceAndPermissionsUpdatedTimestamp } = storeToRefs(useWorkspacesStore());

    let pollingInterval: ReturnType<typeof setInterval>;

    const columns = computed(() => {
      if (display.mobile.value) {
        return MOBILE_COLUMNS;
      }

      if (display.xs.value) {
        return SMALL_SCREEN_COLUMNS;
      }

      return DEFAULT_COLUMNS;
    });

    function createWidget<T>(widget: {
      id: string;
      component: Component;
      data: T;
      retryCallback: GenericCallback;
    }): Widget<T> {
      return widget;
    }

    const ticketsBreakdownData = computed(() => {
      return {
        ticketsPerModuleData: moduleTickets.value,
        ticketsPerWorkspaceData: workspaceTickets.value,
      };
    });

    const widgets = computed(() => [
      createWidget({
        id: "generatedTicketsWidget",
        component: GeneratedTicketsWidget,
        data: generatedTickets.value,
        retryCallback: mspDashboardStore.fetchGeneratedTickets,
      }),
      createWidget({
        id: "protectedUsersWidget",
        component: ProtectedUsersWidget,
        data: usersStats.value,
        retryCallback: mspDashboardStore.fetchUsersStats,
      }),
      createWidget({
        id: "protectedDevicesWidget",
        component: ProtectedDevicesWidget,
        data: devicesStats.value,
        retryCallback: mspDashboardStore.fetchDevicesStats,
      }),
      createWidget({
        id: "ticketsBreakdownWidget",
        component: TicketsBreakdownWidget,
        data: ticketsBreakdownData.value,
        retryCallback: async (part: TicketsBreakdownWidgetPart) => {
          switch (part) {
            case TicketsBreakdownWidgetPart.PER_MODULE:
              await mspDashboardStore.fetchModuleTickets();
              break;
            case TicketsBreakdownWidgetPart.PER_WORKSPACE:
              await mspDashboardStore.fetchWorkspaceTickets();
              break;
          }
        },
      }),
      createWidget({
        id: "workspacesStatsWidget",
        component: WorkspacesStatsWidget,
        data: workspacesStats.value,
        retryCallback: mspDashboardStore.fetchWorkspacesStats,
      }),
    ]);

    onMounted(async () => {
      await mspDashboardStore.getAllWidgetsData();
      pollingInterval = setInterval(
        async () => {
          await mspDashboardStore.getAllWidgetsData();
        },
        5 * 60 * 1000
      );
    });

    onUnmounted(() => {
      clearInterval(pollingInterval);
    });

    watch(workspaceAndPermissionsUpdatedTimestamp, async (newVal) => {
      if (newVal) {
        await mspDashboardStore.getAllWidgetsData();
      }
    });

    return {
      columns,
      widgets,
    };
  },
});
</script>

<style scoped lang="scss">
.v-col {
  padding: 8px;
}
</style>
