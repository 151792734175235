<template>
  <div class="d-flex align-center mt-5 mb-7">
    <v-icon
      class="laptop-icon mr-3"
      :class="{ offline: deviceDetails.offline }"
      :icon="deviceIcon"
      size="40"
    >
    </v-icon>
    <span
      :class="{ 'text-indigo-medium': deviceDetails.offline }"
      class="headline5"
      data-testid="devices-preview-device-header"
    >
      {{ isMobile ? deviceDetails.device.model : deviceDetails.device.hostname }}
      <span v-if="deviceDetails.offline" data-testid="devices-preview-is-offline">{{
        ` (${$t("general.offline")})`
      }}</span>
    </span>
  </div>
  <div
    v-if="deviceDetails.mergeState === DuplicateDeviceMergeState.PENDING_POTENTIAL_MERGE"
    class="warning-block pa-4"
  >
    <div class="d-flex justify-space-between align-start">
      <div class="d-flex align-start flex-grow-1">
        <v-icon size="24" icon="$warning" />
        <div class="d-flex flex-column flex-grow-1 ml-2">
          <div class="subtitle1 mb-2">
            {{ $t("deviceDetails.duplicateBanner.title") }}
          </div>
          <div class="body2 mb-2" v-html="$t('deviceDetails.duplicateBanner.description')"></div>
          <div class="d-flex">
            <span
              class="text-decoration-underline body2"
              role="button"
              @click="$emit('ignore-duplicates-clicked')"
            >
              {{ $t("deviceDetails.duplicateBanner.ignore") }}
            </span>
            <v-divider class="mx-3" vertical />
            <span
              class="text-decoration-underline body2"
              role="button"
              @click="$emit('merge-duplicates-clicked')"
            >
              {{ $t("deviceDetails.duplicateBanner.merge") }}
            </span>
          </div>
        </div>
      </div>
      <v-icon
        size="24"
        role="button"
        icon="$x"
        @click="deviceDetails.mergeState = DuplicateDeviceMergeState.MERGE_IGNORED"
      />
    </div>
  </div>

  <v-row class="mb-2">
    <v-col cols="6">
      <div class="mb-4" v-if="deviceDetails.workspaceId || deviceDetails.workspaceName">
        <div class="subtitle1">
          {{ $t("deviceDetails.customer") }}
        </div>
        <div v-if="deviceDetails.workspaceId" class="body1">
          {{ deviceDetails.workspaceId }}
        </div>
        <div v-if="deviceDetails.workspaceName" @click="goToWorkspace()" class="coro-link">
          {{ deviceDetails.workspaceName }}
        </div>
      </div>
      <div class="subtitle1">
        {{ $t("deviceDetails.device.modelAndId") }}
      </div>
      <div v-if="deviceDetails.device.model" class="body1" data-testid="devices-preview-model">
        {{ $t("deviceDetails.device.model") }}:
        {{ deviceDetails.device.model }}
      </div>
      <div v-if="deviceDetails.deviceModelId" class="body1" data-testid="devices-preview-model-id">
        {{ $t("deviceDetails.device.modelId") }}:
        {{ deviceDetails.deviceModelId }}
      </div>
      <div v-if="isMobile && deviceDetails.device.id" class="body1" data-testid="devices-device-id">
        {{ $t("deviceDetails.device.mobileId") }}:
        {{ deviceDetails.device.id }}
      </div>
      <div
        v-else-if="deviceDetails.deviceSerialNumber"
        class="body1"
        data-testid="devices-preview-serial-number"
      >
        {{ $t("deviceDetails.device.serialNumber") }}:
        {{ deviceDetails.deviceSerialNumber }}
      </div>
      <div
        v-if="deviceDetails.enrollmentCode"
        class="body1 mb-4"
        data-testid="devices-preview-enrollment-code"
      >
        {{ $t("deviceDetails.device.deviceID") }}:
        {{ deviceDetails.enrollmentCode }}
      </div>
      <div class="subtitle1">{{ $t("deviceDetails.device.os") }}</div>
      <div class="body1 mb-4" data-testid="devices-preview-os">
        {{ deviceDetails.osDisplayName }}
      </div>
      <template v-if="isMobile">
        <div class="subtitle1">
          {{ $t("deviceDetails.device.username") }}
        </div>
        <div class="body1" data-testid="devices-preview-username">
          {{ deviceDetails.lastLoggedInUsers[0] ?? deviceDetails.email }}
        </div>
      </template>
      <template v-else>
        <div class="subtitle1">
          {{ $t("deviceDetails.device.hostName") }}
        </div>
        <div class="body1" data-testid="devices-preview-hostname">
          {{ deviceDetails.device.hostname }}
        </div>
      </template>
      <template v-if="deviceDetails.lastKnownIp">
        <div class="subtitle1 mt-4">
          {{ $t("deviceDetails.device.lastKnownIp") }}
        </div>
        <div class="body1" data-testid="devices-preview-model">
          {{ deviceDetails.lastKnownIp }}
        </div>
      </template>
    </v-col>
    <v-col cols="6">
      <div class="subtitle1 mb-1">
        {{ $t("deviceDetails.device.coroProtection") }}
      </div>
      <div class="body1" :class="{ 'text-red-dark': deviceDetails.appOutdated }">
        {{ $t("deviceDetails.device.version") }}
        <span data-testid="devices-preview-coro-version">{{ deviceDetails.appVersion }}</span>
        <span
          v-if="deviceDetails.marketingVersion"
          data-testid="devices-preview-coro-marketing-version"
        >
          ({{ deviceDetails.marketingVersion }})</span
        >
        <span v-if="deviceDetails.rebootRequired" class="body1 text-red-dark"
          >&nbsp;&nbsp;{{ $t("deviceDetails.device.rebootNeeded") }}</span
        >
      </div>
      <div class="body1 mb-2">
        {{ $t("deviceDetails.device.added") }}
        <span data-testid="devices-preview-coro-added-date">{{
          getFormattedDateTime(deviceDetails.activationTime, "ll")
        }}</span>
      </div>
      <div class="body2 text--grey">
        <template v-if="deviceDetails.malwareScanInProgress || deviceDetails.dlpScanInProgress">
          <div class="d-flex align-center">
            <v-icon class="mr-1" icon="$sync"></v-icon>
            {{ $t("deviceDetails.device.malwareScanInProgress") }}
          </div>
        </template>
        <template v-else-if="deviceDetails.lastRemoteScan > 0">
          {{ $t("deviceDetails.device.lastRemoteScan") }}
          {{ getFormattedDateTime(deviceDetails.lastRemoteScan, "ll") }}
        </template>
      </div>
      <div v-if="showLogsUploadedDate" class="body2 text--grey">
        {{ $t("deviceDetails.device.lastLogsUploadedAt") }}
        {{ getFormattedDateTime(deviceDetails.lastLogsUploadedAt) }}
      </div>
      <template v-if="deviceDetails.lastActivity > 0">
        <div class="subtitle1 mt-4">
          {{ $t("deviceDetails.lastSeen") }}
        </div>
        <div class="body1" data-testid="devices-preview-last-seen">
          {{ getFormattedDateTime(deviceDetails.lastActivity) }}
        </div>
      </template>
      <template v-if="!!deviceDetails.vpnIp">
        <div class="subtitle1 mt-4">
          {{ $t("deviceDetails.device.staticIp") }}
        </div>
        <div class="body1" data-testid="devices-preview-static-ip">
          {{ deviceDetails.vpnIp }}
        </div>
      </template>
      <template v-if="deviceDetails.labels?.length">
        <div class="subtitle1 mt-4 mb-3">
          {{ $t("deviceDetails.labels") }}
        </div>
        <div data-testid="devices-preview-labels">
          <template v-for="label in deviceDetails.labels" :key="label.id">
            <v-chip
              variant="flat"
              label
              v-if="!label.predefined"
              :color="label.color"
              size="small"
              class="mr-1 mb-1"
            >
              <div class="d-flex align-center justify-space-between">
                <span class="text-uppercase mr-1">{{ label.name.replace(/ /g, "&nbsp;") }}</span>
                <v-icon
                  v-if="!actionNotAllowed"
                  size="16"
                  @click="$emit('action-clicked', DeviceAction.REMOVE_LABEL, deviceDetails, label)"
                  icon="$closeCircle"
                />
              </div>
            </v-chip>
          </template>
        </div>
      </template>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { DeviceAction, DuplicateDeviceMergeState, OsType } from "@/constants/devices.ts";
import { useAccountStore } from "@/_store";
import { getFormattedDateTime } from "@/_helpers/utils.ts";
import type { MspDeviceDetails } from "@/_store/msp/devices/msp-devices.module.ts";
import { RouteName } from "@/constants/routes.ts";
import { useWorkspacesStore } from "@/_store/workspaces.module.ts";
import { useRouter } from "vue-router";
import { useMspStore } from "@/_store/msp/msp.module.ts";

export default defineComponent({
  props: {
    actionNotAllowed: {
      type: Boolean,
      required: true,
    },
    deviceDetails: {
      type: Object as PropType<MspDeviceDetails>,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["action-clicked", "merge-duplicates-clicked", "ignore-duplicates-clicked"],
  setup(props) {
    const accountStore = useAccountStore();
    const workspaceStore = useWorkspacesStore();
    const router = useRouter();

    const showLogsUploadedDate = computed(() => {
      return accountStore.isGlobalAdmin && props.deviceDetails.logsUploaded;
    });
    const deviceIcon = computed(() => {
      if (props.deviceDetails.osType) {
        const iconMap = {
          [OsType.WINDOWS]: `$windowsPc`,
          [OsType.OSX]: `$macPc`,
          [OsType.IOS]: `$iosDevice`,
          [OsType.ANDROID]: `$androidDevice`,
          [OsType.MAC_OS]: `$macPc`,
          [OsType.LINUX]: `$linuxPc`,
        };
        return iconMap[props.deviceDetails.osType];
      }
      return undefined;
    });

    const goToWorkspace = async () => {
      await workspaceStore.updateWorkspaceAndPermissions(props.deviceDetails.workspaceId);
      await useMspStore().checkMspAccess();
      await router.push({ name: RouteName.DASHBOARD });
    };

    return {
      DeviceAction,
      deviceIcon,
      showLogsUploadedDate,
      getFormattedDateTime,
      DuplicateDeviceMergeState,
      goToWorkspace,
    };
  },
});
</script>
<style lang="scss" scoped>
.laptop-icon {
  &.offline:before {
    color: rgb(var(--v-theme-indigo-medium));
  }
}
:deep(*) {
  .label-item {
    display: inline-block;
    border-radius: 2px;
    overflow-wrap: initial;
    padding: 0 4px;

    span {
      max-width: 246px;
      overflow-x: clip;
      display: inline-block;
      text-overflow: ellipsis;
    }
  }
}
</style>
