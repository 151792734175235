<template>
  <div>
    <v-form ref="form" v-model="formValid" class="mt-3" lazy-validation @submit.prevent>
      <div class="subtitle1 mb-4">
        {{ $t("modals.addZtnaRule.titles.resourceDetails") }}
      </div>
      <v-text-field
        class="mb-2"
        v-model="localValue.item.name"
        variant="outlined"
        :counter="64"
        :rules="[resourceNameRule, required(), maxCharactersRule(64)]"
        :label="$t('modals.addZtnaRule.placeholders.resourceName')"
      />
      <v-text-field
        class="mb-2"
        v-model="localValue.item.description"
        :counter="255"
        :rules="[maxCharactersRule(255)]"
        variant="outlined"
        :label="$t('modals.addZtnaRule.placeholders.resourceDescription')"
      />
      <div class="mb-4">
        <div class="subtitle1">{{ $t("modals.addZtnaRule.titles.resourceConfigurations") }}</div>
        <div class="caption text-grey-darken-1">
          {{ $t("modals.addZtnaRule.upToInstances", { max: MAX_INSTANCES }) }}
        </div>
        <div class="info-block d-flex py-2 px-4 my-4 align-start">
          <v-icon size="24" class="mr-3" icon="$info" />
          <div class="body2">
            {{ $t("modals.addZtnaRule.bannerDescription") }}
          </div>
        </div>
      </div>

      <v-expansion-panels multiple flat v-model="openedPanels">
        <ztna-rule-config
          v-for="(instance, index) in localValue.item.configs"
          :key="index"
          :config="instance"
          :index="index + 1"
          :show-remove-btn="localValue.item.configs.length > 1"
          :errors="getErrorsByIndex(index)"
          @update:errors="updateErrors()"
          @update:localValue="onUpdateRuleConfig($event, index)"
          @remove-instance="onRemoveInstance(index)"
        />
      </v-expansion-panels>
      <div
        v-if="localValue.item.configs.length < MAX_INSTANCES"
        class="coro-link body2 mb-4"
        role="button"
        @click="onAddInstance"
      >
        {{ "+ " + $t("modals.addZtnaRule.addResourceConfigField") }}
      </div>
      <div class="subtitle1 mb-4 mt-6">
        {{ $t("modals.addZtnaRule.titles.deviceLabels") }}
      </div>
      <v-autocomplete
        v-model="localValue.item.labels"
        :items="labels"
        :label="$t('modals.addZtnaRule.labelsAutocompleteLabel')"
        :placeholder="$t('modals.addZtnaRule.placeholders.labels')"
        variant="outlined"
        :rules="[labelRules]"
        multiple
        return-object
        chips
        closable-chips
        clear-on-select
        data-testid="device-settings-tamper-resistance-labels"
        item-value="id"
        item-title="name"
      >
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            :closable="true"
            variant="flat"
            size="default"
            :color="item.raw.color"
            close-icon="$closeCircle"
            :text="item.raw.name"
            class="label-chip"
          >
            <div class="d-flex align-center">
              <span class="ml-1 mr-2 body2" v-text="item.title" />
            </div>
          </v-chip>
        </template>
        <template v-slot:item="{ item, props }">
          <v-list-item v-bind="props" title="">
            <template #default>
              <div class="d-flex align-center label-popup-block">
                <div class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
                <span v-text="item.raw.name" />
              </div>
            </template>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, ref, watch } from "vue";
import type { VuetifyFormRef } from "@/types";
import { useI18n } from "vue-i18n";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { maxCharactersRule, required } from "@/_helpers/validators";
import { storeToRefs } from "pinia";
import { type DeviceLabel, useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { type RuleConfig } from "@/_store/network/ztna.module";
import ZtnaRuleConfig from "@/components/network/ztna/AddZtnaModal/ZtnaRuleConfig.vue";
import { ConfigType } from "@/constants/network.ts";
import cloneDeep from "lodash/cloneDeep";
import type { ErrorPayload } from "@/_store/errors.module.ts";

const MAX_INSTANCES = 5;

const RULE_CONFIG: RuleConfig = {
  type: ConfigType.IP,
  value: "",
  ports: [],
  protocol: null,
  domains: {},
};

const defaultRulesConfigs = {
  configs: [
    {
      ...RULE_CONFIG,
    },
  ],
  description: "",
  name: "",
};

export default defineComponent({
  components: { ZtnaRuleConfig },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
    errors: {
      type: Array as PropType<ErrorPayload[]>,
      default() {
        return [];
      },
    },
  },
  emits: ["update:valid", "update:localValue", "update:errors"],
  setup(props, { emit }) {
    const form = ref<VuetifyFormRef>();
    const { t } = useI18n();
    const formValid = ref(null);
    const previousName = ref("");
    const nameErrorMessage = ref("");
    const localValue = ref(
      props.config.item
        ? { item: { ...props.config.item } }
        : { item: { ...cloneDeep(defaultRulesConfigs) } }
    );
    const openedPanels = ref<number[]>(props.config.item ? [] : [0]);
    const deviceSettingsStore = useDevicesSettingsStore();
    const { getLabels } = deviceSettingsStore;
    const { labels } = storeToRefs(deviceSettingsStore);

    const labelRules = (labels: DeviceLabel[]) => {
      if (!labels?.length) return t("validations.required");
      return true;
    };

    const nameError = computed(() => {
      return props.errors.find((e: ErrorPayload) => e.field === "name");
    });

    const resourceNameRule = (name: string = "") => {
      if (nameError.value || previousName.value === name) {
        previousName.value = name;
        nameErrorMessage.value = nameError.value?.message || nameErrorMessage.value;
        emit(
          "update:errors",
          props.errors.filter((e) => e.field !== "name")
        );
        return nameErrorMessage.value;
      }
      return true;
    };

    const onAddInstance = () => {
      localValue.value.item.configs.push({ ...RULE_CONFIG });
    };

    const onUpdateRuleConfig = (event: RuleConfig, index: number) => {
      localValue.value.item.configs[index] = event;
    };

    const onRemoveInstance = (index: number) => {
      localValue.value.item.configs.splice(index, 1);
      updateErrors();
    };

    const updateOpenPanels = () => {
      const configsLength = localValue.value.item.configs.length;

      if (configsLength > 0) {
        // If a new panel was added, open just that one
        const lastIndex = configsLength - 1;
        if (!openedPanels.value.includes(lastIndex) && lastIndex >= openedPanels.value.length) {
          openedPanels.value.push(lastIndex);
        }
      }
    };

    const validate = async () => {
      const { valid } = (await form.value?.validate()) ?? { valid: false };
      return valid;
    };

    const getErrorsByIndex = (index: number) => {
      return props.errors.find((e: ErrorPayload) => e.field === "configs" && e.index === index)
        ?.errors;
    };

    const updateErrors = () => {
      emit(
        "update:errors",
        props.errors.filter((e) => e.field !== "configs")
      );
    };

    onMounted(async () => {
      await getLabels();
    });

    watch(
      () => localValue.value.item.configs.length,
      () => {
        updateOpenPanels();
      }
    );

    watch(
      () => localValue,
      () => {
        emit("update:localValue", {
          ...localValue.value.item,
        });
      },
      { deep: true, immediate: true }
    );

    watch(
      formValid,
      () => {
        if (props.errors.length === 0) {
          emit("update:valid", formValid.value);
        }
      },
      { immediate: true }
    );

    watch(
      () => props.errors,
      () => {
        if (props.errors.length === 0) {
          emit("update:valid", formValid.value);
        }
      }
    );

    return {
      MAX_INSTANCES,
      maxCharactersRule,
      localValue,
      onAddInstance,
      onUpdateRuleConfig,
      required,
      formValid,
      labelRules,
      form,
      labels,
      onRemoveInstance,
      openedPanels,
      validate,
      getErrorsByIndex,
      resourceNameRule,
      previousName,
      updateErrors,
    };
  },
});
</script>
<style lang="scss" scoped>
.side-by-side-inputs {
  .ports-input {
    flex: 3;
  }
}

.label-popup-block {
  .color-preview {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }
}
</style>
