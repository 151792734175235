import { SocContactsActions, SocRemediationActions } from "@/constants/soc";
import {
  SocContactClosureNotification,
  SocContactNotificationTypes,
} from "@/_store/soc/soc-contact.module";
import { SocRemediationType } from "@/_store/soc/soc-remediation.module";
import {
  EndpointDataGovernanceAction,
  SensitiveDataScansActions,
} from "@/constants/endpoint-data-governance";
import {
  TicketTrigger,
  TicketType,
  TicketAction,
  TicketActionSeverity,
  SocStatus,
  TicketsModal,
  CommentAction,
  TicketsModuleFilter,
} from "@/constants/tickets";
import {
  MspWorkspaceAction,
  SubscriptionAddon,
  SubscriptionBundle,
  SubscriptionModule,
  SubscriptionType,
  WorkspaceAction,
  WorkspaceLocale,
  WorkspaceNavigationButton,
  WorkspaceType,
} from "@/constants/workplaces";
import { ConnectorActions, CoroProductNames, PsaProviders } from "@/constants/connectors";
import {
  AgentDeploymentAction,
  AgentDeploymentChannel,
  DeviceAction,
  DeviceAllowBlockListTypes,
  DeviceAllowListActions,
  DevicePostureAction,
  DisableNgavAction,
  DriveEncryptionStatus,
  DriveFailedReason,
  LabelAction,
} from "@/constants/devices";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  MspPortalScopeSection,
  MspRolePermissionScopes,
  ProtectionScopeModules,
  RolePermissionsScope,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import {
  GlobalRolesAction,
  MspRolesAction,
  RolePermissionAccessMode,
  RolesAction,
} from "@/constants/roles";
import {
  AdminUsersAction,
  GlobalAdminUsersAction,
  MspAdminUsersAction,
} from "@/constants/admin-accounts";
import {
  AccessPermissionAction,
  Service,
  ServiceStatus,
  ServiceAction,
  ThirdPartyAppsAction,
} from "@/constants/cloud-apps";
import { ActiveSessionsAction } from "@/constants/active-session";
import { EdrAction } from "@/constants/edr";
import { ExceptionsAction, UserAction, UserGroupAction } from "@/constants/users";
import { DataLossAction, UserDataGovernanceAction } from "@/constants/user-data-governance";
import { PhishingAction } from "@/constants/phishing";
import { EmailProxyAction, ProxySecurityMode } from "@/constants/email-proxy";
import { ActivityLogsType } from "@/constants/activity-logs";
import { MalwareAction } from "@/constants/malware-settings";
import { BackOfficeOverviewWidgetType, WorkspaceCodeActions } from "@/constants/back-office";
import { ExportCategory, ExportChoice, ExportFileExtension } from "@/_store/exports.module";
import { ExportAction } from "@/constants/exports";
import { ActivateMobileErrorType } from "@/views/ActivateMobilePage.vue";
import {
  WorkspaceCodeRelationType,
  WorkspaceCodeStatus,
  WorkspaceCodeType,
} from "@/_store/back-office/workspace-codes.module";
import {
  WebhookAction,
  WebhookStatus,
  WebhookTriggerType,
  WebhookTriggerTestStatus,
} from "@/constants/webhooks";
import {
  SecureWebGatewayAction,
  NetworkSettingsAction,
  SiteToSiteTunnelsAction,
  VirtualOfficeAction,
  ZtnaAction,
  ConfigType,
  Protocol,
} from "@/constants/network";
import {
  SiteToSiteTunnelStatus,
  SiteToSiteTunnelTestState,
} from "@/_store/network/site-to-site-tunnels.module";
import {
  ScheduledReportFrequency,
  ScheduledReportScope,
} from "@/_store/scheduled-reports/scheduled-reports.module.ts";
import { ScheduledReportsAction } from "@/constants/scheduled-reports";
import { ThirdPartyAppStatus } from "@/_store/cloud-apps/third-party-apps.module";
import { BillingAction } from "@/constants/billing";
import {
  UserSettingsAppStatus,
  UserSettingsJoinedVia,
} from "@/_store/users-settings/users-tab.module";
import { EmailSettingsAction, SecurityMode, ScanSensitivityLevels } from "@/constants/emails";
import { ReportType } from "@/_store/reports.module";
import { MessageType } from "@/constants/messages";
import { AccountAction, AccountErrors, SignUpError } from "@/constants/account";
import type { MasterTranslationsSchema } from "@/translations/en";
import { NotificationsLevel } from "@/_store/msp/msp.module.ts";
import { it as vuetifyItLocale } from "vuetify/locale";
import { MspExportAction } from "@/constants/msp-exports.ts";
import { Industries } from "@/constants/data-governance";
import { SelectorValue } from "@/constants/selector.ts";
import {
  SecurityAwarenessAction,
  SecurityAwarenessTraining,
} from "@/constants/security-awareness.ts";
import { CoroMarketingLink } from "@/constants/general.ts";
import { DemoWillStartModal, TutorialAction } from "@/constants/tutorial.ts";

export const it: MasterTranslationsSchema = {
  $vuetify: {
    ...vuetifyItLocale,
  },
  validations: {
    cidr: "[TRANSLATION] Enter a valid IP address and subnet (up to 32)",
    verificationCodeIsRequired: "Il codice di verifica è obbligatorio",
    businessAccountOnly: "Per favore, usa solo un account aziendale",
    invalidWorkspaceCode: "Usa un codice per l'area di lavoro valido",
    required: "Il campo è obbligatorio",
    domain: "Puoi usare solo domini validi",
    emailOrDomain: "Puoi usare solo indirizzi e-mail o domini validi",
    emailOrDomainOrIp: "Puoi usare solo indirizzi e-mail/domini o IP/intervalli di IP validi",
    workspaceShouldMatch: "Inserisci l’area di lavoro che desideri eliminare",
    onlyInteger: "Puoi usare solo numeri interi",
    bundleOrModuleIsRequired: "È richiesto un pacchetto o un modulo",
    onlyDigits: "Il campo può contenere solo cifre",
    ip: "Usa indirizzi ipv4 o ipv6 validi",
    ip4: "Usa un indirizzo ipv4 valido",
    email: "Puoi usare solo indirizzi e-mail validi",
    password:
      "La password deve essere composta da un minimo di 8 simboli e contenere almeno 1 lettera maiuscola, 1 minuscola e 1 numero.",
    general: "Correggi gli errori riportati di seguito",
    matchesOldPassword: "La password non può corrispondere alla precedente",
    incorrectOldPassword: "Vecchia password errata",
    maxNCharacters: "Il campo non può contenere più di {n} caratteri",
    maxNItems: "Non puoi selezionare più di {n} elementi",
    minNCharacters: "Il campo non può contenere meno di {n} caratteri",
    json: "Carica un file JSON valido",
    boxConfigurationFile: "Carica un file di configurazione Box valido",
    onlyPositiveNumbers: "Sono consentiti solo numeri maggiori di 0",
    maxNumberAllowed: "Sono consentiti solo numeri inferiori a {n}",
    minNumberAllowed: "Sono consentiti solo numeri maggiori di {n}",
    maxNDecimalPoints: "Il campo non può contenere più di {n} punti decimali",
    noSpecialCharacters: "I caratteri speciali non sono ammessi",
    onlyCertainSpecialCharactersAllowed:
      "Sono consentiti solo i seguenti caratteri speciali: {characters}",
    invalidSubdomain: "Sub-dominio non valido. Sono consentiti solo lettere, numeri e trattini",
    strengthMeter: {
      great: "Ottimo",
      good: "Bene",
      soso: "Così così",
      veryWeak: "Molto debole",
    },
    nDigits: "Il campo deve contenere esattamente {n} cifre",
    invalidVerificationCode: "Codice di verifica errato",
    invalidPhoneNumber: "Numero di telefono non valido",
    tooManyOtpResetRequests:
      "Troppe richieste di sms. La prossima richiesta sarà disponibile tra 1 minuto.",
    domainZoneIsNotAllowed: "La zona del dominio non è consentita",
    noSpacesAllowed: "Non sono ammessi spazi",
    invalidHexColor: "Colore esadecimale non valido",
    maximumAllowedKeywords: "Il numero massimo di parole chiave è 200",
    script: "Carica solo script con estensioni consentite",
    ssid: "Puoi usare solo nomi di rete validi",
    url: "L’URL non è valido",
    domainAlreadyExists: "Questo dominio esiste già",
    emailIsNotInTheListOfProxyDomains:
      "L’indirizzo e-mail non proviene dai domini Inbound Gateway elencati.",
    ipOrDomain: "Dominio o indirizzo IP non valido. Inserisci valori validi.",
    ipAddress: "Usa un indirizzo IP valido.",
    atLeastOneFieldRequired: "Almeno un campo è obbligatorio",
    pleaseSelectAtLeastOneOption: "Seleziona almeno un’opzione",
    eitherBothOrNone: "Specifica entrambi i valori o nessuno dei due",
    ipRange: "Utilizza un intervallo IP valido nella notazione CIDR (/XX)",
    ipExists: "Questo intervallo di indirizzi IP esiste già",
    overlap: "Intervallo di sovrapposizione",
    fileRequired: "Questo file è obbligatorio",
    sha256: "Inserisci un hash SHA-256 valido",
    sha256OrCdhash: "Inserisci un hash SHA-256 o un CDHash valido",
    hashOrFilePath: "Inserisci un hash SHA-256/CDHash o un percorso file valido",
    reservedIp:
      "[TRANSLATION] This subnet is reserved for VPN connections and cannot be used. Choose a different subnet for your configuration.",
    domainOrIp: "[TRANSLATION] Please use only valid domains or IPs/IP ranges",
    maxPorts: "[TRANSLATION] You can not have more than {n} ports selected",
    filePath: "Inserisci un percorso file valido",
    maximumNumberOfItemsExceeded: "Il numero massimo di elementi è stato superato",
    port: "[TRANSLATION] Port numbers must be between 1 and 65535",
    tcpOrUdp: "Usa il protocollo TCP o UDP",
    missingFile: "Aggiungi un file",
    fileSizeError: "Le dimensioni del file superano i 10MB. Riprova",
    punycodeDomain: "Deve essere un dominio valido che usa solo caratteri ASCII",
    expiredTriggerCannotBeSelected: "Non si può selezionare un trigger scaduto",
    accountAddedAsAdminUser:
      "Questo account è già stato aggiunto come amministratore del channel in un'altra area di lavoro. ",
    typeApprove: {
      approve: "Approva",
      required: "Per confermare l’azione è necessario digitare APPROVA",
      exactMatch: "Devi digitare esattamente la parola APPROVA",
    },
  },
  services: {
    [Service.BOX]: "Box",
    [Service.G_SUITE]: "Google Workspace",
    [Service.GOOGLE]: "Google",
    googleDirectoryEnforcementAlias: "G-Suite",
    [Service.DROPBOX]: "Dropbox",
    [Service.OFFICE_365]: "Microsoft 365",
    emailProxyEnforcement: "Inbound Gateway",
    [Service.SLACK]: "Slack",
    [Service.SALES_FORCE]: "Salesforce",
    [Service.CORONET]: "Coro",
    hubspotEnforcement: "Hubspot",
    oktaEnforcement: "Okta",
    status: {
      [ServiceStatus.CONNECTED]: "Connesso",
      [ServiceStatus.DISCONNECTED]: "Disconnesso",
      [ServiceStatus.ENABLED]: "Connesso",
      disabled: "Disabilitato",
      [ServiceStatus.INCOMPLETE]: "Incompleto",
      [ServiceStatus.NOT_CONNECTED]: "Non connesso",
      [ServiceStatus.CONNECTED_NOT_SECURE]: "Connesso (non sicuro)",
    },
  },
  snackbar: {
    close: "Chiudi",
    messages: {
      account: {
        mode: {
          visibility: "La modalità di rilevamento è stata attivata correttamente",
          nonIntrusive: "La modalità di protezione è stata attivata correttamente",
          enforcement: "La modalità di protezione è stata attivata correttamente",
        },
        modeChangedByRemoteUser: "La modalità di sistema è stata modificata da uno degli utenti",
        settingsSaved: "Impostazioni dell’account salvate.",
      },
      general: {
        actionError:
          "L’azione non è andata a buon fine, controlla i registri delle attività per i dettagli.",
        actionSuccessful: "Azione eseguita correttamente",
        oops: "Ops, si è verificato un errore",
        copiedToClipboard: "Copiato negli appunti",
        settingsUpdated: "Impostazioni aggiornate",
        exportCsvStarted:
          "Esportazione su file CSV in corso. Potrai accedervi a breve dalla pagina dei registri delle attività.",
      },
      user: {
        usersUpdated: "Utenti aggiornati",
        usersAreAdding: "Abbiamo iniziato ad aggiungere utenti, ti invitiamo ad attendere...",
        NO_USERS_PROTECTED: "Nessun utente protetto",
        usersSyncStarted: "Avviata la sincronizzazione degli utenti dalle applicazioni cloud",
        PROTECTED_USERS_MASS_REDUCTION: "{requestedLicenses} licenze rimosse dagli utenti ",
        LESS_THEN_8O_PERCENT_USERS_PROTECTED: "Meno dell’80% degli utenti è protetto",
        USER_SUSPENDED_BY_ADMIN:
          "L'utente {userEmail} è stato sospeso dal servizio {service} da {by}",
        USER_SIGNED_OUT_BY_ADMIN:
          "L'utente {userEmail} è stato disconnesso dal servizio {service} da {by}",
        USER_SUSPENDED_FROM_SERVICE:
          "L'utente {userEmail} è stato  sospeso dal servizio {service} in base alle regole di {by}",
        MASS_USER_SIGNED_OUT_OF_SERVICE:
          "L'utente {userEmails} è stato disconnesso dal servizio {service} | Gli utenti {userEmail} sono stati disconnessi dal servizio {service}",
      },
      users: {
        markAsProcessed: "Le segnalazioni correlate sono state chiuse.",
        signInToAll:
          "È stata inviata una richiesta di effettuare di nuovo l’accesso a tutte le app cloud.",
        signInToService:
          "È stata inviata una richiesta di effettuare di nuovo l’accesso all'app cloud.",
        suspendFromAll: "È stata inviata una richiesta di sospensione a tutte le app cloud.",
        contactUser: "Il messaggio è stato inviato.",
      },
      userGroups: {
        userGroupsUpdated: "Gruppi di utenti aggiornati",
      },
      exceptions: {
        exceptionsUpdated: "Esclusioni aggiornate",
      },
      operator: {
        OPEN_SENSITIVE_FILES_ALLOWED_TO_ADMINS:
          "Gli utenti amministratori sono stati autorizzati a scaricare file sensibili da {by}.",
        VIEWER_USER_ADDED: "Nuovo utente visualizzatore {userEmail} aggiunto da {by} ",
        ADMIN_USER_ADDED: "Nuovo utente amministratore {userEmail} aggiunto da {by} ",
      },
      service: {
        SERVICE_DISCONNECTED: "Il servizio {service} è stato disconnesso da {by}",
        SERVICE_NOT_MONITORED: "Il servizio {service} non è monitorato da {by}",
        SERVICE_REMOVED: "Il servizio {service} è stato cancellato da {by}",
      },
      notification: {
        CONTENT_SCAN_REPORT_READY: "Il rapporto sulla scansione alla ricerca di contenuti è pronto",
      },
      mspTickets: {
        [TicketAction.MARK_AS_PROCESSED]:
          "La segnalazione è stata chiusa. | Le segnalazioni sono state chiuse.",
        [TicketAction.MARK_AS_UNPROCESSED]:
          "La segnalazione è stata riaperta. | Le segnalazioni sono state riaperte.",
        [TicketAction.ENCRYPT_DRIVE]:
          "Il processo di crittografia dell’unità verrà avviato alla prossima comunicazione con il dispositivo. | Il processo di crittografia dell’unità verrà avviato alla prossima comunicazione con i dispositivi.",
        [TicketAction.REMOTE_SCAN]:
          "La scansione remota dei malware verrà avviata alla prossima comunicazione con il dispositivo. | La scansione remota dei malware verrà avviata alla prossima comunicazione con i dispositivi.",
        [TicketAction.ENABLE_FIREWALL]:
          "Firewall abilitato. | Il firewall è stato abilitato sui dispositivi selezionati.",
        [TicketAction.ENFORCE_UAC]:
          "Notifica UAC riattivata. | La notifica UAC è stata riattivata sui dispositivi selezionati.",
        [TicketAction.DISABLE_DEVELOPER_MODE]:
          "Modalità sviluppatore disattivata. | La modalità sviluppatore è stata disabilitata sui dispositivi selezionati.",
      },
      event: {
        coroPrimaryMxRecordTest: "[TRANSLATION] Test completed",
        newCommentIsAdded: "Il nuovo commento è stato aggiunto correttamente",
        allowProcess: "Il processo è stato consentito",
        commentUpdated: "Commento aggiornato",
        ABNORMAL_INCREASE_IN_EVENTS:
          "Aumento anomalo delle segnalazioni di {ruleName} nell’ultimo periodo",
        MASS_FILE_COLLABORATION_BLOCKAGE:
          "Blocco della collaborazione di massa sui file. Numero di file bloccati: {filesNumber}, Servizio: {service}",
        eventThreatStatusChanged: "Aggiornamento dello stato di minaccia della segnalazione",
        eventsUpdated: "Segnalazioni aggiornate",
        downloadEmlFile: "È stata effettuata una richiesta di download per l’e-mail",
        markAsProcessed: "La segnalazione è stata chiusa. | Sono stati chiusi {quantity} ticket.",
        markAsUnprocessed:
          "La segnalazione è stata riaperta. | Sono stati riaperti {quantity} ticket.",
        treatFileAsSafe: "Il file verrà trattato come sicuro.",
        excludeFilePathAndCertificateFromScan:
          "Il file verrà trattato come sicuro (in base al certificato).",
        excludeFilePathAndFileHashFromScan:
          "Il file verrà trattato come sicuro (in base all’hash).",
        treatFileAsMalicious: "Il file verrà trattato come dannoso.",
        excludeFolderFromScan: "Cartella esclusa dalla scansione dei malware.",
        excludeCertificateFromScan: "escludiCertificatoDallaScansione",
        suspendFromAll: "È stata inviata una richiesta di sospensione a tutte le app cloud.",
        suspendFromService: "È stata inviata una richiesta di sospensione all’app cloud.",
        signInToAll:
          "È stata inviata una richiesta di effettuare di nuovo l’accesso a tutte le app cloud.",
        signInToService:
          "È stata inviata una richiesta di effettuare di nuovo l’accesso all'app cloud.",
        encryptDrive:
          "Il processo di crittografia dell’unità verrà avviato alla prossima comunicazione con il dispositivo. | Il processo di crittografia dell’unità verrà avviato alla prossima comunicazione con i {quantity} dispositivi.",
        decryptDrive:
          "Il processo di decrittazione dell’unità verrà avviato alla prossima comunicazione con il dispositivo. | Il processo di decrittazione dell’unità verrà avviato alla prossima comunicazione con i {quantity} dispositivi.",
        remoteScan:
          "La scansione remota dei malware verrà avviata alla prossima comunicazione con il dispositivo. | La scansione remota dei malware verrà avviata alla prossima comunicazione con i {quantity} dispositivi.",
        startDlpFullScan:
          "La scansione remota dei dati sensibili verrà avviata alla prossima comunicazione con il dispositivo. | La scansione remota dei dati sensibili verrà avviata alla prossima comunicazione con i {quantity} dispositivi.",
        startDlpPartialScan:
          "La scansione remota dei dati sensibili inizierà alla prossima comunicazione con il dispositivo. | La scansione remota dei dati sensibili inizierà alla prossima comunicazione con i {quantity} dispositivi.",
        uploadLogs:
          "I registri verranno memorizzati alla successiva comunicazione con il dispositivo.",
        collectQuarantineData:
          "L’elenco degli elementi in quarantena verrà memorizzato alla prossima comunicazione con il dispositivo.",
        stopRemoteScan:
          "La scansione remota dei malware si interromperà alla prossima comunicazione con il dispositivo. | La scansione remota dei malware si interromperà alla prossima comunicazione con i {quantity} dispositivi.",
        stopDlpScan:
          "La scansione remota dei dati sensibili si interromperà alla successiva comunicazione con il dispositivo. | La scansione remota dei dati sensibili si interromperà alla successiva comunicazione con i {quantity} dispositivi.",
        enableFirewall:
          "Firewall abilitato. | Il firewall è stato abilitato sui {quantity} dispositivi.",
        removeFromProtection:
          "Dispositivo escluso dalla protezione. | I {quantity} dispositivi sono stati esclusi dalla protezione.",
        updateAgentToLatest:
          "Il dispositivo verrà aggiornato all’ultima versione stabile dell’agente. | I {quantity} dispositivi verranno aggiornati all’ultima versione stabile dell’agente.",
        enforceUAC:
          "Notifica UAC riattivata. | La notifica UAC è stata riattivata sui {quantity} dispositivi.",
        disableDevMode:
          "Modalità sviluppatore disattivata. | La modalità sviluppatore è stata disattivata sui {quantity} dispositivi.",
        removeExposingSharing: "La richiesta di rimozione è stata inviata.",
        approveFile: "La richiesta di approvazione del file è stata inviata",
        deleteFile: "La richiesta di rimozione del file è stata inviata",
        generalApprove: "L’e-mail è stata approvata",
        specificApprove: {
          crowdblockedSender:
            "Mittente/dominio nell’elenco globale degli elementi bloccati per contenuti sospetti inserito tra gli elementi consentiti.",
          blocklistedSender:
            "Mittente/dominio rimosso dall’elenco degli elementi bloccati per contenuti sospetti.",
          missingRequiredAuthentication:
            "Indirizzo o intervallo IP rimosso dall’elenco degli elementi bloccati per autenticazione non riuscita.",
        },
        approveEmailsFromDomain:
          "L'elemento {item} è stato inserito nell’elenco consentiti | I domini selezionati sono stati inseriti nell’elenco consentiti",
        discardEmailsFromDomain:
          "L'elemento {item} è stato inserito nell’elenco dei bloccati | I domini selezionati sono stati inseriti nell’elenco degli elementi bloccati",
        approveEmailsFromSender:
          "L'elemento {item} è stato inserito nell’elenco consentiti | Gli indirizzi e-mail selezionati sono stati inseriti nell’elenco consentiti",
        discardEmailsFromSender:
          "L'elemento {item} è stato inserito nell’elenco dei bloccati | Gli indirizzi e-mail selezionati sono stati inseriti nell’elenco degli elementi bloccati",
        approveEmailsFromSenderIp:
          "L'email è stata consentita e l'IP del mittente è stato inserito nella lista consentita",
        discardEmailsFromSenderIp:
          "L'email è stata eliminata e l' IP del mittente è stato bloccato",
        approveEmail: "L’e-mail è stata autorizzata",
        discardEmail: "L’e-mail è stata eliminata",
        contactUser: "Il messaggio è stato inviato",
        allowNoEncryption: "Il disco rigido è stato inserito nell’elenco consentiti",
        approveProcessGroup: "Il gruppo di processi verrà trattato come sicuro",
        discardInfectingFile: "Il file verrà trattato come dannoso",
        enableTamperResistance:
          "Difesa dalle manomissioni abilitata. | La difesa dalle manomissioni è stata abilitata sui {quantity} dispositivi.",
        disableTamperResistance:
          "Difesa dalle manomissioni disabilitata. | La difesa dalle manomissioni è stata disabilitata sui {quantity} dispositivi.",
        logForAuditReports: "Il ticket è stato registrato. | I ticket sono stati registrati",
        unLogForAuditReports: "Il ticket è stato cancellato. | I ticket sono stati cancellati",
        disableNetworkBlockMode:
          "L’isolamento dalla rete verrà disabilitato alla prossima comunicazione con il dispositivo | L’isolamento dalla rete verrà disabilitato alla prossima comunicazione con i dispositivi",
        enableNetworkBlockMode:
          "L’isolamento dalla rete verrà abilitato alla prossima comunicazione con il dispositivo | L’isolamento dalla rete verrà abilitato alla prossima comunicazione con i dispositivi",
        shutdownDevice:
          "Lo spegnimento verrà eseguito alla prossima comunicazione con il dispositivo | Lo spegnimento verrà eseguito alla prossima comunicazione con i dispositivi",
        rebootDevice:
          "Il riavvio verrà eseguito alla prossima comunicazione con il dispositivo | Il riavvio verrà eseguito alla prossima comunicazione con i dispositivi",
        addLabel:
          "L’etichetta è stata aggiunta al dispositivo | L’etichetta è stata aggiunta ai dispositivi",
        removeLabel:
          "L’etichetta è stata rimossa dal dispositivo | L’etichetta è stata rimossa dai dispositivi",
        addToDataGovernancePermissions:
          'L\'utente {users} è stato autorizzato alla violazione del tipo "{violationDirection}" dei dati contenenti {trigger}.  | Gli utenti {users} sono stati autorizzati alla violazione del tipo "{violationDirection}" dei dati contenenti {trigger}. ',
      },
      export: {
        exportCsvSuccessful:
          "Il file CSV è stato esportato correttamente. Vai alla sezione rapporti per scaricare il file CSV.",
      },
      rule: {
        rulesUpdated: "Regola aggiornata | Regole aggiornate",
      },
      devices: {
        devicesUpdated: "Dispositivi aggiornati",
        markAsProcessed: "Le segnalazioni correlate sono state chiuse.",
        downloadLogs: "Download dei registri in corso, ti invitiamo ad attendere...",
        mergeSuccessful: "I dispositivi sono stati uniti correttamente.",
        defaultLabelAdded: "[TRANSLATION] This field cannot be empty. 'All devices' label added.",
      },
      cloudApps: {
        disconnected: "Disconnesso dal servizio {service}",
        connected: "Il servizio {service} è connesso a Coro ",
      },
      accessPermissions: {
        accessPermissionsUpdated: "Autorizzazioni di accesso aggiornate",
      },
      adminAccounts: {
        adminUsersUpdated: "Utenti amministratore aggiornati",
        inviteSent: "1 utente amministratore invitato",
        permissionsUpdated: "Autorizzazioni ai contenuti aggiornate",
        mfaDeleted: "Dati 2FA eliminati",
        mfaUpdated: "Impostazioni 2FA aggiornate",
        copyInviteLink: "Link di invito copiato negli appunti",
      },
      resetPassword: {
        linkSent:
          "Le istruzioni per il ripristino della password sono state inviate all'indirizzo {email}",
      },
      emails: {
        approveEmailsFromDomain:
          "L'elemento {item} è stato inserito nell’elenco consentiti | I domini selezionati sono stati inseriti nell’elenco consentiti",
        discardEmailsFromDomain:
          "L'elemento {item} è stato inserito nell’elenco dei bloccati | I domini selezionati sono stati inseriti nell’elenco degli elementi bloccati",
        approveEmailsFromSender:
          "L'elemento {item} è stato inserito nell’elenco consentiti | Gli indirizzi e-mail selezionati sono stati inseriti nell’elenco consentiti",
        discardEmailsFromSender:
          "L'elemento {item} è stato inserito nell’elenco dei bloccati | Gli indirizzi e-mail selezionati sono stati inseriti nell’elenco degli elementi bloccati",
        approveEmail: "L’e-mail è stata autorizzata",
        discardEmail: "L’e-mail è stata bloccata",
        markAsProcessed: "Le segnalazioni correlate sono state chiuse.",
        markAsUnprocessed: "Segnalazione riaperta.",
        contactUser: "Il messaggio è stato inviato",
      },
      myAccount: {
        updated: "Profilo aggiornato",
      },
      dashboard: {
        vulnerabilityMuted: "L'opzione {vulnerability} è stata disattivata",
        vulnerabilityUnmuted: "L'opzione {vulnerability} è stata riattivata",
      },
      demoMode: {
        dataResetCompleted: "Ripristino dei dati completato",
      },
      manageWorkspaces: {
        workspaceCreated: "Area di lavoro creata",
        workspaceUpdated: "Area di lavoro aggiornata",
        workspacesUpdated: "{n} Area di lavoro aggiornato | {n} Aree di lavoro aggiornati",
        workspaceDeleted: "Area di lavoro eliminata",
        subscriptionUpdated: "Abbonamento aggiornato",
        workspaceArchived: "Area di lavoro archiviata",
        exportToCsv:
          "L’esportazione su file CSV è in preparazione. Sarà disponibile a breve nella pagina dedicata ai registri attività.",
      },
      edr: {
        blockProcess: "Il processo è stato bloccato | {quantity} processi sono stati bloccati",
        unblockProcess: "Il processo è stato sbloccato | {quantity} processi sono stati sbloccati",
        enableNetworkBlockMode:
          "L’isolamento dalla rete verrà abilitato alla prossima comunicazione con il dispositivo | L’isolamento dalla rete verrà abilitato alla prossima comunicazione con i dispositivi",
        disableNetworkBlockMode:
          "L’isolamento dalla rete verrà disabilitato alla prossima comunicazione con il dispositivo | L’isolamento dalla rete verrà disabilitato alla prossima comunicazione con i dispositivi",
        shutdownDevice:
          "Lo spegnimento verrà eseguito alla prossima comunicazione con il dispositivo | Lo spegnimento verrà eseguito alla prossima comunicazione con i dispositivi",
        rebootDevice:
          "Il riavvio verrà eseguito alla prossima comunicazione con il dispositivo | Il riavvio verrà eseguito alla prossima comunicazione con i dispositivi",
      },
      connectors: {
        syncCompleted: "Sincronizzazione completata",
        apiKeyAdded: "Le credenziali API sono state create",
        apiKeyRemoved: "Le credenziali API sono state revocate",
      },
      emailProxy: {
        proxyDomainTestCompleted:
          "La verifica dell'e-mail dell'Inbound Gateway è stata completata.",
      },
      secureWebGateway: {
        importUrlGroupToAllowlist:
          "La categoria {nickname} contenente {quantity} voce è stata aggiunta all’elenco degli elementi consentiti. | La categoria {nickname} contenente {quantity} voci è stata aggiunta all’elenco degli elementi consentiti.",
        importUrlGroupToBlocklist:
          "La categoria {nickname} contenente {quantity} voci è stata aggiunta all’elenco degli elementi bloccati. | La categoria {nickname} contenente {quantity} voci è stata aggiunta all’elenco degli elementi bloccati.",
        addUrlToAllowlist:
          "{quantity} voce è stata aggiunta all’elenco degli elementi consentiti. | {quantity} voci sono state aggiunte all’elenco degli elementi consentiti.",
        addUrlToBlocklist:
          "{quantity} voce è stata aggiunta all’elenco degli elementi bloccati. | {quantity} voci sono state aggiunte all’elenco degli elementi bloccati.",
      },
      sideToSideTunnels: {
        syncCompleted: "Sincronizzazione completata",
        listUpdated: "Aggiornamento dei tunnel da sito a sito",
      },
      voFirewall: {
        listUpdated: "Elenco firewall aggiornato",
      },
      roles: {
        [RolesAction.ADD]: "{name} creato correttamente",
        [RolesAction.EDIT]: "{name} aggiornato correttamente",
        [RolesAction.DELETE]: "{name} eliminato correttamente",
      },
      exports: {
        exportCsvStarted:
          "Esportazione su file CSV in corso. Sarà disponibile per il download a breve.",
      },
      socket: {
        suspendFromAll: {
          ok: "L’accesso dell’utente a tutte le applicazioni cloud è stato sospeso.",
          failed:
            "Errore: sospendi tutte le applicazioni cloud. Controlla i registri attività per i dettagli.",
        },
        csvExport: {
          ok: "Esportazione su file CSV correttamente eseguita. Puoi accedervi dalla pagina dei registri attività.",
          failed:
            "Errore nell'esportazione su file CSV. Controlla i registri attività per i dettagli.",
        },
        suspendFromService: {
          ok: "L’accesso dell’utente a {service} è stato sospeso.",
          failed:
            "Errore: la sospensione dell’utente dal servizio {service} è stata negata dallo stesso.",
        },
        signInToAll: {
          ok: "La richiesta di effettuare di nuovo l’accesso a tutte le app cloud è stata inviata correttamente.",
          failed: "Errore: accesso a tutto. Controlla i registri attività per i dettagli.",
        },
        signInToService: {
          ok: "La richiesta di effettuare di nuovo l’accesso all'app cloud è stata inviata correttamente.",
          failed:
            "Errore: accesso al servizio {service}. Controlla i registri attività per i dettagli.",
        },
        removeExposingSharing: {
          ok: "Tutte le condivisioni con persone esterne all’organizzazione sono state rimosse.",
          failed:
            "Errore: rimuovi la condivisione dell'esposizione. Controlla i registri attività per i dettagli.",
        },
        approveFile: {
          ok: "Il file {fileName} è stato ripristinato.",
          failed:
            "Errore: il file {fileName} non è stato ripristinato. Controlla i registri attività per i dettagli.",
        },
        deleteFile: {
          ok: "Il file {fileName} è stato eliminato.",
          failed:
            "Errore: il file {fileName} non è stato eliminato. Controlla i registri attività per i dettagli.",
        },
        importUsersFromCsv: {
          ok: "L’elenco degli indirizzi e-mail è stato importato correttamente. {validUsersCount} utenti contenuti nel file CSV sono ora protetti.",
          failed:
            "L’importazione dell’elenco di indirizzi e-mail non è riuscita per il seguente motivo: {reason}",
        },
        [DeviceAllowListActions.IMPORT_ALLOW_BLOCK_LIST_CSV]: {
          ok: "L’elenco degli elementi consentiti/bloccati è stato importato correttamente. {validSenderRecordsCount} record dal file CSV sono stati aggiunti all’elenco.",
          failed:
            "L’importazione dell’elenco degli elementi consentiti/bloccati non è riuscita per il seguente motivo: {reason}",
        },
        operatorExportCreated: {
          ok: "Il report in CSV è pronto.",
          failed: "Non è stato possibile generare il report in CSV, riprova.",
        },
      },
    },
  },
  smartSearch: {
    search: "Cerca",
    placeholder: "Cerca valori singoli o multipli...",
    enterValue: "Inserisci un valore...",
    moreOptions: "Più opzioni di ricerca",
    lessOptions: "Meno opzioni di ricerca",
    searchBy: "Cerca per:",
    addOperator: "Aggiungi operatore",
    searchByForensic: "Cerca per valori forensi:",
    noRecentSearches: "Non ci sono ricerche recenti",
    searchPrefixes: {
      name: "Inserimento principale",
      processHash: "Hash di processo",
      hostname: "Dispositivo",
      enrollmentCode: "ID dispositivo Coro",
      processName: "Processo",
      user: "Nome utente",
      eventId: "ID evento",
      currentValue: "Valore attuale",
      oldValue: "Valore precedente",
      operationType: "Tipo di operazione",
      registryKeyOperationType: "Tipo di operazione",
      scheduledTaskOperationType: "Tipo di operazione",
      action: "Azione",
      targetAccount: "Account target",
      domain: "Dominio account",
    },
    clearRecentSearches: "Cancella le ricerche recenti",
  },
  general: {
    retry: "[TRANSLATION] Retry",
    noAccess: "Non puoi accedere",
    protocol: "[TRANSLATION] Protocol",
    port: "[TRANSLATION] Port | Ports",
    ipAddress: "[TRANSLATION] IP address",
    details: "Dettagli",
    domain: "Dominio account",
    customer: "Clienti",
    value: "Value",
    gateway: "Gateway",
    unknown: "Sconosciuto",
    allowed: "Consentito",
    description: "Descrizione",
    coroConsole: "Coro console",
    workspacesManagement: "Gestione delle aree di lavoro",
    workspace: "Area di lavoro",
    workspaceId: "ID area di lavoro",
    workspaces: "{n} area di lavoro | {n} aree di lavoro",
    review: "Revisione",
    notify: "Notifica",
    comment: "Commento",
    or: "O",
    during: "Durante",
    type: "Tipo",
    device: "Dispositivo",
    process: "Processo",
    enrollmentCode: "Codice di registrazione",
    added: "Aggiunto",
    noActionsAvailable: "Nessuna azione disponibile",
    itemsPerPage: "Elementi per pagina",
    sortBy: "Ordina per",
    aliases: "pseudonimi",
    exportCsv: "Esporta CSV",
    cloudApps: "App cloud",
    backToControlPanel: "‹ Pannello di controllo",
    genericBackTo: "< Indietro",
    backToDashboard: "‹ Actionboard",
    users: "Utenti protetti",
    messages: "Messaggi",
    manageWorkspaces: "Gestisci aree di lavoro",
    tickets: "Registro delle segnalazioni",
    socTicketLog: "Registro delle segnalazioni di servizio gestite",
    controlPanel: "Pannello di controllo",
    devices: "Dispositivi",
    backOffice: "Back office",
    cancel: "Cancella",
    apply: "Applica",
    admin: "amministratore",
    yes: "Sì",
    no: "No",
    applyFilters: "Applica filtri",
    clearFilters: "Cancella filtri",
    create: "Crea",
    reset: "Ripristina",
    back: "Indietro",
    save: "Salva",
    close: "Chiudi",
    imDone: "Ho terminato",
    done: "Fatto",
    login: "Accesso",
    copied: "Copiato",
    finish: "Termina",
    confirm: "Conferma",
    gotIt: "Ho capito",
    ok: "OK",
    search: "Ricerca...",
    clearAllFilters: "Cancella tutti i filtri",
    clearAll: "Cancella tutto",
    disconnected: "Disconnesso",
    connected: "Connesso",
    incomplete: "Incompleto",
    notConnected: "Non connesso",
    enabled: "Abilitato",
    disabled: "Disabilitato",
    bulkActions: "Azioni in blocco",
    copyToClipboard: "Copia negli appunti",
    copy: "Copia",
    viewEvents: "Visualizza segnalazioni",
    viewAll: "Visualizza tutto",
    viewLess: "[TRANSLATION] View less",
    view: "Visualizza",
    viewUsers: "Visualizza utenti",
    service: "Servizio",
    needHelp: "Ho bisogno di aiuto per questo problema",
    pleaseCorrectErrors: "Correggi gli errori",
    home: "Actionboard",
    events: "Segnalazioni",
    ticketLog: "Registro delle segnalazioni",
    ticketDetails: "Dettagli della segnalazione",
    goToDashboard: "Vai alla mia schermata iniziale",
    desktopNotSupported:
      "Il nostro sistema è progettato per funzionare su un computer desktop o portatile, con un browser aperto a schermo intero. Assicurati di utilizzare un computer desktop o portatile e di ingrandire la finestra del browser.",
    low: "Basso",
    medium: "Medio",
    high: "Alto",
    noData: "Nessun dato",
    download: "Scarica",
    learnMore: "Scopri di più",
    poweredBy: "Reso disponibile da",
    outlook: "Outlook 365",
    google: "Google",
    gmail: "Gmail",
    connect: "Connetti",
    enable: "Abilita",
    offline: "Offline",
    mute: "Disattiva l’audio",
    unmute: "Riattiva l’audio",
    resolve: "Risolvi",
    actions: "Azioni",
    detectionLog: "Registro di rilevamento",
    activityLog: "Registro attività",
    allActivity: "Tutte le attività",
    openTickets: "Apri segnalazione",
    lastLoggedInUsers: "Utenti che hanno effettuato l'accesso di recente",
    allOpenTickets: "Tutte le segnalazioni aperte",
    noOpenTickets: "Non ci sono segnalazioni aperte",
    noRecentLogins: "Nessun accesso recente",
    noActivity: "Nessuna attività registrata",
    protectedSince: "Protetto dal",
    processed: "Chiuso",
    processedOn: "Chiuso in data",
    unresolvedTickets: "Segnalazioni non risolte",
    noTickets: "Nessuna segnalazione",
    select: "Seleziona",
    selected: "Selezionato",
    all: "Tutto",
    open: "Apri",
    revoke: "Revoca",
    notAvailable: "N/D",
    addon: "Componente aggiuntivo",
    step: "Passaggio",
    osVersion: {
      android: "Android",
      ios: "iOS",
      windows: "Windows",
      macOs: "macOS",
      osx: "macOS",
      linux: "Linux",
    },
    refresh: "Aggiorna",
    at: "alle",
    until: "fino al",
    everyXDays: "Ogni giorno | Ogni {n} giorni",
    none: "Nessuno",
    and: "e",
    on: "il giorno",
    of: "di",
    group: "gruppo | gruppi",
    recaptcha:
      'Questa pagina è protetta da reCAPTCHA ed è soggetta <a data-testid="login-privacy-policy" href="https://www.google.com/policies/privacy/" target="_blank">all’Informativa sulla privacy</a> di Google e ai<a data-testid="login-terms-of-service" href="https://www.google.com/policies/terms/" target="_blank">\nTermini del servizio</a>',
    add: "Aggiungi",
    tooltips: {
      usersSaySafe: "Utenti contrassegnati come sicuri",
      usersSayPhishing: "Utenti contrassegnati come phishing",
      syncUsers: "Sincronizza ora gli utenti dalle applicazioni cloud",
    },
    by: "da",
    seeNMore: "Visualizza altri {n}",
    seeMore: "Vedi altro",
    resolvedLastNinetyDays: "Risolto negli ultimi 90 giorni",
    nUsers: "{n} utente | {n} utenti",
    edr: "Endpoint Detection & Response",
    seeLess: "Vedi meno",
    drives: "Unità",
    name: "Nome",
    status: "Stato",
    key: "Chiave",
    blocked: "Bloccato",
    nonBlocked: "Non bloccato",
    closeUponConfirmation: "Chiudi questa segnalazione e i ticket correlati alla conferma",
    closeOneTicketUponConfirmation: "Chiudi questa segnalazione alla conferma",
    showAll: "Mostra tutto",
    emails: "E-mail",
    email: "E-mail",
    remove: "Rimuovi",
    edit: "Modifica",
    hours: "{n} ora | {n} ore",
    minutes: "{n} minuto | {n} minuti",
    days: "{n} giorno | {n} giorni",
    test: "Test",
    format: "Formato",
    success: "Riuscito correttamente",
    tryAgain: "Riprova",
    subscriptionExpired: "L’abbonamento è scaduto.",
    noPermissions: "Non disponi delle autorizzazioni per eseguire questa azione.",
    optional: "facoltativo",
    next: "Avanti",
    filters: "Filtri",
    accessToSensitiveDataRestricted: "L’accesso ai dati sensibili è limitato",
    localeDisplayNames: {
      [WorkspaceLocale.EN_US]: "English (US)",
      [WorkspaceLocale.EN_GB]: "English (UK)",
      [WorkspaceLocale.ES]: "Español (ES)",
      [WorkspaceLocale.IT]: "Italiano (IT)",
    },
    viewByList: "Visualizza per elenco",
    views: "Visualizzazioni",
    loadingItems: "Caricamento elementi...",
    noDataAvailable: "Nessun dato disponibile",
  },
  appBar: {
    userContextMenu: {
      logout: "disconnetti",
      myAccount: "Il mio account",
      contactSupport: "Contatta l’assistenza",
    },
    [WorkspaceNavigationButton.WORKSPACE]: "Vista dell'area di lavoro",
    [WorkspaceNavigationButton.GLOBAL]: "Vista globale",
  },
  errors: {
    fixErrorsBelow: "[TRANSLATION] Please fix the errors below",
    somethingWentWrong:
      'Si è verificato un problema nel tentativo di recuperare "{url}". Errore {status}',
    invalidVerificationCode: "Codice di verifica non valido",
    tooManyOtpResetRequests: "Troppe richieste di ripristino",
    incorrectPSACredentials:
      "Si è verificato un problema. Verifica che le credenziali siano corrette e riprova.",
    incorrectSIEMCredentials: "Sono state fornite credenziali non corrette per il provider SIEM.",
    siemProviderNotAvailable:
      "Ops, il provider SIEM non è disponibile al momento. Riprova più tardi.",
    generic: "Si è verficato un problema, riprova.",
    webhookTriggerTestFailed: "Test trigger webhook non riuscito",
    webhookTestFailed: "Test webhook non riuscito",
    domainAlreadyExists: "Il dominio esiste già",
    emailProxyRelayHostIsNotReachable:
      "Coro non è in grado di raggiungere l'host di relay SMTP. Verifica le impostazioni dell'host e della porta",
    emailProxyCoroMxIsNotPrimary:
      "L'MX primario per il dominio non punta al server proxy SMTP CORO. Verifica le impostazioni DNS",
    cannotBlocklistDomain: "Il dominio non può essere inserito nell‘elenco bloccati",
    cannotBlocklistEmail: "L'e-mail non può essere bloccata",
  },
  modals: {
    [SecurityAwarenessAction.ALLOW_CLOUD_SERVICE]: {
      title: "[TRANSLATION] Allow Security Awareness Training in {service}",
      step1: {
        title: "[TRANSLATION] Allow Security Awareness Training",
        description:
          "[TRANSLATION] To enable Coro to send phishing simulation emails to your users, <span class='subtitle2'>use the guide below</span> to update your {service} allowlist.",
        guide: "[TRANSLATION] {service} allowlist guide",
        actionBtn: "[TRANSLATION] I've done it",
      },
      step2: {
        title: "[TRANSLATION] Send test emails",
        description:
          "[TRANSLATION] Coro will send 2 test emails to your inbox.<br>Once you receive it, you’ll need to confirm to proceed.",
        actionBtn: "[TRANSLATION] Send test email",
      },
      step3: {
        title: "[TRANSLATION] Confirm you received the test emails",
        description:
          "[TRANSLATION] <span class='subtitle2'>Check your {service} inbox</span> for the 2 test emails.<br>Select confirm once you've received it to complete the setup.",
        actionBtn: "[TRANSLATION] Confirm receipt",
      },
      successMessage:
        "[TRANSLATION] Security Awareness Training was successfully allowlisted in Google Workspace.<br>Your users will now start receiving training and simulation emails.",
    },
    [TicketAction.CONFIGURE_PROXY]: {
      title: "Mancano le impostazioni del gateway {proxyDirection} o sono errate",
      description:
        "Correggi le impostazioni attraverso il Pannello di controllo, quindi ritorna su questa segnalazione",
      actionBtn: "Configura",
    },
    newOfficeAccountError: {
      title: "Questo account Microsoft 365 sembra essere nuovissimo",
      description:
        "Considerato che Microsoft 365 impiega alcune ore per configurare i privilegi dei nuovi account, ti invitiamo a iscriverti a Coro in un secondo momento.",
      actionButton: "Ho capito",
    },
    [WorkspaceCodeActions.CREATE]: {
      title: "Crea codice dell'area di lavoro",
      actionBtn: "Crea",
      codeName: "Nome codice",
      codeType: "Tipo di codice",
      priceBookNumber: "Numero del listino prezzi",
      expirationDate: "Data di scadenza",
      workspaceType: "Tipo di area di lavoro",
      bundles: "Pacchetti",
      commercialTerms: "Termini commerciali",
      discount: "Sconto",
      trialDuration: "Durata periodo di prova",
      creditCardRequired: "Carta di credito necessaria",
      relatedTo: "Correlato a",
      agentDetails: "Agente",
      salesDetails: "AE (vendite)",
      mspDetails: "MSP",
    },
    [WorkspaceCodeActions.DELETE]: {
      title: "Vuoi rimuovere il codice dell'area di lavoro?",
      actionBtn: "Sì, elimina",
      description: "Il codice dell'area di lavoro verrà eliminato con tutte le sue impostazioni",
    },
    [TicketsModal.CONTENTS]: {
      title: "Contenuti della formazione sulla sicurezza",
    },
    [WorkspaceCodeActions.ACTIVATE]: {
      title: "Vuoi attivare il codice?",
      actionBtn: "Sì, attiva",
      validTill: "Valido fino al",
      description:
        "Il codice dell'area di lavoro verrà attivato e sarà disponibile per le nuove aree di lavoro. Se necessario, è possibile impostarne la data di validità. Se non fosse necessario, basterà lasciare il campo vuoto",
    },
    [WorkspaceCodeActions.DEACTIVATE]: {
      title: "Vuoi disattivare il codice dell'area di lavoro? ",
      actionBtn: "Sì, disattiva",
      description:
        "Il codice dell'area di lavoro è associato alle aree di lavoro e pertanto non può essere eliminato. Puoi disattivare il codice in modo che non sia disponibile nelle nuove aree di lavoro",
    },
    [UserGroupAction.ADD_NEW_GROUP]: {
      title: "Aggiungi gruppi alla protezione Coro",
      actionBtn: "Aggiungi gruppi",
      description:
        "Puoi aggiungere utenti alla protezione di gruppo, utilizzando i gruppi definiti nella directory degli utenti.",
      placeholder: "Inserisci il/i nome/i del/i gruppo/i",
      noData: "Non è stato trovato alcun gruppo corrispondente",
      label: "Nome del gruppo",
    },
    [UserGroupAction.REMOVE_GROUPS]: {
      title: "Vuoi rimuovere {n} gruppo? | Vuoi rimuovere {n} gruppi?",
      description: "I servizi di protezione Coro non verranno più applicati a questi gruppi.",
      actionBtn: "Sì, elimina",
    },
    [UserAction.IMPORT_USERS_FROM_CSV]: {
      title: "",
      actionBtn: "Aggiungi utenti",
      disclaimer:
        "La prima colonna del file CSV deve contenere gli indirizzi e-mail validi di tutti gli utenti da aggiungere alla protezione Coro. Le colonne aggiuntive e le voci non valide verranno ignorate.",
      parsingCsv: "Analisi del file CSV in corso, ti invitiamo ad attendere",
      uploadTxt: "Carica un file CSV",
      subtitle: "o trascina il file qui",
      downloadTemplate: "Scarica il nostro modello per evitare dati non validi.",
      errors: {
        general: "Si è verificato un problema nell’importazione del file CSV. Riprova.",
      },
      importInProgress:
        "L’importazione è in corso. Per la conferma dell’esito, consulta il registro attività.",
    },
    [UserAction.ADD_ALL_USERS_CONFIRMATION]: {
      title: "Vuoi aggiungere la protezione a tutti gli utenti da {service}?",
      description:
        "Tutti gli utenti attuali di {service} verranno aggiunti alla protezione dopo la sincronizzazione con {service}. In futuro, i nuovi utenti di {service} verranno automaticamente aggiunti alla protezione Coro.",
      actionBtn: "Aggiungi protezione",
    },
    [UserAction.ADD_USERS]: {
      title: "Aggiungi utenti alla protezione Coro",
      actionBtn: "Aggiungi utenti",
      label: "E-mail",
      description: "Il piano prevede 19 posti disponibili.",
      placeholder: "Inserisci e-mail",
    },
    [UserAction.ADD_ALL_USERS]: {
      title: "Aggiungi protezione a tutti gli utenti",
      description: "Seleziona l’applicazione cloud che desideri proteggere per tutti gli utenti.",
      noAppsProtected: "Al momento nessuna delle tue applicazioni cloud è protetta",
      noAppsProtectedDescription: "Collega almeno un servizio cloud per avviare la protezione",
      connectServicesBtn: "Connetti",
      actionBtn: "Aggiungi protezione",
    },
    [ExceptionsAction.ADD_NEW_EXCEPTION]: {
      title: "Aggiungi singoli utenti all’elenco delle esclusioni",
      description:
        "Gli utenti aggiunti all’elenco delle esclusioni non saranno coperti dalla protezione",
      actionBtn: "Aggiungi utenti",
    },
    [UserAction.REMOVE_USERS]: {
      title: "Rimuovi utente",
      description: "I servizi di protezione Coro non verranno più applicati a <b>{email}</b>.",
      checkbox: "Aggiungi alle esclusioni",
      actionBtn: "Sì, rimuovi",
    },
    [UserAction.ADD_TO_EXCEPTION]: {
      title: "Aggiungi un singolo utente all’elenco delle esclusioni",
      description:
        "Gli utenti aggiunti all’elenco delle esclusioni non saranno coperti dalla protezione",
      actionBtn: "Aggiungi utenti",
    },
    [UserAction.REMOVE_EXCEPTION]: {
      title: "Rimuovi esclusione",
      actionBtn: "Sì",
      description: "I servizi di protezione Coro verranno applicati nuovamente a questo utente.",
    },
    [TicketAction.SUSPEND_FROM_ALL]: {
      title:
        "Vuoi sospendere l’utente da tutte le app protette? | Vuoi sospendere gli utenti da tutte le app protette?",
      description:
        "L’accesso dell’utente ai propri account su tutte le app cloud protette da Coro sarà temporaneamente sospeso. | L’accesso degli utenti ai loro account su tutte le app cloud protette da Coro sarà temporaneamente sospeso.",
      actionBtn: "Sospendi",
    },
    [TicketAction.SUSPEND_FROM_SERVICE]: {
      title: "Vuoi sospendere l’utente da {service}?",
      description:
        "L’accesso dell’utente al proprio account su {service} sarà temporaneamente sospeso.",
      actionBtn: "Sospendi",
    },
    [TicketAction.SIGN_IN_TO_ALL]: {
      title:
        "Vuoi chiedere all’utente di accedere a tutte le app protette? | Vuoi chiedere agli utenti di accedere a tutte le app protette?",
      description:
        "All’utente verrà chiesto di effettuare nuovamente l’accesso a tutte le app cloud protette da Coro che sta utilizzando. | Agli utenti verrà chiesto di effettuare nuovamente l’accesso a tutte le app cloud protette da Coro che stanno utilizzando.",
      actionBtn: "Richiesta",
    },
    [TicketAction.MARK_AS_PROCESSED]: {
      title: "Vuoi chiudere le segnalazioni selezionate?",
      description: "Le segnalazioni chiuse verranno rimosse dalla tua actionboard.",
      permissionsBanner: "Non disponi dell’autorizzazione per riaprire le segnalazioni relative a:",
      notRestrictedModules:
        "Le segnalazioni chiuse sui seguenti moduli verranno rimosse dalla tua actionboard:",
      actionBtn: "Chiudi le segnalazioni",
    },
    [TicketAction.MARK_AS_UNPROCESSED]: {
      title: "Vuoi riaprire le segnalazioni selezionate?",
      description: "Le segnalazioni riaperte verranno aggiunte alla tua actionboard.",
      permissionsBanner: "Non disponi dell’autorizzazione per riaprire le segnalazioni relative a:",
      notRestrictedModules:
        "Le segnalazioni riaperte sui seguenti moduli verranno aggiunte alla tua actionboard.",
      actionBtn: "Riapri segnalazioni",
    },
    [MalwareAction.TOGGLE_INIT_SCAN]: {
      title: "Avviso",
      description:
        "La scansione delle unità endpoint è un’operazione che richiede risorse e può, quindi, influire sull’esperienza degli utenti finali.",
      actionBtn: "Procedi",
    },
    [AdminUsersAction.ADD]: {
      title: "Aggiungi utente amministratore",
      firstName: {
        placeholder: "Nome",
        label: "Nome",
      },
      lastName: {
        placeholder: "Cognome",
        label: "Cognome",
      },
      email: {
        placeholder: "E-mail",
        label: "E-mail",
      },
      role: {
        placeholder: "Ruolo",
        label: "Ruolo",
      },
      actionBtn: "Salva",
      errors: {
        general: "Correggi gli errori riportati di seguito",
        restriction: "Seleziona il tipo di autorizzazione",
      },
    },
    [AdminUsersAction.EDIT]: {
      title: "Modifica utente amministratore",
      actionBtn: "Salva",
    },
    [DisableNgavAction.REAL_TIME_PROTECTION]: {
      title: "[TRANSLATION] Disable Real-Time Protection?",
      description:
        "[TRANSLATION] Disabling <span class='subtitle1'>Real-Time Malware and Ransomware Protection</span> leaves your device vulnerable to threats such as malware, viruses, and ransomware. <div class='mt-4'>Are you sure you want to continue?</div>",
      actionBtn: "[TRANSLATION] Disable",
    },
    [DisableNgavAction.ADVANCED_THREAT_CONTROL]: {
      title: "[TRANSLATION] Disable Advanced Threat Control?",
      description:
        "[TRANSLATION] Disabling <span class='subtitle1'>Advanced Threat Control</span> stops device protection against suspicious processes, potentially increasing security risks. <div class='mt-4'>Are you sure you want to continue?</div>",
      actionBtn: "[TRANSLATION] Disable",
    },
    [DisableNgavAction.SECURED_SHADOW_BACKUP]: {
      title: "[TRANSLATION] Disable Secured Shadow Backups?",
      description:
        "[TRANSLATION] Disabling <span class='subtitle1'>Secured Shadow Backups</span> may leave your device vulnerable to ransomware and data loss. <div class='mt-4'>Are you sure you want to continue?</div>",
      actionBtn: "[TRANSLATION] Disable",
    },
    addConnectwiseConnector: {
      integrationName: {
        label: "Nome dell’integrazione",
        placeholder: "Inserisci il nome dell’integrazione",
      },
      title: {
        generic: "Aggiungi un connettore all’ambiente PSA",
        add: {
          [PsaProviders.GRADIENT]: "Aggiungi un connettore Gradient",
          [PsaProviders.CONNECTWISE]: "Aggiungi un connettore ConnectWise",
          [PsaProviders.AUTOTASK]: "Aggiungi un connettore Autotask",
        },
        edit: {
          [PsaProviders.GRADIENT]: "Modifica il connettore Gradient",
          [PsaProviders.CONNECTWISE]: "Modifica il connettore ConnectWise",
          [PsaProviders.AUTOTASK]: "Modifica il connettore Autotask",
        },
      },
      companyName: {
        label: "Nome della società",
        placeholder: "Inserisci il nome della società (MSP)...",
      },
      domain: {
        label: "Dominio ConnectWise",
        placeholder: "Inserisci il dominio ConnectWise...",
      },
      publicKey: {
        label: "Chiave pubblica",
        placeholder: "Inserisci chiave pubblica...",
      },
      privateKey: {
        label: "Chiave privata",
        placeholder: "Inserisci chiave privata...",
      },
      enableTicketing: "Attiva l’invio di segnalazioni",
      enableBilling: "Abilita le informazioni di fatturazione",
    },
    [ConnectorActions.ADD_SIEM]: {
      title: "Aggiungi connettore",
      actionBtn: "Aggiungi",
      listenerUrlLabel: "URL del listener",
      listenerUrlHint: "Supporta HTTP e HTTPS",
      customHeaders: "Personalizza intestazioni",
      applyToAll: "Si applica a tutti i clienti",
      header: "Intestazione",
      value: "Valore",
      token: "Token",
      addBtn: "+ intestazione",
      azureSentinel: {
        workspaceId: "ID area di lavoro",
        primaryKey: "Chiave principale",
        logType: "Tipo di registro",
      },
    },
    [ConnectorActions.UPDATE_SIEM]: {
      title: "Modifica connettore",
      actionBtn: "Salva",
    },
    [ConnectorActions.DELETE_SIEM]: {
      title: "Vuoi eliminare il connettore?",
      description:
        "Il connettore {name} verrà disattivato e rimosso dalla tua area di lavoro. | Il connettore {name} verrà disattivato e rimosso da tutte le aree di lavoro dei clienti.",
      actionBtn: "Sì, elimina",
    },
    [PhishingAction.ADD_TO_ALLOWLIST]: {
      title: "Aggiungi all’elenco degli elementi consentiti",
      label: "Inserisci indirizzi e-mail, domini o IP/intervalli di IP",
      addToListLabel: "Aggiungi indirizzi e-mail, domini o IP/intervalli di IP",
      placeholder: "Inserisci uno o più indirizzi e-mail, domini, indirizzi IP o intervalli di IP",
      actionBtn: "Aggiungi all’elenco",
      authList: "Errore di autenticazione del tipo {listType}",
      suspiciousContentList: "Contenuto sospetto del tipo {listType}",
    },
    [PhishingAction.ADD_TO_BLOCKLIST]: {
      title: "Aggiungi all’elenco degli elementi bloccati",
      actionBtn: "Aggiungi all’elenco",
    },
    [PhishingAction.REMOVE_FROM_BLOCKLIST]: {
      title: "Vuoi rimuovere l'elemento dall’elenco dei bloccati?",
      description: "{name} non sarà più inserito nell’elenco bloccati.",
      actionBtn: "Sì, rimuovi",
    },
    [PhishingAction.REMOVE_FROM_ALLOWLIST]: {
      title: "Vuoi rimuovere l'elemento dall’elenco consentiti?",
      description: "{name} non sarà più inserito nella lista degli elementi ammessi.",
      actionBtn: "Sì, rimuovi",
    },
    [PhishingAction.DOWNLOAD_ALLOW_BLOCK_LIST_EXAMPLE_CSV]: {
      title: "",
      actionBtn: "Aggiungi utenti",
      disclaimer:
        "La prima colonna del file CSV deve contenere gli indirizzi e-mail, i domini, gli indirizzi IP o gli intervalli di IP validi di tutti gli utenti da aggiungere alla protezione Coro. Le colonne aggiuntive e le voci non valide verranno ignorate.",
      parsingCsv: "Analisi del file CSV in corso, ti invitiamo ad attendere",
      parsingFile: "Analisi del file in corso, ti invitiamo ad attendere",
      uploadTxt: "Carica un file CSV",
      subtitle: "o trascina il file qui",
      downloadTemplate: "Scarica il nostro modello per evitare dati non validi.",
      errors: {
        general: "Si è verificato un problema nell’importazione del file CSV. Riprova.",
      },
      importInProgress:
        "L’importazione è in corso. Per la conferma dell’esito, consulta il registro attività.",
    },
    [DataLossAction.ADD_PERMISSION_FOR_ALL_USERS]: {
      title: "Aggiungi autorizzazione per tutti gli utenti",
      actionBtn: "Aggiungi",
    },
    [DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_GROUPS]: {
      title: "Aggiungi autorizzazioni per gruppi specifici",
      placeholder: "Inserisci i nomi dei gruppi protetti",
      label: "Nome del gruppo",
      actionBtn: "Aggiungi",
    },
    [DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_USERS]: {
      title: "Applica i criteri a questi utenti o domini",
      placeholder: "Inserisci l’e-mail o il nome del dominio degli utenti...",
      label: "Inserisci l’e-mail o il nome del dominio degli utenti...",
      actionBtn: "Aggiungi",
    },
    addAutotaskConnector: {
      userName: {
        label: "Nome utente",
        placeholder: "Inserisci nome utente...",
      },
      secret: {
        label: "Segreto",
        placeholder: "Inserisci segreto...",
      },
    },
    removePsa: {
      title: "Vuoi disconnettere il connettore?",
      description: "Vuoi davvero scollegare il connettore?",
      actionBtn: "Sì, disconnetti",
    },
    [AdminUsersAction.DELETE]: {
      title: "Vuoi rimuovere l’utente amministratore?",
      description:
        "<span class='text--semibold'>{email}</span> non avrà più accesso a quest’area di lavoro.",
      channelDescription:
        "<span class='text--semibold'>{email}</span> non avrà più accesso a questa area di lavoro. Se l'utente amministratore verrà aggiunto come amministratore MSP, anche le relative autorizzazioni verranno rimosse.",
      actionBtn: "Sì, rimuovi",
    },
    [AdminUsersAction.DELETE_MFA]: {
      title: "Vuoi eliminare i dati 2FA?",
      description:
        "A <span class='text--semibold'>{email}</span> verrà chiesto di reimpostare i dati 2FA.",
      actionBtn: "Sì, elimina",
    },
    [AdminUsersAction.TOGGLE_WORKSPACE_MFA]: {
      title: "Non vuoi richiedere la 2FA?",
      description:
        "Se l’autenticazione a due fattori verrà disattivata, l’identità degli utenti amministratori dell’area di lavoro verrà verificata solo tramite password e renderà l’area di lavoro meno sicura.",
      actionBtn: "Sì",
    },
    [AccountAction.TOGGLE_MFA]: {
      title: "Vuoi disabilitare l'autenticazione 2FA?",
      description:
        "Se si disattiva l’autenticazione a due fattori, l’identità di un amministratore verrà verificata solo tramite password. Tale azione renderà l’area di lavoro meno sicura.",
      actionBtn: "Sì, disabilita",
    },
    removeApiKey: {
      title: "Vuoi revocare le credenziali API?",
      description:
        "Queste credenziali API non saranno più valide. Quest’azione non può essere annullata.",
      actionBtn: "Revoca",
    },
    addApiKey: {
      title: "Crea le credenziali API",
      description: "Descrizione",
      expirationDate: "Data di scadenza",
      makeSureYouSaveIt: "Assicurati di salvare: non potrai più accedervi.",
      yourNewToken: "Il tuo nuovo token di accesso personale è stato generato",
      actionBtn: "Crea",
      clientId: "ID client",
      clientSecret: "Segreto client",
    },
    copyInviteLink: {
      title: "Copia link di invito",
      description: "I destinatari utilizzeranno questo collegamento per accedere a Coro.",
      actionBtn: "Copia",
    },
    [DevicePostureAction.ADD]: {
      title: "Aggiungi un nuovo criterio del dispositivo a {osType}",
      macOsDisclaimer:
        "I dispositivi macOS 15 supportano solo l'azione di revisione, mentre l'applicazione dei criteri non è disponibile",
      selectPolicyType: "Seleziona il tipo di criterio",
      action: "Azione",
      postureActions: {
        enforce: "Attua",
        review: "Revisione",
      },
      applyPolicyToDevices: "Applica i criteri ai dispositivi con queste etichette",
      labels: "Etichette",
      actionBtn: "Salva",
      wifiSettings: {
        connection: "Connessione",
        connectionTypes: {
          ALL_ENCRYPTED: "Consenti la connessione a tutte le reti crittografate",
          SPECIFIC: "Consenti la connessione a reti specifiche",
        },
        specificConnectionDescription:
          'Limita la connessione Wi-Fi a questo elenco di reti affidabili. Separa più voci con il tasto "Invio" o "Tab".',
        networksPlaceholder: "Inserisci reti specifiche...",
      },
      usbLockdown: {
        blockPortableDevices: "Blocca i dispositivi portatili",
        blockMassStorage: "Blocca i dispositivi di archiviazione di massa",
        atLeastOneRequired: "È richiesto almeno un criterio",
        policyIsRequired: "Il criterio è obbligatorio",
      },
      dlpScanSchedule: {
        days: "{n} giorno | {n} giorni",
        every: "Ogni",
        preferredTime: "Orario preferito",
        applyTo: "Applica a",
        driveTypes: {
          ALL: "Tutte le unità",
          UNENCRYPTED: "Unità non crittografate",
        },
      },
      userAccountPolicy: {
        password: "Password",
        screenLockout: "Blocco dello schermo",
        minimumLength: "Lunghezza minima",
        maximumAge: "Età massima (giorni)",
        minimumAge: "Età minima (giorni)",
        enforcePasswordHistory: "Applica la cronologia delle password",
        passwordComplexity: "La password deve soddisfare il criterio della complessità",
        passwordComplexityDescription:
          "Attiva questa impostazione per consentire a Windows e macOS di applicare i requisiti di complessità delle password stabiliti dall'organizzazione.",
        lockoutDuration: "Durata del blocco",
        lockoutThreshold: "Soglia di blocco (tentativi)",
      },
    },
    [DevicePostureAction.EDIT]: {
      title: "Modifica il criterio del dispositivo {osType}",
      actionBtn: "Salva",
    },
    [DevicePostureAction.REMOVE]: {
      title: "Vuoi eliminare il criterio?",
      description:
        "Il criterio del comportamento del dispositivo eliminato non sarà più applicato ai rispettivi dispositivi.",
      actionBtn: "Conferma",
    },
    [SocContactsActions.EDIT]: {
      title: "Modifica il contatto del servizio gestito",
      email: "E-mail",
      firstName: "Nome",
      lastName: "Cognome",
      notificationSettings: {
        title: "Impostazioni di notifica",
        [SocContactNotificationTypes.EMAIL]: "E-mail",
        [SocContactNotificationTypes.DO_NOT_NOTIFY]: "Non inviare notifiche",
      },
      closureSettings: {
        title:
          "Ricevi via e-mail i report sui ticket gestiti dal nostro team di assistenza dei servizi gestiti",
        [SocContactClosureNotification.WEEKLY]: "Riepilogo settimanale",
        [SocContactClosureNotification.PER_TICKET]: "Per segnalazione",
        [SocContactClosureNotification.DO_NOT_NOTIFY]: "Non inviare notifiche",
      },
      actionBtn: "Salva",
    },
    [SocContactsActions.CREATE]: {
      title: "Crea il contatto del servizio gestito",
      email: "E-mail",
      firstName: "Nome",
      lastName: "Cognome",
      notificationSettings: {
        title: "Impostazioni di notifica",
        [SocContactNotificationTypes.EMAIL]: "E-mail",
        [SocContactNotificationTypes.DO_NOT_NOTIFY]: "Non inviare notifiche",
      },
      actionBtn: "Salva",
    },
    [SocContactsActions.REMOVE]: {
      title: "Cancella il contatto del servizio gestito",
      description: "Vuoi rimuovere il contatto del servizio gestito?",
      actionBtn: "Sì, rimuovi",
    },
    [ServiceAction.CONNECT]: {
      title: "Connettiti a {service}",
      noServiceTitle: "Connettiti alle applicazioni cloud",
      description: "Vuoi davvero cancellare il servizio '{service}'?",
      actionBtn: "OK",
    },
    [ServiceAction.GRANT_PRIVILEGES]: {
      title: "Aggiorna le autorizzazioni Google dell’area di lavoro",
      actionBtn: "OK",
    },
    [ServiceAction.DISCONNECT]: {
      title: "Vuoi scollegare il servizio {service} dalla protezione Coro?",
      description: "Il tuo servizio {service} e i tuoi dati non saranno più monitorati e protetti.",
      actionBtn: "Sì, disconnetti",
    },
    [ServiceAction.CONNECTION_STATUS]: {
      title: "Stato della connessione di {service}",
      lastHeartbeat: "Ultimo heartbeat",
    },
    [SensitiveDataScansActions.CREATE]: {
      title: "Aggiungi un nuovo programma di scansione",
      selectOs: "Seleziona il sistema operativo",
      actionBtn: "Salva",
      every: "Ogni",
      preferredTime: "Orario preferito",
      applyTo: "Applica a",
      applyToLabels: "Applica i criteri ai dispositivi con queste etichette",
      labelsPlaceholder: "Seleziona etichette...",
      labelsLabel: "Etichette",
      driveTypes: {
        ALL: "Tutto",
        UNENCRYPTED: "Non crittografato",
      },
    },
    [SensitiveDataScansActions.EDIT]: {
      title: "Modifica programma di scansione",
      selectOs: "Seleziona il sistema operativo",
      actionBtn: "Salva",
      every: "Ogni",
      preferredTime: "Orario preferito",
      applyTo: "Applica a",
      applyToLabels: "Applica i criteri ai dispositivi con queste etichette",
      labelsPlaceholder: "Seleziona etichette...",
      labelsLabel: "Etichette",
      driveTypes: {
        ALL: "Tutto",
        UNENCRYPTED: "Non crittografato",
      },
    },
    [SensitiveDataScansActions.DELETE]: {
      title: "Vuoi annullare la scansione pianificata?",
      description:
        "Il criterio di pianificazione della scansione eliminato non verrà più applicato ai rispettivi dispositivi.",
      actionBtn: "Elimina",
    },
    [WorkspaceAction.SELECT_WORKPLACE]: {
      title: "Seleziona l’area di lavoro da visualizzare",
      actionBtn: "Lancia",
    },
    [WorkspaceAction.LAUNCH_WITH_2FA]: {
      title: "Vuoi configurare l'autorizzazione 2FA?",
      description:
        "L’area di lavoro <b>{workspaceId}</b> richiede di impostare l’autenticazione a due fattori.",
      actionBtn: "Sì, configura",
    },
    [SocRemediationActions.CHANGE_REMEDIATION_TYPE]: {
      title: "Conferma la selezione",
      description:
        '<b>Devi leggere quanto segue</b><br><br>Cliccando su "Conferma", dichiaro di aver concesso l’autorizzazione a eseguire azioni remote sull’area di lavoro Coro e sull’accesso alla posta elettronica, al cloud o all’endpoint degli utenti finali con la piena consapevolezza delle potenziali conseguenze. Sono consapevole che Coro non sarà ritenuta responsabile per eventuali perdite commerciali, interruzioni del flusso di lavoro o altri problemi, in quanto tali azioni verranono eseguite su mia richiesta e con il mio esplicito consenso.<br>',
      actionBtn: "Conferma",
    },
    [DeviceAllowListActions.ADD_PROCESS_RECORD]: {
      title: "Aggiungi un nuovo record per il processo",
      descriptionTitle: "Aggiungi descrizione",
      namePlaceholder: "Inserisci testo...",
      descriptionPlaceholder: "Inserisci testo...",
      descriptionLabel: "Descrizione",
      list: "Elenco",
      listDescription:
        "I processi verranno aggiunti automaticamente all’elenco degli elementi bloccati.",
      allow: "Consenti",
      block: "Blocca",
      nameLabel: "Hash",
      nameDescription: "Aggiungi hash",
      actionBtn: "Salva",
    },
    [DeviceAllowListActions.ADD_FOLDER_RECORD]: {
      title: "Aggiungi un nuovo record per la cartella",
      descriptionTitle: "Aggiungi descrizione",
      namePlaceholder: "Inserisci testo...",
      descriptionLabel: "Descrizione",
      descriptionPlaceholder: "Inserisci testo...",
      list: "Elenco",
      listDescription: "Le cartelle verranno aggiunte automaticamente all’elenco consentiti.",
      allow: "Consenti",
      block: "Blocca",
      nameLabel: "Cartella",
      nameDescription: "Aggiungi cartella",
      nameDescription2:
        "Usa le variabili relative a ogni ambiente per applicare i valori a più elementi. Per maggiori informazioni, puoi consultare la nostra <a class='coro-link' href='https://docs.coro.net/endpointsecurity/allow-block/' target='_blank'>documentazione</a>",
      actionBtn: "Salva",
    },
    [DeviceAllowListActions.ADD_FILE_RECORD]: {
      title: "Aggiungi nuovo record per il file",
      descriptionTitle: "Aggiungi descrizione",
      namePlaceholder: "Inserisci testo...",
      descriptionLabel: "Descrizione",
      descriptionPlaceholder: "Inserisci testo...",
      list: "Elenco",
      listDescription: "I file verranno aggiunti automaticamente all’elenco consentiti.",
      allow: "Consenti",
      block: "Blocca",
      nameLabel: "Percorso",
      nameDescription: "Aggiungi percorso del file",
      nameDescription2:
        "Usa le variabili relative a ogni ambiente per applicare i valori a più elementi. Per saperne di più, consultate la nostra <a class='coro-link' href='https://docs.coro.net/endpointsecurity/allow-block/' target='_blank'>documentazione</a>",
      actionBtn: "Salva",
    },
    [DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST]: {
      title: "Aggiungi all’elenco consentiti",
      infoText:
        "I processi specificati in base al loro hash o alla posizione del file immagine di seguito verranno contrassegnati come sicuri e non genereranno ticket EDR.<br>Le informazioni relative al processo non verranno memorizzate nelle schede relative a telemetria e processi.",
      namePlaceholder: "Inserisci l'hash o il percorso della cartella...",
      nameLabel: "Hash o percorso",
      nameDescription: "Aggiungi hash del processo o percorso della cartella",
      descriptionTitle: "Aggiungi descrizione",
      descriptionLabel: "Descrizione",
      descriptionPlaceholder: "Inserisci descrizione...",
      actionBtn: "Salva",
    },
    [DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST]: {
      title: "Aggiungi all’elenco degli elementi bloccati",
      infoText: "L'esecuzione dei processi bloccati è stata impedita per l'intera organizzazione.",
      namePlaceholder: "Digita hash...",
      nameLabel: "Hash",
      nameDescription: "Aggiungi hash di processo",
      descriptionTitle: "Aggiungi descrizione",
      descriptionLabel: "Descrizione",
      descriptionPlaceholder: "Inserisci descrizione...",
      actionBtn: "Salva",
    },
    [DeviceAllowListActions.REMOVE_RECORD]: {
      title: "Vuoi eliminare il record?",
      descriptionBlocked: "Il record non sarà più inserito nell’elenco bloccati",
      descriptionAllowed: "Il record non sarà più inserito nell’elenco consentiti",
      actionBtn: "Sì, elimina",
    },
    [DeviceAllowListActions.IMPORT_ALLOW_BLOCK_LIST_CSV]: {
      title: "Importazione del file CSV nell’elenco degli elementi consentiti/bloccati",
      actionBtn: "Importa",
      disclaimer:
        "Le voci devono contenere valori validi in tutte e 4 le colonne. Le voci con colonne aggiuntive o valori non validi verranno ignorate.",
      parsingFile: "Analisi del file CSV in corso, ti invitiamo ad attendere",
      downloadTemplate: "Scarica il nostro modello per evitare dati non validi.",
      errors: {
        general: "Si è verificato un problema nell’importazione del file CSV. Riprova.",
      },
      importInProgress:
        "L’importazione è in corso. Per la conferma dell’esito, consulta il registro attività.",
      uploadTxt: "<span class='coro-link'>Clicca per caricare</span> oppure trascina e rilascia",
      subtitle: "CSV (max {size})",
      successfullyUploaded: "{n} record aggiornati correttamente",
      invalidRecords: "{n} record di {sum} non erano validi",
      notAllRecordsUploaded: "Non tutti i record sono stati aggiornati correttamente",
    },
    importUrlGroupAllowBlockListFromCsv: {
      allowListTitle: "Importa un gruppo di URL nell’elenco degli elementi consentiti",
      blockListTitle: "Importa un gruppo di URL nell’elenco degli elementi bloccati",
      actionButton: "Importa",
      disclaimer:
        "Le voci devono contenere valori validi in una colonna. Le voci con colonne aggiuntive o valori non validi verranno ignorate.",
      parsingFile: "Analisi del file in corso, ti invitiamo ad attendere",
      uploadTxt: "Carica un file TXT",
      uploadTextSubtitle: "o trascina il file qui",
      downloadTemplate: "Scarica il nostro modello per evitare dati non validi.",
      errors: {
        general: "Si è verificato un problema nell’importazione del file. Riprova.",
      },
      importInProgress: "Voci aggiunte correttamente",
    },
    [RolesAction.ADD]: {
      title: "Aggiungi ruolo",
      role: "Ruolo",
      roleName: "Nome del ruolo",
      workspacePermissions: "Autorizzazioni dell’area di lavoro",
      actionBtn: "Salva",
    },
    [RolesAction.EDIT]: {
      title: "Modifica ruolo",
      actionBtn: "Salva",
    },
    [RolesAction.DUPLICATE]: {
      title: "Duplica ruolo",
      actionBtn: "Salva",
    },
    [RolesAction.DELETE]: {
      title: "Vuoi eliminare questo ruolo?",
      description: "Il ruolo <strong>{name}</strong> verrà eliminato",
      actionBtn: "Sì, elimina",
    },
    [RolesAction.UNABLE_TO_DELETE]: {
      title: "Non è stato possibile eliminare il ruolo",
      description:
        "È possibile eliminare solo i ruoli non associati a utenti amministratori. Rimuovi questo ruolo dagli utenti amministratori e riprova.",
      actionBtn: "Sì, elimina",
    },
    [MspRolesAction.ADD]: {
      title: "Aggiungi ruolo MSP",
      role: "Ruolo",
      roleName: "Nome del ruolo",
      childWorkspacePermissions: "Autorizzazioni dell’area di lavoro secondaria",
      workspace: {
        placeholder: "Seleziona l’area di lavoro",
        label: "Area di lavoro",
        hint: "Seleziona l'area di lavoro del channel a cui vuoi aggiungere il ruolo. Questo ruolo definisce le autorizzazioni per l'area di lavoro principale e le secondarie.",
      },
      tooltips: {
        [MspRolePermissionScopes.MSP_PORTAL_SCOPE]:
          "Gestisci l'accesso a tutte le sottoscrizioni, le impostazioni e la gestione dello spazio di lavoro.",
        [RolePermissionsScope.WORKSPACE_MANAGEMENT]:
          "Queste opzioni determinano le autorizzazioni degli amministratori MSP per la sezione area di lavoro e il pannello di controllo in tutte le aree di lavoro secondarie.",
        [RolePermissionsScope.VIEWS]:
          "Gestisci l'accesso ai dispositivi dell'area di lavoro combinata e individuale e agli elenchi degli utenti protetti.",
        [RolePermissionsScope.PROTECTION]:
          "Queste opzioni determinano le autorizzazioni degli amministratori MSP per la sezione protezione e il pannello di controllo in tutte le aree di lavoro secondarie.",
        [RolePermissionsScope.TICKETS]:
          "Gestisci l'accesso al registro dei ticket combinato, nonché ai registri dei ticket e alle actionboard per tutti gli spazi di lavoro.",
      },
      actionBtn: "Salva",
    },
    [MspRolesAction.EDIT]: {
      title: "Modifica ruolo",
      actionBtn: "Salva",
    },
    [MspRolesAction.DUPLICATE]: {
      title: "Duplica ruolo",
      actionBtn: "Salva",
    },
    [MspRolesAction.DELETE]: {
      title: "Vuoi eliminare questo ruolo?",
      description: "Il ruolo <strong>{name}</strong> verrà eliminato",
      actionBtn: "Sì, elimina",
    },
    [MspRolesAction.UNABLE_TO_DELETE]: {
      title: "Non è stato possibile eliminare il ruolo",
      description:
        "È possibile eliminare solo i ruoli non associati a utenti amministratori. Rimuovi questo ruolo dagli utenti amministratori e riprova.",
      actionBtn: "Sì, elimina",
    },
    [GlobalRolesAction.ADD]: {
      title: "Aggiungi ruolo globale",
      actionBtn: "Salva",
    },
    [GlobalRolesAction.EDIT]: {
      title: "Modifica ruolo",
      actionBtn: "Salva",
    },
    [GlobalRolesAction.DUPLICATE]: {
      title: "Duplica ruolo",
      actionBtn: "Salva",
    },
    [GlobalRolesAction.DELETE]: {
      title: "Vuoi eliminare questo ruolo?",
      description: "Il ruolo <strong>{name}</strong> verrà eliminato",
      actionBtn: "Sì, elimina",
    },
    [GlobalRolesAction.UNABLE_TO_DELETE]: {
      title: "Non è stato possibile eliminare il ruolo",
      description:
        "È possibile eliminare solo i ruoli non associati a utenti amministratori. Rimuovi questo ruolo dagli utenti amministratori e riprova.",
      actionBtn: "Sì, elimina",
    },
    [ActiveSessionsAction.REVOKE]: {
      title: "Vuoi revocare questa sessione attiva?",
      description: "L’utente <strong>{email}</strong> verrà disconnesso dal sistema.",
      actionBtn: "Sì, revoca",
    },
    [AccessPermissionAction.ADD]: {
      title: "Nuove autorizzazioni all’accesso per {type}",
      description: "Consenti l’accesso al servizio {service} da specifici paesi/indirizzi IP",
      restrict: {
        title: "Consenti l’accesso da:",
        placeholder: "Seleziona",
        allCountries: "Tutti i paesi",
        usaStates: "Stati membri degli USA",
        country: {
          placeholder: "Inserisci un elenco di paesi da cui consentire l’accesso",
          label: "Paesi",
          noData: "Non è stato trovato alcun paese corrispondente",
        },
        ip: {
          placeholder:
            "Inserisci un elenco di IP o intervalli di IP in notazione CIDR da cui consentire l’accesso.",
          label: "Indirizzi IP",
        },
        ipDescription: {
          placeholder:
            "Breve descrizione facoltativa dell’insieme di IP selezionati (ad es., Office)",
          label: "Descrizione",
        },
        types: {
          country: "Paese",
          ip: "Indirizzo IP",
        },
      },
      group: {
        title: "L’accesso sarà consentito a:",
        label: "Gruppi",
        placeholder: "Inserisci il nome del gruppo protetto",
        noData: "Non è stato trovato alcun gruppo corrispondente",
        types: {
          all: "Tutti gli utenti protetti",
          group: "Gruppi specifici protetti",
        },
      },
      users: {
        label: "E-mail",
        placeholder: "Inserisci e-mail",
      },
      remediation: {
        title: "Correzione automatica",
        types: {
          none: "Nessuno",
          suspend: "Sospendi",
          signin: "Esci",
        },
      },
      actionBtn: "Salva autorizzazioni",
      errors: {
        general: "Mancano alcune informazioni",
        restriction: "Seleziona il tipo di autorizzazione",
      },
    },
    [AccessPermissionAction.EDIT]: {
      title: "Modifica l’autorizzazione all’accesso",
      actionBtn: "Salva autorizzazioni",
    },
    [AccessPermissionAction.DELETE]: {
      title: "Vuoi cancellare l’autorizzazione di accesso?",
      description: "Le autorizzazioni di accesso non verranno più applicate ai rispettivi utenti.",
      actionBtn: "Sì, elimina",
    },
    [LabelAction.CREATE_LABEL]: {
      title: "Aggiungi etichetta",
      placeholder: "Titolo",
      actionBtn: "Aggiungi",
      selectColor: "Seleziona colore",
    },
    [LabelAction.EDIT_LABEL]: {
      title: "Modifica etichetta",
      actionBtn: "Salva",
    },
    [LabelAction.DELETE_LABEL]: {
      title: "Vuoi eliminare l’etichetta {name}?",
      description:
        "Ti informiamo che l’etichetta <b>{name}</b> verrà rimossa su <b>{deviceCount}</b> dispositivo . | Ti informiamo che l’etichetta <b>{name}</b> verrà rimossa su <b>{deviceCount} </b>dispositivi .",
      actionBtn: "Sì, elimina",
    },
    [AgentDeploymentAction.SELECT_CHANNEL]: {
      title: "Mostra i canali di rilascio",
      description: "Impostazione dei canali di rilascio consentiti nell’area di lavoro",
      channelChoices: {
        stable: "Canali di rilascio stabili",
        all: "Tutti i canali di rilascio",
      },
      actionBtn: "Salva",
    },
    [DeviceAction.REMOVE_FROM_PROTECTION]: {
      title: "Vuoi disattivare la protezione? | Vuoi nascondere questi dispositivi?",
      multipleDeviceTitle: "Vuoi nascondere questi dispositivi?",
      description:
        "Il dispositivo non verrà più monitorato e protetto dall’agente di protezione endpoint Coro. | I dispositivi non saranno più monitorati e protetti dagli agenti di protezione endpoint Coro.",
      actionBtn: "Conferma",
    },
    [DeviceAction.ENFORCE_UAC]: {
      title: "Vuoi attivare la notifica UAC?",
      description: "La notifica UAC verrà riattivata su questo dispositivo.",
      actionBtn: "Attua",
    },
    [DeviceAction.DISABLE_DEVELOPER_MODE]: {
      title: "Vuoi disattivare la modalità sviluppatore?",
      description:
        "La modalità sviluppatore verrà disabilitata su questo dispositivo. | La modalità sviluppatore è stata disabilitata sui dispositivi selezionati.",
      actionBtn: "Disabilita",
    },
    [DeviceAction.DISABLE_TAMPER_RESISTANCE]: {
      title: "Vuoi disabilitare la difesa dalle manomissioni?",
      description:
        "La disattivazione della difesa dalle manomissioni proteggerà l'agente Coro Endpoint da eventuali interferenze da parte di utenti o terze parti.",
      actionBtn: "Disabilita",
    },
    [DeviceAction.ENABLE_FIREWALL]: {
      title: "Abilita firewall",
      description: "Vuoi abilitare il firewall?",
      actionBtn: "Sì",
    },
    [DeviceAction.REMOTE_SCAN]: {
      title: "Scansione remota dei malware",
      description: "Vuoi eseguire una scansione remota alla ricerca di malware?",
      actionBtn: "Sì",
    },
    [DeviceAction.DLP_PARTIAL_SCAN]: {
      title: "Scansione remota dei dati sensibili",
      description:
        "La scansione delle unità endpoint alla ricerca di dati sensibili è un’operazione che richiede risorse (CPU/memoria).\nVuoi procedere con la scansione remota?\n",
      partialScan: "Interrompi la scansione quando i risultati sono già abbastanza consistenti.",
      actionBtn: "Sì",
    },
    [DeviceAction.STOP_REMOTE_SCAN]: {
      title: "Interrompi la scansione remota dei malware",
      description: "Vuoi interrompere la scansione remota dei malware?",
      actionBtn: "Sì",
    },
    [DeviceAction.STOP_DLP_SCAN]: {
      title: "Interrompi la scansione dei dati sensibili",
      description: "Vuoi interrompere la scansione dei dati sensibili?",
      actionBtn: "Sì",
    },
    [DeviceAction.ALLOW_NO_ENCRYPTION]: {
      title:
        "Vuoi aggiungere questo disco rigido all'elenco dei permessi? | Vuoi aggiungere questi dischi rigidi all'elenco dei permessi?",
      description:
        "In futuro, questo disco rigido verrà considerato come non contenente dati sensibili che ne richiedono la crittografia. | In futuro, questi dischi rigidi saranno trattati come non contenenti dati sensibili che ne richiedono la crittografia.",
      actionBtn: "Conferma",
    },
    [DeviceAction.COLLECT_LOGS]: {
      title: "Vuoi memorizzare i registri?",
      description:
        "Vuoi davvero memorizzare i registri per il dispositivo <b>{enrollmentCode}</b>?",
      actionBtn: "Sì, memorizza",
    },
    [DeviceAction.ENABLE_NETWORK_BLOCK_MODE]: {
      title: "Vuoi isolare il dispositivo interessato? | Vuoi isolare i dispositivi interessati?",
      description:
        "Il dispositivo verrà isolato dalla rete. | I {quantity} dispositivi verranno isolati dalla rete.",
      actionBtn: "Conferma",
    },
    [DeviceAction.DISABLE_NETWORK_BLOCK_MODE]: {
      title:
        "Vuoi disattivare l’isolamento del dispositivo? | Vuoi disattivare l’isolamento dei dispositivi?",
      description:
        "L’isolamento del dispositivo dalla rete verrà disabilitato. |  L’isolamento dei {quantity} dispositivi dalla rete verrà disabilitato.",
      actionBtn: "Conferma",
    },
    [DeviceAction.REBOOT_DEVICE]: {
      title: "Vuoi riavviare il dispositivo? | Vuoi riavviare i dispositivi?",
      description: "Il dispositivo verrà riavviato. | I {quantity} dispositivi verranno riavviati.",
      actionBtn: "Conferma",
    },
    [DeviceAction.SHUTDOWN_DEVICE]: {
      title: "Vuoi spegnere il dispositivo? | Vuoi spegnere i dispositivi?",
      description: "Il dispositivo verrà spento. | I {quantity} dispositivi verranno spenti.",
      actionBtn: "Conferma",
    },
    [DeviceAction.UPDATE_AGENT_TO_LATEST]: {
      title: "Vuoi aggiornare l’agente all’ultima versione stabile?",
      description:
        "Gli agenti sui dispositivi selezionati verranno aggiornati all’ultima versione stabile.",
      actionBtn: "Sì",
    },
    [DeviceAction.ENABLE_TAMPER_RESISTANCE]: {
      title: "Vuoi abilitare la difesa dalle manomissioni?",
      description:
        "Consentendo la disinstallazione esporrai l'agente Coro Endpoint e i backup di ripristino ad attacchi con malware e ransomware. L'opzione Abilita difesa dalle manomissioni va attivata solo per la manutenzione controllata del dispositivo o per consentire all'utente di disinstallare Coro Endpoint Agent.",
      actionBtn: "Abilita",
    },
    [DeviceAction.COLLECT_QUARANTINE_DATA]: {
      title: "Vuoi memorizzare l’elenco degli elementi in quarantena?",
      description:
        "Vuoi davvero memorizzare l’elenco degli elementi in quarantena per il dispositivo <b>{enrollmentCode}</b>?",
      actionBtn: "Sì, memorizza",
    },
    [DeviceAction.ADD_LABEL]: {
      title: "Aggiungi etichetta del dispositivo",
      placeholder: "Nome...",
      actionBtn: "Salva",
      manageLabels: "Gestisci etichette ›",
    },
    [DeviceAction.REMOVE_LABEL]: {
      title: "Rimuovi l’etichetta del dispositivo",
      placeholder: "Nome...",
      actionBtn: "Salva",
      manageLabels: "Gestisci etichette ›",
    },
    [DeviceAction.OPEN_REMOTE_SHELL_SESSION]: {
      title: "Connessione alla Remote shell",
      connectingTo:
        "Connessione in corso a {name}. La connessione potrebbe richiedere fino a {minutes} minuti",
      connectedTo: "Ora disponi di una connessione a {name}",
      commandInProgress: "In attesa dell’esecuzione del comando",
      helpTitle: "Di seguito è riportato l’elenco dei comandi supportati:",
      unknownCommand:
        "Comando sconosciuto: {command}. Digita “help” per visualizzare l’elenco dei comandi supportati.",
      combinedCommandNotSupported:
        "Comandi combinati non supportati. Per conoscere i comandi supportati esegui “help”.",
      uploadScriptLabel: "Carica script",
      uploadScriptHint: "Sono consentiti solo i file con estensione .ps1",
      run: "Esegui",
      errors: {
        shellSessionOpeningFailed:
          "Il dispositivo non risponde alla richiesta di remote shell.<br> Riprova più tardi.",
        shellSessionClosed: "La sessione di Remote shell è stata chiusa sul dispositivo.",
        commandFailed: "Il dispositivo non risponde ai comandi.<br> Riprova più tardi.",
        tooManyShellSessionsOpened: "Il dispositivo ha troppe connessioni remote shell attive.",
      },
    },
    [EdrAction.BLOCK_PROCESS]: {
      title: "Vuoi bloccare il processo? | Vuoi bloccare i processi selezionati?",
      description:
        "Il processo <b>{name}</b> con SHA-256 <b>{processHash}</b> verrà bloccato. | I processi selezionati verranno bloccati.",
      actionBtn: "Conferma",
    },
    [EdrAction.UNBLOCK_PROCESS]: {
      title: "Vuoi sbloccare il processo? | Vuoi sbloccare i processi selezionati?",
      description:
        "Il processo <b>{name}</b> con SHA-256 <b>{processHash}</b> verrà sbloccato. | I processi selezionati verranno sbloccati.",
      actionBtn: "Conferma",
    },
    [MspWorkspaceAction.EDIT_SUBSCRIPTION]: {
      title: "Modifica abbonamento",
      actionBtn: "Salva",
    },
    [MspWorkspaceAction.STOP_BULK_SUBSCRIPTION]: {
      title: "Vuoi interrompere l’abbonamento?",
      description:
        "Interromperà gli abbonamenti e gli accordi di fatturazione per tutti gli spazi di lavoro selezionati. La protezione verrà disattivata per tutti gli utenti e i dispositivi in questi spazi di lavoro.",
      actionBtn: "Sì",
    },
    [MspWorkspaceAction.START_BULK_SUBSCRIPTION]: {
      title: "Vuoi avviare l’abbonamento?",
      description:
        "Avvia gli abbonamenti e gli accordi di fatturazione per tutti gli spazi di lavoro selezionati.",
      actionBtn: "Sì",
    },
    [MspWorkspaceAction.BULK_SUBSCRIPTION_CONFIRMATION]: {
      title: "Vuoi modificare l'abbonamento?",
      description:
        "La sottoscrizione verrà applicata a tutti gli spazi di lavoro selezionati. I moduli non inclusi nell'abbonamento verranno disabilitati e i ticket relativi a questi moduli verranno chiusi automaticamente.",
      actionBtn: "Conferma",
    },
    [MspWorkspaceAction.STOP_SUBSCRIPTION]: {
      title: "Vuoi interrompere l’abbonamento?",
      description:
        "Interrompi l’abbonamento e il contratto di fatturazione con Coro. La protezione verrà disattivata per tutti gli utenti e i dispositivi.",
      actionBtn: "Sì",
    },
    [MspWorkspaceAction.EDIT_SUBSCRIPTION_CONFIRMATION]: {
      title: "Vuoi disattivare i moduli?",
      description:
        "Disattiva i <b>{removedModules}</b> moduli. Le segnalazioni relative a questi moduli verranno chiuse automaticamente.",
      actionBtn: "Conferma",
    },
    [MspWorkspaceAction.EXTEND_TRIAL]: {
      title: "Vuoi estendere il periodo di prova?",
      description: "Seleziona una nuova data di scadenza per il periodo di prova",
      expirationDate: "Data di scadenza del periodo di prova",
      actionBtn: "Conferma",
    },
    [MspWorkspaceAction.START_SUBSCRIPTION]: {
      title: "Vuoi iniziare l’abbonamento?",
      description: "Inizia il tuo contratto di abbonamento e fatturazione con Coro.",
      actionBtn: "Sì",
    },
    [MspWorkspaceAction.ARCHIVE_WORKSPACE]: {
      title: "Vuoi archiviare l’area di lavoro?",
      description: "D’ora in poi quest‘area di lavoro sarà archiviata.",
      actionBtn: "Conferma",
    },
    [MspWorkspaceAction.UNARCHIVE_WORKSPACE]: {
      title: "Vuoi ripristinare l’area di lavoro?",
      description:
        "La tua area di lavoro archiviata verrà ripristinata. Prima di poterla utilizzare, è necessario ricollegare le applicazioni cloud, aggiungere utenti alla protezione e riconfigurare le impostazioni di protezione.",
      actionBtn: "Conferma",
    },
    [MspWorkspaceAction.CONVERT]: {
      title: "Converti area di lavoro",
      selectTypeLabel: "Seleziona la tipologia in cui desideri convertire l’area di lavoro.",
      selectChannelLabel: "Seleziona l’area di lavoro del channel da usare come principale",
      actionBtn: "Converti",
      convertToChannelDescription: "Vuoi davvero convertire quest’area di lavoro in un channel?",
      convertToRegular: "Converti in area di lavoro ordinaria",
      changeParent: "Modifica area di lavoro principale",
      changeChannelParent: "Modifica area di lavoro principale",
      convertToChannel: "Converti in area di lavoro del channel",
      convertToChild: "Converti in area di lavoro secondaria",
      workspacesAutocomplete: {
        label: "Area di lavoro",
        placeholder: "Seleziona l’area di lavoro",
      },
      descendantConversionOptionsTitle:
        "L'area di lavoro del tuo channel potrebbe contenere aree secondarie: seleziona cosa ne vuoi fare qui di seguito",
      descendantConversionOptions: {
        convertToRegular:
          "Converti tutte le aree di lavoro secondarie in normali e sposta solo questo channel",
        cascadeMigration:
          "Sposta le aree di lavoro secondarie insieme a questo channel sotto una nuova area principale",
      },
    },
    [MspWorkspaceAction.CREATE_CHILD_WORKSPACE]: {
      title: "Crea un’area di lavoro secondaria",
      actionBtn: "Crea area di lavoro",
    },
    [MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE]: {
      title: "Crea un’area di lavoro del channel",
      actionBtn: "Crea area di lavoro",
    },
    [MspWorkspaceAction.CREATE_REGULAR_WORKSPACE]: {
      title: "Crea un’area di lavoro ordinaria",
      actionBtn: "Crea area di lavoro",
    },
    [ThirdPartyAppsAction.BLOCK_APP]: {
      actionBtn: "Sì, blocca",
      notifyUsersMessage: "Notifica agli utenti che usano questa app bloccata",
      description:
        "Questa azione bloccherà <b>{appName}</b> dall'accesso ai dati degli utenti in <b>{cloudAppName}</b>. Non sarà possibile usare l'app e l'accesso in futuro sarà revocato in automatico",
      title: "Vuoi bloccare {name}?",
    },
    [EmailProxyAction.ADD_DOMAIN]: {
      title: "Aggiungi un dominio e-mail",
      inboundDescription:
        "L'Inbound Gateway Coro elaborerà i messaggi di posta elettronica ricevuti da uno qualsiasi dei relay SMTP associati al dominio inserito.",
      outboundDescription: "lorem ipsum",
      domain: {
        label: "Dominio",
        placeholder: "Inserisci il nome del dominio...",
      },
      smtpProxy: "Relay SMTP",
      host: {
        title: "Host",
        placeholder: "Inserisci host...",
      },
      ip: {
        placeholder:
          "Inserisci gli IP (o gli intervalli IP in notazione CIDR) dei server SMTP consentiti.",
      },
      port: "Porta",
      actionBtn: "Aggiungi",
      addBtn: "+ Host",
      customHeader: "Personalizza intestazione",
      addHeaderBtn: "+ Intestazione",
      header: {
        placeholder: "Nome dell'intestazione",
      },
      headerValue: {
        placeholder: "Valore dell'intestazione",
      },
    },
    [EmailProxyAction.EDIT_DOMAIN]: {
      title: "Modifica dominio",
      actionBtn: "Salva",
    },
    [EmailProxyAction.REMOVE_DOMAIN]: {
      title: "Vuoi rimuovere il dominio?",
      description: "Coro smetterà di elaborare le e-mail inviate a <b>{name}</b>.",
      actionBtn: "Sì, rimuovi",
    },
    [EmailProxyAction.TEST_DOMAIN]: {
      title: "Connessione di test",
      description:
        "Verifica che la connessione al gateway in entrata sia stata stabilita correttamente.",
      mailTo: {
        title: "Invia un’e-mail a",
        placeholder: "Invia un’e-mail a...",
      },
      actionButton: "Invia e-mail di prova",
      emailSentMessage:
        "È stata inviata un’e-mail di verifica all'indirizzo {email}. Clicca sul collegamento contenuto nell’e-mail per completare il test.",
      proxyFailedMessage:
        "<b>Test connessione Inbound Gateway fallito</b>. Assicurati che il gateway in entrata sia configurato come indicato nella documentazione.",
    },
    [TicketAction.TEST_MX_RECORD]: {
      title: "Connessione di test",
      description:
        "<span class='subtitle1'>Testa la connessione del gateway in entrata</span>. <br>Se il test dovesse avere successo, il ticket verrà chiuso automaticamente.",
      actionBtn: "Test",
    },
    [WebhookAction.ADD]: {
      title: "Crea webhook",
      actionBtn: "Crea",
      generalInfo: {
        title: "Informazioni generali",
        name: "Nome",
        description: "Descrizione",
      },
      authentication: {
        title: "Autenticazione ",
        url: "URL",
        secret: "Segreto",
        authorization: "Autorizzazione",
      },
      triggers: {
        title: "Trigger",
      },
      additionalConfiguration: {
        applyToChildWorkspace: "Applica all'area di lavoro secondaria",
      },
    },
    [WebhookAction.EDIT]: {
      title: "Modifica webhook",
      actionBtn: "Salva",
    },
    [WebhookAction.REMOVE]: {
      title: "Vuoi rimuovere il webhook?",
      description: "Vuoi davvero rimuovere questo webhook?",
      actionBtn: "Sì, rimuovi",
    },
    [WebhookAction.DEACTIVATE]: {
      title: "Vuoi disattivare il webhook?",
      description: "Vuoi davvero disattivare questo webhook?",
      actionBtn: "Sì, disabilita",
    },
    [WebhookAction.ACTIVATE]: {
      title: "Vuoi attivare il webhook?",
      description: "Vuoi davvero attivare questo webhook?",
      actionBtn: "Sì, attiva",
    },
    [WebhookAction.ACTION_REQUIRED]: {
      description:
        ' È disponibile una nuova versione del trigger per <b>{name}</b>. Per mantenere attivo il webhook, seleziona " <b>Modifica</b>" dal menu con i tre puntini e configuralo entro il giorno {expirationDate}. <b>Dopo questa data, il webhook non funzionerà più.</b><br><span style="line-height: 2">Passa alla nuova versione del trigger per garantire la continuità della funzionalità.</span> | È disponibile una nuova versione del trigger per <b>{name}</b>. Per mantenere attivo il webhook, seleziona " <b>Modifica</b>" dal menu con i tre puntini e configuralo entro il giorno {expirationDate}. <b>Dopo questa data, i webhook non funzioneranno più.</b><br><span style="line-height: 2">Passa alla nuova versione del trigger per garantire la continuità della funzionalità.</span>',
      actionBtn: "Configura webhook",
      cancelBtn: "Ignora",
    },
    [WebhookAction.CANNOT_ACTIVATE]: {
      title: "Non si può riattivare il webhook",
      description:
        'Non si può attivare il webhook perché utilizza un trigger superato. <br><span style="line-height: 2"> Passa alla nuova versione del trigger per riattivarlo.</span>',
      actionBtn: "Configura webhook",
      cancelBtn: "Chiudi",
    },
    createWorkspace: {
      chooseYourSubscription: "Scegli i tuoi abbonamenti",
      recommendedModules: "Moduli consigliati",
      addons: "Componenti aggiuntivi",
      includedInBundles: "Incluso nei tuoi pacchetti",
      includedInBundle: "Incluso nel pacchetto",
      companyDetails: "Dati società",
      brandingSettings: "Impostazioni del branding",
      replaceCoroIcons: "Sostituisci le icone Coro",
      promotionalCodes: "Codici promozionali",
      reviewSubscription: "Rivedi il tuo abbonamento",
      advancedSettings: "Impostazioni avanzate",
      workspaceSettings: "Impostazioni dell’area di lavoro",
      descriptions: {
        createChannelMspWorkspace:
          "Creando un’area di lavoro, verrà inviato un invito all’indirizzo e-mail del rappresentante.",
        createChildMspWorkspace:
          "Un invito verrà inviato all’amministratore dell’area di lavoro. Avrai il ruolo di amministratore per questo account.",
        createRegularWorkspace:
          "Un invito verrà inviato all’amministratore dell’area di lavoro. Avrai il ruolo di amministratore per questo account.",
      },
      workspaceCreated: "{workspace} è stata creata correttamente",
      inviteSent: "Un invito è stato inviato a {email}",
      mspAdminExists:
        "Tieni presente che l'account dell'amministratore {email} esiste già come amministratore MSP nell'area di lavoro principale. Pertanto, questo account non verrà aggiunto come amministratore all'area di lavoro, ma sarà comunque in grado di gestire l'area principale e le relative secondarie in base alle autorizzazioni del ruolo MSP",
      requestInProgress: "Ti invitiamo ad attendere... Stiamo elaborando la tua richiesta",
      requestFailed:
        "Si è verificato un problema nella creazione dell’area di lavoro. Riprova più tardi.",
      companyName: "Nome della società",
      domain: "Nome del dominio",
      parentWorkspace: "Area di lavoro principale",
      domainPlaceholder: "Nome del dominio (ad es. “customerdomain.com”)",
      domainHint: "Inserisci il dominio e-mail completo del cliente",
      displayName: "Mostra nome",
      displayNameHint: "Max 30 caratteri",
      limitProtectedUsers: "Limita gli utenti protetti (facoltativo)",
      limitProtectedUsersPlaceholder: "Opzionale: limita il numero di utenti protetti",
      limitProtectedUsersHint:
        "In presenza di un limite impostato, il cliente non potrà aggiungere alla protezione Coro un numero di utenti superiore a quanto specificato.",
      adminEmail: "E-mail amministratore",
      adminEmailOptional: "E-mail amministratore (opzionale)",
      adminEmailPlaceholder: "E-mail dell’amministratore del cliente",
      adminEmailHint:
        "L’amministratore del cliente verrà invitato all’area di lavoro. Puoi farlo anche in un secondo momento dall’interno dell’area di lavoro.",
      notifications: "Notifiche",
      imageError: "L’immagine non corrisponde alle specifiche",
      headerIcon: "Icona intestazione",
      replaceImage: "Sostituisci",
      imageHint: "Icona quadrata bianca su sfondo trasparente. Massimo 500KB. File png, svg",
      promoCodes: {
        label: "Codice promozionale",
        placeholder: "Immetti il codice di configurazione dell'area di lavoro",
        description: "Indica il codice dell'area di lavoro per aiutare il team Coro a configurarla",
      },
      language: {
        label: "Lingua della piattaforma predefinita",
        placeholder: "Lingua della piattaforma predefinita",
      },
      notificationLevels: {
        [WorkspaceType.CHANNEL]: {
          [NotificationsLevel.NONE]: "Nessuno",
          [NotificationsLevel.PARENT]: "Solo gli amministratori MSP di questa area di lavoro",
          [NotificationsLevel.ALL]: "Amministratori locali e MSP",
          [NotificationsLevel.LOCAL_ADMINS]: "Solo gli amministratori locali",
          subchannelParent: "Solo gli amministratori MSP dell'area di lavoro <b>principale</b>",
        },
        [WorkspaceType.CHILD]: {
          [NotificationsLevel.NONE]: "Nessuno",
          [NotificationsLevel.PARENT]: "Solo gli amministratori MSP dell'area di lavoro principale",
          [NotificationsLevel.ALL]: "Amministratori locali e MSP",
          [NotificationsLevel.LOCAL_ADMINS]: "Solo gli amministratori locali",
        },
        [WorkspaceType.REGULAR]: {
          [NotificationsLevel.NONE]: "Nessuno",
          [NotificationsLevel.PARENT]:
            "Solo agli utenti amministratori del dominio <b>{domain}</b> e agli utenti di <b>questa</b> area di lavoro",
          [NotificationsLevel.ALL]: "Tutti gli utenti amministratori",
        },
      },
      actionBtn: "Crea area di lavoro",
      edrEnabled: "Endpoint Detection & Response abilitati",
      psaEnabled: "PSA attivato",
      showSupportLink: "Mostra il collegamento per contattare l’assistenza",
      scanUnprotectedUsers: "Scansiona gli utenti protetti",
      showDisabledModules: "Mostra i moduli e i componenti aggiuntivi disabilitati",
      coroEmployeeWorkspace: "Area di lavoro dipendenti Coro",
      branding: {
        title: "Configurazione branding",
        aliasLabel: "Sotto-dominio",
        aliasHint: "Max 40 caratteri",
        aliasFullDomainHint: "L’indirizzo completo del dominio sarà {domain}.coro.net",
        supportEmailLabel: "E-mail dell'assistenza",
        supportEmailHint: "Max 100 caratteri",
        noReplyEmailLabel: "E-mail No-Reply",
        noReplyEmailHint:
          "L'e-mail No-Reply viene generata automaticamente dal campo Sottodominio.",
        brandColorLabel: "Colore del brand",
        brandColorHint: "Utilizza un formato esadecimale valido per il colore (#262260)",
        companyAddressLabel: "Indirizzo della società",
        companyAddressHint:
          'Il nome e l’indirizzo della società appariranno nel piè di pagina delle e-mail inviate e contrassegnate come "No-Reply"',
      },
      managed: "Gestito",
      unmanaged: "Non gestito",
      summary: {
        modulesAndAddons: "Moduli e componenti aggiuntivi",
        bundles: "Pacchetti",
        title: "Sintesi dell'abbonamento",
        userMonth: "/utente/mese",
        billedAnnually: "Fatturazione annuale*",
        [SubscriptionBundle.CORO_CLASSIC]: {
          title: "Coro Classic",
          subtitle: "Non gestito",
        },
        [SubscriptionBundle.CORO_COMPLETE]: {
          title: "Coro Complete",
          subtitle: "Gestito",
        },
        [SubscriptionBundle.MANAGED_CORO_COMPLETE]: {
          title: "Coro Complete",
          subtitle: "Gestito",
        },
        [SubscriptionBundle.MANAGED_CORO_CLASSIC]: {
          title: "Coro Classic",
          subtitle: "Gestito",
        },
        [SubscriptionBundle.ENDPOINT_PROTECTION]: {
          title: "Endpoint Protection",
          subtitle: "Non gestito",
        },
        [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: {
          title: "Endpoint Protection",
          subtitle: "Gestito",
        },
        [SubscriptionBundle.SASE]: {
          title: "SASE",
          subtitle: "Non gestito",
        },
        [SubscriptionBundle.MANAGED_SASE]: {
          title: "SASE",
          subtitle: "Gestito",
        },
        [SubscriptionBundle.EMAIL_PROTECTION]: {
          title: "Protezione e-mail",
          subtitle: "Non gestito",
        },
        [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: {
          title: "Protezione e-mail",
          subtitle: "Gestito",
        },
        [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: {
          title: "Coro Essentials",
          subtitle: "Gestito",
        },
        [SubscriptionBundle.CORO_ESSENTIALS]: {
          title: "Coro Essentials",
          subtitle: "Non gestito",
        },
        [SubscriptionModule.EMAIL_SECURITY]: {
          title: "Email Security",
        },
        [SubscriptionModule.NETWORK]: {
          title: "Network",
        },
        [SubscriptionModule.USER_DATA_GOVERNANCE]: {
          title: "User Data Governance",
        },
        [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
          title: "Endpoint Data Governance",
        },
        [SubscriptionModule.MDM]: {
          title: "Mobile Device Mgmt",
        },
        [SubscriptionModule.ENDPOINT_SECURITY]: {
          title: "Endpoint Security",
        },
        [SubscriptionModule.CLOUD_SECURITY]: {
          title: "Cloud Security",
        },
        [SubscriptionModule.EDR]: {
          title: "EDR",
        },
        [SubscriptionModule.SECURITY_AWARENESS]: {
          title: "[TRANSLATION] Security Awareness Training",
        },
        [SubscriptionAddon.WIFI_PHISHING]: {
          title: "Wi-Fi Phishing",
        },
        [SubscriptionAddon.SECURED_MESSAGES]: {
          title: "Secure Messages",
        },
        [SubscriptionAddon.INBOUND_GATEWAY]: {
          title: "Inbound Gateway",
        },
        [SubscriptionAddon.SWG]: {
          title: "Secure Web Gateway",
        },
        [SubscriptionAddon.ZTNA]: {
          title: "Zero Trust Network Access",
        },
      },
    },
    [MspWorkspaceAction.EDIT_MSP_WORKSPACE_LABEL]: {
      title: "Modifica etichette dell'area di lavoro",
      selectLabel: "Etichette",
      placeholder: "Nome...",
      actionBtn: "Salva",
      manageLabels: "Gestisci etichette ›",
    },
    [MspWorkspaceAction.ADD_MSP_BULK_LABEL]: {
      title: "Aggiungi le etichette dell'area di lavoro",
      actionBtn: "Aggiungi",
    },
    [MspWorkspaceAction.EDIT_WORKSPACE]: {
      titles: {
        channel: "Modifica le informazioni sull’area di lavoro del channel",
        child: "Modifica le informazioni sull’area di lavoro secondaria",
        regular: "Modifica le informazioni sull’area di lavoro",
      },
      companyName: "Nome della società",
      selectLabel: "Etichette",
      displayName: "Mostra nome",
      displayNameHint: "Max 30 caratteri",
      limitProtectedUsersPlaceholder: "Opzionale: limita il numero di utenti protetti",
      limitProtectedUsersHint:
        "In presenza di un limite impostato, il cliente non potrà aggiungere alla protezione Coro un numero di utenti superiore a quanto specificato.",
      limitProtectedUsers: "Limita gli utenti protetti (facoltativo)",
      notifications: "Notifiche",
      imageError: "L’immagine non corrisponde alle specifiche",
      headerIcon: "Icona intestazione",
      replaceImage: "Sostituisci",
      deleteWorkspace: "Elimina area di lavoro",
      imageHint: "Icona quadrata bianca su sfondo trasparente. Massimo 500KB. File png, svg",
      notificationLevels: {
        [NotificationsLevel.NONE]: "Nessuno",
        [NotificationsLevel.PARENT]: "Solo gli utenti admin del channel",
        [NotificationsLevel.ALL]: "Tutti gli utenti admin",
        [NotificationsLevel.LOCAL_ADMINS]: "Solo gli amministratori locali",
      },
      actionBtn: "Salva",
      edrEnabled: "Endpoint Detection & Response abilitati",
      psaEnabled: "PSA attivato",
      branding: {
        title: "Configurazione branding",
        aliasLabel: "Sotto-dominio",
        aliasHint: "Max 40 caratteri",
        aliasFullDomainHint: "L’indirizzo completo del dominio sarà {domain}.coro.net",
        supportEmailLabel: "E-mail dell'assistenza",
        supportEmailHint: "Max 100 caratteri",
        noReplyEmailLabel: "E-mail No-Reply",
        noReplyEmailHint:
          "L'e-mail No-Reply viene generata automaticamente dal campo Sottodominio.",
        brandColorLabel: "Colore del brand",
        brandColorHint: "Utilizza un formato esadecimale valido per il colore (#262260)",
        companyAddressLabel: "Indirizzo della società",
        companyAddressHint:
          'Il nome e l’indirizzo della società appariranno nel piè di pagina delle e-mail inviate e contrassegnate come "No-Reply"',
      },
    },
    [MspAdminUsersAction.ADD]: {
      title: "Aggiungi utente amministratore MSP",
      email: {
        placeholder: "Seleziona utente amministratore",
        label: "Utente amministratore",
      },
      role: {
        placeholder: "Seleziona ruolo",
        label: "Ruolo",
      },
      workspace: {
        placeholder: "Seleziona l’area di lavoro",
        label: "Area di lavoro",
        hint: "Seleziona l'area di lavoro a cui aggiungere utente amministratore e ruolo",
      },
      actionBtn: "Salva",
      errors: {
        general: "Correggi gli errori riportati di seguito",
        restriction: "Seleziona il tipo di autorizzazione",
      },
    },
    [MspAdminUsersAction.EDIT]: {
      title: "Modifica utente amministratore globale",
      actionBtn: "Salva",
    },
    [MspAdminUsersAction.DELETE]: {
      title: "Vuoi rimuovere l’utente amministratore globale?",
      description:
        "<span class='text--semibold'>{email}</span> non avrà più accesso a quest’area di lavoro secondaria.",
      actionBtn: "Sì, rimuovi",
    },
    [GlobalAdminUsersAction.ADD]: {
      title: "Aggiungi utente amministratore globale",
      actionBtn: "Salva",
    },
    [GlobalAdminUsersAction.EDIT]: {
      title: "Modifica utente amministratore globale",
      actionBtn: "Salva",
    },
    [GlobalAdminUsersAction.DELETE]: {
      title: "Vuoi rimuovere l’utente amministratore globale?",
      description:
        "<span class='text--semibold'>{email}</span> non avrà più accesso a quest’area di lavoro secondaria.",
      actionBtn: "Salva",
    },
    [ExportAction.EXPORT_ACTIVITY_LOGS]: {
      title: "Esportazione del nuovo registro attività",
      disclaimer: "I dati degli ultimi 90 giorni verranno inclusi per impostazione predefinita",
      subtitle: "Attività",
      choices: {
        [ExportChoice.ALL]: "Tutte le attività",
        [ExportChoice.SPECIFIC]: "Attività specifiche",
      },
      actionBtn: "Genera",
    },
    [ExportAction.EXPORT_PROTECTED_USERS]: {
      title: "Esportazione dei nuovi utenti protetti",
      subtitle: "Utenti protetti",
      choices: {
        [ExportChoice.ALL]: "Tutti gli utenti protetti",
        [ExportChoice.SPECIFIC]: "Utenti protetti specifici",
      },
      actionBtn: "Genera",
    },
    [ExportAction.EXPORT_DEVICES]: {
      title: "Esportazione dei nuovi dispositivi",
      subtitle: "Dispositivi",
      choices: {
        [ExportChoice.ALL]: "Tutti i dispositivi",
        [ExportChoice.SPECIFIC]: "Dispositivi specifici",
      },
      actionBtn: "Genera",
    },
    [ExportAction.EXPORT_TICKETS]: {
      title: "Esportazione dei nuovi ticket",
      disclaimer: "I dati degli ultimi 90 giorni verranno inclusi per impostazione predefinita",
      subtitle: "Segnalazioni",
      choices: {
        [ExportChoice.ALL]: "Tutti i ticket",
        [ExportChoice.SPECIFIC]: "Ticket specifici",
      },
      actionBtn: "Genera",
    },
    [SecureWebGatewayAction.TOGGLE_DNS_FILTERING]: {
      title: "Abilita il filtraggio DNS",
      description:
        "<b>Devi leggere quanto segue!</b> <br><br> L’attivazione del filtro DNS blocca l’accesso a qualsiasi risorsa esterna sospetta o esplicitamente bloccata dal tuo ufficio virtuale.",
      actionBtn: "Sì, attiva",
    },
    [SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_ALLOW_LIST]: {
      title: "Vuoi rimuovere l'elemento dall’elenco consentiti?",
      description: "Gli URL di <b>{item}</b> non saranno più consentiti.",
      actionBtn: "Sì, rimuovi",
    },
    [SecureWebGatewayAction.DISABLE_URL_GROUP_BLOCK_LIST]: {
      title: "Vuoi disattivare l’elenco degli URL?",
      description: "Gli URL di <b>{name}</b> non saranno più bloccati.",
      actionBtn: "Sì, disabilita",
    },
    [SecureWebGatewayAction.ENABLE_URL_GROUP_BLOCK_LIST]: {
      title: "Vuoi attivare l’elenco degli URL?",
      description: "Gli URL di <b>{name}</b> saranno bloccati.",
      actionBtn: "Sì, attiva",
    },
    [SecureWebGatewayAction.ENABLE_URL_GROUP_ALLOW_LIST]: {
      title: "Vuoi attivare l’elenco degli URL?",
      description: "Gli URL di <b>{name}</b> saranno consentiti.",
      actionBtn: "Sì, attiva",
    },
    [SecureWebGatewayAction.REMOVE_FROM_ALLOWLIST]: {
      title: "Vuoi rimuovere l'elemento dall’elenco consentiti?",
      description: "<b>{item}</b> non sarà più consentito.",
      actionBtn: "Sì, rimuovi",
    },
    [SecureWebGatewayAction.DISABLE_URL_GROUP_ALLOW_LIST]: {
      title: "Vuoi disattivare l’elenco degli URL?",
      description: "Gli URL di <b>{name}</b> non saranno più consentiti.",
      actionBtn: "Sì, disabilita",
    },
    [SecureWebGatewayAction.REMOVE_FROM_BLOCKLIST]: {
      title: "Vuoi rimuovere l'elemento dall’elenco dei bloccati?",
      description: "<b>{item}</b> non sarà più bloccato.",
      actionBtn: "Sì, rimuovi",
    },
    [SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_BLOCK_LIST]: {
      title: "Vuoi rimuovere l'elemento dall’elenco dei bloccati?",
      description: "Gli URL di <b>{item}</b> non saranno più bloccati.",
      actionBtn: "Sì, rimuovi",
    },
    [SecureWebGatewayAction.ADD_URL_TO_ALLOWLIST]: {
      actionBtn: "Aggiungi all’elenco",
    },
    allowBlockListSwg: {
      allowListTitle: "Aggiungi all’elenco degli elementi consentiti",
      blockListTitle: "Aggiungi all’elenco degli elementi bloccati",
      recordType: "Tipo di record",
      addCustomCategory: "Aggiungi una categoria personalizzata",
      addSpecificUrl: "Aggiungi un indirizzo URL specifico",
      uploadCategoryCsv: "Carica il file della categoria",
      actionBtn: "Aggiungi all’elenco",
      nicknamePlaceholder: "Inserisci il nickname della categoria...",
      nicknameLabel: "Soprannome",
      linkLabel: "URL",
      linkPlaceholder: "Elenco dei collegamenti a URL esterni",
      urlLabel: "URL",
      urlPlaceholder: "Inserisci gli indirizzi URL...",
      uploadFailed: "Caricamento non riuscito",
      incorrectFileExtension: "Estensione del file non corretta",
      fileMissing: "Aggiungi un file",
      fileSizeError: "Il file è troppo grande. Carica un file di dimensioni inferiori a 1 MB",
    },
    [ZtnaAction.EDIT_ZTNA_RULE]: {
      title: "Modifica di un criterio di accesso alle risorse",
      actionBtn: "Salva",
    },
    [ZtnaAction.ADD_ZTNA_RULE]: {
      title: "Crea una policy di accesso alle risorse",
      resourceTypes: {
        [ConfigType.IP]: "[TRANSLATION] Single IP address",
        [ConfigType.CIDR]: "[TRANSLATION] IP & subnet",
        [ConfigType.RANGE]: "[TRANSLATION] IP address range",
        [ConfigType.DOMAIN]: "[TRANSLATION] Domain",
      },
      actionBtn: "Salva",
      titles: {
        resourceDetails: "Dettagli risorsa",
        resourceConfigurations: "Configurazione delle risorse",
        deviceLabels: "[TRANSLATION] Device labels",
      },
      placeholders: {
        resourceName: "Nome della risorsa",
        resourceDescription: "[TRANSLATION] Resource description",
        resourceType: "[TRANSLATION] Resource type",
        cidr: "[TRANSLATION] IP & subnet (CIDR)",
        startIpAddress: "[TRANSLATION] Start IP address",
        endIpAddress: "[TRANSLATION] End IP address",
        labels: "[TRANSLATION] Select labels...",
        enterUpToPorts: "[TRANSLATION] Enter up to {max} ports",
        ipAddress: "Indirizzo IP",
        protocol: "Protocollo",
        domain: "[TRANSLATION] Domain",
      },
      labelsAutocompleteLabel: "[TRANSLATION] Device labels",
      upToInstances: "[TRANSLATION] Up to {max} instances",
      addResourceConfigField: "[TRANSLATION] Add resource configuration field",
      instance: "[TRANSLATION] Instance {index}",
      bannerDescription:
        "[TRANSLATION] When enabling peer-to-peer traffic, connections must be allowed in both directions. Coro automatically creates a reverse connection when both devices are in the same subnet. This ensures your ZTNA rules function correctly without additional configuration.",
    },
    [ZtnaAction.DISABLE_ZTNA]: {
      actionBtn: "SÌ, DISABILITA",
      title: "Vuoi disattivare ZTNA?",
      description:
        "La disabilitazione dell'accesso di una rete non affidabile (zero trust network access, ZTNA) rimuove le restrizioni e consente tutte le connessioni di rete a tutti gli utenti dell'ufficio virtuale, non solo a chi è ammesso dal criterio delle risorse",
    },
    [ZtnaAction.ENABLE_ZTNA]: {
      actionBtn: "SÌ, ABILITA",
      title: "Vuoi attivare ZTNA?",
      description:
        "L'abilitazione dell'accesso a una rete non affidabile (zero trust network access, ZTNA) bloccherà per impostazione predefinita tutte le connessioni all'interno della rete virtuale. Solo le risorse configurate attraverso il criterio delle risorse rimarranno accessibili",
    },
    [VirtualOfficeAction.SETUP]: {
      title: "Conferma la selezione",
      description:
        "La selezione della zona avviene una sola volta durante la configurazione! Per modificare la zona dopo la configurazione, contatta l’assistenza.",
      selectedRegion: "Zona selezionata:",
      confirmInputLabel: 'Digita "APPROVA" per confermare:',
      actionBtn: "Conferma",
    },
    [VirtualOfficeAction.SELECT_PREDEFINED_VPN_LABEL]: {
      title: "Conferma la selezione",
      description:
        "L'assegnazione di etichette predefinite espone potenzialmente la rete a rischi per la sicurezza. I dispositivi aggiunti a questa etichetta otterranno automaticamente l'accesso. <br>Ti consigliamo di creare etichette manuali per supervisionare e gestire i dispositivi assegnati.",
      selectedLabelConfirmation: "Vuoi davvero assegnare questa etichetta?",
      selectedLabel: "Etichetta selezionata: ‘{name}’",
      actionBtn: "Conferma",
    },
    [NetworkSettingsAction.TOGGLE_ENCRYPTION_STRENGTH]: {
      title: "Vuoi modificare il livello di intensità della crittografia?",
      description:
        "Modificando il livello di intensità della crittografia l’ufficio virtuale si disconnetterà da Internet per un tempo massimo di due minuti. Consigliamo di effettuare questa modifica in orari non lavorativi.",
      selectedLabelConfirmation: "Vuoi davvero assegnare questa etichetta?",
      selectedLabel: "Etichetta selezionata: ‘{name}’",
      actionBtn: "Sì, modifica",
    },
    [VirtualOfficeAction.ENABLE_FULL_TUNNELING]: {
      title: "Vuoi attivare una VPN full-tunneling?",
      description:
        "L'attivazione della VPN full-tunneling può comportare <b>velocità di Internet più basse, accesso limitato a causa di siti che bloccano le connessioni VPN</b> e altri potenziali problemi di funzionalità <b>.</b>",
      actionBtn: "Sì, attiva",
    },
    [VirtualOfficeAction.DISABLE_FULL_TUNNELING]: {
      title: "Disattivare la VPN a tunnel completo?",
      description:
        "La disattivazione della VPN full-tunneling <b>rimuoverà l'accesso VPN dai dispositivi assegnati.</b><br><br>Se un dispositivo fosse stato assegnato anche alla VPN split-tunneling, tornerà a utilizzare la VPN split-tunneling.",
      actionBtn: "Sì, DISATTIVA",
    },
    [VirtualOfficeAction.VIEW_ALL_IPS]: {
      title: "Elenco di indirizzi IP",
      description: "{n} indirizzi IP",
      actionBtn: "Chiudi",
    },
    [VirtualOfficeAction.REMOVE_FROM_INCLUDELIST]: {
      title: "Vuoi rimuovere dall’elenco di inclusione?",
      description: "<b>{item}</b> non sarà più incluso.",
      actionBtn: "Sì, rimuovi",
    },
    [VirtualOfficeAction.REMOVE_FROM_EXCLUDELIST]: {
      title: "Vuoi rimuovere dall’elenco delle esclusioni?",
      description: "<b>{item}</b> non sarà più escluso.",
      actionBtn: "Sì, rimuovi",
    },
    [VirtualOfficeAction.GET_VPN_MOBILE_APP]: {
      title: "Applicazione mobile Coro VPN",
      description:
        "L'applicazione mobile Coro VPN è stata progettata per essere installata dagli utenti finali sui propri dispositivi. I link qui sotto consentono di accedere ai rispettivi app store per scaricare l'applicazione direttamente sui propri dispositivi.",
      osTypes: {
        android: "Condividi il link per il download per i dispositivi Android",
        ios: "Condividi il link di download per i dispositivi iOS",
      },
    },
    [VirtualOfficeAction.ADD_URL_TO_URL_LIST]: {
      includeListTitle: "Aggiungi all’elenco delle inclusioni",
      excludedListTitle: "Aggiungi all’elenco delle esclusioni",
      label: "Domini o indirizzi IP non validi...",
      placeholder: "Inserisci il/i dominio/i e/o gli indirizzi IP...",
      actionBtn: "Aggiungi all’elenco",
    },
    [ZtnaAction.DELETE_ZTNA_RULE]: {
      title: "Vuoi rimuovere la regola ZTNA?",
      description: "L'accesso a questa risorsa verrà bloccato.",
      actionBtn: "Sì, rimuovi",
    },
    [SiteToSiteTunnelsAction.EDIT]: {
      title: "Modifica tunnel",
      actionBtn: "Salva",
    },
    [SiteToSiteTunnelsAction.DELETE]: {
      title: "Elimina tunnel",
      description: "Vuoi cancellare questo tunnel?",
      actionBtn: "Sì",
    },
    [SiteToSiteTunnelsAction.TEST]: {
      title: "Registro dei test del tunnel",
      actionBtn: "Chiudi",
    },
    [SiteToSiteTunnelsAction.ADD]: {
      title: "Aggiungi tunnel",
      actionBtn: "Salva",
      formTitle: "Dettagli del sito",
      inputs: {
        siteName: {
          placeholder: "Inserisci il nome del sito...",
          label: "Nome del sito",
        },
        siteDescription: {
          placeholder: "Inserisci la descrizione del sito...",
          label: "Descrizione del sito",
        },
        remoteGatewayIp: {
          placeholder: "Inserisci l’IP del gateway pubblico remoto...",
          label: "IP del gateway pubblico remoto",
        },
        remoteNetworkIps: {
          subtitle: "Rete di subnet interna remota",
          placeholder: "IP di rete interno/Maschera di rete",
          label: "IP di rete interno/Maschera di rete",
          add: "+ Aggiungi rete interna remota",
        },
        presharedKey: {
          placeholder: "Inserisci la chiave pre-condivisa",
          label: "Chiave pre-condivisa",
          generateKey: "Genera chiave",
        },
        lifetimeKey: {
          placeholder: "Inserisci chiave senza termine...",
          label: "Chiave a vita (in sec)",
        },
      },
      detailsTitle: "Ufficio virtuale",
      gatewayIp: "IP gateway",
      subnet: "Sotto-rete",
      settings: {
        title: "Impostazioni del firewall",
        firewallType: "Tipo di firewall",
        remoteNetworkMask: "Maschera di rete remota",
        ikeVersion: "Versione IKE",
        phase1Encryption: "Crittografia fase 1",
        phase2Encryption: "Crittografia fase 2",
        aggressiveMode: "Modalità aggressiva",
      },
      copySuccessMessage: "Chiave precondivisa copiata negli appunti",
    },
    [ScheduledReportsAction.ADD_EXPORT]: {
      title: "Nuova esportazione pianificata",
      actionBtn: "Programma",
      frequency: "Frequenza",
      scope: "Scopo",
      recipients: "Destinatari",
      exportType: "Tipologia di esportazione",
      recipientsChoice: {
        allAdminUsers: "Tutti gli utenti amministratori delle aree di lavoro",
        specificAdminUsers: "Utenti amministratori specifici",
      },
      name: {
        label: "Nome esportazione",
      },
    },
    [ScheduledReportsAction.ADD_REPORT]: {
      title: "Nuovo report pianificato",
      actionBtn: "Pianifica",
      frequency: "Frequenza",
      scope: "Scopo",
      recipients: "Destinatari",
      reportType: "Tipo di rapporto",
      recipientsChoice: {
        allAdminUsers: "Tutti gli utenti amministratori delle aree di lavoro",
        specificAdminUsers: "Utenti admin specifici",
      },
      name: {
        label: "Nome del rapporto",
      },
    },
    [ScheduledReportsAction.DELETE]: {
      title: "Vuoi rimuovere il report pianificato?",
      actionBtn: "Sì, rimuovi",
      description: "Le segnalazioni non verranno più inviate.",
    },
    [ScheduledReportsAction.EDIT_EXPORT]: {
      title: "Modifica esportazione pianificata",
      actionBtn: "Programma",
    },
    [ScheduledReportsAction.EDIT_REPORT]: {
      title: "Modifica un rapporto programmato",
      actionBtn: "Pianifica",
    },
    [BillingAction.UPDATE_SUBSCRIPTION]: {
      title: "Aggiorna piano",
      actionBtn: "Salva",
    },
    [TicketsModal.IPS_MODAL]: {
      title: "Indirizzi IP",
    },
    [TicketsModal.DLP_FINDINGS_MODAL]: {
      title: "Risultati",
      type: "<div class='v-row body2'><div class='v-col-3'>Tipo:</div> <div class='v-col-9'>{type}</div></div>",
      quote:
        "<div class='v-row body2'><div class='v-col-3'>Citazione:</div> <div class='v-col-9'><span class='text-orange-base'>&quot;{quote}&quot;</span></div></div>",
      quotePlain:
        "<div class='v-row body2'><div class='v-col-3'>Citazione:</div> <div class='v-col-9'><span class='text-orange-base'>{quote}</span></div></div>",
      fileName:
        "<div class='v-row body2'><div class='v-col-3'>Nome del file:</div> <div class='v-col-9'><span>{fileName}</span></div></div>",
      virusName:
        "<div class='v-row body2'><div class='v-col-3'>Nome del virus:</div> <div class='v-col-9'><span>{virusName}</span></div></div>",
      longQuote:
        "<div class='v-row body2'><div class='v-col-3'>Citazione:</div> <div class='v-col-9'>&quot;{textBeforeHighlight}<span class='text-orange-base'>{highlightedText}</span>{textAfterHighlight}&quot;</div></div>",
      longQuoteWithoutHighlight:
        "<div class='v-row body2'><div class='v-col-3'>Citazione:</div> <div class='v-col-9'>&quot;{longQuote}&quot;</div></div>",
      archivePath:
        "<div class='v-row body2'><div class='v-col-3'>Percorso di archiviazione:</div> <div class='v-col-9'><b>{archive}</b> &rarr; {path}<b>{file}</b></div></div>",
    },
    [TicketsModal.RECIPIENTS]: {
      title: "{n} email recipients",
    },
    [TicketsModal.USERS]: {
      title: "{n} utenti",
    },
    [TicketsModal.BSSID_MODAL]: {
      title: "BSSID",
    },
    [TicketsModal.SHARED_WITH]: {
      title: "Condiviso con",
    },
    [CommentAction.ADD_COMMENT]: {
      title: "Nuovo commento",
      label: "Commento",
      placeholder: "Inserisci testo",
      notifyAffectedUsers: "Invia una notifica agli utenti interessati",
      notifyAdmins: "Invia una notifica a tutti gli amministratori dell’area di lavoro",
      notifyCustomRecipients: "Invia una notifica a destinatari specifici",
      notifySocContacts: "Invia una notifica ai contatti dei servizi gestiti",
      notifyAllWorkspaceAdmins: "Invia una notifica a tutti gli amministratori dell’area di lavoro",
      notifyCoroAdmins: "Invia una notifica agli amministratori Coro",
      notifySpecificCoroAdmins: "Invia una notifica a specifici amministratori Coro",
      actionBtn: "Commento",
    },
    [CommentAction.BULK_ADD_COMMENT]: {
      title: "Nuovo commento",
      actionBtn: "Commento",
    },
    [TicketAction.APPROVE_EMAIL_WITH_OPTIONS]: {
      title: "Consenti",
      actionBtn: "Conferma",
    },
    [TicketAction.DISCARD_EMAIL_WITH_OPTIONS]: {
      title: "Blocca",
      actionBtn: "Conferma",
    },
    [TicketAction.CONTACT_USER]: {
      title: "Contatta utente",
      description: "Utente | Utenti",
      actionBtn: "Invia",
      more: "+ altri {n}",
      placeholder: "Aggiungi messaggio",
      includeEventDetails: "Includi i dettagli della segnalazione nel messaggio",
    },
    [TicketAction.REMOVE_EXPOSING_SHARING]: {
      title: "Vuoi rimuovere l’esposizione?",
      description: "Tutte le condivisioni con persone esterne all’organizzazione verranno rimosse.",
      actionBtn: "Rimuovi",
    },
    [TicketAction.ADD_TO_DATA_GOVERNANCE_PERMISSIONS]: {
      title: "Vuoi estendere le autorizzazioni alla Governance Dei Dati?",
      description:
        'L\'utente {users} verrà autorizzato alla violazione del tipo "{violationDirection}" dei dati contenenti {trigger}. | Gli utenti {violationUsers} verranno autorizzati alla violazione del tipo "{violationDirection}" dei dati contenenti {violationTrigger}.',
      share: "Accesso ed esposizione",
      access: "Accesso",
      actionBtn: "Conferma",
    },
    [TicketAction.GENERAL_APPROVE]: {
      title: "Consenti",
      description:
        "Il mittente/dominio/l'indirizzo o intervallo IP verrà rimosso dall’elenco degli elementi bloccati.",
      specificDescription: {
        crowdblockedSender:
          "Il mittente/dominio/l'indirizzo o intervallo IP nell'elenco bloccati per contenuti sospetti verrà aggiunto all'elenco consentiti.",
        blocklistedSender:
          "Il mittente/dominio/l'indirizzo o intervallo IP verrà rimosso dall'elenco bloccati per contenuti sospetti.",
        missingRequiredAuthentication:
          "Il mittente/dominio/l'indirizzo o intervallo IP verrà rimosso dall’elenco degli elementi bloccati per autenticazione non riuscita.",
      },
      proxy: "Questa e-mail verrà ripristinata nella casella di posta dei destinatari.",
      actionBtn: "Conferma",
    },
    [TicketAction.APPROVE_EMAILS_FROM_DOMAIN]: {
      title:
        "Vuoi aggiungere tutti i mittenti di {name} all’elenco consentiti? | Vuoi aggiungere tutti i mittenti dei domini selezionati all’elenco consentiti?",
      description:
        "In futuro, tutte le e-mail provenienti da <b>{name}</b> verranno approvate automaticamente. | In futuro, tutte le e-mail provenienti dai domini selezionati verranno approvate automaticamente.",
      actionBtn: "Conferma",
    },
    [TicketAction.ENCRYPT_DRIVE]: {
      title: "[TRANSLATION] Encrypt drive? | Encrypt selected drives?",
      description:
        "[TRANSLATION] Encrypting your drives protects your data by requiring a recovery key or passphrase for access. | Encrypting your drives protects your data by requiring a recovery key or passphrase for access.<br><br><span class=\'subtitle1\'>Selected drives: {quantity}</span>",
      actionBtn: "Si",
    },
    [DeviceAction.DECRYPT_DRIVE]: {
      title: "[TRANSLATION] Decrypt drive? | Decrypt selected drives?",
      description:
        "[TRANSLATION] Decrypting your drives removes their protection by making the data accessible without a recovery key or passphrase. | Decrypting your drives removes their protection by making the data accessible without a recovery key or passphrase.<br><br><span class=\'subtitle1\'>Selected drives: {quantity}</span>",
      actionBtn: "Si",
    },
    [TicketAction.DISCARD_EMAILS_FROM_DOMAIN]: {
      title:
        "Vuoi bloccare tutti i mittenti di questo dominio? | Vuoi aggiungere tutti i mittenti dei domini selezionati all’elenco bloccati?",
      description:
        "In futuro, tutte le e-mail di <b>{name}</b> verranno eliminate automaticamente. | In futuro, tutte le e-mail provenienti dai domini selezionati verranno eliminate automaticamente.",
      error: "Il mittente del dominio {domain} non può essere inserito nell‘elenco bloccati",
      actionBtn: "Conferma",
    },
    [TicketAction.APPROVE_EMAILS_FROM_SENDER]: {
      title:
        "Vuoi aggiungere {name} all’elenco consentiti? | Vuoi aggiungere le e-mail selezionate all’elenco consentiti?",
      description:
        "In futuro, tutte le e-mail di <b>{name}</b> verranno approvate automaticamente. | In futuro, tutte le e-mail dei mittenti selezionati verranno approvate automaticamente.",
      actionBtn: "Conferma",
    },
    [TicketAction.DISCARD_EMAILS_FROM_SENDER]: {
      title:
        "Vuoi bloccare questo mittente? | Vuoi aggiungere le e-mail selezionate all’elenco bloccati?",
      description:
        "In futuro, tutte le e-mail di <b>{name}</b> verranno eliminate automaticamente. | In futuro, tutte le e-mail dei mittenti selezionati verranno eliminate automaticamente.",
      error: "Il mittente del dominio {domain} non può essere inserito nell‘elenco bloccati",
      actionBtn: "Conferma",
    },
    [TicketAction.APPROVE_EMAIL]: {
      title:
        "Vuoi trattare l’e-mail come legittima? | Vuoi trattare le e-mail selezionate come legittime?",
      description:
        "Questa e-mail verrà ripristinata automaticamente nella casella di posta dei destinatari | Le e-mail selezionate verranno ripristinate automaticamente nella casella di posta dei destinatari",
      actionBtn: "Conferma",
    },
    [TicketAction.DISCARD_EMAIL]: {
      title:
        "Vuoi trattare l’e-mail come dannosa? | Vuoi trattare le e-mail selezionate come dannose?",
      description:
        "Questa e-mail verrà automaticamente cancellata per tutti i suoi destinatari | Le e-mail selezionate verranno automaticamente cancellate per tutti i loro destinatari",
      actionBtn: "Conferma",
    },
    [TicketAction.DOWNLOAD_EML_FILE]: {
      title: "Vuoi scaricare il file EML?",
      description:
        "Considerato che l’e-mail è stata segnalata come sospetta, gli allegati e i collegamenti incorporati andranno trattati con maggiore attenzione.",
      actionBtn: "Conferma",
    },
    [TicketAction.TREAT_FILE_AS_SAFE]: {
      title: "Vuoi trattare il file come sicuro?",
      description:
        "Il file selezionato verrà tolto dalla quarantena (se applicabile). In futuro, i file identici verranno considerati sicuri e non saranno messi in quarantena.",
      actionBtn: "Conferma",
    },
    [TicketAction.EXCLUDE_FILE_PATH_AND_CERTIFICATE_FROM_SCAN]: {
      title: "Vuoi trattare il file come sicuro?",
      description:
        "In futuro, i file identici a quanto selezionato saranno considerati sicuri e, quindi, non verranno messi in quarantena.",
      actionBtn: "Conferma",
    },
    [TicketAction.EXCLUDE_FILE_PATH_AND_FILE_HASH_FROM_SCAN]: {
      title: "Vuoi trattare il file come sicuro?",
      description:
        "In futuro, i file identici a quanto selezionato saranno considerati sicuri e, quindi, non verranno messi in quarantena.",
      actionBtn: "Conferma",
    },
    [TicketAction.TREAT_FILE_AS_MALICIOUS]: {
      title: "Vuoi trattare il file come dannoso?",
      description:
        "In futuro, i file identici a quanto selezionato verranno considerati dannosi e, quindi, eliminati all’istante.",
      actionBtn: "Conferma",
    },
    [TicketAction.EXCLUDE_FOLDER_FROM_SCAN]: {
      title: "Vuoi escludere la cartella dalla scansione malware?",
      description:
        "In futuro, il contenuto della cartella selezionata non verrà esaminato alla ricerca di malware e ransomware.",
      actionBtn: "Escludi",
    },
    [TicketAction.SIGN_IN_TO_SERVICE]: {
      title: "Vuoi chiedere all’utente di accedere a {service}?",
      description: "All’utente verrà richiesto di effettuare nuovamente l’accesso a {service}.",
      actionBtn: "Richiesta",
    },
    [TicketAction.APPROVE_FILE]: {
      title: "Approva file | Approva file",
      description:
        "Vuoi davvero ripristinare l’accesso a questo file ed escluderlo dalle scansioni malware in futuro? | Vuoi davvero ripristinare l'accesso a questi file ed escluderli dalle scansioni malware in futuro?",
      actionBtn: "Sì",
    },
    [TicketAction.DELETE_FILE]: {
      title: "Elimina file | Elimina file",
      description:
        "Vuoi davvero eliminare definitivamente questo file? | Vuoi davvero eliminare definitivamente questi file?",
      actionBtn: "Elimina",
    },
    [TicketAction.APPROVE_PROCESS_GROUP]: {
      title: "Vuoi trattare il gruppo di processi come sicuro?",
      description:
        "I gruppi di processi identici a questo verranno considerati sicuri, quindi, non verranno terminati.",
      actionBtn: "Conferma",
    },
    [TicketAction.DISCARD_INFECTING_FILE]: {
      title: "Vuoi trattare il file come dannoso?",
      description:
        "In futuro, i file identici a questo verranno considerati dannosi e, quindi, eliminati all’istante.",
      actionBtn: "Conferma",
    },
    [TicketAction.ENABLE_GATEKEEPER]: {
      title: "Attiva il gatekeeper",
      description: "Vuoi applicare l’abilitazione del gatekeeper?",
      actionBtn: "Sì",
    },
    [TicketAction.ENABLE_APPLE_MOBILE_FILE_INTEGRITY]: {
      title: "Abilita l’integrità dei file di Apple Mobile",
      description: "Vuoi applicare l’abilitazione all’integrità dei file di Apple Mobile?",
      actionBtn: "Sì",
    },
    [TicketAction.EXPORT_MASS_DOWNLOAD_FILES]: {
      title: "Esporta un elenco di file interessati",
      description:
        "Scarica sul computer un file con valori delimitati da virgole (CSV) contenente l’elenco dei file interessati.",
      actionBtn: "Conferma",
    },
    [TicketAction.EXPORT_MASS_DELETE_FILES]: {
      title: "Esporta un elenco di file interessati",
      description:
        "Scarica sul computer un file con valori delimitati da virgole (CSV) contenente l’elenco dei file interessati.",
      actionBtn: "Conferma",
    },
    [TicketAction.ALLOW_PROCESS]: {
      title: "Consenti processo",
      description: "Vuoi aggiungere il processo all’elenco consentiti?",
      checkboxText:
        "Chiudi alla conferma questa segnalazione e gli altri ticket correlati a questo processo",
      checkboxDescription:
        "L'hash del processo corrispondente sarà considerato sicuro e non genererà ticket EDR.<br>Le informazioni relative al processo non verranno memorizzate nelle schede relative a telemetria e processi.",
      actionBtn: "Sì",
    },
    allowBlockEmail: {
      closeAllTicketsInGroup: "Chiudi tutte le segnalazioni correlate",
      actions: {
        default: {
          approveEmailsFromSender: {
            title:
              "Autorizza questa e-mail e aggiungi l'indirizzo del mittente all’elenco consentiti",
          },
          approveEmailsFromDomain: {
            title:
              "Autorizza questa e-mail e aggiungi il dominio del mittente all’elenco consentiti",
          },
          discardEmailsFromSender: {
            title:
              "Aggiungi l’indirizzo e-mail del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo mittente.",
          },
          discardEmailsFromDomain: {
            title:
              "Aggiungi il dominio del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo dominio.",
          },
          approveEmail: {
            title: "Consenti solo questa e-mail",
          },
          discardEmail: {
            title: "Elimina definitivamente solo questa e-mail",
          },
          approveEmailsFromSenderIp: {
            title:
              "Autorizza questa e-mail e aggiungi l'indirizzo IP del mittente all’elenco consentiti",
          },
          discardEmailsFromSenderIp: {
            title:
              "Aggiungi l'IP del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo IP.",
          },
        },
        [ProxySecurityMode.WARNING]: {
          approveEmailsFromSender: {
            title: "Aggiungi l’indirizzo e-mail del mittente all’elenco consentiti",
          },
          approveEmailsFromDomain: {
            title: "Aggiungi il dominio del mittente all’elenco consentiti",
          },
          discardEmailsFromSender: {
            title:
              "Blocca l’indirizzo e-mail del mittente e tutte le e-mail provenienti in futuro da questo mittente.",
          },
          discardEmailsFromDomain: {
            title:
              "Blocca il dominio del mittente e tutte le e-mail provenienti in futuro da questo dominio.",
          },
          approveEmail: {
            title: "Consenti solo questa e-mail",
          },
          discardEmail: {
            title:
              "Blocca l’indirizzo IP del mittente e tutte le e-mail provenienti in futuro da questo mittente.",
          },
          approveEmailsFromSenderIp: {
            title: "Aggiungi l’indirizzo IP del mittente all’elenco consentiti",
          },
          discardEmailsFromSenderIp: {
            title:
              "Blocca l’indirizzo IP del mittente e tutte le e-mail provenienti in futuro da questo mittente.",
          },
        },
        [ProxySecurityMode.BLOCK]: {
          approveEmailsFromSender: {
            title:
              "Autorizza questa e-mail e aggiungi l'indirizzo del mittente all’elenco consentiti",
          },
          approveEmailsFromDomain: {
            title:
              "Autorizza questa e-mail e aggiungi il dominio del mittente all’elenco consentiti",
          },
          discardEmailsFromSender: {
            title:
              "Aggiungi l’indirizzo e-mail del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo mittente.",
          },
          discardEmailsFromDomain: {
            title:
              "Aggiungi il dominio del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo dominio.",
          },
          approveEmailsFromSenderIp: {
            title:
              "Autorizza questa e-mail e aggiungi l'indirizzo IP del mittente all’elenco consentiti",
          },
          discardEmailsFromSenderIp: {
            title:
              "Aggiungi l'IP del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo IP.",
          },
          approveEmail: {
            title: "Consenti solo questa e-mail",
          },
          discardEmail: {
            title: "Elimina definitivamente solo questa e-mail",
          },
        },
      },
      proxyActions: {
        [ProxySecurityMode.WARNING]: {
          approveEmailsFromSender: {
            title: "Aggiungi l’indirizzo e-mail del mittente all’elenco consentiti",
          },
          approveEmailsFromDomain: {
            title: "Aggiungi il dominio del mittente all’elenco consentiti",
          },
          discardEmailsFromSender: {
            title:
              "Blocca l’indirizzo e-mail del mittente e tutte le e-mail provenienti in futuro da questo mittente.",
          },
          discardEmailsFromDomain: {
            title:
              "Blocca il dominio del mittente e tutte le e-mail provenienti in futuro da questo dominio.",
          },
          approveEmailsFromSenderIp: {
            title: "Aggiungi l’indirizzo IP del mittente all’elenco consentiti",
          },
          discardEmailsFromSenderIp: {
            title:
              "Blocca l’indirizzo IP del mittente e tutte le e-mail provenienti in futuro da questo mittente.",
          },
          approveEmail: {
            title: "Consenti solo questa e-mail",
          },
          discardEmail: {
            title: "Elimina definitivamente solo questa e-mail",
          },
        },
        [ProxySecurityMode.BLOCK]: {
          approveEmailsFromSender: {
            title:
              "Autorizza questa e-mail e aggiungi l'indirizzo del mittente all’elenco consentiti",
          },
          approveEmailsFromDomain: {
            title:
              "Autorizza questa e-mail e aggiungi il dominio del mittente all’elenco consentiti",
          },
          discardEmailsFromSender: {
            title:
              "Aggiungi l’indirizzo e-mail del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo mittente.",
          },
          discardEmailsFromDomain: {
            title:
              "Aggiungi il dominio del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo dominio.",
          },
          approveEmailsFromSenderIp: {
            title:
              "Autorizza questa e-mail e aggiungi l'indirizzo IP del mittente all’elenco consentiti",
          },
          discardEmailsFromSenderIp: {
            title:
              "Aggiungi l'IP del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo IP.",
          },
          approveEmail: {
            title: "Consenti solo questa e-mail",
          },
          discardEmail: {
            title: "Elimina definitivamente solo questa e-mail",
          },
        },
      },
      malwareActions: {
        default: {
          approveEmailsFromSender: {
            title: "Aggiungi l’indirizzo e-mail del mittente all’elenco consentiti",
          },
          approveEmailsFromDomain: {
            title: "Aggiungi il dominio del mittente all’elenco consentiti",
          },
          discardEmailsFromSender: {
            title:
              "Blocca l’indirizzo e-mail del mittente e cancella definitivamente tutte le e-mail provenienti in futuro da questo mittente.",
          },
          discardEmailsFromDomain: {
            title:
              "Blocca il dominio del mittente e cancella definitivamente tutte le e-mail provenienti in futuro da questo mittente.",
          },
          approveEmail: {
            title: "Consenti solo questa e-mail",
          },
          discardEmail: {
            title: "Elimina definitivamente solo questa e-mail",
          },
          approveEmailsFromSenderIp: {
            title: "Aggiungi l’indirizzo IP del mittente all’elenco consentiti",
          },
          discardEmailsFromSenderIp: {
            title:
              "Aggiungi l'indirizzo IP del mittente all’elenco bloccati e cancella definitivamente tutte le e-mail provenienti in futuro da questo IP.",
          },
        },
        [ProxySecurityMode.WARNING]: {
          approveEmailsFromSender: {
            title:
              "Autorizza questa e-mail e aggiungi l'indirizzo del mittente all’elenco consentiti",
          },
          approveEmailsFromDomain: {
            title:
              "Autorizza questa e-mail e aggiungi il dominio del mittente all’elenco consentiti",
          },
          discardEmailsFromSender: {
            title:
              "Aggiungi l’indirizzo e-mail del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo mittente.",
          },
          discardEmailsFromDomain: {
            title:
              "Aggiungi il dominio del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo dominio.",
          },
          approveEmailsFromSenderIp: {
            title:
              "Autorizza questa e-mail e aggiungi l'indirizzo IP del mittente all’elenco consentiti",
          },
          discardEmailsFromSenderIp: {
            title:
              "Aggiungi l'indirizzo IP del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo IP.",
          },
          approveEmail: {
            title: "Consenti solo questa e-mail",
          },
          discardEmail: {
            title: "Elimina definitivamente solo questa e-mail",
          },
        },
        [ProxySecurityMode.BLOCK]: {
          approveEmailsFromSender: {
            title:
              "Autorizza questa e-mail e aggiungi l'indirizzo del mittente all’elenco consentiti",
          },
          approveEmailsFromDomain: {
            title:
              "Autorizza questa e-mail e aggiungi il dominio del mittente all’elenco consentiti",
          },
          discardEmailsFromSender: {
            title:
              "Aggiungi l’indirizzo e-mail del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo mittente.",
          },
          discardEmailsFromDomain: {
            title:
              "Aggiungi il dominio del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo dominio.",
          },
          approveEmailsFromSenderIp: {
            title:
              "Autorizza questa e-mail e aggiungi l'indirizzo IP del mittente all’elenco consentiti",
          },
          discardEmailsFromSenderIp: {
            title:
              "Aggiungi l'indirizzo IP del mittente all’elenco bloccati e cancella definitivamente questa e tutte le e-mail provenienti in futuro da questo IP.",
          },
          approveEmail: {
            title: "Consenti solo questa e-mail",
          },
          discardEmail: {
            title: "Elimina definitivamente solo questa e-mail",
          },
        },
      },
    },
    [EmailSettingsAction.EDIT_SECURITY_AWARENESS_CONFIGURATION]: {
      title: "Modifica configurazione",
      actionBtn: "Salva",
    },
    [EmailSettingsAction.REMOVE_SECURITY_AWARENESS_CONFIGURATION]: {
      title: "Vuoi rimuovere la configurazione?",
      description: "Questa azione è irreversibile.",
      actionBtn: "Sì, rimuovi",
    },
    [EmailSettingsAction.ADD_SECURITY_AWARENESS_CONFIGURATION]: {
      addHeaderBtn: "+ Aggiungi nuova intestazione",
      actionBtn: "Aggiungi alla lista",
      title: "Aggiungi all’elenco consentiti",
      sectionsTitles: {
        details: "Dettagli",
        emailAndDomain: "Domini, IP o intervalli di IP",
        emailHeader: "Intestazione e-mail",
      },
      labels: {
        name: "Nome",
        emailAndDomain: "Inserisci domini o IP...",
        value: "Valore",
      },
      validations: {
        atLeastOneHeaderOrTermIsRequired:
          "È richiesta almeno un'intestazione di un'e-mail, un dominio o un IP.",
      },
    },
    [EndpointDataGovernanceAction.SET_INDUSTRY]: {
      title: "Seleziona settore",
      noDataAvailable: "Nessun dato disponibile",
      clearToReset: "Azzeramento per il reset,",
      differentSearch: "poi, prova un'altra ricerca.",
      actionBtn: "Salva",
    },
    [UserDataGovernanceAction.SET_INDUSTRY]: {
      title: "Seleziona settore",
      noDataAvailable: "Nessun dato disponibile",
      clearToReset: "Azzeramento per il reset,",
      differentSearch: "poi, prova un'altra ricerca.",
      description:
        "Seleziona il settore che meglio si adatta alla tua organizzazione. Questo ci aiuterà a personalizzare le impostazioni di rilevamento dei dati per allinearle alle normative e alle best practice specifiche di settore.",
      industries: {
        [Industries.ACCOUNTING_SERVICES]: "Servizi contabili",
        [Industries.AGRICULTURE_AND_FOOD]: "Agricoltura e alimentari",
        [Industries.AUTOMOTIVE]: "Automotive",
        [Industries.BUSINESS_AND_MARKETING]: "Business e marketing",
        [Industries.BUSINESS_SERVICES]: "Servizi alle imprese",
        [Industries.INSURANCE]: "Assicurazioni",
        [Industries.COLLEGES_AND_UNIVERSITIES]: "Università e college",
        [Industries.CONSTRUCTION]: "Costruzioni",
        [Industries.CONSULTING]: "Consulenze",
        [Industries.CONSUMER_SERVICES]: "Servizi ai consumatori",
        [Industries.EDUCATION]: "Formazione",
        [Industries.ENERGY_UTILITIES_AND_WASTE]: "Energia, utenze e rifiuti",
        [Industries.FINANCE]: "Servizi finanziari",
        [Industries.GOVERNMENT]: "Pubbliche amministrazioni",
        [Industries.HEALTH]: "Sanità",
        [Industries.HOLDING_COMPANIES_AND_CONGLOMERATES]:
          "Società di partecipazione e conglomerate",
        [Industries.HOSPITALITY]: "Hospitality",
        [Industries.HR]: "Risorse umane",
        [Industries.IT_SERVICES]: "Servizi IT",
        [Industries.LAW_FIRMS_AND_LEGAL_SERVICES]: "Studi legali e servizi legali",
        [Industries.MANUFACTURING]: "Industria manifatturiera",
        [Industries.MEDIA_AND_INTERNET]: "Media e Internet",
        [Industries.MINERALS_AND_MINING]: "Industria mineraria",
        [Industries.ORGANIZATIONS]: "Organizzazioni",
        [Industries.PHARMA]: "Settore farmaceutico",
        [Industries.REAL_ESTATE]: "Settore immobiliare",
        [Industries.RETAIL]: "Commercio al dettaglio",
        [Industries.SOFTWARE]: "Software",
        [Industries.TELECOMMUNICATIONS]: "Telecomunicazioni",
        [Industries.TRANSPORTATION]: "Trasporti",
        [Industries.OTHER]: "Altro",
      },
      actionBtn: "Salva",
    },
    [MspExportAction.EXPORT_TICKETS]: {
      title: "Esportazione completa dei nuovi ticket",
      disclaimer: "I dati degli ultimi 90 giorni verranno inclusi per impostazione predefinita",
      subtitle: "ticket",
      choices: {
        [ExportChoice.ALL]: "Tutti i ticket",
        [ExportChoice.SPECIFIC]: "Ticket specifici",
      },
      actionBtn: "Genera",
    },
    [MspExportAction.EXPORT_DEVICES]: {
      title: "Esportazione completa dei nuovi dispositivi",
      subtitle: "Dispositivi",
      choices: {
        [ExportChoice.ALL]: "Tutti i dispositivi",
        [ExportChoice.SPECIFIC]: "Dispositivi specifici",
      },
      actionBtn: "Genera",
    },
    [MspExportAction.EXPORT_USERS]: {
      title: "Esportazione completa dei nuovi utenti protetti",
      subtitle: "Utenti protetti",
      choices: {
        [ExportChoice.ALL]: "Tutti gli utenti protetti",
        [ExportChoice.SPECIFIC]: "Utenti protetti specifici",
      },
      actionBtn: "Genera",
    },
    [TutorialAction.WELCOME_MODAL]: {
      welcomeToCoro: "Benvenuto in Coro",
      description:
        "<b>Prima che inizi la tua prova gratuita di 14 giorni</b>, puoi esplorare la <b>Modalità Demo per 14 giorni</b> — uno spazio interattivo con dati di esempio per scoprire subito le funzionalità di Coro.",
      instantAccessFeature: "<b>Accesso immediato</b> a un ambiente preconfigurato",
      exploreFeature: "<b>Esplora le funzionalità</b> con dati di esempio reali",
      noSetupRequired: "<b>Nessuna configurazione necessaria</b> — inizia subito!",
      actionBtn: "Esplora la Demo",
    },
    [TutorialAction.START_TRIAL_FROM_DEMO_STATE]: {
      trialStartsNow: "La tua prova di 14 giorni inizia ora!",
      description:
        "Collega i tuoi servizi per essere completamente protetto e avere pieno accesso alle funzionalità di Coro.",
      leavingDemoMode: "Stai uscendo dalla Modalità Demo e questa azione è irreversibile.",
      actionBtn: "Inizia la prova",
      cancelBtn: "Continua a esplorare",
    },
    [TutorialAction.TRIAL_HAS_STARTED]: {
      trialStarted: "La tua prova è iniziata!",
      demoModeHasFinished:
        "<b>La tua modalità Demo è terminata e la tua prova è iniziata.</b><br>Collega i tuoi servizi per ottenere protezione completa e avere pieno accesso alle funzionalità di Coro.",
      actionBtn: "Continua",
    },
    [DemoWillStartModal.CLOUD_APPS]: {
      title: "Connetti applicazione cloud",
      description:
        "Stai per connettere una vera applicazione cloud. Una volta connessa, tutti i dati demo saranno rimossi e l'ambiente passerà in modalità di prova. Questa azione è irreversibile. Vuoi procedere?",
      actionBtn: "Procedi",
    },
    [DemoWillStartModal.DEVICES]: {
      title: "Ottieni agente endpoint di Coro",
      description:
        "Stai per connettere un dispositivo reale. Una volta connesso, tutti i dati demo saranno rimossi e l'ambiente passerà in modalità di prova. Questa azione è irreversibile. Vuoi procedere?",
      actionBtn: "Procedi",
    },
    [DemoWillStartModal.PROXY]: {
      title: "Aggiungi un dominio email",
      description:
        "Stai per connettere un dominio email reale. Una volta connesso, tutti i dati demo saranno rimossi e l'ambiente passerà in modalità di prova. Questa azione è irreversibile. Vuoi procedere?",
      actionBtn: "Procedi",
    },
    [DemoWillStartModal.NETWORK]: {
      title: "Configura l'Ufficio Virtuale",
      description:
        "Stai per configurare un vero Ufficio Virtuale. Una volta completata la configurazione, tutti i dati demo verranno rimossi e lo spazio di lavoro entrerà in modalità di prova. Questa azione è irreversibile. Vuoi procedere?",
      actionBtn: "Procedi",
    },
    [TutorialAction.ENABLE_WORKSPACE_DEMO_MODE]: {
      title: "Abilita Modalità Demo",
      description:
        "Stai per abilitare la Modalità Demo. Solo i dati demo verranno visualizzati nella console di Coro, mentre i dati reali rimarranno protetti in background. Le funzionalità di protezione continueranno a funzionare a piena capacità. Vuoi procedere?",
      actionBtn: "Procedi",
    },
  },
  accessControlPage: {
    title: "Controllo degli accessi",
    adminUsers: {
      title: "Utenti amministratori",
      addAdmin: "Aggiungi amministratore",
      grantLoginAccess: "Concedi l’accesso al login agli agenti dell’assistenza tecnica Coro",
      requireMFA: "Richiedi l’autenticazione a due fattori per tutti gli amministratori",
      table: {
        headers: {
          name: "Nome",
          email: "E-mail",
          status: "Stato",
          mfa: "2FA",
          role: "Ruolo",
        },
        actions: {
          [AdminUsersAction.CONTENT_INSPECTION]: "Autorizzazioni per l’ispezione dei contenuti",
          [AdminUsersAction.EDIT]: "Modifica utente amministratore",
          [AdminUsersAction.DELETE]: "Rimuovi l’utente amministratore",
          [AdminUsersAction.RESEND_INVITE]: "Invia di nuovo l’invito",
          [AdminUsersAction.DELETE_MFA]: "Elimina i dati 2FA",
          [AdminUsersAction.COPY_INVITE_LINK]: "Copia link di invito",
        },
        you: "Tu",
      },
      status: {
        active: "Attivo",
        invited: "Invitato in data {date}",
      },
      mfaStatus: {
        none: "Disabilitato",
        totp: "Abilitato",
      },
      details: {
        title: "{name} - Autorizzazioni all’ispezione dei contenuti",
        description:
          "Puoi usare questa pagina per abilitare le autorizzazioni all'utente amministratore individuato per visualizzare il contenuto e i risultati nelle e-mail contrassegnate per gli utenti protetti. Tieni presente che ciò potrebbe esporre ai rischi informazioni sensibili private e aziendali o contenuti protetti da leggi, policy o obblighi contrattuali.",
        connectMore: "Collega altri servizi cloud ›",
        connectMoreProxies: "Collega altri proxy e-mail ›",
        noItems: {
          connectBtn: "Collega le applicazioni cloud",
          title: "Devi innanzitutto connetterti ai servizi cloud che desideri proteggere.",
          description:
            "Una volta connesso, imposta le autorizzazioni di ispezione dei contenuti per le e-mail e i file sospetti.",
        },
        noCloudServicesConnected: "Nessun servizio cloud connesso",
        noProxyConnected: "Nessun Inbound Gateway collegato",
      },
    },
    roles: {
      title: "Ruoli",
      table: {
        headers: {
          role: "Ruolo",
          assigneesCount: "N. di utenti",
        },
        actions: {
          [RolesAction.ADD]: "Aggiungi ruolo",
          [RolesAction.EDIT]: "Modifica ruolo",
          [RolesAction.DUPLICATE]: "Duplica ruolo",
          [RolesAction.DELETE]: "Elimina ruolo",
        },
      },
      scopes: {
        [GlobalRoleScopes.GLOBAL_SCOPE]: {
          title: "Gestione globale di Coro",
          [GlobalRoleScopeSection.GLOBAL_ADMIN_USERS]: {
            title: "Utenti amministratori globali",
            editAccessModePermission: {
              add: "Aggiungi utenti amministratori globali",
              remove: "Rimuovi utenti amministratori globali",
              assignRoles: "Assegna ruoli agli utenti amministratori globali",
            },
          },
          [GlobalRoleScopeSection.GLOBAL_ROLES]: {
            title: "Ruoli globali",
            editAccessModePermission: {
              add: "Aggiungi/duplica ruoli globali",
              edit: "Modifica ruoli globali",
              remove: "Rimuovi ruoli globali",
            },
          },
          [GlobalRoleScopeSection.SOC_PORTAL]: {
            title: "Portale SOC",
            editAccessModePermission: {
              commentTickets: "Commento alle segnalazioni",
              [TicketActionSeverity.LOW]: "Azioni a basso impatto",
              [TicketActionSeverity.MEDIUM]: "Azioni a medio impatto",
              [TicketActionSeverity.CRITICAL]: "Azioni a impatto critico",
              tier1Statuses: "Stati di livello 1",
              tier2Statuses: "Stati di livello 2",
            },
          },
          [GlobalRoleScopeSection.WORKSPACE_SUBSCRIPTION_TYPE_ACCESS]: {
            title: "Accesso all'area di lavoro tramite abbonamento",
            editAccessModePermission: {
              prospects: "Potenziali (nuovo, inattivo, in prova, archiviato)",
              customers: "Clienti (abbonamenti)",
            },
          },
          [GlobalRoleScopeSection.WORKSPACE_TYPE_ACCESS]: {
            title: "Accesso all'area di lavoro per tipo",
            editAccessModePermission: {
              regular: "Aree di lavoro normali",
              channel: "Aree di lavoro dei canali",
              child: "Aree di lavoro secondarie",
            },
          },
          [GlobalRoleScopeSection.SPECIAL_PERMISSIONS]: {
            title: "Autorizzazioni specifiche",
            editAccessModePermission: {
              collectLogs: "Memorizza registri",
              collectQuarantineData: "Memorizza dati sugli elementi in quarantena",
              showAdvancedDeviceInformation: "Mostra informazioni avanzate dei dispositivi",
              backOffice: "Accedi al back office",
              manageEndpointAutoUpdateGroups:
                "Gestisci i gruppi di aggiornamento automatico degli endpoint",
              manageWorkspaceCodes: "Gestisci codici delle aree di lavoro",
              demoMode: "Modalità demo",
            },
          },
        },
        [MspRolePermissionScopes.MSP_PORTAL_SCOPE]: {
          title: "Gestione MSP",
          [MspPortalScopeSection.MSP_WORKSPACE_LABELS]: "Etichette dello spazio di lavoro",
          [MspPortalScopeSection.MSP_WORKSPACES]: {
            title: "Portale MSP",
            editAccessModePermission: {
              editWorkspace: "Modifica i dettagli dell'area di lavoro",
              startSubscription: "Avvia abbonamento",
              stopSubscription: "Interrompi abbonamento",
              editSubscription: "Modifica abbonamento",
              createChildWorkspace: "Crea un’area di lavoro secondaria",
              createChannelWorkspace: "Crea un’area di lavoro del channel",
              archiveWorkspace: "Archivia area di lavoro",
              generateMspExport: "Genera esportazione MSP",
              generateMspSummaryReport: "Genera report di sintesi MSP",
              exportMspNotifications: "Esporta le notifiche MSP",
              extendTrial: "Estendi il periodo di prova",
              convertWorkspace: "Converti area di lavoro",
              restoreWorkspace: "Ripristina l’area di lavoro",
              createRegularWorkspace: "Crea un’area di lavoro normale",
            },
          },
          [MspPortalScopeSection.MSP_ADMIN_USERS]: {
            title: "Utenti amministratori MSP",
            editAccessModePermission: {
              add: "Aggiungi utenti amministratori MSP",
              remove: "Rimuovi utenti amministratori MSP",
              assignRoles: "Assegna ruoli agli utenti amministratori MSP",
            },
          },
          [MspPortalScopeSection.MSP_ROLES]: {
            title: "Ruoli MSP",
            editAccessModePermission: {
              add: "Aggiungi/duplica ruoli MSP",
              edit: "Modifica ruoli MSP",
              remove: "Rimuovi ruoli MSP",
            },
          },
        },
        [RolePermissionsScope.WORKSPACE_MANAGEMENT]: {
          title: "Gestione",
          [WorkspaceManagementScopeSections.USERS]: "Utenti",
          [WorkspaceManagementScopeSections.DEVICES]: "Dispositivi",
          [WorkspaceManagementScopeSections.CLOUD_APPS]: "App cloud",
          [WorkspaceManagementScopeSections.ACTIVE_SESSIONS]: "Sessioni attive",
          [WorkspaceManagementScopeSections.ACTIVITY_LOGS]: "Registri attività",
          [WorkspaceManagementScopeSections.CONNECTORS]: "Connettori",
          [WorkspaceManagementScopeSections.ROLES]: "Ruoli",
          [WorkspaceManagementScopeSections.REPORTS]: "Rapporti",
          [WorkspaceManagementScopeSections.ADMIN_USERS]: {
            title: "Utenti amministratori",
            editAccessModePermission: {
              add: "Aggiungi",
              edit: "Modifica",
              remove: "Rimuovi",
              removeMfaData: "Elimina i dati 2FA",
              assignRoles: "Assegna ruoli",
              manageContentPermissions: "Gestisci le autorizzazioni ai contenuti",
            },
          },
        },
        [RolePermissionsScope.VIEWS]: {
          title: "Visualizzazioni",
          usersView: "Utenti",
          devicesView: "Dispositivi",
        },
        [RolePermissionsScope.PROTECTION]: {
          title: "Protezione",
          [SubscriptionModule.CLOUD_SECURITY]: "Cloud Security",
          [SubscriptionModule.ENDPOINT_SECURITY]: "Endpoint Security",
          [SubscriptionModule.EMAIL_SECURITY]: "Email Security",
          [SubscriptionModule.USER_DATA_GOVERNANCE]: "User Data Governance",
          [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: "Endpoint Data Governance",
          [SubscriptionAddon.INBOUND_GATEWAY]: "Inbound Gateway",
          [SubscriptionModule.EDR]: "EDR",
          [SubscriptionModule.NETWORK]: "Network",
          [ProtectionScopeModules.MANAGED_SOC]: "Servizi gestiti",
          [SubscriptionModule.MDM]: "MDM",
          [SubscriptionAddon.SWG]: "Secure Web Gateway",
          [SubscriptionAddon.WIFI_PHISHING]: "WiFi Phishing",
          [SubscriptionAddon.SECURED_MESSAGES]: "Secure Messages",
          [SubscriptionModule.SECURITY_AWARENESS]: "Security Awareness Training",
        },
        [RolePermissionsScope.TICKETS]: {
          title: "Segnalazioni",
          [SubscriptionModule.CLOUD_SECURITY]: {
            title: "Cloud Security",
            editAccessModePermission: {
              commentTickets: "Commento alle segnalazioni",
              [TicketActionSeverity.LOW]: "Azioni a basso impatto",
              [TicketActionSeverity.MEDIUM]: "Azioni a medio impatto",
              [TicketActionSeverity.CRITICAL]: "Azioni a impatto critico",
            },
          },
          [SubscriptionModule.ENDPOINT_SECURITY]: {
            title: "Endpoint Security",
            editAccessModePermission: {
              commentTickets: "Commento alle segnalazioni",
              [TicketActionSeverity.LOW]: "Azioni a basso impatto",
              [TicketActionSeverity.MEDIUM]: "Azioni a medio impatto",
              [TicketActionSeverity.CRITICAL]: "Azioni a impatto critico",
            },
          },
          [SubscriptionModule.EMAIL_SECURITY]: {
            title: "Email Security",
            editAccessModePermission: {
              commentTickets: "Commento alle segnalazioni",
              [TicketActionSeverity.LOW]: "Azioni a basso impatto",
              [TicketActionSeverity.MEDIUM]: "Azioni a medio impatto",
              [TicketActionSeverity.CRITICAL]: "Azioni a impatto critico",
            },
          },
          [SubscriptionModule.USER_DATA_GOVERNANCE]: {
            title: "User Data Governance",
            editAccessModePermission: {
              commentTickets: "Commento alle segnalazioni",
              [TicketActionSeverity.LOW]: "Azioni a basso impatto",
              [TicketActionSeverity.MEDIUM]: "Azioni a medio impatto",
              [TicketActionSeverity.CRITICAL]: "Azioni a impatto critico",
            },
          },
          [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
            title: "Endpoint Data Governance",
            editAccessModePermission: {
              commentTickets: "Commento alle segnalazioni",
              [TicketActionSeverity.LOW]: "Azioni a basso impatto",
              [TicketActionSeverity.MEDIUM]: "Azioni a medio impatto",
              [TicketActionSeverity.CRITICAL]: "Azioni a impatto critico",
            },
          },
          [SubscriptionModule.EDR]: {
            title: "EDR",
            editAccessModePermission: {
              commentTickets: "Commento alle segnalazioni",
              [TicketActionSeverity.LOW]: "Azioni a basso impatto",
              [TicketActionSeverity.MEDIUM]: "Azioni a medio impatto",
              [TicketActionSeverity.CRITICAL]: "Azioni a impatto critico",
            },
          },
        },
      },

      details: {
        adminUsers: "Utenti amministratori",
        permissions: "Autorizzazioni",
        permissionModes: {
          [RolePermissionAccessMode.EDIT]: "Può modificare",
          [RolePermissionAccessMode.VIEW]: "Può visualizzare",
          [RolePermissionAccessMode.NO_ACCESS]: "Nessun accesso",
        },
      },
    },
    activeSessions: {
      title: "Sessioni attive",
      description:
        "Questi dispositivi hanno effettuato l’accesso al sistema come utenti amministratori. Revoca le sessioni non riconosciute.",
      table: {
        headers: {
          ip: "Indirizzi IP",
          user: "Utente",
          country: "Paese",
          signedIn: "Accesso effettuato",
          lastAccessed: "Ultimo accesso",
        },
        revoke: "Revoca",
        currentSession: "Sessione attuale",
      },
    },
  },
  soc: {
    title: "Servizi gestiti",
    tabs: {
      remediation: "Correzione",
      contacts: "Contatti",
    },
    contactsTab: {
      addBtn: "Aggiungi il contatto del servizio gestito",
      emptyState: {
        description: "Al momento non disponi di contatti per il servizio gestito",
        subtitle:
          "Coro deve conoscere i punti di contatto principali dell’organizzazione per tutti i problemi legati al centro operativo di sicurezza (servizio gestito).",
      },
      table: {
        email: "E-mail",
        name: "Nome",
        notifications: "Notifiche",
      },
      actions: {
        [SocContactsActions.EDIT]: "Modifica",
        [SocContactsActions.REMOVE]: "Rimuovi",
      },
      notificationType: {
        email: "E-mail",
        emailCriticalIssues: "E-mail - problemi critici",
        doNotNotify: "Non inviare notifiche",
      },
    },
    remediationTab: {
      title: "Scegli la modalità di gestione della correzione",
      [SocRemediationType.REMEDIATE_MYSELF]:
        "Risolvi autonomamente i problemi sulla base delle raccomandazioni del team del servizio gestito da Coro.",
      remediateMyselfSubtitle:
        "Coro invierà una notifica sulle raccomandazioni ai tuoi contatti del servizio gestito in base al livello di notifica da loro specificato.",
      [SocRemediationType.REMEDIATE_BY_CORO_SOC]:
        "Consenti al team del servizio gestito da Coro di risolvere i problemi da remoto per mio conto.",
      remediateByCoroSocSubtitle:
        "Coro aggiornerà i tuoi contatti del servizio gestito sulle azioni intraprese in base al livello di notifica da loro specificato.",
    },
  },
  endpointDataGovernance: {
    title: "Endpoint Data Governance",
    privacySensitiveDataTab: {
      selectedParameters: {
        title: "Parametri selezionati",
        industries: "Settori",
        locations: "Posizioni",
      },
      emptyState: {
        title: "Impostazione dei parametri di privacy dei dati",
        subtitle:
          "Seleziona la tua industria per identificare i tipi di dati più rilevanti per le tue esigenze di governance. <br>",
        cta: "Impostazione dei parametri",
      },
      title: "Dati sensibili per la privacy",
      subtitle: "Seleziona i tipi di scansione desiderati",
      monitorPII:
        "Informazioni personali identificabili (Personally identifiable information, PII)",
      monitorPCI: "Informazioni sulle carte di pagamento (Payment card information, PCI)",
      monitorPHI: "Informazioni sanitarie protette (Protected health information, PHI)",
      monitorNPI: "Informazioni non pubbliche (Non-public information, NPI)",
      monitorPersonalData: "Dati personali",
      monitorHealthData: "Dati sanitari",
      monitorCreditCardData: "Dati sulle carte di credito",
      monitorNonPublicData: "Dati non pubblici",
      recommendedDataTypes: "Tipologie di dati consigliati",
      otherDataTypes: "Altri tipi di dati",
      noOtherDataTypes:
        "Nessun elemento disponibile. Tutte le tipologie di dati sono già incluse tra le consigliate.",
      sensitiveDataSubtitle:
        "In base alle industrie selezionate, Coro <b>raccomanda</b> questi tipi di dati. Abilitali o disabilitali secondo necessità. Il rilevamento supporta più lingue. Consulta la <a class='coro-link cursor-pointer' href='https://docs.coro.net/compliance/data-descriptors/' target='_blank'>documentazione</a> per l'elenco più recente dei tipi supportati.",
    },
    sensitiveDataScansTab: {
      title: "Scansioni dati sensibili",
      addBtn: "Aggiungi programma",
      emptyState: {
        description: "Al momento non sono state pianificate scansioni dei dati sensibili.",
        subtitle:
          "Esegue scansioni e identifica potenziali violazioni relative all'archiviazione di dati sensibili sulle unità dei dispositivi endpoint.",
      },
      table: {
        os: "Sistema operativo",
        action: "Azione",
        drives: "Unità",
        labels: "Etichette",
        appliedDriveTypes: {
          ALL: "Tutto",
          UNENCRYPTED: "Non crittografato",
        },
        actions: {
          [SensitiveDataScansActions.DELETE]: "Elimina",
          [SensitiveDataScansActions.EDIT]: "Modifica",
        },
      },
    },
  },
  cloudSecurity: {
    title: "Cloud Security",
    description: "Monitora e controlla l’accesso alle applicazioni e ai dati del cloud",
    accessPermissions: "Autorizzazioni di accesso",
    noItems: {
      title: "Aggiungi le tue applicazioni cloud alla protezione Coro",
      description:
        "Monitora gli account delle app cloud aziendali per proteggere l’ambiente e rispettare \nle normative sulla privacy.",
      connectBtn: "Connetti le app cloud",
      modalDescription: "Seleziona l’applicazione cloud che desideri collegare.",
    },
    tabs: {
      accessPermission: "Autorizzazioni di accesso",
      thirdPartyApps: "Applicazioni di terze parti",
      settings: "Impostazioni",
    },
  },
  cloudSecuritySettings: {
    inactiveUsers: "Utenti inattivi",
    default: "Default",
    custom: "Personalizza",
    numberOfDays: "Numero di giorni",
    inactiveUsersSubtitle:
      "Per impostazione predefinita, gli utenti diventeranno inattivi se non dovessero utilizzare un'applicazione cloud per 30 giorni. È possibile personalizzare il numero di giorni dopo cui un utente diventerà inattivo",
  },
  userDataGovernanceSettings: {
    title: "User Data Governance",
    description:
      "Monitoraggio e controllo dell’accesso a dati riservati e sensibili in base alle normative.",
    noEmailServicesConnected:
      "Collega <b>Microsoft 365</b> o <b>G-Suite</b> della tua società a Coro, per una governance dei dati efficace e la protezione dalla perdita di dati.",
    connectCloudAppsBtn: "Connetti le app cloud",
    addPermission: "Aggiungi autorizzazione",
    tabs: {
      monitoring: "Monitoraggio",
      permissions: "Autorizzazioni",
      exclusions: "Esclusioni",
      outboundGateway: "Outbound Getaway",
    },
    dataLossModals: {
      setPermissions: "Applica i criteri a questi utenti o domini",
      selectDataType: "Seleziona il tipo di dati",
      dataTypes: {
        [TicketTrigger.DLP_PCI]:
          "Informazioni sulle carte di pagamento (Payment Card Information, PCI)",
        [TicketTrigger.DLP_PHI]:
          "Informazioni sanitarie protette (Protected Health Information, PHI)",
        [TicketTrigger.DLP_PII]:
          "Informazioni personali identificabili (Personally Identifiable Information, PII)",
        [TicketTrigger.DLP_NPI]: "Informazioni non pubbliche (Non-public Information, NPI)",
        [TicketTrigger.DLP_PERSONAL]: "Dati personali",
        [TicketTrigger.DLP_HEALTH]: "Dati sanitari",
        [TicketTrigger.DLP_CREDIT_CARD]: "Dati sulle carte di credito",
        [TicketTrigger.DLP_NON_PUBLIC]: "Dati non pubblici",
        [TicketTrigger.CRITICAL_DATA_PASSWORD]: "Password",
        [TicketTrigger.CRITICAL_DATA_CERTIFICATE]: "Certificati",
        [TicketTrigger.CRITICAL_DATA_SOURCE_CODE]: "Codice sorgente",
        [TicketTrigger.CRITICAL_DATA_KEYWORDS]: "Parole chiave sensibili",
        [TicketTrigger.CRITICAL_DATA_FILE_TYPES]: "Tipi di file sensibili",
      },
    },
    permissionsTab: {
      usersAndGroups: "Utenti/gruppi",
      permission: "Autorizzazione",
      allUsersPolicy: "Tutti gli utenti amministratori",
      dlpPci: "Informazioni sulle carte di pagamento (Payment Card Information, PCI)",
      dlpPhi: "Informazioni sanitarie protette (Protected Health Information, PHI)",
      dlpPii: "Informazioni personali identificabili (Personally Identifiable Information, PII)",
      dlpNpi: "Informazioni non pubbliche (Non-public Information, NPI)",
      dlpPersonalData: "Dati personali",
      dlpHealthData: "Dati sanitari",
      dlpCreditCardData: "Dati sulle carte di credito",
      dlpNonPublicData: "Dati non pubblici",
      criticalDataPassword: "Password",
      criticalDataCertificate: "Certificati",
      criticalDataSourceCode: "Codice sorgente",
      criticalDataKeywords: "Oggetti contenenti dati con parole chiave specifiche",
      criticalDataFileTypes: "Tipi di file sensibili",
      addPermissionOptions: {
        addPermissionForAllUsers: "Tutti gli utenti",
        addPermissionForSpecificGroups: "Gruppi specifici",
        addPermissionForSpecificUsers: "Utenti/domini specifici",
      },
      actions: {
        accessOnly: "Può accedere",
        accessAndExposure: "Può accedere ed esporre",
        remove: "Rimuovi autorizzazione",
      },
      noPermissionsDescription:
        "Al momento non disponi delle autorizzazioni specifiche per questi dati.",
    },
    monitoringTab: {
      dataPossession: "Possesso dei dati",
      dataExposure: "Esposizione dei dati",
      dataExposureDescription:
        "Tipi di dati sensibili da monitorare per verificarne l’accesso e l’esposizione nelle e-mail e nella condivisione di dati su cloud drive.",
      dataPossessionDescription:
        "Tipi di dati sensibili da ricercare nelle scansioni avviate in remoto delle unità dei dispositivi endpoint.",
      monitorPII:
        "Informazioni personali identificabili (Personally identifiable information, PII)",
      monitorPCI: "Informazioni sulle carte di pagamento (Payment card information, PCI)",
      monitorPHI: "Informazioni sanitarie protette (Protected health information, PHI) ",
      monitorNPI: "Informazioni non pubbliche (Non-public information, NPI)",
      monitorCustomDataEntries: "Voci di dati personalizzati",
      excludeEmailScans: "Escludi le scansioni delle e-mail in uscita",
      keywordsTitle: "Parole chiave specifiche nell’oggetto",
      keywordsPlaceholder: "Inserisci parole chiave come “keyword1” o “keyword2”",
      passwords: "Password",
      certificates: "Certificati",
      sourceCode: "Codice sorgente",
      dataObjectsWithSpecificKeywords: "Oggetti contenenti dati con parole chiave specifiche",
      specificKeywordsPlaceholder: "Inserisci parole chiave come “keyword1” o “keyword2”",
      specificFileTypesTitle: "Tipi di file specifici",
      specificFileTypesPlaceholder: "png, psd, docx…",
      privacySensitiveData: "Dati sensibili per la privacy",
      securityAndBusinessData: "Sicurezza e dati sensibili della società",
      recommendedDataTypes: "Tipologie di dati consigliati",
      otherDataTypes: "Altri tipi di dati",
      noOtherDataTypes:
        "Nessun elemento disponibile. Tutte le tipologie di dati sono già incluse tra le consigliate.",
      sensitiveDataSubtitle:
        "In base alle industrie selezionate, Coro <b>raccomanda</b> questi tipi di dati. Abilitali o disabilitali secondo necessità. Il rilevamento supporta più lingue. Consulta la <a class='coro-link cursor-pointer' href='https://docs.coro.net/compliance/data-descriptors/' target='_blank'>documentazione</a> per l'elenco più recente dei tipi supportati.",
      selectedParameters: {
        title: "Parametri selezionati",
        industries: "Settori",
        locations: "Posizioni",
      },
      dataPrivacyTitle: "Impostazione dei parametri di privacy dei dati",
      dataPrivacySubtitle:
        "Seleziona la tua industria per identificare i tipi di dati più rilevanti per le tue esigenze di governance. <br>",
      dataPrivacyCta: "Impostazione dei parametri",
      monitorPersonalData: "Dati personali",
      monitorHealthData: "Dati sanitari",
      monitorCreditCardData: "Dati sulle carte di credito",
      monitorNonPublicData: "Dati non pubblici",
    },
    exclusionsTab: {
      title: "Configura i dati da escludere dalle scansioni dei dati sensibili.",
      excludeEmailsWithKeywords: "Escludi e-mail con parole chiave specificate nell’oggetto",
      specificKeywordsPlaceholder: "Inserisci parole chiave come “keyword1” o “keyword2”",
    },
  },
  securityAwarenessSettings: {
    title: "Security Awareness Training",
    tabs: {
      phishingSimulation: "Simulazione di phishing",
      securityTraining: "Formazione sulla sicurezza",
    },
    allowBanner: {
      title: "[TRANSLATION] Allowlist Security Awareness Training on {service}",
      description:
        "[TRANSLATION] You’ve successfully allowlisted phishing simulations for {allowedService}, but <span class='subtitle2'>{notAllowedService} is not yet configured</span>.<br>If you will use {notAllowedService} to receive Phishing Simulation emails and training, allowlist it now.",
      allowBtn: "[TRANSLATION] Allowlist in {service}",
    },
    emptyState: {
      connectTitle:
        "[TRANSLATION] Connect your cloud applications to activate Security Awareness Training",
      allowTitle: "[TRANSLATION] Allow Security Awareness Training in your Cloud apps",
      connectSubtitle:
        "[TRANSLATION] To enable this module, connect Microsoft 365 or Google Workspace.",
      allowSubtitle:
        "[TRANSLATION] To enable Security Awareness Training, add it to your cloud application allowlist.",
      warning:
        "[TRANSLATION] We recommend completing the allowlisting process within {n} days so users have full access to all content.",
      allowButtonText: "[TRANSLATION] Allow in {service}",
      selectCloudApp: "[TRANSLATION] Select the email service provider you use:",
    },
    cloudApps: "[TRANSLATION] Cloud Apps",
    phishingSimulation: {
      activate: "Attiva la simulazione di phishing",
      activateHint:
        "Invia automaticamente e-mail di simulazione di phishing a tutti gli utenti protetti.",
      simulationsLanguage: "Linguaggio della simulazione",
      enrollUsers: "Iscrivi gli utenti vittime di phishing alla formazione",
      enrollUsersHint:
        "Ogni volta che un utente fallirà una simulazione di phishing, verrà iscritto a un corso di formazione pertinente",
      scheduledSimulations: "Simulazioni programmate",
      simulationsHistory: "Cronologia delle simulazioni",
      deliveryDate: "Data di consegna",
      emailPreview: "Anteprima e-mail",
      simulationsHistoryEmpty: "Nessuna simulazione inviata",
      scheduledSimulationsEmpty: "Nessuna simulazione programmata",
    },
    securityTraining: {
      activate: "Attiva l'addestramento alla sicurezza",
      activateHint:
        "Tutti gli utenti protetti sono iscritti a un programma di formazione sulla sicurezza programmato.",
      enableOnboarding: "Abilita il corso di onboarding per i nuovi utenti",
      enableOnboardingHint:
        "La formazione sarà inviata durante il primo mese e il secondo mese seguirà il resto dell'azienda.",
      complianceTraining: "Formazione sulla conformità",
      complianceTrainingHint:
        "Tutti gli utenti protetti e i nuovi utenti saranno iscritti alla formazione sulla conformità selezionata entro 24 ore.",
      adaptiveTraining: "Formazione adattativa",
      adaptiveTrainingHint:
        "Assegna automaticamente corsi di formazione personalizzati analizzando i comportamenti a rischio degli utenti, contribuendo a rafforzare la consapevolezza della sicurezza individuale.",
    },
  },
  myAccount: {
    tabs: {
      profile: "Profilo",
      password: "Password",
      mfa: "Autenticazione a due fattori",
      notifications: "Notifiche",
    },
    profile: {
      title: "Informazioni del profilo",
      language: "Lingua",
      firstName: {
        label: "Nome",
      },
      lastName: {
        label: "Cognome",
      },
      email: {
        label: "E-mail",
        hint: "L’e-mail non può essere aggiornata",
      },
      updateProfile: "Aggiorna il profilo",
    },
    password: {
      title: "Password",
      password: {
        label: "Password",
      },
      oldPassword: {
        label: "Vecchia Password",
      },
      newPassword: {
        label: "Nuova Password",
      },
      setPassword: "Imposta password",
      changePassword: "Modifica password",
      warningMessage:
        "Non è stata ancora impostata alcuna password. L’impostazione di una password si applica a tutte le aree di lavoro di cui fai parte.",
      successMessage: "La password è stata aggiornata.",
      passwordSentMessage:
        "Ti abbiamo inviato un’e-mail con le istruzioni per reimpostare la password.",
      forgotPasswordBtn: "Hai dimenticato la password?",
    },
    notifications: {
      generalNotifications: "Notifiche generali",
      dailyEmailUpdates: "Aggiornamenti sullo stato dell‘area di lavoro (consigliato)",
      criticalTickets:
        "Segnalazioni critiche che necessitano di un’azione tempestiva (consigliati)",
      releaseNotes: "Note di rilascio",
      productAnnouncements: "Annunci di prodotti",
      specificNotifications: "Notifiche per segnalazioni specifiche",
      cloudSecurity: "Cloud Security",
      endpointSecurity: "Endpoint Security",
      emailSecurity: "Email Security",
      userDataGovernance: "User Data Governance",
      endpointDataGovernance: "Endpoint Data Governance",
      edr: "EDR",
    },
    mfa: {
      title: "Autenticazione a due fattori",
      notice:
        "L’aggiornamento dell’autenticazione a due fattori verrà applicato anche a tutte le aree spazi di lavoro di cui fai parte.",
      mfaEnabled: "L’autenticazione a due fattori è abilitata",
      mfaEnabledDesc:
        "Hai attivato l’autenticazione a due fattori per il tuo account con un’app di autenticazione.",
      mfaDisabled: "L’autenticazione a due fattori è disabilitata",
      recoveryMobile: "Il tuo numero di cellulare per il recupero",
      disableMFAButton: "Elimina i dati dell’autenticazione a due fattori",
      fallbackTitle: "Abilita il metodo di recupero di riserva",
      fallbackDesc:
        "Inserisci un numero di telefono nel caso in cui tu non riesca ad autenticarti e debba recuperare il codice tramite SMS.",
      fallbackCodeTitle: "Inserisci il codice a 6 cifre che hai ricevuto sul telefono",
      finishSetupBtn: "Termina la configurazione",
      smsSent: "SMS inviato",
    },
  },
  tickets: {
    eventTypes: {
      [TicketType.ABNORMAL_ADMIN_ACTIVITY]: "Attività amministratore anomala",
      [TicketType.MALWARE_IN_EMAIL_ATTACHMENTS]: "Malware nell’allegato dell’e-mail",
      [TicketType.SUSPECTED_IDENTITY_COMPROMISE]: "Sospetto di compromissione dell’identità",
      [TicketType.MALWARE_IN_CLOUD_DRIVE]: "Malware su drive cloud",
      [TicketType.ACCESS_PERMISSIONS_VIOLATION]: "Violazione delle autorizzazioni di accesso",
      [TicketType.MALWARE_IN_DEVICE]: "Malware su endpoint",
      [TicketType.ENDPOINT_VULNERABILITY]: "Vulnerabilità endpoint",
      [TicketType.EMAIL_PHISHING]: "Phishing tramite e-mail [superato]",
      [TicketType.WIFI_PHISHING]: "Wi-Fi Phishing",
      [TicketType.WIFI_FORBIDDEN_NETWORK]: "Rete Wi-Fi proibita",
      [TicketType.ABNORMAL_DATA_MANIPULATION]: "Manipolazione anomala dei dati",
      [TicketType.SUSPECTED_BOT_ATTACKS]: "Sospetti attacchi bot",
      [TicketType.SUSPECTED_DATA_EXPOSURE_BY_EMAIL]:
        "Sospetta esposizione di dati critici tramite e-mail",
      [TicketType.SUSPECTED_DATA_EXPOSURE_BY_SHARING]:
        "Sospetta esposizione di dati critici tramite condivisione",
      [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_EMAIL]:
        "Sospetta violazione della conformità dei dati tramite e-mail",
      [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_SHARING]:
        "Sospetta violazione della conformità dei dati tramite condivisione",
      [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE]: "Unità endpoint con dati sensibili",
    },
    eventTriggers: {
      [TicketTrigger.CRITICAL_DATA_FILE_TYPES]: "Esposizione sospetta del tipo di file",
      [TicketTrigger.CRITICAL_DATA_SOURCE_CODE]: "Esposizione sospetta del codice sorgente",
      [TicketTrigger.CRITICAL_DATA_PASSWORD]: "Esposizione sospetta della password",
      [TicketTrigger.CRITICAL_DATA_CERTIFICATE]: "Esposizione sospetta del certificato",
      [TicketTrigger.DLP_PII]: "Dati personali",
      [TicketTrigger.DLP_PCI]: "Dati sulle carte di credito",
      [TicketTrigger.DLP_PHI]: "Dati sanitari",
      [TicketTrigger.DLP_NPI]: "Dati non pubblici",
      [TicketTrigger.DEVICE_DLP_PII]: "Unità endpoint con Dati personali",
      [TicketTrigger.DEVICE_DLP_PCI]: "Unità endpoint con Dati sulle carte di credito",
      [TicketTrigger.DEVICE_DLP_PHI]: "Unità endpoint con Dati sanitari",
      [TicketTrigger.DEVICE_DLP_NPI]: "Unità endpoint con Dati non pubblici",
      [TicketTrigger.DLP_PERSONAL]: "Dati personali",
      [TicketTrigger.DLP_HEALTH]: "Dati sanitari",
      [TicketTrigger.DLP_CREDIT_CARD]: "Dati sulle carte di credito",
      [TicketTrigger.DLP_NON_PUBLIC]: "Dati non pubblici",
      [TicketTrigger.DEVICE_DLP_PERSONAL]: "Unità endpoint con dati personali",
      [TicketTrigger.DEVICE_DLP_HEALTH]: "Unità endpoint con dati sanitari",
      [TicketTrigger.DEVICE_DLP_CREDIT_CARD]: "Unità endpoint con dati sulle carte di credito",
      [TicketTrigger.DEVICE_DLP_NON_PUBLIC]: "Unità endpoint con dati non pubblici",
      [TicketTrigger.CRITICAL_DATA_KEYWORDS]: "Esposizione sospetta dei dati critici",
      [TicketTrigger.ENCRYPTION_DISABLED]: "Unità endpoint non crittografata",
      [TicketTrigger.APPS_FROM_UNKNOWN_SOURCES]: "Applicazioni da fonti sconosciute",
      [TicketTrigger.ANTI_VIRUS_DISABLED]: "Antivirus disattivato",
      [TicketTrigger.DEVICE_PASSWORD_MISSING]: "Password del dispositivo mancante",
      [TicketTrigger.DEVELOPMENT_MODE_ENABLED]: "Modalità di sviluppo abilitata",
      [TicketTrigger.VSS_BACKUP_PROTECTION]: "Protezione del backup VSS",
      [TicketTrigger.MOCK_LOCATION_ENABLED]: "Posizione fittizia abilitata",
      [TicketTrigger.NON_GENUINE_WINDOWS]: "Copia non autentica di Windows",
      [TicketTrigger.FIREWALL_DISABLED]: "Firewall disabilitato",
      [TicketTrigger.UAC_NOTIFICATIONS_MISSING]: "Notifica UAC mancante",
      [TicketTrigger.ACTIVEX_CODE_EXECUTION_ENABLED]: "Esecuzione del codice X attivo abilitata",
      [TicketTrigger.REMOTE_CODE_EXECUTION_ENABLED]: "Protocollo SMB non sicuro",
      [TicketTrigger.CRITICAL_UPDATES_NOT_INSTALLED]: "Mancano aggiornamenti critici di Windows",
      [TicketTrigger.EXECUTION_POLICY_ENABLED]: "Configurazione PowerShell non sicura",
      [TicketTrigger.INFECTED_PROCESS]: "Processo interessato",
      [TicketTrigger.ABNORMAL_ADMIN_ACTIVITY]: "Attività amministratore anomala",
      [TicketTrigger.SUSPECTED_IDENTITY_COMPROMISE]: "Sospetto di compromissione dell’identità",
      [TicketTrigger.MALWARE_IN_CLOUD_DRIVE]: "Malware su drive cloud",
      [TicketTrigger.EMAIL_PHISHING]: "Phishing tramite e-mail [superato]",
      [TicketTrigger.MALWARE_IN_EMAIL_ATTACHMENTS]: "Malware nell’allegato dell’e-mail",
      [TicketTrigger.ABNORMAL_DATA_MANIPULATION]: "Manipolazione anomala dei dati",
      [TicketTrigger.ACCESS_PERMISSIONS_VIOLATION]: "Violazione delle autorizzazioni di accesso",
      [TicketTrigger.MALWARE_ON_ENDPOINT]: "Malware su endpoint",
      [TicketTrigger.SUSPECTED_BOT_ATTACKS]: "Sospetti attacchi bot",
      [TicketTrigger.MASS_DELETE]: "Cancellazione di massa dei dati",
      [TicketTrigger.MASS_DOWNLOAD]: "Download di massa dei dati",
      [TicketTrigger.WIFI_PHISHING]: "WiFi Phishing",
      [TicketTrigger.FORBIDDEN_NETWORK_CONNECTION]: "Connessione Wi-Fi proibita",
      [TicketTrigger.GATEKEEPER_UNAVAILABLE]: "Gatekeeper disabilitato",
      [TicketTrigger.APPLE_MOBILE_FILE_INTEGRITY_UNAVAILABLE]:
        "Integrità dei file di Apple Mobile disabilitata",
      [TicketTrigger.SYSTEM_INTEGRITY_PROTECTION_UNAVAILABLE]:
        "Protezione dell’integrità del sistema disattivata",
      [TicketTrigger.USB_LOCKDOWN]: "Protezione USB",
      [TicketTrigger.BLOCKLISTED_SENDER]: "Mittente bloccato",
      [TicketTrigger.BRAND_IMPERSONATION]: "Imitazione del brand",
      [TicketTrigger.CROWDBLOCKED_SENDER]: "Crowd ha bloccato il mittente",
      [TicketTrigger.DOMAIN_IMPERSONATION]: "Imitazione del dominio",
      [TicketTrigger.FORBIDDEN_ATTACHMENT_TYPE]: "Tipo di allegato vietato",
      [TicketTrigger.MISSING_REQUIRED_AUTHENTICATION]: "Manca l’autenticazione richiesta",
      [TicketTrigger.SUSPICIOUS_CONTENT]: "Contenuto sospetto",
      [TicketTrigger.SPAM]: "Spam",
      [TicketTrigger.UNVERIFIED_SENDER]: "Mittente non verificato",
      [TicketTrigger.USER_IMPERSONATION]: "Imitazione dell’utente",
      [TicketTrigger.REPORTED_BY_USER]: "Segnalato dall’utente",
      [TicketTrigger.DOMAIN_SPOOFING]: "Spoofing del dominio",
      [TicketTrigger.EDR_DETECTION_SUSPICIOUS_PROCESS]: "Processo sospetto",
      [TicketTrigger.AUTOMATIC_UPDATE_DISABLED]: "Aggiornamenti Windows disabilitati",
      [TicketTrigger.EDR_DETECTION_COLLECTION]: "Memorizzazione",
      [TicketTrigger.EDR_DETECTION_COMMAND_AND_CONTROL]: "Comando e controllo",
      [TicketTrigger.EDR_DETECTION_CREDENTIAL_ACCESS]: "Accesso con credenziali",
      [TicketTrigger.EDR_DETECTION_DEFENCE_EVASION]: "Elusione della difesa",
      [TicketTrigger.EDR_DETECTION_EXECUTION]: "Attuazione",
      [TicketTrigger.EDR_DETECTION_DISCOVERY]: "Rilevamento",
      [TicketTrigger.EDR_DETECTION_EXFILTRATION]: "Esfiltrazione",
      [TicketTrigger.EDR_DETECTION_IMPACT]: "Impatto",
      [TicketTrigger.EDR_DETECTION_INITIAL_ACCESS]: "Accesso iniziale",
      [TicketTrigger.EDR_DETECTION_LATERAL_MOVEMENT]: "Movimento laterale",
      [TicketTrigger.EDR_DETECTION_PERSISTENCE]: "Persistenza",
      [TicketTrigger.EDR_DETECTION_PRIVILEGE_ESCALATION]: "Escalation dei privilegi",
      [TicketTrigger.EDR_DETECTION_RECONNAISSANCE]: "Esplorazione",
      [TicketTrigger.EDR_DETECTION_RESOURCE_DEVELOPMENT]: "Sviluppo delle risorse",
      [TicketTrigger.SUSPICIOUS_METADATA]: "Metadati sospetti",
      [TicketTrigger.USER_INACTIVITY]: "Utente inattivo",
      [TicketTrigger.INBOUND_GATEWAY_MX_RECORD_MISCONFIGURATION]:
        "Inbound Gateway: record MX non configurato correttamente",
    },
    filters: {
      type: "Tipo",
      during: "Durante",
      status: "Stato",
      searchTickets: "Ricerca segnalazioni...",
      searchCustomers: "Ricerca clienti...",
      widgets: {
        placeholder: "Modulo",
        [TicketsModuleFilter.DEVICE_WIDGET]: "Endpoint Security",
        [TicketsModuleFilter.CLOUD_SECURITY_WIDGET]: "Cloud Security",
        [TicketsModuleFilter.EMAILS_WIDGET]: "Email Security",
        usersWidget: "Utenti",
        [TicketsModuleFilter.USER_DATA_GOVERNANCE]: "User Data Governance",
        [TicketsModuleFilter.ENDPOINT_DATA_GOVERNANCE]: "Endpoint Data Governance",
        [TicketsModuleFilter.EDR]: "EDR",
      },
    },
    socStatuses: {
      [SocStatus.NEW]: "Novità",
      [SocStatus.WIP_TIER1]: "WIP di livello 1",
      [SocStatus.WIP_TIER2]: "WIP di livello 2",
      [SocStatus.REQUIRES_TIER2]: "Richiede il livello 2",
      [SocStatus.NEW_COMMENTS]: "Nuovi commenti",
      closed: "Chiuso",
    },
  },
  workspaces: {
    title: "Seleziona l’area di lavoro da visualizzare",
    tabs: {
      workplaces: "Aree di lavoro",
      pendingInvitations: "Inviti in sospeso",
    },
    tableHeaders: {
      id: "ID",
      name: "Nome dell’area di lavoro",
    },
    selected: "Selezionato",
    search: "Ricerca area di lavoro",
    actions: {
      [WorkspaceAction.INVITE]: "Invita",
      [WorkspaceAction.LAUNCH]: "Visualizza",
      [WorkspaceAction.JOIN]: "Partecipa",
    },
    modals: {
      invite: {
        title: "Invita utente",
        label: "Indica gli utenti che desideri invitare nell’area di lavoro.",
        actionButton: "Invita utenti",
      },
      selectWorkplace: {
        title: "Seleziona l’area di lavoro da visualizzare",
        actionButton: "Lancia",
      },
    },
  },
  reports: {
    title: "Rapporti",
    tabs: {
      reports: "Rapporti",
      exports: "Esportazioni",
      scheduled: "Programmato",
    },
    executiveSummaryReport: {
      download: {
        title: "Scarica",
        toPdf: "PDF",
      },
      executiveSummary: "Riepilogo esecutivo",
      version: "Versione",
      generatedOn: "Generato in data:",
      totalTicketsByModuleAndVulnerabilities:
        "Totale segnalazioni per modulo e principali vulnerabilità",
      totalTicketsByModule: "Totale segnalazioni per modulo",
      topVulnerabilities: "Vulnerabilità principali",
      protection: "Protezione",
      ticketsByType: "Segnalazioni per tipologia",
      dateRange: "Intervallo di date",
      disabledModuleNotification: "Questo modulo è disattivato al momento",
      ticketsGenerated: "Segnalazioni generate",
      users: {
        total: "Utenti totali",
        protected: "Protetto",
        unprotected: "Non protetto",
      },
      devices: {
        total: "Dispositivi coperti",
        totalOffline: "{n} offline",
        outdated: "{n} obsoleti",
      },
      tickets: {
        total: "Segnalazioni generate",
        processed: "Segnalazioni chiuse",
        unprocessed: "Apri segnalazione",
      },
      [SubscriptionModule.USER_DATA_GOVERNANCE]: {
        title: "User Data Governance",
        description:
          "Analisi delle anomalie per identificare e ridurre l’esposizione di dati sensibili attraverso la posta elettronica e la condivisione di file, rispettando le configurazioni dello spazio di lavoro; include l’esposizione di Dati personali, Dati sulle carte di credito, Dati sanitari, Dati non pubblici e aziendali.",
        topViolatorsTitle: "Violatori principali",
      },
      [SubscriptionModule.EMAIL_SECURITY]: {
        title: "Email Security",
        description:
          "Protezione contro l’imitazione dei domini, le e-mail ingannevoli e gli allegati dannosi, garantendo un ambiente e-mail sicuro.",
        topViolatorsTitle: "Le principali e-mail sospette",
      },
      [SubscriptionModule.CLOUD_SECURITY]: {
        title: "Cloud Security",
        description:
          "Rilevamento avanzato e correzione di malware, ransomware e accessi non autorizzati nelle unità cloud. Gli amministratori possono configurare il geo/network-fencing in modo che il modulo possa ottimizzare il rilevamento degli incidenti in tempo reale attraverso l’analisi dei dati consolidati.",
        topViolatorsTitle: "Principali utenti target",
      },
      [SubscriptionModule.ENDPOINT_SECURITY]: {
        title: "Endpoint Security",
        description:
          "Protezione in tempo reale contro malware e ransomware grazie ad antivirus di nuova generazione (Next-Generation Antivirus, NGAV). Gestione completa delle funzionalità di sicurezza critiche del dispositivo, tra cui scansioni delle unità, aggiornamenti degli agenti, monitoraggio delle attività, impostazioni di applicazione personalizzabili, gestione del firewall e notifiche UAC (User Account Control).",
        topViolatorsTitle: "I principali dispositivi vulnerabili",
      },
      [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
        title: "Endpoint Data Governance",
        description:
          "Scansioni degli endpoint alla ricerca di dati sensibili in base alle configurazioni dell’area di lavoro; include l’esposizione di Dati personali, Dati sulle carte di credito, Dati sanitari, Dati non pubblici e aziendali.",
        topViolatorsTitle: "Violatori principali",
      },
      [SubscriptionModule.EDR]: {
        title: "EDR",
        description:
          "Rilevamento e risposta alle minacce avanzate che colpiscono i dispositivi endpoint, tra cui attività sospette, comportamenti dannosi e segni di compromissione.",
        topViolatorsTitle: "I processi più vulnerabili",
      },
      [SubscriptionModule.SECURITY_AWARENESS]: {
        title: "Security Awareness Training",
        description:
          "Fornisci al tuo team il modo per rilevare e prevenire le minacce informatiche con una formazione mirata, simulazioni di phishing e moduli di conformità. Diffondi una solida cultura della sicurezza in tutta l'organizzazione.",
        simulationsSent: "Simulazioni inviate",
        trainingsSent: "Formazione sulla sicurezza inviata",
        trainingsCompleted: "Formazione sulla sicurezza completata",
        topPhishedUsers: "Utenti che hanno subito più attacchi phishing",
        topOverdueUsers: "Utenti più in ritardo",
      },
    },
    managedServicesSummaryReport: {
      title: "Sintesi dei servizi gestiti",
      totalTicketsResolved: "Totale ticket risolti",
      resolvedTicketsByModules: "Ticket risolti per modulo",
      ticketsResolved: "Ticket risolti",
    },
    securedMessagesSummaryReport: {
      title: "Sintesi dei Secure Messages",
      totalSecuredMessagesSent: "Totale dei messaggi sicuri inviati",
      securedMessagesSentByUser: "Messaggi sicuri inviati per utente",
    },
    dnsSummaryReport: {
      title: "Sintesi DNS",
      totalQueries: "Query totali",
      blockedQueries: "Query bloccate",
      topDomainsPermitted: "Principali domini consentiti",
      topDomainsBlocked: "Principali domini bloccati ",
      topClients: "Principali client consentiti",
      topClientsBlocked: "Principali client bloccati",
      perHits: "per accesso",
      perRequest: "per richiesta",
      queries: "Query",
      noData: "Non hai dati",
      noItems: {
        title: "Il filtro DNS è stato disattivato",
        description: "Abilita il filtro DNS per visualizzare il riepilogo delle query DNS",
        actionBtn: "Vai alle impostazioni SWG",
      },
    },
    securityAwarenessReport: {
      title: "[TRANSLATION] Security training report",
      securityTrainingSummary: "[TRANSLATION] Security training summary",
      trainingEngagement: "[TRANSLATION] Training engagement",
      listOfUsers: "[TRANSLATION] List of users",
      trainingStatus: "[TRANSLATION] Training status <span class='body3'>(% completed)</span>",
      overdueTraining: "[TRANSLATION] Top users with overdue training",
      trainingsSent: "Sessioni di formazione inviate",
      passed: "Completato",
      noAction: "Da fare",
      failed: "In ritardo",
      user: "[TRANSLATION] User",
      trainingSent: "[TRANSLATION] Training sent",
      completed: "Completato",
      averageScore: "[TRANSLATION] Average score",
      overdueDays: "[TRANSLATION] Overdue ({n} day) | Overdue ({n} days)",
      exportAllUsers: "[TRANSLATION] Export all users",
    },
    exports: {
      newExport: "Nuova esportazione",
      actions: {
        [ExportAction.EXPORT_DEVICES]: "Dispositivi",
        [ExportAction.EXPORT_PROTECTED_USERS]: "Utenti protetti",
        [ExportAction.EXPORT_TICKETS]: "Registro delle segnalazioni",
        [ExportAction.EXPORT_ACTIVITY_LOGS]: "Registro attività",
      },
      disclaimer:
        "<b>Le esportazioni sono disponibili per il download per 24 ore.</b> Se l'esportazione è scaduta, generane una nuova per scaricarla.",
      table: {
        name: "Nome",
        category: "Categoria",
        created: "Creato",
      },
      categories: {
        [ExportCategory.ACTIVITY_LOGS]: "Registro attività",
        [ExportCategory.DEVICES]: "Dispositivi",
        [ExportCategory.USERS]: "Utenti protetti",
        [ExportCategory.TICKETS]: "Registro delle segnalazioni",
        [ExportCategory.TICKETS_MSP]: "Registro globale dei ticket",
        [ExportCategory.DEVICES_MSP]: "Dispositivi globali",
        [ExportCategory.USERS_MSP]: "Utenti globali protetti",
      },
      exportFileExtensions: {
        [ExportFileExtension.CSV]: "CSV",
        [ExportFileExtension.ZIP]: "ZIP",
      },
      filters: {
        types: "Categoria",
        dateRange: "Intervallo di date",
      },
    },
    scheduled: {
      schedule: "Programma",
      table: {
        name: "Nome",
        reportType: "Tipo",
        frequency: "Frequenza",
        recipients: "Destinatari",
        scope: "Scopo",
      },
      type: {
        report: "Report",
        export: "Esporta",
      },
      actions: {
        [ScheduledReportsAction.ADD_REPORT]: "Report",
        [ScheduledReportsAction.EDIT_REPORT]: "Modifica",
        [ScheduledReportsAction.ADD_EXPORT]: "Esporta",
        [ScheduledReportsAction.EDIT_EXPORT]: "Modifica",
        [ScheduledReportsAction.DELETE]: "Rimuovi",
      },
      subTypes: {
        [ExportCategory.ACTIVITY_LOGS]: "Esportazione del registro attività",
        [ExportCategory.DEVICES]: "Esportazione dei dispositivi",
        [ExportCategory.USERS]: "Esportazione degli utenti protetti",
        [ExportCategory.TICKETS]: "Esportazione dei ticket",
        [ReportType.DNS_SUMMARY]: "Rapporto di sintesi DNS",
        [ReportType.SECURED_MESSAGES]: "Sintesi dei report Secure Messages",
        [ReportType.EXECUTIVE_SUMMARY]: "Report del riepilogo esecutivo",
        [ReportType.MANAGED_SERVICES_SUMMARY]: "Report di sintesi dei servizi gestiti",
      },
      frequencies: {
        [ScheduledReportFrequency.DAILY]: "Quotidiana",
        [ScheduledReportFrequency.WEEKLY]: "Settimanale",
        [ScheduledReportFrequency.MONTHLY]: "Mensile",
      },
      scopes: {
        [ScheduledReportScope.WORKSPACE_AND_DIRECT_DESCENDANTS]:
          "Area di lavoro personale e secondarie dirette",
        [ScheduledReportScope.WORKSPACE]: "La mia area di lavoro",
        [ScheduledReportScope.WORKSPACE_AND_ALL_DESCENDANTS]:
          "Area di lavoro personale e tutte le secondarie",
      },
      recipients: {
        allAdminUsers: "Tutti gli utenti amministratori delle aree di lavoro",
        specificAdminUsers: "Utenti amministratori specifici",
      },
    },
  },
  dateRangePicker: {
    cancel: "Cancella",
    reset: "Ripristina",
    select: "Seleziona",
    lastThreeDays: "Ultimi 3 giorni",
    lastSevenDays: "Ultimi 7 giorni",
    lastThirtyDays: "Ultimi 30 giorni",
    lastNinetyDays: "Ultimi 90 giorni",
    last365Days: "Ultimi 365 giorni",
    all: "Tutto",
    today: "Oggi",
    yesterday: "Ieri",
    thisMonth: "Questo mese",
    lastMonth: "Il mese scorso",
  },
  devicesSettings: {
    title: "Endpoint Security",
    devicesTitle: "Dispositivi",
    description:
      "Distribuzione degli agenti di protezione endpoint Coro sui dispositivi degli utenti",
    helpText: "Ho bisogno di aiuto per questo problema",
    gpoDeployment: "Ho bisogno di una distribuzione di massa",
    universalDownloadCard: {
      title: "Invia un collegamento universale per il download tramite e-mail",
      subtitle:
        "I destinatari utilizzeranno questo link per installare e attivare gli agenti di protezione endpoint Coro sui loro dispositivi.",
      generateLink: "Genera un nuovo link di invito",
      copyLinkBtn: "Copia link",
    },
    windowsDownloadCard: {
      title: "Scarica per Windows",
      subtitle:
        "Installa da remoto su più dispositivi Windows, utilizzando uno strumento di distribuzione di massa di tua scelta.",
    },
    macOSDownloadCard: {
      title: "Scarica per macOS",
      subtitle:
        "Installa da remoto su più dispositivi macOS, utilizzando uno strumento di distribuzione di massa di tua scelta.",
    },
    tabs: {
      agentDeployment: "Distribuzione dell'agente",
      settings: "Impostazioni",
      devicePosture: "Comportamento del dispositivo",
      labels: "Etichette",
      ngav: "NGAV",
      allowBlock: "Consenti/blocca",
      addons: "Componenti aggiuntivi",
    },
    allowBlockList: {
      applyToChildWorkspaces:
        "Applica le regole di autorizzazione/blocco a tutte le aree di lavoro secondarie",
      applyFilesToChildWorkspaces:
        "Applica le regole di autorizzazione/blocco per i file e le cartelle a tutte le aree di lavoro secondarie",
      applyProcessesToChildWorkspaces:
        "Applica le regole di autorizzazione/blocco per i processi a tutte le aree di lavoro secondarie",
      table: {
        value: "Valore",
        list: "Elenco",
        description: "Descrizione",
      },
      actions: {
        [DeviceAllowListActions.ADD_PROCESS_RECORD]: "Aggiungi record per il processo",
        [DeviceAllowListActions.ADD_FOLDER_RECORD]: "Aggiungi record per la cartella",
        [DeviceAllowListActions.ADD_FILE_RECORD]: "Aggiungi record per il file",
        [DeviceAllowListActions.REMOVE_RECORD]: "Elimina record",
        [DeviceAllowListActions.EDIT_ALLOW_BLOCK_LIST_RECORD]: "Modifica record",
        [DeviceAllowListActions.IMPORT_ALLOW_BLOCK_LIST_CSV]: "Importa da CSV",
        [DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST]:
          "Aggiungi all’elenco degli elementi consentiti",
        [DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST]:
          "Aggiungi all’elenco degli elementi bloccati",
      },
      filters: {
        type: "Tipo",
        types: {
          [DeviceAllowBlockListTypes.EDR_FOLDER]: "Cartella",
          [DeviceAllowBlockListTypes.FOLDER]: "Cartella",
          [DeviceAllowBlockListTypes.EDR_FILE]: "File",
          [DeviceAllowBlockListTypes.FILE]: "File",
          [DeviceAllowBlockListTypes.PROCESS]: "Processo",
        },
      },
    },
    agentDeploymentTab: {
      version: "Versione",
      released: "Pubblicato",
      channel: "Canale",
      devices: "Dispositivi",
      universalLink: "Link universale",
      beta: "Beta",
      stable: "Stabile",
      copyLink: "Copia link",
      releaseNotes: "Note di rilascio",
      osTypes: {
        osx: "macOS",
        windows: "Windows",
        linux: "Linux",
      },
      actions: {
        copyDownloadLink: "Copia link",
        download: "Scarica",
      },
      channels: {
        [AgentDeploymentChannel.BETA]: "Beta",
        [AgentDeploymentChannel.RELEASE_CANDIDATE]: "Versione candidata",
        [AgentDeploymentChannel.GRADUAL_ROLLOUT]: "Implementazione graduale",
        [AgentDeploymentChannel.GENERAL_AVAILABILITY]: "Disponibilità generale",
      },
    },
    settingsCard: {
      heartbeatInterval: "Intervallo di heartbeat",
      selectOption: "Ogni minuto | Ogni {n} minuti",
      heartbeatIntervalDescription:
        "Un intervallo di heartbeat più breve può comportare un carico supplementare sulla gestione. Un intervallo di heartbeat più lungo può portare a registri e rapporti meno aggiornati.",
      allowToUninstallEnabled: "Protezione dalle manomissioni",
      allowToUninstallDescription:
        "Impedisce agli utenti e ad alcuni tipi di malware di manomettere importanti funzionalità di sicurezza e di bloccare le protezioni offerte dall’agente endpoint.",
      vssPolicyEnabled: "Backup shadow protetti",
      vssPolicyDescription:
        "Impone la creazione di istantanee di backup ogni quattro ore e blocca i processi che presentano rischi per il backup.",
      atcEnabled: "Controllo avanzato delle minacce",
      atcDescription:
        "Monitora i processi attivi alla ricerca di minacce note e potenziali e blocca i processi che mostrano un comportamento sospetto senza essere esplicitamente inseriti nell'elenco consentiti.",
      initialDevicesScan: "Attiva una scansione iniziale di malware e ransomware",
      initialDevicesScanDescription:
        "Esegue una scansione del malware sul dispositivo quando l’agente viene installato all'inizio. Si possono sempre avviare scansioni più approfondite da remoto, in qualsiasi momento.",
      wifiPhishing: "Wi-Fi Phishing",
      wifiPhishingDescription:
        "Rileva le connessioni ai punti di accesso  Wi-Fi che sono probabili attacchi man-in-the-middle, volti a dirottare la comunicazione del dispositivo.",
      realTimeMalwareProtection: "Protezione in tempo reale da malware e ransomware",
      applyToDevices: "Applica ai dispositivi con queste etichette",
      deviceLabelsPlaceholder: "Etichette del dispositivo",
      autoUpdateTitle: "Consenti l’aggiornamento automatico all’ultima versione stabile",
      allowToUninstallTitle: "Consenti la disinstallazione di Coro Agent",
      passcode: "Codice di accesso",
      regenerateBtn: "Rigenera",
      visibilityMode: "Modalità di visibilità",
      visibilityModeDescription:
        "Limita gli agenti endpoint Coro al solo rilevamento, senza azioni di correzione con risposta automatica.",
      enforceContainerQuarantine: "Quarantena dei contenitori infetti",
      enforceContainerQuarantineDescription:
        "Se la protezione endpoint Coro dovesse rilevare un file dannoso all'interno di un contenitore (archivio) e non fosse in grado di estrarlo, metterà in quarantena l'intero contenitore.",
    },
    devicePostureTab: {
      vulnerability: "Vulnerabilità",
      action: "Azione",
      policies: "Criteri",
      required: "Richiesto",
      policiesQuantity: "{n} criterio | {n} criteri",
      noPolicies: "Nessun criterio",
      osTypes: {
        macOsSettingsLabeled: "macOS",
        windowsSettingsLabeled: "Windows",
        windowsServerSettingsLabeled: "Server Windows",
      },
      everyXDays: "Ogni giorno | Ogni {n} giorni",
      networks: "{n} rete | {n} reti",
      encrypted: "Crittografato",
      choices: {
        enforce: "Attua",
        review: "Revisione",
        ignore: "Ignora",
      },
      actions: {
        addDevicePosturePolicy: "Aggiungi a {service}",
        deleteDevicePosturePolicy: "Elimina criterio",
        editDevicePosturePolicy: "Modifica criterio",
      },
      posturePolicies: {
        uacNotificationMissing: "Notifica UAC mancante",
        missingPinAndPasswordProtection: "Password del dispositivo mancante",
        isUnencrypted: "Unità endpoint non crittografata",
        isDeveloperModeEnabled: "Modalità di sviluppo abilitata",
        firewallUnavailable: "Firewall disabilitato",
        isNonGenuineWindows: "Copia non autentica di Windows",
        wifiSettings: "Connessione Wi-Fi",
        dlpScanScheduleSettings: "Scansioni dati sensibili",
        userAccountPolicies: "Blocco remoto della password e della sessione",
        gatekeeperUnavailable: "Gatekeeper disabilitato",
        appleMobileFileIntegrityUnavailable: "Integrità dei file di Apple Mobile disabilitata",
        systemIntegrityProtectionUnavailable: "Protezione dell’integrità del sistema disattivata",
        usbLockdown: "Protezione USB",
      },
    },
    labelsTab: {
      addLabel: "Aggiungi etichetta",
      deviceCount: "N. di dispositivi",
      actions: {
        editLabel: "Modifica",
        deleteLabel: "Elimina",
      },
      noLabelsTitle: "Gestione di dispositivi con etichette diverse",
      noLabelsSubtitle:
        "Crea etichette per i dispositivi in modo da poterli raggruppare e gestire più facilmente.",
      addNewLabel: "Aggiungi nuova etichetta",
      labelInUseTooltip: "L’etichetta è in uso nelle impostazioni del dispositivo",
    },
    channels: {
      all: "Tutti i canali",
      stable: "Canali stabili",
    },
  },
  phishingSettings: {
    applyToChildWorkspaces:
      "Applica le regole di autorizzazione/blocco a tutte le aree di lavoro secondarie",
    title: "Phishing",
    description:
      "Difesa contro gli attacchi di ingegneria sociale, tra cui phishing tramite e-mail, attacchi e-mail mirati e phishing Wi-Fi.",
    noEmailServicesConnected:
      "Collega le copie di <b>Microsoft 365  o</b> <b>G-Suite</b> della tua società a Coro per ottenere una protezione anti-phishing trasparente.",
    connectCloudAppsBtn: "Connetti le app cloud",
    tabs: {
      settings: "Impostazioni",
      allowBlockList: "Consenti/blocca",
    },
    allowed: "Consentito",
    blocked: "Bloccato",
    domain: "Dominio",
    email: "E-mail",
    ip: "IP",
    ipRange: "Intervallo IP",
    allow: "Elenco consentiti",
    block: "Elenco bloccati",
    table: {
      name: "Nome",
      list: "Elenco",
      updateTimestamp: "Data di modifica",
    },
    actions: {
      addToAllowlist: "Aggiungi all’elenco degli elementi consentiti",
      addToBlocklist: "Aggiungi all’elenco degli elementi bloccati",
      remove: "Rimuovi",
      importFromCSV: "Importa da CSV",
    },
    card: {
      title: "Phishing tramite e-mail [superato]",
      contentScan: "Contenuto",
      suspiciousImpersonation: "Imitazione sospetta",
      suspiciousLinksAndAttachments: "Collegamenti e allegati sospetti",
      phishingTypeContent: "Contenuti tipo phishing",
      feedbackTitle: "Feedback degli utenti",
      feedbackDescription:
        "Per consentire il feedback degli utenti in merito a e-mail sospette o erroneamente segnalate, installa il componente aggiuntivo Coro:",
      feedbackDisclaimer:
        "Coro memorizza e aggrega continuamente i feedback degli utenti sulle imprecisioni nella classificazione delle e-mail e li mette a disposizione dell’utente.",
      wifiPhishing: "Wi-Fi Phishing",
      wifiPhishingDescription:
        "Rileva le connessioni a punti di accesso Wi-Fi sospetti che si possono usare per rubare dati agli utenti, comprese le credenziali di accesso e i numeri delle carte di credito.",
    },
    allEntities: "Tutte le entità",
    allLists: "Tutti gli elenchi",
  },
  ticketDetails: {
    lastFailedTest: "Ultimo test fallito",
    inboundGatewayMxRecordMisconfigurationDescription:
      "<span class='body1'>Coro non è in grado di proteggere le e-mail in arrivo in quanto il record MX di massima priorità nel DNS per il dominio <b>{domain}</b> è indirizzato verso un server proxy SMTP non di Coro.<br><br><b>Assicurarti di aggiungere il dominio di Coro come record MX con priorità 0</b></span>",
    processed: "Chiuso",
    triggers: "Trigger",
    severity: "Gravità",
    rules: "Regole",
    rule: "regola | regole",
    service: "Servizio",
    actions: "Azioni",
    user: "Utente",
    lastSeen: "Visto l’ultima volta",
    firstSeen: "Visto per la prima volta",
    duration: "Durata",
    showFindings: "Mostra risultati",
    certificates: "Certificati",
    sourceCode: "Codice sorgente",
    otherFileTypes: "Altri tipi di file",
    sharedBy: "Condiviso da",
    sharedWith: "Condiviso con",
    receiveTime: "Orario di ricezione",
    campaignName: "Nome della campagna",
    emailTemplate: "Modello di e-mail",
    attachmentType: "Tipo di allegato",
    happened: "È successo {n} volta | È successo {n} volte",
    downloaded: "Scaricato {n} volta | Scaricato {n} volte",
    happenedMoreThanForHundredTimes: "È successo più di 400 volte",
    downloadedMoreThanForHundredTimes: "Scaricato più di 400 volte",
    registryValue: "Valore di registro",
    processName: "Nome del processo",
    threatStatus: "Stato della minaccia",
    hostName: "Nome host",
    inactiveSince: "Inattivo da:",
    inactivityPeriod: "Periodo di inattività",
    osVersion: "Versione del sistema operativo",
    deviceId: "Id dispositivo",
    affectedDevices: "Dispositivi interessati",
    upn: "UPN",
    times: "{n} volta | {n} volte",
    deviceLoginUserName: "Nome utente per l’accesso al dispositivo",
    userName: "Nome utente",
    email: "E-mail",
    addProtectionBtn: "Aggiungi protezione",
    notProtectedUsersNotice: "L’utente non è protetto | I {n} utenti non sono protetti",
    lastModifiedBy: "Ultima modifica di",
    lastModifiedTime: "Orario dell’ultima modifica",
    interfaceType: "Tipo di interfaccia",
    driveModel: "Modello dell’unità",
    driveName: "Nome dell’unità",
    processPath: "Percorso di processo",
    processHash: "Hash di processo",
    processGroup: "Gruppo di processi",
    type: "Tipo",
    threatType: "Tipo di minaccia",
    suspiciousCharacteristics: "Caratteristiche sospette",
    reports: "Rapporti",
    users: "Utenti",
    lastUsersOnDevice: "Ultimi utenti del dispositivo",
    when: "Quando",
    from: "Da",
    contents: "Contenuti",
    to: "A",
    customer: "Clienti",
    internetMessageId: "ID messaggio",
    senderIp: "IP mittente",
    attachments: "Allegato | Allegati",
    labels: "Etichette",
    plusNFindings: "+ {n} risultati",
    plusNFiles: "+ {n} file",
    keyDetails: {
      title: "Dettagli chiave",
    },
    threatTypes: {
      virus: "Virus",
      spyware: "Spyware",
      ransomware: "Ransomware",
      adware: "Adware",
      dialer: "Dialer",
      potentiallyMaliciousApp: "Applicazione potenzialmente dannosa",
      archiveBombFile: "Archivia file bomba",
      rootkit: "Rootkit",
      spam: "Spam",
      suspiciousBehavior: "Comportamento sospetto",
      trojan: "Trojan",
      worm: "Worm",
      backdoor: "Backdoor",
      tool: "Strumento",
      unknown: "Sconosciuto",
      potentiallyUnwantedSoftware: "Software potenzialmente indesiderato",
    },
    eventActions: {
      coroPrimaryMxRecordTest: "Esegui il test",
      treatFileAsSafe: "Approva questo file",
      treatFileAsMalicious: "Scarta questo file",
      excludeFolderFromScan: "Escludi la cartella dalla scansione dei malware",
      enableFirewall: "Abilita firewall",
      remoteScan: "Scansione remota dei malware",
      markAsProcessed: "Chiudi segnalazione",
      markAsUnprocessed: "Riapri",
      suspendFromAll: "Sospendi l’utente da tutte le applicazioni cloud",
      suspendFromService: "Sospendi l’utente da {service}",
      signInToAll: "Richiedi all’utente l’accesso a tutte le applicazioni cloud",
      signInToService: "Richiedi all’utente di accedere a {service}",
      enforceUAC: "Attiva la notifica UAC",
      disableDevMode: "Disattiva la modalità sviluppatore",
      inspectEmail: "Ispeziona e-mail",
      approveEmailsFromSender: "Aggiungi il mittente all’elenco consentiti",
      approveEmailsFromDomain: "Aggiungi il dominio del mittente all’elenco consentiti",
      discardEmailsFromSender: "Aggiungi il mittente all’elenco bloccati",
      discardEmailsFromDomain: "Aggiungi il dominio del mittente all’elenco bloccati",
      approveEmail: "Approva questa e-mail",
      discardEmail: "Elimina questa e-mail",
      removeExposingSharing: "Rimuovi la condivisione dell'esposizione",
      contactUser: "Contatta utente",
      allowNoEncryption: "Crittografia non consentita",
      approveProcessGroup: "Approva il gruppo di processi",
      discardInfectingFile: "Scarta il file infetto",
      approveFile: "Approva file",
      deleteFile: "Elimina file",
      logForAuditReports:
        "Registro e segnalazione per i rapporti di verifica | [TRANSLATION] Tickets have been logged",
      unLogForAuditReports:
        "Disconnessione e rimozione dai rapporti di controllo | [TRANSLATION] Tickets have been unlogged",
      stopRemoteScan: "Interrompi la scansione remota dei malware",
      encryptDrive: "Crittografia dell’unità",
      addToDataGovernancePermissions: "Aggiungi alle autorizzazioni per la governance dei dati",
      downloadEmlFile: "Scarica il file EML",
      enableGatekeeper: "Attiva gatekeeper",
      enableAppleMobileFileIntegrity: "Abilita l’integrità dei file di Apple Mobile",
      approveEmailWithOptions: "Consenti",
      discardEmailWithOptions: "Blocca",
      generalApprove: "Consenti",
      exportMassDownloadFiles: "Esporta l’elenco dei file",
      exportMassDeleteFiles: "Esporta l’elenco dei file",
      isolateDevice: "Isola il dispositivo",
      blockProcess: "Blocca processo",
      unblockProcess: "Sblocca processo",
      enableNetworkBlockMode: "Isola i dispositivi interessati dalla rete",
      disableNetworkBlockMode: "Disattiva l’isolamento dei dispositivi dalla rete",
      rebootDevice: "Riavvia i dispositivi",
      shutdownDevice: "Spegni i dispositivi",
      allowProcess: "Consenti processo",
    },
    eventBulkActions: {
      markAsProcessed: "Chiudi segnalazione | Chiudi segnalazioni",
      markAsUnprocessed: "Riapri segnalazione | Riapri segnalazioni",
      bulkAddComment: "Aggiungi commento",
      exportCsv: "Esporta CSV",
    },
    actionTypes: {
      suspendUser: "Sospendi l’utente da tutti i servizi",
      alertUser: "Avvisa utente",
    },
    fileName: "Nome del file",
    fileSize: "Dimensioni del file",
    path: "Percorso del file",
    allFiles: "Tutti i file",
    link: "Collegamento",
    file: "File",
    ip: "IP",
    ipAddress: "Indirizzo IP",
    ipAndCountry: "IP/paese",
    ticketDetails: "Dettagli della segnalazione",
    eventHappenedMessages: {
      default: "Evento accaduto",
      suspectedBotAttacks: "Accesso non riuscito",
      suspiciousDownload: "Scaricato",
      potentialRansomwareActivity: "File sospetti",
    },
    filesCount: "{n} file | {n} file",
    suspiciousFiles: "File sospetti",
    virusName: "Nome del virus",
    virusType: "Tipo di virus",
    unknownVirus: "Sconosciuto (rilevato dal servizio)",
    owner: "Proprietario",
    sender: "Mittente",
    senderName: "Nome del mittente",
    senderEmail: "E-mail del mittente",
    recipients: "Destinatari",
    subject: "Oggetto",
    suspiciousContentLocation: "Localizzazione dei contenuti sospetti",
    sharedAt: "Condiviso alle",
    foundIn: "Presente in",
    performedBy: "Eseguito da",
    ownedBy: "Posseduto da",
    lastModified: "Ultima modifica",
    created: "Creato",
    mimeType: "Tipo mime",
    country: "Paese",
    foundInTypes: {
      attachment: "Allegato e-mail",
      body: "Corpo dell’e-mail",
      subject: "Oggetto dell’e-mail",
    },
    emailSubject: "Oggetto dell’e-mail",
    emailSender: "Mittente dell’e-mail",
    inspectEmail: "Ispeziona e-mail",
    device: "Dispositivo",
    fileHash: "Hash del file",
    filePath: "Percorso del file",
    enrollmentCode: "Codice di registrazione | Codici di registrazione",
    model: "Modello",
    os: "Sistema operativo",
    network: "Rete",
    ssid: "SSID",
    bssid: "BSSID",
    security: "Sicurezza",
    findings: "Risultati",
    additionalFindings: "Risultati aggiuntivi",
    process: "Processo",
    hash: "Hash",
    allowedOn: "Processo consentito in data {date}",
    blockedOn: "Processo bloccato in data {date}",
    incidentFindings: {
      type: {
        PHISHING_GENERAL: "Phishing tramite e-mail [superato]",
        PHISHING_ABNORMAL_SENDER: "Mittente anomalo",
        MALWARE_DETECTION: "File malware",
        UNMET_ENFORCED_AUTHENTICATION_REQUIREMENTS:
          "Non è stato possibile soddisfare i requisiti di autenticazione previsti",
        UNVERIFIED_SENDER: "Mittente non verificato",
        SPEAR_PHISHING_USER_IMPERSONATION: "Spear phishing: imitazione dell'utente",
        SUSPICIOUS_ENVELOPE: "Busta sospetta",
        COUSIN_DOMAIN_DOMAIN_IMPERSONATION: "Dominio cugino: imitazione del marchio",
        MESSAGE_HONEYPOT_BRAND_IMPERSONATION: 'Messaggio "honeypot\: imitazione del marchio',
        BRAND_DOMAIN_SPOOFING: "Spoofing del dominio del marchio",
        ENVELOPE_HONEYPOT_DOMAIN_IMPERSONATION: 'Busta "honeypot\: imitazione del dominio',
        COUSIN_DOMAIN_BRAND_IMPERSONATION: "Dominio cugino: imitazione del dominio",
        DOMAIN_SPOOFING: "Spoofing del dominio",
        UNAUTHENTICATED_SELF_NOTE: "Nota personale non autenticata",
        HOMOGRAPHIC_DOMAIN_SPOOFING: "Spoofing del dominio omografico",
        ENVELOPE_HONEYPOT_USER_IMPERSONATION: "Busta \"honeypot\: imitazione dell'utente",
        ENVELOPE_HONEYPOT_BRAND_IMPERSONATION: 'Busta "honeypot\: imitazione del marchio',
        HOMOGRAPHIC_USER_ADDRESS_SPOOFING: "Spoofing omografico dell’indirizzo dell’utente",
        HOMOGRAPHIC_USER_NAME_SPOOFING: "Spoofing omografico del nome utente",
        ABNORMAL_SENDER: "Mittente anomalo",
        MALICIOUS_URL: "Collegamento dannoso",
        MALICIOUS_QR_CODE: "Codice QR sospetto",
        EMAIL_MALWARE_ACTIVITY: "Malware negli allegati dell'e-mail",
        MALICIOUS_SENDER: "Mittente dannoso",
        PHISHING_TEST: "Test sul phishing",
        CREDIT_CARD_NUMBER: "Numero di carta di credito",
        IBAN_CODE: "Codice IBAN",
        SWIFT_CODE: "Codice Swift",
        US_BANK_ROUTING_MICR: "Coordinate bancarie statunitensi",
        AMERICAN_BANKERS_CUSIP_ID: "ID CUSIP degli istituti bancari americani",
        EMAIL_ADDRESS: "Indirizzo e-mail",
        IP_ADDRESS: "Indirizzo IP",
        IP_ADDRESS_KEYWORD: "Indirizzo IP",
        MAC_ADDRESS: "Indirizzo MAC",
        MAC_ADDRESS_KEYWORDS: "Indirizzo MAC",
        PERSON_NAME: "Nome della persona",
        ADDRESS: "Indirizzo",
        IMEI_HARDWARE_ID: "ID hardware IMEI",
        PHONE_NUMBER: "Numero di telefono",
        PHONE_NUMBER_KEYWORD: "Numero di telefono",
        US_PASSPORT: "Passaporto statunitense",
        US_PASSPORT_KEYWORD: "Passaporto statunitense",
        DRIVER_LICENSE: "Patente di guida",
        DRIVER_LICENSE_KEYWORD: "Patente di guida",
        DRIVER_LICENSE_NUMBER: "Numero di patente di guida",
        BANK_ROUTING_NUMBER: "Coordinate bancarie",
        BANK_ROUTING_NUMBER_KEYWORD: "Coordinate bancarie",
        FINANCIAL_METADATA: "Metadati finanziari",
        FINANCIAL_CONTENT_KEYWORD: "Contenuto finanziario",
        FINANCIAL_CONTENT: "Contenuto finanziario",
        US_SOCIAL_SECURITY_NUMBER: "Numero di previdenza sociale statunitense",
        US_SOCIAL_SECURITY_NUMBER_KEYWORD: "Previdenza sociale statunitense",
        US_BANK_KEYWORD: "Banca statunitense",
        US_EMPLOYER_IDENTIFICATION_NUMBER: "Identificativo del datore di lavoro statunitense",
        TAXPAYER_IDENTIFICATION_NUMBER: "Identificativo del contribuente",
        TAXPAYER_IDENTIFICATION_NUMBER_KEYWORD: "Identificativo del contribuente",
        US_INDIVIDUAL_TAXPAYER_IDENTIFICATION_NUMBER:
          "Identificativo del singolo contribuente statunitense",
        VEHICLE_IDENTIFICATION_NUMBER: "Identificativo del veicolo",
        US_VEHICLE_IDENTIFICATION_NUMBER: "Numero di identificazione del veicolo statunitense",
        CANADA_PASSPORT: "Passaporto canadese",
        CANADA_SOCIAL_INSURANCE_NUMBER: "Numero di assicurazione sociale del Canada",
        ISRAEL_ID: "ID Israele",
        ICD9_CODE: "Codice ICD9",
        ICD10_CODE: "Codice ICD10",
        FDA_CODE: "Codice FDA",
        ES_PASSPORT: "Passaporto spagnolo",
        ES_NATIONAL_IDENTITY_NUMBER: "Numero d'identità spagnolo per stranieri",
        ES_NIE: "Numero d'identità spagnolo per stranieri",
        IT_PASSPORT: "Passaporto italiano",
        IT_DRIVER_LICENSE: "Patente di guida italiana",
        IT_CODICE_FISCALE: "Codice fiscale italiano",
        IT_VAT_NUMBER: "Partita IVA italiana",
        CREDIT_COMPANY: "Agenzia di valutazione del credito",
        NET_INCOME: "Utile netto",
        INTEREST_RATE: "Tasso di interesse",
        MARITAL_STATUS: "Stato civile",
        HOUSING_STATUS: "Stato abitativo",
        BANK_INFORMATION: "Informazioni bancarie",
        US_HEALTHCARE_NPI: "NPI sanitario statunitense",
        US_HEALTHCARE_NPI_KEYWORD: "NPI sanitario statunitense",
        US_DEA_NUMBER: "Numero DEA statunitense",
        US_DEA_NUMBER_KEYWORD: "Numero DEA statunitense",
        CANADA_QUEBEC_HIN: "HIN del Quebec Canada",
        HEALTH_KEYWORDS: "Parole chiave sulla salute",
        DATE_OF_BIRTH: "Data di nascita",
        ISBN: "ISBN",
        ISBNKeyword: "ISBN",
        PASSWORD: "Password",
        PASSWORD_KEYWORD: "Password",
        KEYWORDS: "Parole chiave",
        REGEX: "Espressioni regolari",
        PHISHING_SUSPICIOUS_SUBJECT: "Oggetto sospetto",
        PHISHING_SUSPICIOUS_TAGS_IN_ATTACHMENT: "Contenuto degli allegati sospetto",
        MSP_PHISHING_BLOCKED_SENDER: "Mittente sospetto (bloccato)",
        MSP_PHISHING_BLOCKED_URL: "Contenuto incorporato sospetto (bloccato)",
        BLOCKLISTED_DOMAIN: "Dominio bloccato",
        BLOCKLISTED_SENDER: "Mittente bloccato",
        REPORTED_BY_USER: "Segnalato dall’utente",
        HEALTH_CODE: "Codice sanitario",
        IBAN: "IBAN",
        SWIFT: "SWIFT",
        SWIFT_KEYWORD: "SWIFT",
        ANNUAL_CREDIT_REPORT_FORM: "Rapporto di credito annuale",
        ANNUAL_CREDIT_REPORT_FORM_KEYWORD: "Rapporto di credito annuale",
        BILL_OF_SALE_FORM: "Atto di vendita",
        BILL_OF_SALE_FORM_KEYWORD: "Atto di vendita",
        INSURANCE_CARD_FORM: "Tessera dell'assicurato",
        INSURANCE_CARD_FORM_KEYWORD: "Tessera dell'assicurato",
        FR44_FORM: "FR44",
        FR44_FORM_KEYWORD: "FR44",
        SR22_FORM: "SR22",
        SR22_FORM_KEYWORD: "SR22",
        PAY_STUB_FORM: "Busta paga",
        PAY_STUB_FORM_KEYWORD: "Busta paga",
        MISC_1099_FORM: "MISC 1099",
        MISC_1099_FORM_KEYWORD: "MISC 1099",
        BANK_STATEMENT_FORM: "Estratto conto bancario",
        BANK_STATEMENT_FORM_KEYWORD: "Estratto conto bancario",
        PERSONAL_NET_WORTH_FORM: "Patrimonio netto personale",
        PERSONAL_NET_WORTH_FORM_KEYWORD: "Patrimonio netto personale",
        VEHICLE_REGISTRATION_APPLICATION_FORM: "Domanda di immatricolazione del veicolo",
        VEHICLE_REGISTRATION_APPLICATION_FORM_KEYWORD: "Domanda di immatricolazione del veicolo",
        LEASE_AGREEMENT_FORM: "Contratto di locazione",
        LEASE_AGREEMENT_FORM_KEYWORD: "Contratto di locazione",
        CAR_TITLE_FORM: "Certificato di proprietà del veicolo",
        CAR_TITLE_FORM_KEYWORD: "Certificato di proprietà del veicolo",
        CREDIT_CARD_STATEMENT_FORM: "Estratto conto della carta di credito",
        CREDIT_CARD_STATEMENT_FORM_KEYWORD: "Estratto conto della carta di credito",
        W2_FORM: "W2",
        W2_FORM_KEYWORD: "W2",
        ODOMETER_DISCLOSURE_FORM: "Condivisione del contachilometri",
        ODOMETER_DISCLOSURE_FORM_KEYWORD: "Condivisione del contachilometri",
        USERNAME: "Nome utente",
        CUSTOM_KEYWORD: "Personalizza",
        US_EMPLOYER_ID_NUMBER: "Identificativo del datore di lavoro statunitense",
        US_EMPLOYER_ID_NUMBER_KEYWORD: "Identificativo del datore di lavoro statunitense",
        MEDICAL_BENEFICIARY_IDENTIFIER: "Numero di identificazione sanitaria del beneficiario",
        MEDICAL_BENEFICIARY_IDENTIFIER_KEYWORD:
          "Numero di identificazione sanitaria del beneficiario",
        MEDICAL_RECORDS_NUMBER_KEYWORD: "Numero di cartella clinica",
        DATE: "Data",
        DATE_KEYWORD: "Data",
        INSURANCE_PLAN_PAYMENT: "Pagamento del piano assicurativo",
        GENERAL_MEDICAL_KEYWORD: "Informazioni mediche",
        MEDICAL_FILE: "File medico",
        CREDIT_CARD_NUMBER_KEYWORD: "Numero di carta di credito",
        VEHICLE_IDENTIFICATION_NUMBER_KEYWORD: "Identificativo del veicolo",
        US_ADDRESS: "Indirizzo statunitense",
        HEALTH_INSURANCE_CLAIM_FORM: "Richiesta di assicurazione sanitaria",
        US_DEATH_CERTIFICATE_FORM: "Certificato di morte",
        US_BIRTH_CERTIFICATE_FORM: "Certificato di nascita",
        IMEI: "IMEI",
        PHISHING_SUSPICIOUS_CONTENT: "Contenuto sospetto per tentativo di phishing",
        BLOCKED_ATTACHMENT_TYPE: "Tipo di allegato bloccato",
        CUSTOM_FILE_TYPE: "Personalizza il tipo di file",
        MISSING_REQUIRED_AUTHENTICATION: "Manca l’autenticazione richiesta",
        CROWDBLOCKED_SENDER: "Mittente bloccato da Crowd",
        MALWARE_IN_EMAIL_ATTACHMENT: "Malware nell’allegato dell’e-mail",
        USER_IMPERSONATION: "Imitazione dell’utente",
        DOMAIN_IMPERSONATION: "Imitazione del dominio",
        BRAND_IMPERSONATION: "Imitazione del marchio",
        FORBIDDEN_ATTACHMENT_TYPE: "Tipo di allegato vietato",
        SUSPICIOUS_CONTENT: "Contenuto sospetto",
        SENDER_OR_DOMAIN_IN_GLOBAL_BLOCKLIST:
          "Mittente/dominio/indirizzo o intervallo IP già presente nell'elenco globale degli elementi bloccati",
        SPF_AUTHENTICATION_FAILED: "Mittente non verificato",
        MALICIOUS_SENDER_DOMAIN: "Dominio mittente dannoso",
        SPAM_CONTENT: "Contenuto spam",
      },
    },
    fullDetails: {
      title: "Dati completi",
      notAvailable: "I dati completi non sono disponibili",
      unknownDetectedBy: "Sconosciuto ({detectedBy})",
      detectedByBitdefender: "Rilevato da BitDefender",
      detectedByMicrosoftDefender: "Rilevato da Microsoft Defender",
      detectedByBoxShield: "Rilevato da Box Shield",
      detectedByService: "Rilevato dal servizio",
      emailAttachments: "Allegati e-mail",
      emailContent: "Contenuto dell’e-mail",
      anomalyDetection: {
        login: "Accesso",
        failedLogin: "Accesso non riuscito",
        share: "Condividi",
        upload: "Carica",
        download: "Scarica",
        collaborationInvite: "Invito alla collaborazione",
        admin: "Accesso amministratore",
        countryAndIp: "Paese/IP",
        time: "Ora",
        type: "Tipo di record",
        delete: "Elimina",
        orgName: "ISP",
        threatType: "Tipo di minaccia",
        proxy: "Proxy",
        unhandled: "Evento attività dell'utente",
        lastDetected: "Ultima individuazione:",
      },
      accessViolations: {
        title: "Violazioni dei criteri",
        share: "L’utente {user} non è autorizzato a esporre {trigger}.",
        access: "L’utente {user} non è autorizzato ad accedere a {trigger}.",
        [TicketTrigger.DLP_PCI]: "PCI",
        [TicketTrigger.DLP_PHI]: "PHI",
        [TicketTrigger.DLP_PII]: "PII",
        [TicketTrigger.DLP_NPI]: "NPI",
        [TicketTrigger.CRITICAL_DATA_SOURCE_CODE]: "Codice sorgente",
        [TicketTrigger.DLP_PERSONAL]: "Dati personali",
        [TicketTrigger.DLP_HEALTH]: "Dati sanitari",
        [TicketTrigger.DLP_CREDIT_CARD]: "Dati della carta di credito",
        [TicketTrigger.DLP_NON_PUBLIC]: "Dati non pubblici",
        [TicketTrigger.CRITICAL_DATA_PASSWORD]: "Password",
        [TicketTrigger.CRITICAL_DATA_CERTIFICATE]: "Certificato",
        [TicketTrigger.CRITICAL_DATA_KEYWORDS]: "Parole chiave",
        [TicketTrigger.CRITICAL_DATA_FILE_TYPES]: "Tipi di file",
      },
      devicePasswordMissing: {
        title: "Utenti con accesso senza password a questo dispositivo",
        entry: "Utente {userIdentifier}.",
      },
      fileFindings: {
        title: "Risultati",
        count: "N. di risultati",
      },
      abnormalDataManipulation: {
        countryOrIP: "Paese / IP",
        host: "Host",
        files: "File",
        name: "Nome",
        mimeType: "Tipo MIME",
        folder: "Percorso",
        filesAffected: "File interessati",
        massDownloadFiles: "File scaricati",
        massDeleteFiles: "File eliminati",
      },
      edrAffectedDevices: {
        device: "Dispositivo",
        executionTime: "Orario di esecuzione",
        commandLine: "Riga di comando",
        path: "Percorso",
        processChain: "Catena di processo",
        mitre: "Mitre",
        insights: "Approfondimenti",
        processGraph: "Grafico del processo",
      },
    },
    filters: {
      type: "Tipo",
      during: "Durante",
      status: "Stato",
      searchTickets: "Ricerca segnalazioni...",
      widgets: {
        placeholder: "Modulo",
        deviceSecurityWidget: "Endpoint Security",
        cloudSecurity: "Cloud Security",
        emailsWidget: "Email Security",
        usersWidget: "Utenti",
        userDataGovernance: "User Data Governance",
        endpointDataGovernance: "Endpoint Data Governance",
        edr: "EDR",
        securityAwareness: "Consapevolezza della sicurezza",
      },
    },
  },
  eventsPage: {
    duration: "Durata",
    whoShared: "Chi ha condiviso",
    withWhom: "Con chi",
    user: "Utente",
    sentBy: "Inviato da",
    sentTo: "Inviato a",
    lastLoggedInUser: "Ultimo utente connesso",
    more: "+ {n} in più",
  },
  edr: {
    title: "Endpoint Detection & Response",
    isolatedFromNetwork: "Isolato dalla rete",
    tabs: {
      telemetry: "Telemetria",
      processes: "Processi",
      allowBlockList: "Consenti/blocca",
    },
    table: {
      processes: "{n} processo | {n} processi",
      devices: "{n} dispositivo | {n} dispositivi",
      lastSeen: "Visto l’ultima volta",
      multipleNameProcess: "Processo con più nomi",
      blocked: "Bloccato",
      blockedOn: "Bloccato in data",
    },
    filters: {
      during: "Durante",
      types: "Tipi",
    },
    actions: {
      isolateDevice: "Isola dispositivo | Isola dispositivi",
      blockProcess: "Blocca il processo | Blocca i processi",
      allowProcess: "Consenti il processo | Consenti i processi",
      unblockProcess: "Sblocca il processo | Sblocca i processi",
      enableNetworkBlockMode:
        "Isola il dispositivo interessato dalla rete | Isola i dispositivi interessati dalla rete",
      disableNetworkBlockMode:
        "Vuoi disattivare l’isolamento del dispositivo dalla rete? | Vuoi disattivare l’isolamento dei dispositivi dalla rete?",
      rebootDevice: "Riavvia il dispositivo | Riavvia i dispositivi",
      shutdownDevice: "Spegni il dispositivo | Spegni i dispositivi",
      viewLog: "Visualizza il registro completo",
    },
    details: {
      blockedOn: "Processo bloccato in data ",
      hash: "Hash",
      devicesAffected: "Dispositivi",
      knownPaths: "Percorsi conosciuti",
      processAliases: "Pseudonimi del processo",
      openTickets: "Apri segnalazione",
      isolated: "isolato",
      numberOfDevices: "Numero di dispositivi",
      time: "Visto l’ultima volta",
      viewTelemetry: "Visualizza telemetria",
      viewOpenTickets: "Tutte le segnalazioni aperte",
      firstExecutionDetails: "Dettagli della prima esecuzione",
    },
    telemetryTab: {
      types: {
        registryKey: "Chiave di registro",
        scheduledTask: "Compito programmato",
        accountEvent: "Evento account",
        usbDeviceActivity: "Attività del dispositivo USB",
        groupPolicyChanges: "Modifiche ai criteri di gruppo",
      },
      table: {
        noData: {
          title: "Nessun risultato disponibile",
          description:
            "Affina la ricerca, applica filtri diversi o cancella la ricerca per riprovare",
          actionBtn: "Cancella ricerca e filtri",
        },
        forensic: "Forense",
        device: "Dispositivo",
        process: "Processo",
        userName: "Nome utente",
        eventId: "ID evento",
        accountName: "Nome dell’account",
        processName: "Nome del processo",
        parentProcessName: "Nome del processo principale",
        processHash: "Hash",
        commandLine: "Riga di comando",
        action: "Azione",
        trigger: "Trigger",
        operationType: "Tipo di operazione",
        currentValue: "Valore attuale",
        oldValue: "Valore precedente",
        accountDomain: "Dominio dell’account",
        targetServer: "Server di destinazione",
        detailedProcessInfo: "Informazioni dettagliate sul processo",
        affectedDevices: "Dispositivi interessati",
        success: "Riuscito correttamente",
        authenticationType: "Tipo",
        uid: "UID",
        userType: "Tipo di utente",
        dbPath: "Percorso",
        instigator: "Artefice",
        usbDevice: "Dispositivo USB",
        friendlyName: "Nome descrittivo",
        targetAccount: "Account di destinazione",
        groupPolicyObjectName: "Nome dell'oggetto criteri di gruppo",
        passwordMinimumAgePolicy: "Criteri sulla durata minima delle password",
        passwordLengthPolicy: "Criteri sulla lunghezza delle password",
        pid: "PID",
      },
    },
    allowBlockListTab: {
      addProcessBtn: "Aggiungi processo",
    },
  },
  activityLogs: {
    title: "Registri attività",
    search: "Ricerca...",
    noData: "Nessun registro trovato",
    undone: "Annullato",
    plusNLogs: "+ {n} registri attività",
    automaticallyByCoro: "automatically, by Coro",
    actions: {
      undo: "Annulla",
      download: "Scarica",
    },
    errors: {
      csvDownloadLinkExpired:
        "I link per il download scadono dopo 24 ore. Genera nuovamente il file csv",
      reportDownloadLinkExpired:
        "I link per il download scadono dopo 24 ore. Genera nuovamente il report",
    },
    filters: {
      types: "Tipi",
      dateRange: "Intervallo di date",
      type: {
        [ActivityLogsType.ACCOUNT]: "Account",
        [ActivityLogsType.CLOUD_APPS]: "Cloud Apps",
        [ActivityLogsType.DEVICE]: "Device",
        [ActivityLogsType.DLP]: "User Data Governance",
        [ActivityLogsType.DEVICE_DLP]: "Endpoint Data Governance",
        [ActivityLogsType.EMAIL]: "Email Security",
        [ActivityLogsType.MALWARE]: "Malware",
        [ActivityLogsType.PHISHING]: "Phishing",
        [ActivityLogsType.UNDO]: "Undo",
        [ActivityLogsType.USERS]: "Users",
        [ActivityLogsType.BILLING]: "Billing",
        [ActivityLogsType.DETECTION_LOGS]: "Detection Logs",
        [ActivityLogsType.CSV_EXPORT]: "CSV Export",
        [ActivityLogsType.WORKSPACE]: "Workspace",
        [ActivityLogsType.MSP]: "MSP",
        [ActivityLogsType.SIEM_CONFIG]: "SIEM Config",
        [ActivityLogsType.API_CREDENTIALS]: "API Credentials",
        [ActivityLogsType.WORKSPACE_REPORT]: "Workspace Report",
        [ActivityLogsType.WORKSPACE_SCHEDULED_REPORT]: "Scheduled Report",
        [ActivityLogsType.SOC]: "Managed Service",
        [ActivityLogsType.MOBILE]: "Mobile",
        [ActivityLogsType.PSA]: "PSA",
        [ActivityLogsType.MDM_DEVICE]: "MDM device",
        [ActivityLogsType.MDM_ACTION]: "MDM action",
        [ActivityLogsType.MDM_DEVICE_ACTION]: "MDM device action",
        [ActivityLogsType.MDM_SYSTEM]: "MDM system",
        [ActivityLogsType.WEBHOOK]: "Webhooks",
        [ActivityLogsType.SECURITY_AWARENESS]: "[TRANSLATION] Security awareness",
        mdm: "MDM",
      },
    },
  },
  thirdPartyApps: {
    columns: {
      thirdPartyApp: "Applicazione di terze parti",
      cloudApplication: "Applicazione cloud",
      protectedUsers: "Utenti protetti",
    },
    filters: {
      cloudApps: "App cloud",
      cloudAppsNoData: "Nessuna applicazione corrisponde alla ricerca",
      status: "Stato",
    },
    status: {
      [ThirdPartyAppStatus.ENABLED]: "Consentito",
      [ThirdPartyAppStatus.DISABLED]: "Bloccato",
    },
    servicePermissionsMissing: {
      title: "{service} permessi richiesti",
      description:
        "Le autorizzazioni attuali di {service} non consentono l'accesso ad applicazioni di terze parti. Devi concedere dei permessi aggiuntivi per abilitare questa funzione per {service}.",
    },
    connectionDate: "Data di connessione:",
    publisher: "Autore:",
    users: "{n} utente | {n} utenti",
    allow: "Consenti",
    block: "Blocca",
  },
  emailProxy: {
    title: "Proxy e-mail",
    addDomain: "Aggiungi dominio",
    directions: {
      inbound: "Inbound",
      outbound: "Outbound",
    },
    tabs: {
      inbound: "Inbound",
      outbound: "Outbound",
    },
    emailSecurityMode: {
      title: "Modalità Email Security",
      types: {
        warning: "Solo avviso",
        block: "Blocca",
      },
      descriptions: {
        outbound: {
          warning:
            "Dal punto di vista della sicurezza, le e-mail sospette in uscita (phishing/malware) non verranno bloccate, ma contrassegnate con avvisi esplicativi per i destinatari.",
          block:
            "Dal punto di vista della sicurezza, le e-mail sospette in arrivo (phishing/malware) verranno bloccate e solo gli amministratori dell’area di lavoro le potranno togliere dalla quarantena.",
        },
      },
    },
    dataGovernanceMode: {
      title: "Modalità di governance dei dati",
      types: {
        warning: "Solo avviso",
        block: "Blocca",
      },
      descriptions: {
        inbound: {
          warning:
            "Le e-mail in arrivo con contenuti sensibili dal punto di vista della privacy non verranno bloccate, ma contrassegnate da avvisi esplicativi per i destinatari.",
          block:
            "Le e-mail in arrivo con contenuti sensibili dal punto di vista della privacy verranno bloccate e solo gli amministratori dell’area di lavoro le potranno togliere dalla quarantena.",
        },
        outbound: {
          warning:
            "Le e-mail in uscita con contenuti sensibili dal punto di vista della privacy non verranno bloccate, ma contrassegnate da avvisi esplicativi per i destinatari.",
          block:
            "Le e-mail in arrivo con contenuti sensibili dal punto di vista della privacy verranno bloccate e solo gli amministratori dell’area di lavoro le potranno togliere dalla quarantena.",
        },
      },
    },
    enableProxyLink: "Come configurare Inbound Gateway",
    table: {
      title: "Utilizza il gateway Coro {direction} per questi domini",
      headers: {
        domain: "Dominio",
        smpt: "Relay SMTP",
        host: "Host",
        port: "Porta",
        status: "Stato del test",
        serverAddress: "Indirizzo del server",
        lastActive: "Ultima attività",
      },
      customHeaderName: "Nome intestazione personalizzato",
      headerValue: "Valore dell'intestazione",
      proxies: "{n} host | {n} host",
      statuses: {
        passed: "Superato",
        notTested: "Non testato",
        pending: "In sospeso",
        failed: "Non superato",
      },
      actions: {
        testDomain: "Test",
        editDomain: "Modifica",
        removeDomain: "Rimuovi",
      },
      noOutgoingEmailsTooltip: "Nessuna e-mail in uscita per più di 12 ore.",
      failedReason: {
        CORO_MX_IS_NOT_PRIMARY:
          "L'MX primario del dominio non è riconosciuto come Inbound Gateway Coro.<br>Verifica le impostazioni DNS.",
        PROXY_POSTFIX_RELAY_HOST_NOT_REACHABLE:
          "Il primario del dominio Coro non può raggiungere l'host del relay SMTP.<br>Verifica le impostazioni dell'host e della porta",
        PROXY_POSTFIX_SETTINGS_INACTIVE:
          "L'host del relay SMTP non è attivo.<br>Contatta l’assistenza Coro per attivarlo.",
        GENERAL_VERIFICATION_FAIL:
          "Impostazione dominio gateway in entrata non riuscita.<br>Verifica le impostazioni e riprova.",
        PROXY_VERIFICATION_TIMEOUT:
          "Il messaggio di verifica non ha raggiunto l'Inbound Gateway Coro.<br>Verifica le impostazioni del relay host SMTP.",
        USER_VERIFICATION_TIMEOUT:
          "Il tempo di approvazione dell'e-mail di verifica è scaduto.<br>Verifica il destinatario e ripeti il test",
      },
    },
    noItems: {
      inbound: {
        description: "Aggiungi domini di posta elettronica in entrata",
        subDescription:
          "Aggiungi uno o più domini di posta elettronica per abilitare l'Inbound Gateway.",
      },
      outbound: {
        description: "Aggiungi i domini delle e-mail in uscita",
        subDescription: "Il proxy non verrà abilitato se non verranno aggiunti dei domini",
      },
    },
    verification: {
      successMessage: "Il processo di verifica dell'e-mail dell'Inbound Gateway è completato.",
      failureMessage1: "Si è verificato un problema :(",
      failureMessage2:
        "Il traffico e-mail in entrata non sembra passare attraverso l'Inbound Gateway Coro.",
      linkExpiredMessage1: "Il collegamento di verifica dell'Inbound Getaway è scaduto.",
      linkExpiredMessage2: "Invia nuovamente l’e-mail di prova e ritenta.",
    },
  },

  cloudApplications: {
    title: "Applicazioni cloud",
    description: "Monitora e controlla l’accesso alle applicazioni e ai dati del cloud",
    boxEnforcement: "Box",
    googleDirectoryEnforcement: "Google Workspace",
    googleDirectoryEnforcementAlias: "G-Suite",
    dropboxEnforcement: "Dropbox",
    office365Enforcement: "Microsoft 365",
    emailProxyEnforcement: "Inbound Gateway",
    oktaEnforcement: "Okta",
    slackEnforcement: "Slack",
    salesforceEnforcement: "Salesforce",
    zoomEnforcement: "Zoom",
    noServicesToAdd: "Nessun servizio da aggiungere",
    google: "Google",
    coronet: "Coro",
    connect: "Connetti",
    cloudApplication: "Applicazioni cloud",
    connectCloudApplication: "Collega le applicazioni cloud",
    numberConnected: "{n} connessi",
    numberProtectedUsers: "{n} utente | {n} utenti",
    activeUsers: "{n} attivo",
    inactiveUsers: "{n} inattivo",
    thirdPartyApps: "Applicazioni di terze parti",
    protectedUsers: "Utenti protetti",
    connectionStatus: "Stato della connessione",
    servicesFound: "{n} servizio | {n} servizi",
    connected: "Connesso",
    disconnected: "Disconnesso",
    disabled: "Disabilitato",
    incomplete: "Incompleto",
    notConnected: "Non connesso",
    hubspotEnforcement: "Hubspot",
    permissions: "Autorizzazioni",
    grantPermissions: "concedi le autorizzazioni",
    demoMicrosoftBanner:
      "<b>Microsoft 365 è attualmente connesso in modalità demo.</b><br>Per connettere una vera applicazione cloud Microsoft 365, prima disconnetti l'applicazione demo di Microsoft 365 o avvia la modalità di prova.",
    connectionStatusValues: {
      [ServiceStatus.CONNECTED]: "Connesso",
      [ServiceStatus.CONNECTED_NOT_SECURE]: "Connesso (non sicuro)",
      [ServiceStatus.NOT_CONNECTED]: "Non connesso",
      [ServiceStatus.DISCONNECTED]: "Disconnesso",
    },
    buttons: {
      connectService: "Connetti",
      disconnectService: "Disconnetti",
      continue: "Continua",
      access: "Autorizzazioni di accesso",
      removeService: "Rimuovi",
    },
  },
  service: {
    zoomEnforcement: {
      notice1:
        "Per impostare la gestione di Zoom, l’amministratore di sistema deve assicurarsi che l’account\namministratore di Zoom abbia i privilegi amministrativi per leggere, scrivere e riposizionare le risorse nell’applicazione.",
      notice2:
        "Le autorizzazioni richieste consentono di utilizzare Coro per monitorare l’accesso di utenti e gruppi Zoom insieme alle relative attività del servizio.",
    },
    hubspotEnforcement: {
      notice1:
        "Per impostare la gestione di Hubspot, l’amministratore di sistema deve assicurarsi che l’account\namministratore di Hubspot abbia i privilegi amministrativi per leggere, scrivere e spostare le risorse nell’applicazione.",
      notice2:
        "Le autorizzazioni richieste consentono di utilizzare Coro per monitorare l’accesso di utenti e gruppi Hubspot insieme alle relative attività del servizio.",
    },
    oktaEnforcement: {
      notice1:
        "Per impostare la gestione di Okta, l’amministratore di sistema deve assicurarsi che l’account\namministratore di Okta abbia i privilegi amministrativi per leggere, scrivere e riposizionare le risorse nell’applicazione.",
      notice2:
        "Le autorizzazioni richieste consentono di utilizzare Coro per monitorare l’accesso di utenti e gruppi Okta insieme alle relative attività del servizio.",
    },
    dropboxEnforcement: {
      stepOne: {
        title: "Autorizza l’applicazione di gestione del team",
        description:
          "Autorizza Coro (applicazione “Gestion team Coro”) ad accedere ai membri del tuo team.",
      },
      stepTwo: {
        title: "Autorizza l’applicazione di accesso ai file del team",
        description:
          "Autorizza Coro (applicazione “Accesso ai file Coro”) ad accedere ai file dei membri del team.",
      },
    },
    permissionsForGoogleDirectoryEnforcement: {
      step2: {
        title: "Modifica ID cliente",
        description1:
          "Visita il sito <a class='coro-link' href='https://admin.google.com/ac/owl/domainwidedelegation' target=\"_blank\">https://admin.google.com/ac/owl/domainwidedelegation</a> per completare l'attività.",
        description2:
          "Sostituisci gli obiettivi tra gli <span class='description-bold'>OAuth Scopes</span> con i seguenti propositi, comprese le virgole:",
        description3: 'Seleziona "Autorizza"',
      },
    },
    googleDirectoryEnforcement: {
      step1: {
        title: "Verifica di essere un amministratore di Google",
        description: "Accedi alla tua area di lavoro Google utilizzando un account amministratore.",
        descriptionError: {
          notAdmin: "Dobbiamo verificare che {email} sia un account amministratore di Google.",
          alreadyConnected:
            "Se hai accesso alla console <a href='https://admin.google.com/u/1/ac/owl/list?tab=apps' target=\"_blank\">Google Admin di {email}</a>, puoi revocare l’accesso all’applicazione Coro e riprovare.",
          generic:
            "Si è verificato un problema nella connessione all’account selezionato, contatta l'assistenza <a href='https://docs.coro.net/' target=\"_blank\">Coro.</a>",
          genericWithoutCoro:
            "Si è verificato un problema nella connessione all’account selezionato, contatta l’assistenza.",
        },
      },
      step2: {
        title: "Registra Coro come client API presso Google",
        description1:
          "Visita il sito <a href='https://admin.google.com/ac/owl/domainwidedelegation' target=\"_blank\">https://admin.google.com/ac/owl/domainwidedelegation</a>.",
        description2:
          "Clicca su ”<span class='description-bold'>Aggiungi nuovo </span>Client API”.",
        description3:
          "Alla voce ID <span class='description-bold'>cliente</span> copia e incolla il seguente ID:",
        description4:
          "Su <span class='description-bold'>OAuth Scopes</span> copia e incolla i seguenti propositi, comprese le virgole:",
        description5: "<span class='description-bold'>Autorizza</span> il nuovo ID cliente.",
      },
      step3: {
        title: "Verifica che l’API Drive SDK sia abilitata",
        description:
          "Visita il sito <a href='https://admin.google.com/u/5/ac/appsettings/55656082996/data' target=\"_blank\">https://admin.google.com/u/5/ac/appsettings/55656082996/data</a>.<br>Verifica che la casella ”Consenti agli utenti di accedere a Google Drive con l’API Drive SDK” sia <b>selezionata</b>.",
      },
      step4: {
        title: "Installa il dominio Coro su Google Workspace Marketplace",
        description:
          'Visita il sito <a href"https://workspace.google.com/marketplace/app/coronet_addon/98372960675" target="_blank">https://workspace.google.com/marketplace/app/coronet_addon/98372960675</a>.<br>Clicca su “<b>Installa dominio</b>”.',
      },
      successText: "Riuscito correttamente! Google Workspace è connesso",
    },
    salesforceEnforcement: {
      notice1:
        "Per impostare la gestione di Salesforce, l’amministratore di sistema deve assicurarsi che l’account\namministratore di Salesforce abbia i privilegi amministrativi per leggere, scrivere e riposizionare le risorse nell’applicazione.",
      notice2:
        "Le autorizzazioni richieste consentono di utilizzare Coro per monitorare l’accesso di utenti e gruppi Salesforce insieme alle relative attività di servizio, di rilevare eventuali perdite dati e minacce informatiche nell’archiviazione di e-mail e file, nonché di mitigare le minacce.",
    },
    slackEnforcement: {
      notice1:
        "A causa di alcune limitazioni imposte da Slack ai servizi di terze parti come Coro, la protezione Coro è ora disponibile solo per i clienti con i piani <b>Slack Business+</b> e <b>Slack Enterprise Grid</b>.",
      notice2:
        "Con la connessione si autorizza Coro a monitorare l’accesso e le attività di servizio di utenti e gruppi di Slack, al rilevamento eventuali perdite di dati e malware nella posta elettronica e nell'archiviazione di file, nonché alla mitigazione delle minacce.",
    },
    office365Enforcement: {
      stepOne: {
        accessDenied: "Si è verificato un problema, riprova.",
        error: "L’account non è associato a un ruolo di amministratore",
        accountAlreadyExist:
          "Servizio già connesso. Per procedere contatta gli amministratori <span class='text--link'>{admins}</span>.",
        title: "Collega Coro a Microsoft 365",
        description:
          "Con la connessione si autorizza Coro a monitorare l’accesso e le attività di servizio di utenti e gruppi di Microsoft 365, al rilevamento eventuali perdite di dati e malware nella posta elettronica e nell'archiviazione di file, nonché alla mitigazione delle minacce.",
        descriptionError:
          "Dobbiamo verificare che il tuo account sia un account amministratore di Microsoft 365.",
      },
      stepTwo: {
        title:
          "Verifica che la memorizzazione dei registri di controllo di Microsoft 365 sia abilitata.",
        description:
          "Vai sul sito<a href='https://purview.microsoft.com/audit/auditsearch' target=\"_blank\">https://purview.microsoft.com/audit/auditsearch</a> per eseguire questa operazione.",
      },
      successText: "Riuscito correttamente! Microsoft 365 è connesso",
    },
    boxEnforcement: {
      stepOne: {
        title: "Crea e configura l’applicazione Box",
        description:
          "Per una descrizione passo passo del processo, consulta l’articolo <a href='https://docs.coro.net/protection/connecting-to-box/' target=\"_blank\">Assistenza e documentazione.</a>",
      },
      stepTwo: {
        title:
          "Carica il file di configurazione della coppia di chiavi pubbliche/private scaricato al punto 1.",
        error: "L’account non è associato a un ruolo di amministratore",
        label: "Carica il file di configurazione",
      },
      successText: "Riuscito correttamente! Box è connesso",
    },
    errors: {
      notAdmin: "L'indirizzo {email} non è associato a un ruolo di amministratore",
      alreadyConnected: "L'indirizzo {email} è già in uso su un altro account Coro",
      generic: "Ops, si è verificato un problema",
    },
  },
  serviceStatuses: {
    active: "Attivo",
    suspendedByCoronet: "Sospeso da Coro",
    suspendedByService: "Sospeso dal servizio",
    invited: "Invitato",
    deleted: "Eliminato",
    inactive: "Inattivo",
  },
  cloudAppsSettings: {
    connect: "Connetti",
    connectionCompleted: "Ottimo! Il tuo servizio {service} è ora connesso.",
    permissionsUpdated: "Ottimo! Le autorizzazioni di {service} sono state aggiornate.",
    noCloudApps: {
      title: "Aggiungi le tue applicazioni cloud alla protezione Coro",
      description:
        "Monitora gli account delle app cloud aziendali per proteggere l’ambiente e rispettare \nle normative sulla privacy.",
      connectBtn: "Connetti le app cloud",
      modalDescription: "Seleziona l’applicazione cloud che vuoi collegare.",
    },
  },
  connectors: {
    title: "Connettori",
    tabs: {
      siem: "SIEM",
      apiKeys: "Credenziali API",
      psa: "PSA",
      webhooks: "Webhooks",
    },
    siem: {
      add: "Aggiungi connettore",
      description: "Esporta i dati in sistemi esterni di aggregazione dei dati di sicurezza.",
      table: {
        headers: {
          name: "Nome",
          status: "Stato",
        },
        actions: {
          edit: "Modifica",
          delete: "Elimina",
          sync: "Sincronizza",
        },
      },
      format: {
        splunk: "Splunk",
        azureSentinel: "Azure Sentinel",
        fluency: "Fluidità",
        generic: "Generico",
      },
      noItems: {
        description:
          "Al momento i dati dell’area di lavoro Coro non si possono esportare su sistemi di terze parti.",
        subDescription:
          "Aggiungi connettori ai sistemi esterni di gestione delle informazioni e degli eventi di sicurezza.",
      },
    },
    webhooks: {
      add: "Crea webhook",
      description: "Esporta i dati in sistemi esterni di aggregazione dei dati di sicurezza.",
      status: {
        [WebhookStatus.ENABLED]: "Attivo",
        [WebhookStatus.DISABLED]: "Disabilitato",
        [WebhookStatus.DISCONNECTED]: "Disconnesso",
        [WebhookStatus.INACTIVE]: "Disabilitato",
        [WebhookStatus.ERROR]: "Errore",
      },
      testStatus: {
        [WebhookTriggerTestStatus.SUCCESS]: "Superato",
        [WebhookTriggerTestStatus.FAILURE]: "Non superato",
        notTested: "Non testato",
      },
      error: {
        title: "Risposta non riuscita dall'URL del webhook.",
        subtitle: "Verifica che l'URL sia configurato correttamente e testalo di nuovo.",
        retest: "Testa di nuovo",
      },
      expirationLabels: {
        expired: "Scaduto",
        expiresIn: "Scadrà tra {n}",
      },
      triggerLabels: {
        newVersion: "Nuova versione",
        aboutToExpire: "Scadrà in data {expirationDate}",
        expired: "Scaduto",
      },
      alerts: {
        aboutToExpire: {
          title:
            "Azione necessaria: aggiorna il tuo webhook | Azione necessaria: aggiorna i tuoi webhook",
          body: ' È disponibile una nuova versione del trigger per <b>"{name}"</b>. Per mantenere attivo il webhook, seleziona " <b>Modifica</b>" dal menu con i tre puntini e configuralo entro il giorno {expirationDate}.',
        },
        expired: {
          title: 'Il webhook "{name}" è ora disabilitato',
          body: 'Seleziona "<b>Modifica</b>" dal menu a tre puntini per configurarne e riattivarne la funzionalità.',
        },
        triggerAboutToExpire: {
          title: "È disponibile un nuovo trigger",
          body: "Coro consiglia di selezionarlo insieme al trigger in scadenza ed eseguire un test. <br><b>Prima della scadenza del vecchio trigger, deselezionalo per evitare interruzioni.</b>",
        },
        triggerExpired: {
          title: "Un trigger è scaduto e il webhook è stato disattivato.",
          body: "Deseleziona il trigger scaduto e seleziona il nuovo trigger per riattivare il webhook.",
        },
      },
      table: {
        headers: {
          name: "Nome",
          status: "Stato",
          created: "Creato",
          expires: "Scade",
          appliedToChildWorkspace: "Applicato all'area di lavoro secondaria",
        },
        sections: {
          description: "Descrizione",
          authenticationUrl: "Autenticazione URL",
          secret: "Segreto",
          authorization: "Autorizzazione",
          trigger: "Trigger",
          lastUsed: "Usato l’ultima volta",
          testStatus: "Stato del test",
          action: "Azione",
          header: "Intestazione",
          value: "Valore",
        },
        actions: {
          [WebhookAction.EDIT]: "Modifica",
          [WebhookAction.TEST]: "Test",
          [WebhookAction.DEACTIVATE]: "Disabilita",
          [WebhookAction.ACTIVATE]: "Attiva",
          [WebhookAction.REMOVE]: "Rimuovi",
        },
      },
      noItems: {
        description: "Al momento non ci sono webhook configurati in questa area di lavoro",
        subDescription: "Crea un webhook per questa area di lavoro.",
      },
      webhookTriggers: {
        [WebhookTriggerType.WORKSPACE_CREATED]: "Area di lavoro creata",
        [WebhookTriggerType.WORKSPACE_STATUS_UPDATED]: "Status dell'area di lavoro aggiornato",
        [WebhookTriggerType.SUBSCRIPTION_MODULES_UPDATED]: "Moduli abbonamento aggiornati",
        [WebhookTriggerType.SUBSCRIPTION_ADDONS_UPDATED]:
          "Componenti aggiuntivi abbonamento aggiornati",
        [WebhookTriggerType.SUBSCRIPTION_BUNDLES_UPDATED]: "Pacchetti abbonamento aggiornati",
        [WebhookTriggerType.PROTECTED_USERS_ADDED]: "Utenti protetti aggiunti",
        [WebhookTriggerType.PROTECTED_USERS_UPDATED]: "Utenti protetti aggiornati",
        [WebhookTriggerType.PROTECTED_USERS_DELETED]: "Utenti protetti cancellati",
        [WebhookTriggerType.PROTECTED_USERS_COUNT_UPDATED]: "Conteggio utenti protetti aggiornato",
        [WebhookTriggerType.PROTECTED_DEVICES_UPDATED]: "Dispositivi protetti aggiornati",
        [WebhookTriggerType.PROTECTED_DEVICES_ADDED]: "Dispositivi protetti aggiunti",
        [WebhookTriggerType.PROTECTED_DEVICES_DELETED]: "Dispositivi protetti cancellati",
        [WebhookTriggerType.PROTECTED_DEVICES_COUNT_UPDATED]:
          "Conteggio dispositivi protetti aggiornato",
        [WebhookTriggerType.SUBSCRIPTION_UPDATED]: "Abbonamento aggiornato",
      },
    },
    psa: {
      title: "Connettori PSA",
      add: "Aggiungi connettore",
      connector: "Connettore",
      workspaceMapping: "Mappatura dell‘area di lavoro",
      serviceMapping: "Mappatura del servizio",
      ticketing: "Invio di segnalazioni",
      setupRequired: "Configurazione richiesta",
      nOfm: "{first} di {second}",
      mappingSelectPlaceholder: "Mappa",
      comingSoon: "In arrivo",
      clearMapping: "Cancella mappatura",
      actions: {
        edit: "Modifica connettore",
        disable: "Disconnetti",
        connect: "Connetti",
      },
      provider: {
        gradient: "Gradient",
        connectwise: "Connectwise",
        autotask: "Autotask",
      },
      status: {
        disconnected: "Disconnesso",
        connected: "Connesso",
        configSuspended: "Disconnesso",
        configNotFound: "Configurazione richiesta",
      },
      activeWorkspaces: "Aree di lavoro attive",
      noItems: {
        description: "Collega i tuoi spazi di lavoro ai sistemi PSA",
        subDescription: "Aggiungi connettori all’ambiente PSA.",
      },
      connectwiseWorkspaceMapping: {
        title: "Mappa le aree di lavoro alle società ConnectWise",
      },
      connectwiseServiceMapping: {
        title: "Mappa moduli e componenti aggiuntivi ai servizi ConnectWise",
      },
      autotaskWorkspaceMapping: {
        title: "Mappa le aree di lavoro per le società Autotask",
      },
      autotaskServiceMapping: {
        title: "Mappa moduli e componenti aggiuntivi ai servizi Autotask",
      },
      addPsaConnector: {
        title: "Aggiungi connessione PSA",
        description: "Seleziona il connettore che vuoi collegare",
        gradient: {
          label: "Chiave API Gradient",
          placeholder: "Inserisci la chiave API Gradient",
          description:
            "Tramite Gradient, puoi collegare l’area di lavoro Coro alla maggior parte dei sistemi PSA in uso.",
        },
        connectwise: {
          subtitle: "Dettagli dell’integrazione API ConnectWise",
          description: "Crea un nuovo connettore ConnectWise",
        },
        autotask: {
          subtitle: "Dettagli dell’integrazione API di Autotask",
          description: "Crea un nuovo connettore Autotask",
        },
        actionButton: "Aggiungi",
      },
      products: {
        [CoroProductNames.CLOUD_SECURITY]: "Cloud security",
        [CoroProductNames.ENDPOINT_SECURITY]: "Endpoint security",
        [CoroProductNames.EMAIL_SECURITY]: "Email security",
        [CoroProductNames.USER_DATA_GOVERNANCE]: "User data governance",
        [CoroProductNames.ENDPOINT_DATA_GOVERNANCE]: "Endpoint data governance",
        [CoroProductNames.EDR]: "EDR",
        [CoroProductNames.NETWORK]: "Network",
        [CoroProductNames.MDM]: "MDM",
        [CoroProductNames.INBOUND_GATEWAY]: "Inbound gateway",
        [CoroProductNames.SECURED_WEB_GATEWAY]: "Secure Web Gateway",
        [CoroProductNames.WIFI_PHISHING]: "WiFi phishing",
        [CoroProductNames.SECURED_MESSAGES]: "Secure Messages",
        [CoroProductNames.MDR]: "MDR",
        [CoroProductNames.SOC_CLOUD_SECURITY]: "Cloud Security, servizi gestiti",
        [CoroProductNames.SOC_ENDPOINT_SECURITY]: "Endpoint Security, servizi gestiti",
        [CoroProductNames.SOC_EMAIL_SECURITY]: "Email Security, servizi gestiti",
        [CoroProductNames.SOC_USER_DATA_GOVERNANCE]: "User Data Governance, servizi gestiti",
        [CoroProductNames.SOC_ENDPOINT_DATA_GOVERNANCE]:
          "Endpoint Data Governance, servizi gestiti",
        [CoroProductNames.SOC_NETWORK]: "Network, servizi gestiti",
        [CoroProductNames.ZERO_TRUST_NETWORK_ACCESS]: "Zero trust network access",
        [CoroProductNames.OUTBOUND_GATEWAY]: "Outbound gateway",
      },
    },
    apiKeys: {
      add: "Crea le credenziali API",
      expired: "Scaduto",
      table: {
        clientId: "Id client",
        expirationTime: "Scade",
        createdTime: "Creato",
        lastUsedTime: "Usato l’ultima volta",
        description: "Descrizione",
      },
      noItems: {
        description: "Al momento non sono ancora state create credenziali API",
        subDescription: "Crea le credenziali per ottenere l’accesso all’API Coro",
      },
    },
  },
  connectwise: {
    tabs: {
      workspaceMapping: "Mappatura dell‘area di lavoro",
      serviceMapping: "Mappatura del servizio",
      ticketing: "Invio di segnalazioni",
    },
  },
  workspaceMapping: {
    statusSelectPlaceholder: "Stato",
    companySelectPlaceholder: "Mappa",
    coroWorkspace: "Area di lavoro Coro",
    connectwiseCompany: "Società Connectwise",
    autotaskCompany: "Società Autotask",
    searchForWorkspaces: "Ricerca aree di lavoro...",
    mappingFilterType: {
      mapped: "Mappato",
      unmapped: "Non mappato",
      all: "Tutto",
    },
  },
  serviceMapping: {
    statusSelectPlaceholder: "Stato",
    companySelectPlaceholder: "Mappa",
    coroModules: "Moduli/componenti aggiuntivi Coro",
    connectwiseServices: "Servizi ConnectWise",
    autotaskServices: "Servizi Autotask",
    searchForModules: "Ricerca moduli...",
    mappingFilterType: {
      mapped: "Mappato",
      unmapped: "Non mappato",
      all: "Tutto",
    },
  },
  betaChip: {
    title: "Beta",
    tooltip: "Questa funzione sarà disponibile fino al giorno {n} in fase Beta.",
  },
  betaBanner: {
    title: "Ti diamo il benvenuto nella versione Beta di {addonOrModule}",
    subtitle: "Ecco cosa c’è da sapere:",
    limitedSupport: "Assistenza limitata",
    limitedSupportDescription: "In questa fase il supporto tecnico è minimo.",
    potentialBugs: "Bug potenziali",
    potentialBugsDescription:
      "Il tuo feedback è fondamentale. Se dovessi riscontrare dei problemi, li dovrai segnalare all’indirizzo <span class='coro-link'>support@coro.net.</span>",
    notForProduction: "Non in ambiente di produzione",
    notForProductionDescription:
      "Questa funzionalità non è ancora pronta per l’uso a pieno regime o per applicazioni critiche.",
    constantImprovements: "Miglioramenti costanti",
    constantImprovementsDescription:
      "Le cose potrebbero cambiare man mano che perfezioniamo e miglioriamo i componenti.",
    releaseUpdates: "Rilascia aggiornamenti",
    releaseUpdatesDescription:
      "Rimani in ascolto per le notizie sul rilascio ufficiale. Ti terremo al corrente di eventuali modifiche.",
  },
  accessPermissions: {
    title: "Autorizzazioni di accesso {service}",
    description:
      "Consenti l’accesso alle applicazioni cloud da paesi e indirizzi IP specifici. I criteri verranno elaborati in modo sequenziale e gli elementi con maggior priorità nell'elenco avranno la precedenza. Trascina gli elementi per riordinarli come necessario.",
    addRestriction: "Nuove autorizzazioni",
    noItems: {
      description:
        "Al momento gli utenti protetti non hanno autorizzazioni specifiche per accedere a {service}",
      subDescription: "Consenti l’accesso al servizio {service} da specifici paesi/indirizzi IP",
    },
    types: {
      all: "Tutti gli utenti",
      group: "Gruppi specifici",
      user: "Utenti specifici",
    },
    actions: {
      editAccessPermissions: "Modifica",
      deleteAccessPermissions: "Elimina",
    },
    table: {
      allUsers: "Tutti gli utenti",
      headers: {
        groups: "Gruppi",
        restrictions: "Paesi/IP consentiti",
        description: "Descrizione",
        remediation: "Correzione automatica",
      },
      remediationTypes: {
        none: "Nessuno",
        signin: "Esci",
        suspend: "Sospendi utente",
      },
    },
  },
  mobileActivation: {
    success: {
      title: "È tutto pronto",
      subtitle:
        "L’attivazione è stata completata correttamente. Apri l’app Coro sul tuo dispositivo mobile per vedere lo stato della tua connessione VPN.",
      mobileSubtitle: "Attivazione completata correttamente",
      openApp: "Apri l’app",
    },
    failure: {
      title: "Attivazione non riuscita",
      mobileSubtitle: "Attivazione dell’account non riuscita",
      resendButton: "Invia di nuovo il link di attivazione",
      errors: {
        [ActivateMobileErrorType.GENERIC]: "Attivazione dell’account non riuscita",
        [ActivateMobileErrorType.ACTIVATION_LINK_EXPIRED]: "Il link di attivazione è scaduto",
        [ActivateMobileErrorType.PART_OF_WORKSPACE]:
          "Fai già parte dell'area di lavoro {workspace}",
        [ActivateMobileErrorType.WORKSPACE_IS_NOT_PROTECTED]:
          "L'utente non è più protetto, pertanto non può entrare nell'area di lavoro. <br>Per maggiori informazioni, prova a contattare l'amministratore.",
        [ActivateMobileErrorType.WORKSPACE_ARCHIVED]:
          "L'area di lavoro associata a questo indirizzo è stata archiviata, quindi, non è possibile accedere nella stessa. <br> Per maggiori informazioni, prova a contattare l'amministratore.",
        [ActivateMobileErrorType.NETWORK_MODULE_DISABLED]:
          "Il modulo di rete associato a questo indirizzo è stato disabilitato, quindi, non è possibile accedere all'area di lavoro. <br> Per maggiori informazioni, prova a contattare l'amministratore.",
      },
    },
    activationLinkSent: {
      title: "Controlla la tua e-mail",
      subtitle:
        "Abbiamo inviato un link di attivazione a {name}. Seleziona l’area di lavoro di cui desideri far parte per attivare l’account.",
      mobileSubtitle:
        "Abbiamo inviato un link di attivazione a {name}. Seleziona l’area di lavoro di cui desideri far parte per attivare l’account.",
    },
  },
  usersPage: {
    title: "Utenti protetti",
    selected: "{n} selezionati",
    dynamicTitle: "Utenti protetti - {n}",
    usersQuantity: "{n} utente | {n} utenti",
    noData: "Nessun utente trovato",
    none: "Nessuno",
    noIssues: "Nessun problema",
    protectedSince: "Protetto dal",
    protectedCloudApps: "Applicazioni cloud protette",
    noProtectedCloudApps: "L’utente non è presente in nessuna app cloud protetta",
    protectedDevices: "Dispositivi protetti",
    noProtectedDevices: "Al momento, nessun dispositivo è protetto",
    tableHeaders: {
      user: "Utente",
      vulnerability: "Vulnerabilità",
    },
    actions: {
      viewDetails: "Visualizza i dettagli",
      markAsProcessed: "Chiudi tutte le segnalazioni",
      suspendFromAll: "Sospendi l’utente da tutte le applicazioni cloud",
      signInToAll: "Richiedi all’utente l’accesso a tutte le applicazioni cloud",
    },
    criticalIssues: "Problemi critici",
  },
  subscriptions: {
    plans: {
      [SubscriptionType.FREEZE]: "Inattivo",
      [SubscriptionType.SUBSCRIPTION]: "Abbonamento",
      [SubscriptionType.TRIAL]: "Prova",
      [SubscriptionType.INITIAL]: "Novità",
      [SubscriptionType.ARCHIVED]: "Archiviato",
    },
    bundles: {
      title: "Pacchetti",
      [SubscriptionBundle.ENDPOINT_PROTECTION]: "Endpoint Protection",
      [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: "Managed Endpoint Protection",
      [SubscriptionBundle.EMAIL_PROTECTION]: "Email Protection",
      [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: "Managed Email Protection",
      [SubscriptionBundle.SASE]: "SASE",
      [SubscriptionBundle.MANAGED_SASE]: "Managed SASE",
      [SubscriptionBundle.CORO_ESSENTIALS]: "Coro Essentials",
      [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: "Managed Coro Essentials",
      [SubscriptionBundle.CORO_CLASSIC]: "Coro Classic",
      [SubscriptionBundle.MANAGED_CORO_CLASSIC]: "Managed Coro Classic",
      [SubscriptionBundle.CORO_COMPLETE]: "Coro Complete",
      [SubscriptionBundle.MANAGED_CORO_COMPLETE]: "Managed Coro Complete",
    },
    modules: {
      title: "Modules",
      [SubscriptionModule.CLOUD_SECURITY]: "Cloud Security",
      [SubscriptionModule.ENDPOINT_SECURITY]: "Endpoint Security",
      [SubscriptionModule.EMAIL_SECURITY]: "Email Security",
      [SubscriptionModule.USER_DATA_GOVERNANCE]: "User Data Governance",
      [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: "Endpoint Data Governance",
      [SubscriptionModule.EDR]: "EDR",
      [SubscriptionModule.MDM]: "MDM",
      [SubscriptionModule.NETWORK]: "Network",
      [SubscriptionModule.SECURITY_AWARENESS]: "Formazione sulla Consapevolezza della Sicurezza",
    },
    addons: {
      title: "Componenti aggiuntivi",
      [SubscriptionAddon.SOC]: "Servizi gestiti",
      [SubscriptionAddon.SOC_CLOUD]: "Servizi gestiti - Cloud Security",
      [SubscriptionAddon.SOC_ENDPOINT]: "Servizi gestiti - Endpoint Security",
      [SubscriptionAddon.SOC_EMAIL]: "Servizi gestiti - Email Security",
      [SubscriptionAddon.SOC_USER_DATA_GOVERNANCE]: "Servizi gestiti - User Data Governance",
      [SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE]:
        "Servizi gestiti - Endpoint Data Governance",
      [SubscriptionAddon.SOC_NETWORK]: "Servizi gestiti - Network",
      [SubscriptionAddon.MDR]: "MDR",
      [SubscriptionAddon.WIFI_PHISHING]: "Wi-Fi Phishing",
      [SubscriptionAddon.SECURED_MESSAGES]: "Secure Messages",
      [SubscriptionAddon.INBOUND_GATEWAY]: "Inbound Gateway",
      [SubscriptionAddon.OUTBOUND_GATEWAY]: "Outbound Gateway",
      [SubscriptionAddon.SWG]: "Secure Web Gateway",
      [SubscriptionAddon.ZTNA]: "Zero Trust Network Access",
    },
    moduleIncludedInBundle: "Questo modulo è già incluso in uno dei pacchetti che hai selezionato",
    addonIncludedInBundle:
      "Questo componente aggiuntivo è già incluso in uno dei pacchetti che hai selezionato",
    usDataCenterOnly: "Solo data center negli USA",
    packages: "Pacchetti",
  },
  emailSettings: {
    title: "Email Security",
    tabs: {
      settings: "Impostazioni",
      allowBlockList: "Consenti/blocca",
      addIns: "Moduli aggiuntivi",
      coroMail: "Secure Messages",
      inboundGateway: "Inbound Gateway",
    },
    settingsTab: {
      realTimeSecurity: "Scansione delle e-mail per i seguenti tipi di minacce",
      scanBypass: "Consenti alle e-mail di bypassare la scansione",
      securityMode: "Modalità di sicurezza",
      sensitivityLevel: "Livello di sensibilità",
      sensitivityLevels: {
        [ScanSensitivityLevels.LOW]: "Basso",
        [ScanSensitivityLevels.MEDIUM]: "Medio",
        [ScanSensitivityLevels.HIGH]: "Alto",
      },
      sensitivityLevelDescription: "Regola la sensibilità per il rilevamento delle minacce e-mail.",
      securityModeInfoText:
        "QUARANTENA: le e-mail contenenti malware rilevati verranno eliminate. Le e-mail contenenti spam/phishing verranno messe in quarantena. Se il gateway in entrata fosse stato abilitato, tutte le e-mail sospette verranno messe in quarantena in modo sicuro" +
        "<br/><br/>" +
        "WARN RECIPIENT: le e-mail sospette non verranno bloccate, ma includeranno un'etichetta/un banner di avviso",
      securityModes: {
        [SecurityMode.QUARANTINE_EMAIL]: "Quarantena",
        [SecurityMode.WARN_RECIPIENT]: "Avvisa destinatario",
      },
      threatType: "Tipo di minaccia",
      realTimeSecurityDisabled: "Sicurezza in tempo reale disabilitata",
      scanMalware: "Malware negli allegati dell'e-mail",
      scanSpam: "Spam",
      scanPhishing: "Sospetto phishing",
      scanNewlyRegisteredDomains: "Domini appena registrati",
      scanEncryptedAttachments: "Allegato crittografato",
      scanUnverifiedSender: "Mittente non verificato",
      scanUnknownExternalSender: "Mittente esterno sconosciuto",
      quarantineByAttachmentType: "Quarantena per tipo di allegato",
      subjectLine: "Oggetto",
      securityAwarenessTraining: "Fornitori terzi di formazione sulla sicurezza",
      securityAwarenessTrainingDisclaimer:
        "Le e-mail per il modulo di <span class='subtitle2'>formazione Coro sulla consapevolezza della sicurezza</span> sono consentite per impostazione predefinita.",
      securityAwarenessTrainingSubtitle:
        "Consenti l'invio di e-mail a scopo di formazione sulla sicurezza",
      emailSubjectKeywordsPlaceHolder: "Parole chiave nell'oggetto",
      securityAwarenessTable: {
        configurationDescription: "{quantity} dominio o IP e | {quantity} domini o IP e",
        headerDescription: "{quantity} intestazione | {quantity} intestazioni",
        domainsOrIp: "Dominio o IP",
        headersTitle: "Intestazioni",
        headers: {
          name: "Nome",
          value: "Valore",
          configuration: "Configurazione",
          date: "Data aggiunta",
        },
        actions: {
          remove: "Rimuovi",
          edit: "Modifica",
        },
      },
      allowList: "Elenco consentiti",
      seeAll: "Vedi tutte le tipologie",
      seeLess: "Vedi meno tipologie",
      quarantineEmails:
        "Metti in quarantena le e-mail con allegati di questi tipi di file specificati.",
      customFileTypes: "Tipologie di file personalizzate",
      apiBasedEmailSecurity: "Email Security basata sulle API",
      quarantineFolder: "Cartella della quarantena",
      scanBypassLabel: "Consenti e-mail con parole chiave specificate nell’oggetto",
      quarantineFolderDescription:
        "Le e-mail bloccate verranno messe in quarantena in questa cartella:",
      quarantineFolderType: {
        suspected: "Cartella “Elementi sospetti” dedicata",
        trash: "Cartella di sistema del cestino",
      },
      quarantineFolderInfoTooltip:
        "Collega una o più app cloud a Coro per abilitare questa impostazione.",
      disclaimer:
        "Per una protezione anti-phishing trasparente, collega l’account Microsoft 365 o Google Workspace della tua organizzazione o imposta il proxy gateway per le e-mail in arrivo.",
    },
    addInsSettings: {
      title: "Moduli aggiuntivi per il feedback degli utenti",
      description:
        "Coro raccoglie feedback costanti direttamente dagli utenti sulla classificazione potenzialmente imprecisa dei messaggi di posta elettronica e li sottopone alla valutazione degli utenti quando questo componente aggiuntivo per le e-mail viene installato sui loro client di posta elettronica. L’installazione è semplicissima e si può effettuare in contemporanea per tutti gli utenti attuali (e futuri).",
      installFor: "Installa {provider}",
    },
  },
  entityTicketsList: {
    modulesTooltip:
      "Le segnalazioni di {modules} non sono incluse perché non puoi accedervi | Le segnalazioni di {modules} non sono incluse perché non puoi accedervi",
    noPermissionsText: "Non hai le autorizzazioni per accedere alle segnalazioni",
  },
  devices: {
    defaultTitle: "Dispositivi",
    mspTitle: "Dispositivi globali",
    advancedTitle: "Dispositivi - {n}",
    selected: "{n} selezionati",
    clientOutdated: "Client obsoleti",
    criticalIssues: "Problemi critici",
    noIssues: "Nessun problema",
    actions: {
      [DeviceAction.EXAMINE_MALWARE]: "Esamina malware",
      [DeviceAction.ENABLE_FIREWALL]: "Abilita firewall",
      [DeviceAction.REMOTE_SCAN]: "Scansione remota dei malware",
      [DeviceAction.DLP_PARTIAL_SCAN]: "Scansione remota dei dati sensibili",
      [DeviceAction.STOP_REMOTE_SCAN]: "Arresta i malware con la scansione remota",
      [DeviceAction.STOP_DLP_SCAN]: "Interrompi la scansione dei dati sensibili",
      [DeviceAction.REMOVE_FROM_PROTECTION]: "Disabilita protezione",
      [DeviceAction.ENFORCE_UAC]: "Attiva la notifica UAC",
      [DeviceAction.DISABLE_DEVELOPER_MODE]: "Disattiva la modalità sviluppatore",
      [DeviceAction.MARK_AS_PROCESSED]: "Chiudi le segnalazioni correlate",
      [DeviceAction.VIEW_DETAILS]: "Visualizza i dettagli",
      [DeviceAction.ENABLE_TAMPER_RESISTANCE]: "Abilita la difesa dalle manomissioni",
      [DeviceAction.DISABLE_TAMPER_RESISTANCE]: "Disabilita la difesa dalle manomissioni",
      [DeviceAction.ALLOW_NO_ENCRYPTION]: "Crittografia non consentita",
      [DeviceAction.COLLECT_LOGS]: "Memorizza registri",
      [DeviceAction.COLLECT_QUARANTINE_DATA]: "Memorizza l’elenco degli elementi in quarantena",
      [DeviceAction.DOWNLOAD_LOGS]: "Scarica registri",
      [DeviceAction.ENABLE_NETWORK_BLOCK_MODE]: "Isola dalla rete",
      [DeviceAction.DISABLE_NETWORK_BLOCK_MODE]: "Disattiva l’isolamento dalla rete",
      [DeviceAction.REBOOT_DEVICE]: "Riavvia dispositivo",
      [DeviceAction.SHUTDOWN_DEVICE]: "Spegni il dispositivo",
      [DeviceAction.UPDATE_AGENT_TO_LATEST]: "Aggiorna l’agente all’ultima versione stabile",
      [DeviceAction.ADD_LABEL]: "Aggiungi etichetta",
      [DeviceAction.REMOVE_LABEL]: "Rimuovi etichetta",
      [DeviceAction.SHOW_ADVANCED_INFORMATION]: "Mostra informazioni avanzate",
      [DeviceAction.OPEN_REMOTE_SHELL_SESSION]: "Apri la remote shell",
      [DeviceAction.EXPORT_TO_CSV]: "Esporta CSV",
    },
    noDevices: "Nessun dispositivo collegato",
    clientVersion: "Coro",
    drives: {
      statuses: {
        [DriveEncryptionStatus.ENCRYPTED]: "Crittografato",
        [DriveEncryptionStatus.ENCRYPTED_BY_CORO]: "Crittografato da Coro",
        [DriveEncryptionStatus.NOT_ENCRYPTED]: "Non crittografato",
        [DriveEncryptionStatus.FAILED]: "Operazione non riuscita",
        [DriveEncryptionStatus.SUSPENDED]: "Sospeso",
        [DriveEncryptionStatus.IN_PROGRESS]: "In corso",
      },
      types: {
        removable: "Rimovibile",
        nonRemovable: "Non rimovibile",
      },
      encryptBtn: {
        windows: "Codifica",
        osx: "Codifica",
      },
      password: "Chiave di recupero",
      model: "Modello",
      serialKey: "Chiave seriale",
      encryptionInProgress: "Crittografia in corso",
    },
    statuses: {
      none: "Nessuno",
      shutdown: "Spegnimento",
      isolated: "Isolato",
      online: "Online",
      offline: "Offline",
      nIssues: "{n} problemi",
      clientOutdated: "Client obsoleti",
      unresolvedTickets: "Segnalazioni non risolte",
    },
    filters: {
      osType: "Tipo di sistema operativo",
      osVersion: "Versione sistema operativo",
      clientVersion: "Versione Coro",
      isolationStatus: "Stato isolamento",
      vulnerability: "Vulnerabilità",
      status: "Stato",
      groups: "Gruppi",
      labels: "Etichette",
      label: {
        title: "Etichetta",
        noData: "Non esiste alcuna etichetta",
      },
    },
  },
  drives: {
    title: "Unità",
    filters: {
      status: "Stato",
      type: "Tipo",
    },
    table: {
      recoveryKey: "Chiave di recupero",
      serialKey: "Chiave seriale",
      type: "Tipo",
      encryptionFailedTooltip:
        "Il tentativo di crittografia di questa unità è fallito.<br>Espandi la voce per maggiori dettagli",
      failedReasons: {
        [DriveFailedReason.GENERAL]:
          "Tentativo di crittografia fallito. Per ulteriori informazioni sulle possibili soluzioni, consulta la documentazione <a class='coro-link' href='https://docs.coro.net/devices/device-drive-encryption/#encryption-fails' target=\_blank\></a> . L'ultimo tentativo è stato fatto alle ore {time}",
        [DriveFailedReason.TPM_NOT_ENABLED]:
          "Tentativo di crittografia fallito. Sul dispositivo non è stato trovato alcun modulo piattaforma attendibile (Trusted Platform Module, TPM) compatibile. <br>Per ulteriori informazioni, consulta la nostra documentazione <a class='coro-link' href='https://docs.coro.net/devices/device-drive-encryption/#encryption-fails' target=\_blank\></a> . L'ultimo tentativo è stato fatto alle ore {time}",
      },
      headers: {
        workspace: "Area di lavoro",
        driveName: "Nome dell’unità",
        deviceName: "[TRANSLATION] Device name",
        model: "Modello",
        status: "Stato",
      },
      actions: {
        encryptDrive: "Crittografia dell’unità",
        decryptDrive: "Decrittazione dell’unità",
      },
    },
  },
  deviceDetails: {
    lastSeen: "Visto l’ultima volta",
    labels: "Etichette",
    customer: "Customer",
    duplicateBanner: {
      title: "Duplica record individuati",
      description:
        "Abbiamo trovato altri dispositivi associati a questo utente. Se si tratta di duplicati di questo dispositivo, sarebbe meglio unire tali dati e i relativi record. ",
      merge: "Gestisci dispositivi",
      ignore: "Ignora",
    },
    deviceUser: {
      title: "Utente dispositivo",
      status: "Stato",
      email: "E-mail",
      viewUser: "Visualizza utente",
      name: "Nome",
      username: "Nome utente per l’accesso al dispositivo",
      upn: "UPN",
      onPremiseUPN: "UPN locale",
      enrollmentCode: "Codice di registrazione",
      clientVersion: "Versione client",
      location: "Posizione",
      vulnerabilities: "Vulnerabilità generali",
      licenseToShowVulnerabilities: "Autorizza l’utente a mostrare le vulnerabilità",
      userNotLicensed: "L’utente associato a questo dispositivo non è abilitato.",
      incompliantVulnerabilities: "Vulnerabilità non conformi",
      firstActivity: "Prima attività",
      lastActivity: "Ultima attività",
      activity: "Attività",
      user: "Utente",
    },
    device: {
      title: "Dispositivo",
      model: "Modello",
      os: "Sistema operativo",
      osVersion: "Versione sistema operativo",
      subVersion: "Sotto-versione",
      hostname: "Nome host",
      deviceID: "Id dispositivo Coro",
      type: "Tipo",
      modelAndId: "Modello e ID",
      hostName: "Nome host",
      coroProtection: "Protezione Coro",
      staticIp: "Indirizzo IP statico dell’ufficio virtuale",
      version: "Versione",
      added: "Aggiunto",
      lastKnownIp: "Ultimo IP conosciuto",
      lastRemoteScan: "Ultima scansione remota in data",
      malwareScanInProgress: "Scansione remota in corso",
      noRemoteScans: "Non sono ancora state eseguite scansioni remote",
      lastLogsUploadedAt: "Registri caricati in data",
      modelId: "ID modello",
      serialNumber: "Numero di serie",
      rebootNeeded: "Riavvio necessario",
      mobileId: "ID cellulare",
      username: "Nome utente",
    },
  },
  coroMailSettings: {
    pageTitle: "Secure Messages",
    title: "Moduli aggiuntivi per l’utilizzo dei Secure Messages",
    description:
      "Grazie ai moduli aggiuntivi dei Secure Messages Coro, gli utenti di Outlook 365 e Gmail possono inviare messaggi protetti direttamente dalle loro app di posta elettronica web e native. Usa i pulsanti qui sotto per iniziare.",
    coroMailPortal: {
      title: "Portale web dei Secure Messages",
      description: "Invia e ricevi messaggi protetti nel portale web dei Secure Messages.",
    },
    installFor: "Installa {provider}",
    messageSettings: "Impostazioni del messaggio",
    restrictMessageForwarding: "Limita l'inoltro dei messaggi",
    setMessageExpiration: "Imposta il periodo di scadenza del messaggio (giorni)",
    numberOfDays: {
      label: "Numero di giorni",
      placeholder: "Inserisci il numero di giorni",
    },
  },
  usersSettings: {
    title: "Utenti protetti",
    excluded: "escluso",
    addUsersBtn: "Aggiungi utenti",
    addGroupsBtn: "Aggiungi gruppi",
    addExceptionsBtn: "Aggiungi esclusioni",
    aliases: "Pseudonimi",
    tabs: {
      users: "Utenti protetti",
      groups: "Gruppi protetti",
      exceptions: "Esclusioni",
    },
    noItems: {
      description:
        "Puoi aggiungere dei gruppi di utenti alla protezione Coro, invece che uno per volta, e risparmia tempo",
      groupsDescription:
        "Puoi aggiungere dei gruppi di utenti già definiti negli account cloud aziendali, tra cui Microsoft 365 o Google Workspace, per aggiungere rapidamente più utenti alla protezione Coro.",
      usersDescription:
        "In alternativa, puoi aggiungere gli utenti alla protezione Coro uno per volta.",
      groupsShortDescription:
        "Aggiungi i gruppi di utenti già definiti negli account cloud aziendali.",
    },
    actions: {
      addUsers: "Aggiungi utenti specifici",
      addGroups: "Aggiungi gruppi specifici",
      addAllUsers: "Aggiungi protezione a tutti gli utenti",
      importUsersFromCsv: "Importa da CSV",
    },
    userGroupsTab: {
      groupsList: "Elenco gruppi",
      noGroups: "Nessun gruppo corrisponde a questa richiesta",
      tableHeaders: {
        groupName: "Nome del gruppo",
        size: "Dimensioni",
        protectionGrantedTime: "Protezione concessa",
      },
      actions: {
        removeGroups: "Rimuovi gruppo",
        removeException: "Rimuovi le esclusioni",
      },
      groupsSelected: "{n} selezionati",
      removeGroupsBtn: "Rimuovi {n} gruppo | Rimuovi {n} gruppi",
    },
    usersTab: {
      noUsers: "Nessun utente protetto corrisponde a questa richiesta",
      directMember: "Aggiunto singolarmente",
      addedViaCsv: "Aggiunto tramite CSV",
      excluded: "(escluso)",
      active: "Attivo",
      inactive: "Inattivo",
      groups: "Gruppi",
      status: {
        [UserSettingsAppStatus.ACTIVE]: "Attivo",
        [UserSettingsAppStatus.INACTIVE]: "Inattivo",
      },
      tableHeaders: {
        user: "Utente",
        joinedVia: "Iscritto tramite",
        appsProtected: "App protette",
        status: "Stato",
        protectionGranted: "Protezione concessa",
      },
      userActions: {
        removeUsers: "Rimuovi utente",
        addToException: "Aggiungi alle esclusioni",
        removeException: "Rimuovi esclusione",
      },
    },
    exceptionsTab: {
      exceptionsList: "Esclusioni",
      noExceptions: "Nessuna esclusione corrisponde a questa query",
      notExcluded: "(amministratori non esclusi)",
      actions: {
        removeException: "Rimuovi esclusione",
      },
      tableHeaders: {
        email: "E-mail",
        addedBy: "Aggiunto da",
        addedOn: "Aggiunto in data",
      },
    },
    filters: {
      protectedVia: "Iscritto tramite",
      inboundGateway: "Inbound Gateway",
      cloudServices: "Servizi cloud",
      status: "Stato",
      protectedApps: "App protette",
      joinedVia: {
        cloudServices: {
          [Service.BOX]: {
            subtitle: "Box groups",
          },
          [Service.DROPBOX]: {
            subtitle: "Gruppi Dropbox",
          },
          [Service.G_SUITE]: {
            subtitle: "Gruppi Google Workspace",
          },
          [Service.OFFICE_365]: {
            subtitle: "Gruppi Microsoft 365",
          },
          [Service.SLACK]: {
            subtitle: "Gruppi Slack",
          },
          [Service.SALES_FORCE]: {
            subtitle: "Gruppi Salesforce",
          },
        },
        general: {
          subtitle: "Generale",
          [UserSettingsJoinedVia.ADMIN]: "Aggiunto dall'amministratore",
          [UserSettingsJoinedVia.INDIVIDUALLY]: "Aggiunto singolarmente",
          [UserSettingsJoinedVia.CSV]: "Aggiunto tramite CSV",
        },
        inboundGateway: "Inbound gateway",
      },
    },
  },
  manageWorkspaces: {
    title: "Panoramica delle aree di lavoro",
    globalLists: "Elenchi globali",
    workspacesOverview: { title: "Panoramica delle aree di lavoro" },
    devices: { title: "Dispositivi" },
    protectedUsers: { title: "Utenti protetti" },
    controlPanel: { title: "Impostazioni globali" },
    tickets: { title: "Ticket" },
    bundles: {
      title: "Pacchetti",
      [SubscriptionBundle.ENDPOINT_PROTECTION]: "Endpoint Protection",
      [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: "Endpoint Protection",
      [SubscriptionBundle.EMAIL_PROTECTION]: "Email Protection",
      [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: "Email Protection",
      [SubscriptionBundle.SASE]: "SASE",
      [SubscriptionBundle.MANAGED_SASE]: "SASE",
      [SubscriptionBundle.CORO_ESSENTIALS]: "Coro Essentials",
      [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: "Coro Essentials",
      [SubscriptionBundle.CORO_CLASSIC]: "Coro Classic",
      [SubscriptionBundle.MANAGED_CORO_CLASSIC]: "Coro Classic",
      [SubscriptionBundle.CORO_COMPLETE]: "Coro Complete",
      [SubscriptionBundle.MANAGED_CORO_COMPLETE]: "Coro Complete",
    },
    table: {
      name: "Nome",
      type: "Tipo",
      plan: "Stato",
      addons: "Componenti aggiuntivi",
      subscription: "Abbonamento",
      maxProtectedUsers: "Numero massimo di utenti",
      protectedDevices: "Dispositivi <br/> protetti",
      createdOn: "Creato in data",
      protectedUsers: "Protetti",
      potentialUsers: "Utenti potenziali",
      tickets: "Ticket",
    },
    filters: {
      type: "Tipo",
      plan: "Stato",
      addons: "Abbonamento",
      labels: "Etichette",
    },
    addonTypes: {
      SOC: "Servizi gestiti",
      SOC_PRO: "Servizi gestiti pro",
      EDR: "EDR",
      MDR: "MDR",
    },
    actions: {
      [MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE]: "Crea un’area di lavoro del channel",
      [MspWorkspaceAction.CREATE_REGULAR_WORKSPACE]: "Crea un’area di lavoro normale",
      [MspWorkspaceAction.EDIT_WORKSPACE]: "Modifica area di lavoro",
      [MspWorkspaceAction.VIEW_WORKSPACE]: "Visualizza area di lavoro",
      [MspWorkspaceAction.CONVERT]: "Converti area di lavoro",
      [MspWorkspaceAction.EXPORT_MSP]: "Esporta l'elenco delle aree di lavoro in CSV",
      [MspWorkspaceAction.EXPORT_MSP_SUMMARY]: "Esporta la sintesi delle aree di lavoro in CSV",
      [MspWorkspaceAction.CREATE_CHILD_WORKSPACE]: "Crea un’area di lavoro secondaria",
      [MspWorkspaceAction.EDIT_SUBSCRIPTION]: "Modifica abbonamento",
      [MspWorkspaceAction.START_SUBSCRIPTION]: "Avvia abbonamento",
      [MspWorkspaceAction.STOP_SUBSCRIPTION]: "Interrompi abbonamento",
      [MspWorkspaceAction.START_BULK_SUBSCRIPTION]: "Avvia abbonamento",
      [MspWorkspaceAction.STOP_BULK_SUBSCRIPTION]: "Interrompi abbonamento",
      [MspWorkspaceAction.EXPORT_MSP_NOTIFICATIONS]: "Esporta le notifiche dell’amministratore",
      [MspWorkspaceAction.ARCHIVE_WORKSPACE]: "Archivia area di lavoro",
      [MspWorkspaceAction.UNARCHIVE_WORKSPACE]: "Ripristina l’area di lavoro",
      [MspWorkspaceAction.EXTEND_TRIAL]: "Estendi il periodo di prova",
      [MspWorkspaceAction.EDIT_MSP_WORKSPACE_LABEL]: "Modifica etichette",
      [MspWorkspaceAction.ADD_MSP_BULK_LABEL]: "Aggiungi etichette",
    },
    workspaceTypes: {
      [WorkspaceType.CHANNEL]: "Channel",
      [WorkspaceType.REGULAR]: "Normale",
      [WorkspaceType.CHILD]: "Secondaria",
    },
    createDisabled: "Puoi creare un’area di lavoro solo quando ti trovi nell’area principale.",
    noData: "Nessun risultato",
    roles: {
      mspPermissionsSectionTitle: "Autorizzazioni MSP",
      workspacePermissionsSectionTitle: "Autorizzazioni dell’area di lavoro secondaria",
      table: {
        headers: {
          role: "Ruolo",
          assigneesCount: "N. di utenti amministratori",
          workspace: "Area di lavoro",
        },
        actions: {
          [MspRolesAction.ADD]: "Aggiungi ruolo MSP",
          [MspRolesAction.EDIT]: "Modifica ruolo",
          [MspRolesAction.DUPLICATE]: "Duplica ruolo",
          [MspRolesAction.DELETE]: "Elimina ruolo",
        },
      },
      scopes: {
        [RolePermissionsScope.VIEWS]: "Tutti gli elenchi",
        [RolePermissionsScope.TICKETS]: "Tutti i ticket",
        [MspRolePermissionScopes.MSP_PORTAL_SCOPE]: "Gestione MSP",
      },
    },
    adminUsers: {
      table: {
        headers: {
          name: "Nome",
          email: "E-mail",
          role: "Ruolo",
          workspace: "Area di lavoro",
        },
        actions: {
          [MspAdminUsersAction.ADD]: "Aggiungi utente amministratore MSP",
          [MspAdminUsersAction.EDIT]: "Modifica utente amministratore MSP",
          [MspAdminUsersAction.DELETE]: "Rimuovi utente amministratore MSP",
        },
        you: "Tu",
      },
    },
  },
  logoUploader: {
    imageError: "L’immagine non corrisponde alle specifiche",
    headerIcon: "Icona intestazione",
    emailIcon: "Icona e-mail",
    replaceImage: "Clicca per sostituire",
    description: "Usa un'icona quadrata bianca su sfondo trasparente. Massimo 500KB. File png, svg",
    emailDescription:
      "Usa un'icona quadrata scura su sfondo trasparente. Massimo 500KB. File png, svg",
    imageHint:
      "Barra dell'intestazione: l'icona del tuo channel verrà mostrata sulla barra dell'intestazione della piattaforma insieme alla tua.",
    emailImageHint:
      "Intestazione dell'e-mail: il logo del channel verrà mostrato nelle comunicazioni e-mail insieme al tuo.",
  },
  backOffice: {
    widgets: {
      overviewWidget: {
        types: {
          [BackOfficeOverviewWidgetType.WINDOWS_DEVICES]: {
            title: "Dispositivi Windows",
          },
          [BackOfficeOverviewWidgetType.MACOS_DEVICES]: {
            title: "dispositivi macOS",
          },
          [BackOfficeOverviewWidgetType.CUSTOMERS]: {
            title: "Clienti",
          },
          [BackOfficeOverviewWidgetType.PROTECTABLE_USERS]: {
            title: "Utenti tutelabili",
          },
          [BackOfficeOverviewWidgetType.PROTECTED_USERS]: {
            title: "Utenti protetti",
          },
          [BackOfficeOverviewWidgetType.PROTECTED_USERS_REPORTED_TO_RECURLY]: {
            title: "Utenti protetti segnalati a recurly",
          },
          [BackOfficeOverviewWidgetType.PROTECTED_USERS_IN_PAID_WORKSPACES]: {
            title: "Utenti protetti nelle aree di lavoro a pagamento",
          },
        },
      },
      table: {
        headers: {
          coroVersion: "Versione Coro",
          count: "Numero di dispositivi",
        },
      },
    },
    manageAdminPermissions: "Gestisci le autorizzazioni amministratore",
    title: "Backoffice",
    beVersion: "Hash del ramo/commit BE:",
    agentsGradualUpdate: "Aggiornamento graduale degli agenti",
    workspaceGroup: "Gruppo area di lavoro",
    deviceGroup: "Gruppo dispositivi",
    betaUpdateGroup: "Gruppo aggiornamenti beta",
    totalWorkspaces: "Aree di lavoro totali",
    totalDevices: "Dispositivi totali",
    permissions: "Autorizzazioni",
    manageWorkspaceCodes: "Gestisci codici delle aree di lavoro",
    roles: {
      workspacePermissionsSectionTitle: "Autorizzazioni dell’area di lavoro",
      table: {
        headers: {
          role: "Ruolo",
          assigneesCount: "N. di utenti amministratori",
        },
        actions: {
          [GlobalRolesAction.ADD]: "Aggiungi ruolo globale",
          [GlobalRolesAction.EDIT]: "Modifica ruolo",
          [GlobalRolesAction.DUPLICATE]: "Duplica ruolo",
          [GlobalRolesAction.DELETE]: "Elimina ruolo",
        },
      },
    },
    adminUsers: {
      table: {
        headers: {
          name: "Nome",
          email: "E-mail",
          role: "Ruolo",
        },
        actions: {
          [GlobalAdminUsersAction.ADD]: "Aggiungi utente amministratore globale",
          [GlobalAdminUsersAction.EDIT]: "Modifica utente amministratore globale",
          [GlobalAdminUsersAction.DELETE]: "Rimuovi l’utente amministratore globale",
        },
        you: "Tu",
      },
    },
  },
  workspaceCodes: {
    title: "Codici area di lavoro",
    description: "Crea codice promozionale dell'area di lavoro",
    subDescription: "Creare il codice dell'area di lavoro da usare durante l'onboarding",
    type: {
      [WorkspaceCodeType.SUBSCRIPTION]: "Abbonamento",
      [WorkspaceCodeType.CREDIT_CARD_DETAILS]: "Dati della carta di credito",
      [WorkspaceCodeType.FIXED_DISCOUNT]: "Sconto fisso",
      [WorkspaceCodeType.COMMERCIAL_TERMS]: "Termini commerciali",
      [WorkspaceCodeType.TRIAL_DURATION]: "Durata periodo di prova",
      [WorkspaceCodeType.COMBINED]: "Combinato",
    },
    status: {
      [WorkspaceCodeStatus.ACTIVATED]: "Attivo",
      [WorkspaceCodeStatus.DEACTIVATED]: "Disattivato",
    },
    actions: {
      [WorkspaceCodeActions.DELETE]: "Elimina",
      [WorkspaceCodeActions.ACTIVATE]: "Attiva",
      [WorkspaceCodeActions.DEACTIVATE]: "Disattiva",
      [WorkspaceCodeActions.COPY_LINK_TO_SIGNUP]: "Copia il link per l'iscrizione",
      [WorkspaceCodeActions.CREATE]: "Crea codice dell'area di lavoro",
      [WorkspaceCodeActions.EXPORT_CSV]: "Esporta CSV",
    },
    codeName: "Nome",
    codeType: "Tipo",
    codeStatus: "Stato",
    validTill: "Valido fino al",
    assignedWorkspacesCount: "Conteggio aree di lavoro",
    priceBookNumber: "Numero del listino prezzi",
    agentDetails: "Agente",
    mspDetails: "MSP",
    salesDetails: "Agente di commercio",
    expirationDate: "Data di scadenza",
    workspaceType: "Tipo di area di lavoro",
    relatedTo: "Correlato a",
    relationTypes: {
      [WorkspaceCodeRelationType.GENERAL]: "Generale",
      [WorkspaceCodeRelationType.AGENT]: "Agente",
      [WorkspaceCodeRelationType.MARKETING]: "Marketing",
      [WorkspaceCodeRelationType.MSP]: "MSP",
      [WorkspaceCodeRelationType.SALES]: "Vendite",
    },
    modules: "Moduli",
    addons: "Componenti aggiuntivi",
    bundles: "Pacchetti",
    commercialTerms: "Termini commerciali",
    discount: "Sconto",
    trialDuration: "Durata periodo di prova",
    creditCardRequired: "Carta di credito necessaria",
  },
  oauthPage: {
    pleaseWait: "Ti invitiamo ad attendere, stiamo gestendo il tuo accesso.",
  },
  rangeBundleSelector: {
    [SubscriptionBundle.ENDPOINT_PROTECTION]: {
      description:
        "Registra tutte le attività degli endpoint, analizza le anomalie dei dati e automatizza la risoluzione della maggior parte dei problemi di sicurezza.",
    },
    [SubscriptionBundle.SASE]: {
      description:
        "Aggiungi protezione ai dispositivi aziendali ovunque si trovino attraverso una rete cloud impenetrabile.",
    },
    [SubscriptionBundle.EMAIL_PROTECTION]: {
      description:
        "Scansiona le e-mail alla ricerca di minacce e correggile automaticamente, riducendo il tempo necessario per gestire Email Security.",
    },
    [SubscriptionBundle.CORO_ESSENTIALS]: {
      description:
        "Ottieni una copertura essenziale per endpoint, e-mail e app cloud, per automatizzare la risoluzione della maggior parte dei problemi di sicurezza.",
    },
    [SubscriptionBundle.CORO_COMPLETE]: {
      description: "",
    },
    [SubscriptionBundle.CORO_CLASSIC]: {
      description: "",
    },
    billedAnnually: "Fatturazione annuale",
    perUser: "Per utente/mese",
    managed: "Gestito",
    unmanaged: "Non gestito",
    managedTooltip:
      "In modalità Non Gestita, puoi abilitare SOC per i singoli moduli nella scheda Moduli. In modalità Gestita, SOC è abilitato per tutti i moduli.",
  },
  moduleOrAddonSelector: {
    [SubscriptionAddon.SOC]: {
      description:
        "Lascia che il nostro team dei servizi di sicurezza gestiti si occupi per te di Email Security, Endpoint Security e SASE",
    },
    [SubscriptionModule.EMAIL_SECURITY]: {
      description: "Proteggiti da phishing, malware e altri messaggi fraudolenti",
    },
    [SubscriptionModule.NETWORK]: {
      description: "Proteggi la tua rete con VPN cloud, firewall e cluster di uffici remoti",
    },
    [SubscriptionModule.USER_DATA_GOVERNANCE]: {
      description:
        "Assicurati una gestione sicura delle informazioni personali e di altri dati critici",
    },
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
      description: "Affidati ai nostri criteri di sicurezza dei dati degli endpoint",
    },
    [SubscriptionModule.MDM]: {
      description:
        "Assicurati che i tuoi dispositivi mobili non vengano attaccati da eventuali minacce",
    },
    [SubscriptionModule.ENDPOINT_SECURITY]: {
      description:
        "Imposta comportamento del dispositivo, protezione NGAV e processi consentiti/bloccati",
    },
    [SubscriptionModule.CLOUD_SECURITY]: {
      description:
        "Proteggiti dai malware nelle unità cloud e dalle attività anomale dell'amministratore",
    },
    [SubscriptionModule.EDR]: {
      description: "Riavvia, arresta e isola i dispositivi e i processi compromessi",
    },
    [SubscriptionAddon.WIFI_PHISHING]: {
      description: "Impedisci agli utenti finali di connettersi a endpoint Wi-Fi sospetti",
    },
    [SubscriptionAddon.SECURED_MESSAGES]: {
      description:
        "Crittografa le e-mail in uscita in modo che solo i destinatari possano visualizzarle",
    },
    [SubscriptionAddon.INBOUND_GATEWAY]: {
      description:
        "Intercetta le e-mail in entrata in modo che solo i mittenti sicuri raggiungano i destinatari",
    },
    [SubscriptionAddon.SWG]: {
      description: "Filtra il traffico web per proteggere la tua attività",
    },
    [SubscriptionAddon.ZTNA]: {
      description: "Assicurati che l'accesso ad app, dati e servizi sia sicuro",
    },
    [SubscriptionModule.SECURITY_AWARENESS]: {
      description:
        "Migliora la sicurezza insegnando al tuo team a riconoscere e prevenire le minacce informatiche.",
    },
    addonDisabled:
      'Seleziona il modulo "<b>{module}</b>" per attivare questo componente aggiuntivo',
    perUser: "per utente",
    month: "/mese",
    managed: "Gestito",
    unmanaged: "Non gestito",
    managedTooltip:
      "In modalità Non Gestito, il componente aggiuntivo SOC non è incluso. In modalità Gestito, SOC è abilitato per il tuo modulo.",
  },
  network: {
    title: "Network",
    noData: {
      title: "Il tuo ufficio virtuale è in fase di allestimento. <br> Torna tra qualche minuto.",
      subtitle:
        "Se il processo di configurazione dovesse richiedere più di 10 minuti,<br> contatta il nostro team di assistenza.",
      contactSupport: "Contatta l'assistenza",
    },
    settings: {
      title: "Impostazioni",
      mobileBanner: {
        title: "Abilita l'accesso all'app mobile",
        description:
          "Solo gli utenti protetti possono utilizzare l'app di protezione endpoint Coro.<br>Assicurati che i tuoi utenti mobili siano inclusi nell'elenco degli utenti protetti.",
        importList: "Importa l'elenco degli utenti mobili",
      },
      labelsAutocompletePlaceholder: "Seleziona etichette...",
      labelsAutocompleteLabel: "Etichette del dispositivo",
      manualDisconnectionEnabled: "Consenti la disconnessione manuale dalla VPN",
      manualDisconnectionDescription:
        "Per impostazione predefinita, gli utenti non possono disconnettere manualmente i dispositivi dalla VPN.",
    },
    virtualOffice: {
      modes: {
        ztna: "ZTNA",
        vpn: "VPN",
      },
      title: "Ufficio virtuale",
      vpnPolicy: "Criterio VPN",
      labelsAutocompleteLabel: "Etichette del dispositivo",
      labelsAutocompletePlaceholder: "Seleziona etichette...",
      activateFullTunnelingVpn: "Attiva la VPN full-tunneling",
      fullTunneling: "VPN full-tunneling",
      vpnMobileApp: "Applicazione mobile Coro",
      deActivateFullTunnelingVpn: "Disattiva la VPN full-tunneling",
      splitTunnelingLabelsPlaceholder:
        "Inserisci le etichette dei dispositivi per concedere l'accesso alla VPN split-tunnelling",
      fullTunnelingLabelsPlaceholder:
        "Inserisci le etichette dei dispositivi per concedere l'accesso alla VPN full-tunnelling",
      includedExternalResources: "Risorse esterne incluse",
      excludedExternalResources: "Risorse esterne escluse",
      defaultPolicy: "Criterio VPN predefinito",
      defaultPolicyDescription:
        "Per impostazione predefinita, ai dispositivi protetti non è consentito l’accesso all’ufficio virtuale.",
      splitTunneling: "VPN split-tunnelling",
      mode1Description:
        "Use the VPN to access all networking except for any external resources listed below.",
      mode2Description:
        "Use the VPN to access your virtual office and any external resources listed below.",
      mode3Description:
        "Usa la VPN per accedere a tutte le reti, ad eccezione delle risorse esterne contrassegnate come escluse.",
      blockLocalNetworkEnabled: "Blocca la rete locale",
      blockLocalNetworkDescription:
        "Specify for which devices the Local Area Network should be blocked.",
      includedExcludedResources: "Include/Exclude Lists",
      excluded: "Excluded",
      included: "Included",
      allLists: "All lists",
      trafficNotThroughVpn: "Il traffico non passa attraverso la VPN",
      trafficThroughVpn: "Il traffico passa attraverso la VPN",
      ipAddresses: "Indirizzo IP | Indirizzi IP",
      encryptionStrength: {
        title: "Livello di crittografia",
        AES128: "AES-128",
        AES256: "AES-256",
        AES256Hint: "Sicurezza più lenta e di livello militare.",
        AES128Hint: "Sicurezza più veloce e di livello aziendale.",
      },
      actions: {
        addUrlToIncludelist: "Aggiungi all'elenco delle inclusioni",
        addUrlToExcludelist: "Aggiungi all'elenco delle esclusioni",
        remove: "Rimuovi",
      },
      table: {
        domainOrIp: "Dominio/Indirizzo IP",
        status: "Stato",
      },
      ztna: {
        ztnaActivationLabel: "Attivare lo ZTNA",
        ztnaResourceList: "Elenco delle risorse",
        ztnaActivationDescription:
          "L'accesso a una rete non affidabile (Zero trust network access, ZTNA) limita la rete dell'ufficio virtuale solo a ciò che è ammesso nei record sottostanti, bloccando tutte le altre connessioni.",
        table: {
          noLabel: "Nessuna etichetta assegnata",
          headers: {
            resourceName: "Nome della risorsa",
            ipAddress: "Indirizzo IP",
            ports: "Porte",
            protocol: "Protocollo",
            devices: "[TRANSLATION] Device labels",
            resourceType: "[TRANSLATION] IP address/ IP range / IP & subnet / Domain",
          },
          resourceConfigurations: "[TRANSLATION] Resource configurations",
        },
        resourceTypes: {
          [ConfigType.IP]: "[TRANSLATION] IP Address",
          [ConfigType.CIDR]: "[TRANSLATION] IP Address",
          [ConfigType.RANGE]: "[TRANSLATION] IP Range",
          [ConfigType.DOMAIN]: "[TRANSLATION] Domain",
        },
        protocols: {
          [Protocol.TCP]: "TCP",
          [Protocol.UDP]: "UDP",
          [Protocol.ALL]: "TCP & UDP",
        },
        alert: "I record sottostanti si usano quando l'opzione ZTNA è abilitata.",
        actions: {
          [ZtnaAction.DELETE_ZTNA_RULE]: "Rimuovi la risorsa",
          [ZtnaAction.EDIT_ZTNA_RULE]: "Modifica la risorsa",
        },
      },
    },
    secureWebGateway: {
      title: "SWG",
      dnsFilteringLabel: "Filtro DNS",
      dnsFilteringDescription:
        "Attiva il filtro DNS per bloccare l'accesso a risorse esterne indesiderate dal tuo ufficio virtuale.",
      labelsAutocompletePlaceholder: "Seleziona etichette...",
      labelsAutocompleteLabel: "Etichette del dispositivo",
      dnsFilteringInfo:
        "Inoltre, applica il filtro DNS ai dispositivi <b>al di fuori dell'ufficio virtuale</b> con queste etichette:",
      dnsResolverLabel: "Modalità anonima del resolver DNS",
      dnsResolverDescription:
        "In modalità anonima, la cronologia delle richieste DNS non verrà analizzata, né memorizzata. Sono disponibili solo statistiche generali sulle richieste DNS.",
      localDnsEnabledLabel: "Usa il server locale per le richieste DNS locali",
      localNetwork: "Rete locale",
      localNetworkInputPlaceholder: "Inserisci l'intervallo IP nell'annotazione CIDR...",
      localNetworkInputLabel: "Indirizzo IP",
      localDNSServer: "Server DNS locale",
      localDNSServerInputPlaceholder: "Inserisci IP...",
      localDNSServerInputLabel: "Indirizzo IP",
      allowBlockList: "Elenchi consentiti/bloccati",
      allowBlockListDescription:
        "I record seguenti si attivano quando il filtraggio DNS è abilitato",
      allLists: "Tutti gli elenchi",
      allCategories: "Tutte le categorie",
      actions: {
        addUrlToAllowlist: "Aggiungi all'elenco degli elementi consentiti",
        addUrlToBlocklist: "Aggiungi all'elenco degli elementi bloccati",
        importUrlGroupToAllowlist: "Importa un gruppo di URL nell'elenco consentiti",
        importUrlGroupToBlocklist: "Importa un gruppo di URL nell'elenco degli elementi bloccati",
        removeUrlFromAllowlist: "Rimuovi elenco",
        removeUrlFromBlocklist: "Rimuovi elenco",
        viewUrlList: "Visualizza elenco",
        disableUrlGroupAllowList: "Disattiva elenco",
        disableUrlGroupBlockList: "Disattiva elenco",
        enableUrlGroupAllowList: "Attiva elenco",
        enableUrlGroupBlockList: "Attiva elenco",
        removeUrlGroupFromAllowList: "Rimuovi elenco",
        removeUrlGroupFromBlockList: "Rimuovi elenco",
      },
      filters: {
        allowed: "Consentito",
        blocked: "Bloccato",
        allowedDisabled: "Consentiti (disabilitato)",
        blockedDisabled: "Bloccati (disabilitato)",
        url: "URL",
        urlGroup: "Gruppo di URL",
        lockedUrlGroup: "Gruppo di URL bloccati",
      },
      table: {
        name: "Nome",
        action: "Azione",
        allowed: "Consentito",
        blocked: "Bloccato",
      },
    },
    siteToSite: {
      title: "Tunnel da sito a sito",
      voFirewall: "[TRANSLATION] Virtual Office Firewall Exceptions",
      voFirewallDisclaimer:
        "[TRANSLATION] All virtual office firewall ports are closed to incoming communication unless explicitly requested otherwise in a record below",
      voFirewallTable: {
        protocol: "Protocollo",
        port: "[TRANSLATION] Port",
        allowConnection: "[TRANSLATION] Allow connection from",
      },
      actions: {
        editVoFirewall: "[TRANSLATION] Edit firewall port",
        deleteVoFirewall: "[TRANSLATION] Delete firewall port",
      },
      tunnels: {
        title: "Tunnel da sito a sito",
        empty: {
          title: "[TRANSLATION] Add firewall tunnel",
          description:
            "[TRANSLATION] Allow Coro-enabled devices to connect to on-prem resources though the company firewall",
          addButton: "[TRANSLATION] Add tunnel",
        },
        addButton: "[TRANSLATION] + Add",
        table: {
          tunnel: "[TRANSLATION] Tunnel",
          siteName: "[TRANSLATION] Site name",
          remoteGateway: "[TRANSLATION] Remote gateway",
          status: "Stato",
          [SiteToSiteTunnelStatus.CONNECTED]: "Connesso",
          [SiteToSiteTunnelStatus.DISABLED]: "Disabilitado",
          [SiteToSiteTunnelStatus.DISCONNECTED]: "Disconnesso",
          testState: {
            title: "[TRANSLATION] Test status",
            [SiteToSiteTunnelTestState.FAILED]: "[TRANSLATION] Failed",
            [SiteToSiteTunnelTestState.PASSED]: "[TRANSLATION] Passed",
          },
        },
        actions: {
          [SiteToSiteTunnelsAction.TEST]: "[TRANSLATION] Test",
          [SiteToSiteTunnelsAction.EDIT]: "Modifica",
          [SiteToSiteTunnelsAction.DELETE]: "Rimuovi",
          [SiteToSiteTunnelsAction.DOWNLOAD_LOG]: "[TRANSLATION] Download log",
          [SiteToSiteTunnelsAction.ENABLE_TUNNEL]: "Abilita",
          [SiteToSiteTunnelsAction.DISABLE_TUNNEL]: "Disconnetti",
        },
      },
    },
    initialSetup: {
      title: "[TRANSLATION] Setting up your Virtual Office",
      selectRegionTitle: "[TRANSLATION] Select region for global VPN routing",
      selectRegionDisclaimer: "[TRANSLATION] Region selection is a one-time process during set up.",
      selectRegionPlaceholder: "[TRANSLATION] Select a region",
    },
  },
  billing: {
    title: "Fatturazione e abbonamento",
    tabs: {
      subscription: "Abbonamento",
      paymentMethod: "Metodo di pagamento",
    },
    subscriptionTab: {
      subscriptionSummary: "Sintesi dell'abbonamento",
      planDetails: "Dettagli del piano",
      devicesDescription:
        "Il tuo abbonamento attuale prevede <strong>{protectedDevicesCount} dispositivo.</strong> | Il tuo abbonamento attuale prevede <strong>{protectedDevicesCount} dispositivi.</strong>",
      usersDescription:
        "Il tuo abbonamento attuale prevede <strong>{protectedUsersCount} utente.</strong> | Il tuo abbonamento attuale prevede <strong>{protectedUsersCount} utenti.</strong>",
      updateSubscriptionDescription:
        "Puoi aggiornare il tuo abbonamento se le tue esigenze dovessero cambiare",
      addDevices: "Aggiungi dispositivi",
      addUsers: "Aggiungi utenti",
      updatePlan: "Aggiorna piano",
    },
  },
  messages: {
    title: "Messaggi",
    categories: {
      cloudApps: "Cloud Security",
      connectors: "Connettori",
    },
    types: {
      [MessageType.ON_BOARDING_COMPLETED]:
        "Ti diamo il benvenuto su Coro! Il tuo account aziendale {service} è stato collegato correttamente e \nda questo momento Coro protegge le tue attività. I nostri sistemi verranno adattati e ottimizzati ulteriormente per fornirti \nun livello di sicurezza dei dati adeguato alle specificità della tua società. Questo processo richiede solitamente alcune ore, ma ne vale assolutamente la pena! &#x1F609;",
      [MessageType.AUDIT_LOGS_DISABLED]:
        "Nessun accesso ai registri di controllo di {service}.\nVerifica che <a href='{link}' target=\"_blank\" class='coro-link'>{linkDescription}</a> sia abilitato.",
      [MessageType.AUDIT_LOGS_DISABLED_RESOLVED]:
        "L’accesso ai registri di controllo {service} è stato ristabilito.",
      [MessageType.AUDIT_LOGS_INTERRUPTED]:
        "Interruzioni nell'accesso ai registri di controllo di {service}.\nNessun registro di controllo dal giorno {startDate}.",
      [MessageType.AUDIT_LOGS_INTERRUPTED_RESOLVED]:
        "Interruzioni nell'accesso ai registri di controllo di {service}.\nIl problema si è verificato dal giorno {startDate} al giorno {endDate}.\nIl problema è stato risolto.",
      [MessageType.GOOGLE_DRIVE_SDK_DISABLED]: "L’SDK di Google Drive è stato disattivato",
      [MessageType.GOOGLE_DRIVE_SDK_DISABLED_RESOLVED]: "L’SDK di Google Drive è stato attivato",
      [MessageType.SERVICE_HAS_BEEN_DISCONNECTED]:
        "Il servizio {service} è stato scollegato da Coro",
      [MessageType.SERVICE_HAS_BEEN_DISCONNECTED_RESOLVED]:
        "La connessione a {service} è stata ristabilita",
      [MessageType.SERVICE_HAS_BEEN_DISCONNECTED_BY_OPERATOR]:
        "Il servizio {service} è stato disconnesso da {operator_name}.",
      [MessageType.ADDON_NOT_INSTALLED]:
        "Ti consigliamo vivamente di installare il modulo aggiuntivo Coro per il servizio {service} per poter inviare feedback immediati sulle e-mail di phishing da parte degli utenti dell’organizzazione.",
      [MessageType.CONNECTOR_HAS_BEEN_DISCONNECTED]:
        "Il connettore {connectorName} è stato scollegato da Coro",
      [MessageType.MAX_PROTECTED_USERS_HAS_EXCEEDED]:
        "Il numero di utenti protetti ha superato il limite di {MAX_PROTECTED_USERS}  utenti. Per aumentare il limite, contatta il tuo fornitore di servizi.",
    },
    auditLogs: "Registri di controllo",
    gSuiteAuditLogs: "Google Workspace registri di controllo",
    learnMore: "Scopri di più",
    noAlerts: "Non ci sono avvisi",
    noNewAlerts: "Non ci sono nuovi avvisi",
    upgradeNow: "Fai subito l'upgrade",
  },
  pniVerification: {
    success: "Grazie!",
    failure: "Si è verificato un problema",
    successMessage: "Abbiamo ricevuto il tuo feedback correttamente.",
    failureMessage: "Non abbiamo ricevuto il tuo feedback. Riprova tra qualche minuto.",
  },
  mdmCallbackPage: {
    contactSupport: "Contatta l’assistenza",
    errors: {
      generic: "Si è verificato un errore durante l’accesso a Mobile Device Management",
    },
  },
  login: {
    title: "Accedi",
    email: "E-mail",
    password: "Password",
    signIn: "Accedi",
    signUp: "Registrati",
    dontHaveAccount: "Non hai un account?",
    forgotPassword: "Hai dimenticato la password?",
    errors: {
      badCredentials: "Credenziali errate. Controlla e riprova.",
      noAccountFound: "Non è disponibile alcun account Coro",
      generalError: "Si è verificato un problema",
      genericError: "Si è verificato un problema",
      workspaceIsArchived: "Non è disponibile alcun account Coro",
      loginUnsuccessful: "Non è stato possibile effettuare l’accesso. Riprova più tardi.",
      recapchaFailed: "La verifica di reCAPTCHA non è riuscita. Per favore, riprova.",
      noOfficeAccount:
        "Questo account Microsoft 365 non è associato a un account Coro. Prova di nuovo o clicca sul link di iscrizione qui sotto",
      noGoogleAccount:
        "Questo account Google Workspace non è associato a un account Coro. Prova di nuovo o clicca sul link di iscrizione qui sotto",
      [AccountErrors.NOT_ACCEPTED_INVITATION]:
        "Il tuo account non è ancora stato attivato. Controlla la tua e-mail dove troverai un link di invito",
    },
  },
  forgotPassword: {
    title: "Password reimposta",
    resetPasswordBtn: "Password reimposta",
    explanation: "Ti aiuteremo a ripristinarla e a tornare in pista.",
    backToLoginBtn: "‹ Ritorna alla pagina di accesso",
    backToDashboardBtn: "Ritorna alla dashboard",
    successText: "Controlla l’e-mail e clicca sul collegamento.",
    emailNotFound: "L’e-mail non è stata trovata. Prova con un altro indirizzo e-mail.",
    genericError: "Si è verificato un problema nella reimpostazione della password, riprova.",
    resendLink: "Invia di nuovo il collegamento",
    tryDifferentEmail: "Prova con un indirizzo e-mail diverso",
    checkYourEmail: "Controlla la tua e-mail",
    recoverInstruction:
      "Abbiamo inviato le istruzioni per il recupero della password all'indirizzo {email}",
    didNotReceiveEmail: "Non avete ricevuto alcuna e-mail? Controlla la cartella dello spam!",
  },
  resetPassword: {
    title: "Crea nuova password",
    resetPasswordBtn: "Password reimposta",
    newPassword: "Nuova password",
    repeatNewPassword: "Ripeti la nuova password",
    successText: "La password è stata reimpostata correttamente",
    passwordsNotMatch: "Le password non corrispondono.",
    passwordShouldBeDifferent: "La password deve essere diversa dalla precedente.",
    resetLinkInvalid:
      "Il collegamento per la reimpostazione della password è scaduto o non è valido.",
    genericError: "Si è verificato un problema nella reimpostazione della password, riprova.",
    description: "La nuova password deve essere diversa dalle opzioni usate in precedenza.",
    weakPassword:
      "La password deve contenere almeno 8 simboli e includere almeno 1 lettera in maiuscolo, 1 in minuscolo e 1 numero.",
  },
  forms: {
    email: {
      label: "E-mail",
      placeholder: "Digita l’indirizzo e-mail",
    },
    password: {
      title: "Password",
      placeholder: "Digita password",
    },
  },
  downloadsPage: {
    title:
      "{customer} ti ha invitato a installare la <b>protezione endpoint Coro</b> sui tuoi dispositivi.",
    downloadBtn: "Scarica e installa",
    footerDescription:
      "Dopo l’installazione, il dispositivo sarà protetto dall’abbonamento di {customer}. Una volta installata la protezione endpoint <b>Coro</b> e attivata con l’indirizzo e-mail lavorativo, il dispositivo verrà registrato nell’account di {customer}. Ciò consentirà a {customer} di monitorare lo stato di sicurezza del dispositivo, nonché di verificarne la posizione in caso di eventi di sicurezza.",
  },
  mfa: {
    title: "Autenticazione a due fattori",
    notice:
      "L’aggiornamento dell’autenticazione a due fattori verrà applicato anche a tutte le aree spazi di lavoro di cui fai parte.",
    mfaEnabled: "L’autenticazione a due fattori è abilitata",
    mfaEnabledDesc:
      "Hai attivato l’autenticazione a due fattori per il tuo account con un’app di autenticazione.",
    mfaDisabled: "L’autenticazione a due fattori è disabilitata",
    recoveryMobile: "Il tuo numero di cellulare per il recupero",
    disableMFAButton: "Elimina i dati dell’autenticazione a due fattori",
    fallbackTitle: "Abilita il metodo di recupero di riserva",
    fallbackDesc:
      "Inserisci un numero di telefono nel caso in cui tu non riesca ad autenticarti e debba recuperare il codice tramite SMS.",
    fallbackCodeTitle: "Inserisci il codice a 6 cifre che hai ricevuto sul telefono",
    finishSetupBtn: "Termina la configurazione",
    smsSent: "SMS inviato",
    createMFATitle: "Genera autenticazione a due fattori",
    createMFADesc:
      "Scansiona questo codice QR in un’applicazione di autenticazione mobile (come <a class='coro-link' href='https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid&oco=0' target='_blank'>Google Authenticator</a>), quindi, inserisci il codice qui sotto per confermare. Per aggiungere manualmente la voce, utilizza questo codice\": <strong>{code}</strong>. L’autenticazione a due fattori non sarà attiva finché non completerai questo passaggio.",
    enterCode: "Inserisci il codice",
    resendSMS: "Invia di nuovo l’SMS",
  },
  verification: {
    title: "Verifica la tua identità",
    app: {
      enterCode: "Inserisci il codice a 6 cifre dall’app di autenticazione",
      fallbackLink: "Invia un codice tramite SMS",
    },
    sms: {
      enterCode: "Inserisci il codice a 6 cifre ricevuto sul telefono.",
      fallbackLink: "Inserisci il codice dall’app di autenticazione",
      noSMS: "Non hai ricevuto l’SMS?",
    },
    supportLink: "<a href='https://docs.coro.net/' target=\"_blank\">Contatta l’assistenza</a>",
    verifyBtn: "Verifica",
    cantFindCode: "Non riesci a trovare il codice?",
  },
  invitedPage: {
    cloudAppsSecurity: "App cloud",
    emailSecurity: "E-mail",
    deviceSecurity: "Dispositivi endpoint",
    dataSecurity: "Dati",
    title: "Imposta password",
    usersSecurity: "Utenti",
    welcomeToCoro: "Ti diamo il benvenuto su Coro",
    youHaveBeenInvited:
      "Hai ricevuto un invito da <span class='text--semibold'>{email}</span> per entrare a far parte dell'area di lavoro <span class='text--semibold'>{workplace}</span>",
    youHaveBeenInvitedViaApi:
      "Hai ricevuto un invito per entrare a far parte dell'area di lavoro <span class='text--semibold'>{workplace}</span>",
    acceptInvitationBtn: "Accetta l’invito",
    acceptAndLoginBtn: "Accetta e accedi",
    setPasswordBtn: "Imposta password",
    newPassword: "Nuova password",
    successText: "La password è stata impostata correttamente",
    successfullyJoined: "L’accesso all’area di lavoro è avvenuto correttamente",
    goToLogin: "Val alla pagina di accesso",
    errors: {
      tokenExpired:
        "Il collegamento di invito è scaduto. Contatta  {admin} per ricevere un nuovo link.",
      inviteRevoked: "L’invito è stato revocato. Contatta {admin} per ricevere un nuovo link.",
      tokenExpiredViaApi:
        "Il collegamento di invito è scaduto. Contatta chi ti ha invitato all'inizio.",
      inviteRevokedViaApi: "L’invito è stato revocato. Contatta chi ti ha invitato all'inizio.",
      privacyPolicy: "L’utente deve accettare le Condizioni d’uso e l’Informativa sulla privacy",
    },
    termsOfUseDescription: "Accetto le condizioni d'uso di Coro",
    termsOfUseLink: "Condizioni d’uso",
    privacyPolicyLink: "Informativa sulla privacy",
  },
  settings: {
    title: "Pannello di controllo",
    protectionCoverage: "Copertura della protezione",
    account: "Account",
    adminAccounts: "Utenti amministratori",
    accessControl: "Controllo degli accessi",
    activityLogs: "Registri attività",
    users: "Utenti",
    notifications: "Notifiche",
    endpointSecurity: "Endpoint Security",
    devices: "Dispositivi",
    connect: "Connetti",
    soc: "Servizi gestiti",
    security: "Impostazioni di sicurezza",
    malware: "Malware e ransomware",
    phishing: "Phishing",
    accountBlocker: "Blocco dell’account",
    dataLoss: "Governance dei dati",
    cloudSecurity: "Cloud Security",
    cloudApps: "Applicazioni cloud",
    accountSecurity: "Sessioni attive",
    settingsBreadCrumb: "Impostazioni",
    securityAwareness: "Security Awareness Training",
    protection: "Protezione",
    connectors: "Connettori",
    reports: "Rapporti",
    billing: "Fatturazione e abbonamento",
    email: "Email Security",
    userDataGovernance: "User Data Governance",
    endpointDataGovernance: "Endpoint Data Governance",
    workspace: "Area di lavoro",
    emailProxy: "Proxy e-mail",
    coroMail: "Secure Messages",
    network: "Network",
    edr: "EDR",
    mdm: "Mobile Device Management",
    mdmShort: "Mobile Device Mgmt.",
    demoMode: {
      title: "Modalità demo",
      resetBtn: "Ripristina",
    },
  },
  sso: {
    loginInProcess: "Ti invitiamo ad attendere, stiamo gestendo il tuo accesso.",
    errors: {
      genericError: "Si è verificato un problema durante l’accesso. Riprova più tardi.",
    },
  },
  signUpPage: {
    defaultLanguage: "Lingua predefinita dell'ambiente di lavoro",
    checkYourEmail: "Controlla la tua email",
    confirmationCodeSent:
      "Abbiamo inviato un codice di verifica a <strong>{email}</strong>. Inseriscilo qui sotto per continuare.",
    getStartedNow: "Inizia ora",
    alreadyHaveAnAccount: "Hai già un account?",
    businessEmail: "Email aziendale",
    enteredWrongEmail: "Hai inserito l'email sbagliata?",
    notReceivedCode: "Non hai ricevuto il codice?",
    resend: "Reinvia",
    sendingNewCode: "Invio di un nuovo codice",
    resendAvailableAfter:
      "Puoi reinviare il codice tra {n} secondo. | Puoi reinviare il codice tra {n} secondi.",
    title: "Prova Coro gratis",
    signUpOffice: "Registrati con Microsoft",
    signUpGoogle: "Registrati con Google",
    signUpBtn: "Registrati",
    errors: {
      [SignUpError.COMPANY_ALREADY_REGISTERED]:
        "L'azienda con questo dominio esiste già. Per procedere, contatta l'amministratore <span class='coro-link'>{admins}</span>. | L'azienda con questo dominio esiste già. Per procedere, contatta gli amministratori <span class='coro-link'>{admins}</span>.",
      [SignUpError.PUBLIC_EMAIL_DOMAIN]:
        "Per favore, inserisci un indirizzo email aziendale valido.",
      notAdmin: "Devi accedere a Coro con un account amministratore.",
      accountNotAssociatedOffice:
        "L’account non è associato a un ruolo di amministratore su Microsoft 365",
      accountNotAssociatedGoogle:
        "L’account non è associato a un ruolo di amministratore su Google Workspace",
      accountAlreadyCreated: "L’account della tua società è già stato creato.",
      contactAdmin:
        "Contatta l'amministratore <span class='text--link'>{admin}</span> per creare un account.",
      newOfficeAccountAlertText: "Questo account Microsoft 365 sembra essere nuovissimo.",
      newOfficeAccountErrorText:
        "Considerato che Microsoft 365 impiega alcune ore per configurare i privilegi dei nuovi account, ti invitiamo a iscriverti a Coro in un secondo momento.",
      privacyPolicy: "L’utente deve accettare le Condizioni d’uso e l’Informativa sulla privacy",
    },
    termsOfUseDescription: `Procedendo, accetti i <a class="coro-link" href='${CoroMarketingLink.TERMS_OF_USE}' target='_blank'>Termini di servizio</a> e la <a class="coro-link" href='${CoroMarketingLink.PRIVACY_POLICY}' target='_blank'>Informativa sulla privacy</a>.`,
  },
  dashboard: {
    addons: "Componenti aggiuntivi",
    seeDisabledModules: "Vedi i moduli disabilitati...",
    hideDisabledModules: "Nascondi i moduli disabilitati...",
    seeDisabledAddons: "Vedi i componenti aggiuntivi disabilitati...",
    hideDisabledAddons: "Nascondi i componenti aggiuntivi disabilitati...",
    widgets: {
      overviewWidget: {
        noIssues: "Nessun problema",
        addProtection: "Aggiungi protezione",
        ticketsToReview: "segnalazione da esaminare | segnalazioni da esaminare",
        types: {
          userDataGovernance: {
            title: "User Data Governance",
            description: "Problemi",
          },
          endpointDataGovernance: {
            title: "Endpoint Data Governance",
            description: "Problemi",
          },
          emailSecurity: {
            title: "Email Security",
            description: "Problemi",
          },
          endpointSecurity: {
            title: "Endpoint Security",
            description: "Problemi",
          },
          cloudSecurity: {
            title: "Cloud Security",
            description: "Problemi",
          },
          edr: {
            title: "EDR",
          },
          network: {
            title: "Network",
            description: "Configurazione delle impostazioni",
          },
          mdm: {
            title: "Mobile Device Management",
          },
          inboundGateway: {
            title: "Inbound Gateway",
            description: "Configurazione delle impostazioni",
          },
          wifiPhishing: {
            title: "Wi-Fi Phishing",
            description: "Configurazione delle impostazioni",
          },
          secureWebGateway: {
            title: "Secure Web Gateway",
            description: "Configurazione delle impostazioni",
          },
          securedMessages: {
            title: "Secure Messages",
            description: "Configurazione delle impostazioni",
          },
          securityAwareness: {
            title: "Security Awareness Training",
          },
          placeholder: {
            title: "Nessun modulo abilitato",
            noAccessTitle: "Nessun accesso ai moduli abilitati",
          },
        },
        resolved: "risolto",
        ninetyDays: "90 giorni",
      },
      alerts: {
        title: "Messaggi",
        categories: {
          cloudApps: "Cloud Security",
          connectors: "Connettori",
        },
        types: {
          ON_BOARDING_COMPLETED:
            "Ti diamo il benvenuto su Coro! Il tuo account aziendale {service} è stato collegato correttamente e \nda questo momento Coro protegge le tue attività. I nostri sistemi verranno adattati e ottimizzati ulteriormente per fornirti \nun livello di sicurezza dei dati adeguato alle specificità della tua società. Questo processo richiede solitamente alcune ore, ma ne vale assolutamente la pena! &#x1F609;",
          AUDIT_LOGS_DISABLED:
            "Nessun accesso ai registri di controllo di {service}.\nVerifica che <a href='{link}' target=\"_blank\">{linkDescription}</a> sia abilitato.",
          AUDIT_LOGS_DISABLED_RESOLVED:
            "L’accesso ai registri di controllo {service} è stato ristabilito.",
          AUDIT_LOGS_INTERRUPTED:
            "Interruzioni nell'accesso ai registri di controllo di {service}.\nNessun registro di controllo dal giorno {startDate}.",
          AUDIT_LOGS_INTERRUPTED_RESOLVED:
            "Interruzioni nell'accesso ai registri di controllo di {service}.\nIl problema si è verificato dal giorno {startDate} al giorno {endDate}.\nIl problema è stato risolto.",
          GOOGLE_DRIVE_SDK_DISABLED: "L’SDK di Google Drive è stato disattivato",
          GOOGLE_DRIVE_SDK_DISABLED_RESOLVED: "L’SDK di Google Drive è stato attivato",
          SERVICE_HAS_BEEN_DISCONNECTED: "Il servizio {service} è stato scollegato da Coro",
          SERVICE_HAS_BEEN_DISCONNECTED_RESOLVED: "La connessione a {service} è stata ristabilita",
          SERVICE_HAS_BEEN_DISCONNECTED_BY_OPERATOR:
            "Il servizio {service} è stato disconnesso da {operator_name}.",
          ADDON_NOT_INSTALLED:
            "Ti consigliamo vivamente di installare il modulo aggiuntivo Coro per il servizio {service} per poter inviare feedback immediati sulle e-mail di phishing da parte degli utenti dell’organizzazione.",
          CONNECTOR_HAS_BEEN_DISCONNECTED:
            "Il connettore {connectorName} è stato scollegato da Coro",
          MAX_PROTECTED_USERS_HAS_EXCEEDED:
            "Il numero di utenti protetti ha superato il limite di {MAX_PROTECTED_USERS}  utenti. Per aumentare il limite, contatta il tuo fornitore di servizi.",
        },
        auditLogs: "Registri di controllo",
        gSuiteAuditLogs: "Registri di controllo di Google Workspace",
        learnMore: "Scopri di più",
        noAlerts: "Non ci sono avvisi",
        noNewAlerts: "Non ci sono nuovi avvisi",
        upgradeNow: "Fai subito l'upgrade",
        subscriptionExpired: "Abbonamento scaduto",
        regularSubscriptionExpiredDescription:
          "L'area di lavoro al momento non è attiva.<br>Per l’abbonamento, contatta il tuo account manager Coro.",
        mspSubscriptionExpiredDescription:
          "L'area di lavoro al momento non è attiva.<br>Per l’abbonamento, contatta il tuo account manager Coro.",
        daysRemaining: "rimanenti per il periodo di prova gratuita",
      },
      allIssues: "Tutti i problemi",
      userDataGovernance: {
        title: "User Data Governance",
        noDataLossProtection:
          "Al momento non disponi di User Data Governance e di una protezione contro la perdita dei dati.",
        noDLPServicesConnected: "Collega Microsoft 365 o Google Workplace a Coro",
        objectsProcessed: "Oggetti dati elaborati",
        topViolators: "Utenti con violazioni delle autorizzazioni",
        last90Days: "90 giorni",
      },
      endpointDataGovernance: {
        title: "Endpoint Data Governance",
        noDataLossProtection:
          "Al momento non disponi di Endpoint Data Governance e una protezione contro la perdita dei dati.",
        noDLPServicesConnected:
          "Installa il client di Endpoint Security Coro sui dispositivi degli utenti.",
        objectsProcessed: "Oggetti dati elaborati",
        topViolators: "Utenti con violazioni delle autorizzazioni",
        last90Days: "90 giorni",
        addProtectionBtn: "Aggiungi protezione",
      },
      devices: {
        title: "Endpoint Security",
        chartLabel: "{n} dispositivo | {n} dispositivi",
        protectedDevices: "Dispositivi",
        noDevicesProtected: "Al momento non hai dispositivi protetti",
        installCoroClients:
          "Installa il client di Endpoint Security Coro sui dispositivi degli utenti.",
        addProtectionBtn: "Aggiungi protezione",
        protectedUsers: "Utenti protetti",
        last90Days: "90 giorni",
      },
      securityAwareness: {
        title: "Security Awareness Training",
        viewReport: "Visualizza il report",
        emailsSent: "E-mail di phishing inviate",
        trainingsSent: "Sessioni di formazione inviate",
        last90Days: "ultimi 90 giorni",
        adaptiveTrainings: "{n} sessioni di formazioni adattiva",
        topPhishedUsers:
          "<span class='subtitle2'>Utenti che hanno subito più attacchi phishing</span> <span class='body2'>(per simulazioni di interazioni)</span>",
        topUsersWithOverdueTraining:
          "<span class='subtitle2'>Utenti con formazione più in ritardo</span> <span class='body2'>(per corsi in attesa)</span>",
        emptyTitle:
          "Aggiungi utenti per attivare la formazione sulla consapevolezza della sicurezza",
        emptySubtitle:
          "Collega l'account Microsoft 365, Google Workspace o aggiungi i singoli utenti protetti.",
        addUsers: "+ Aggiungi utenti",
        connect: "Connetti le app cloud",
        noData: "[TRANSLATION] No data yet",
        labels: {
          reportedPhishing: "Contrassegnato come phishing: {n}",
          noAction: "Nessuna azione: {n}",
          clickedSimulations: "Simulazioni cliccate: {n}",
          toDo: "Da fare: {n}",
          overdue: "In ritardo: {n}",
          completed: "Completato: {n}",
        },
        training: {
          [SecurityAwarenessTraining.SECURITY]: "Formazione sulla sicurezza",
          [SecurityAwarenessTraining.PHISHING]: "Formazione sul phishing",
          [SecurityAwarenessTraining.COMPLIANCE_PCIDDS]: "Formazione sulla conformità (PCI DSS)",
          [SecurityAwarenessTraining.COMPLIANCE_GDPR]: "Formazione sulla conformità (GDPR)",
          [SecurityAwarenessTraining.COMPLIANCE_HIPPA]: "Formazione sulla conformità (HIPAA)",
          [SecurityAwarenessTraining.ADAPTIVE]: "Formazione adattiva",
        },
      },
      emailPhishing: {
        title: "Email Security",
        emailSecurityDisabled: "Email Security è disattivata al momento",
        enableEmailMonitoring:
          "Abilita il monitoraggio delle e-mail per verificare la presenza di malware e phishing.",
        enableEmailMonitoringBtn: "Abilita",
        noEmailServicesConnected: "L’applicazione di posta elettronica non è collegata",
        connectSecureCloud:
          "Al momento non disponi di una connessione a Microsoft 365 e/o a Google Workspace.",
        addProtectionBtn: "Aggiungi protezione",
        topSuspiciousDomains: "Le principali fonti di e-mail sospette",
        emailsScanned: "E-mail elaborata",
        maliciousEmails: "Dannoso",
        quarantined: "Messo in quarantena",
        blockedAndDiscarded: "Bloccato e rimosso",
        noSuspiciousDomainsFound: "Non sono stati trovati domini sospetti",
        suspiciousPhishing: "Contenuto e-mail sospetto",
        suspiciousMalware: "Malware negli allegati dell'e-mail",
        last90Days: "90 giorni",
      },
      cloudSecurity: {
        title: "Cloud Security",
        noAppsProtected: "Al momento nessuna delle tue applicazioni cloud è protetta",
        noAppsProtectedDescription:
          "Collega Microsoft 365 e/o Google Workspace per avviare la protezione",
        noIssuesFound: "Nessun problema individuato",
        notConnected: "Non connesso",
        connectionIncomplete: "Connessione incompleta",
        auditLogsAreDisabled: "I registri di controllo sono disabilitati",
        protectedUsers: "Utenti protetti",
        chartLabel: "{n} utente | {n} utenti",
        services: {
          boxEnforcement: "Box",
          googleDirectoryEnforcement: "Google",
          dropboxEnforcement: "Dropbox",
          office365Enforcement: "Microsoft 365",
          slackEnforcement: "Slack",
          salesforceEnforcement: "Salesforce",
          zoomEnforcement: "Zoom",
        },
      },
      disclaimerWidget: {
        title: "Non sono stati attivati moduli",
        regularWorkspaceDescription:
          "Contatta il reparto vendite Coro per abilitare i moduli desiderati.",
        mspWorkspaceDescription:
          "Contatta il fornitore di servizi per abilitare i moduli desiderati.",
        noAccess: "Non hai accesso a tutti i moduli",
      },
      edr: {
        title: "EDR",
        processes: "Processi",
        topProcesses: "I processi più influenti",
        last30Days: "30 giorni",
      },
    },
  },
  comments: {
    title: "Commenti",
    sentTo: "Notifiche inviate a:",
    notifySocContacts: "Contatti dei servizi gestiti",
    notifyAllWorkspaceAdmins: "Tutti gli amministratori dell’area di lavoro",
    notifyAffectedUsers: "Tutti gli utenti interessati",
    plusNComments: "+ {n} commenti",
    confidential: "Riservato",
    noPermission: "Non disponi dell’autorizzazione per commentare questa segnalazione",
    unread: "Non letto",
    read: "Letto",
    markAsRead: "Segna come letto",
    markAsUnread: "Segna come non letto",
    noComments: "Non ci sono commenti",
  },
  phishingEmailDetails: {
    emailBodyEmpty: "Corpo e-mail vuoto",
  },

  processGraph: {
    backToTicket: "Torna alla segnnalazione",
    relatedTicket: "Segnnalazione correlat",
    pid: "PID",
    relatedUser: "Utente collegato",
    executionTime: "Tempo di esecuzione",
    commandLineArguments: "Argomenti della riga di comando",
    persistence: "Persistenza",
    fileInformation: "Informazioni sul file",
    imageFileInformation: "Posizione del file immagine",
    shaHash: "Hash SHA256",
    fileSize: "Dimensioni del file",
    fileCreationTime: "Tempo di creazione del file",
    lastModifiedTime: "Orario ultima modifica",
    signatureStatus: "Stato della firma",
    verificationStatus: "Stato della verifica",
    anchorProcess: "Processo di allineamento",
    processStatus: "Processo {status} in {date}",
  },
  mspUsersPage: {
    title: "Utenti globali protetti",
    statuses: {
      suspended: "Sospeso",
      unresolvedTickets: "Segnalazioni non risolte",
      nIssues: "{n} problemi",
    },
    filters: {
      withIssues: "Con problemi",
      noIssues: "Nessun problema",
      all: "Tutto",
    },
  },
  mspControlPanel: {
    title: "Impostazioni globali",
    management: "Gestione",
    accessControl: "Controllo degli accessi",
    workspaces: "Aree di lavoro",
    workspaceLabels: "Etichette dell'area di lavoro",
  },
  mspAccessControlPage: {
    title: "Controllo degli accessi",
    tabs: {
      mspAdminUsers: "Utenti amministratori MSP",
      mspRoles: "Ruoli MSP",
    },
  },
  mspTicketsPage: {
    title: "Registro globale dei ticket",
  },
  mspLabelsPage: {
    title: "Etichette dell'area di lavoro",
    addLabel: "Aggiungi etichetta",
    workspaceCount: "N. di aree di lavoro",
  },
  selection: {
    [SelectorValue.SELECT_ALL_PAGES]: "Seleziona gli elementi in tutte le pagine",
    [SelectorValue.DESELECT_ALL_PAGES]: "Deseleziona gli elementi in tutte le pagine",
  },
  trialCountdownNotice: {
    trialPeriodFinishes: "Il tuo periodo di prova termina tra <b>{time}</b>",
    trialPeriodExpired: "Il tuo periodo di prova è <b>scaduto</b>",
    subscriptionExpired: "Abbonamento Scaduto",
    regularSubscriptionExpiredDescription:
      "Il tuo workspace è attualmente inattivo.<br>Per l'abbonamento, contatta il tuo account manager di Coro.",
    mspSubscriptionExpiredDescription:
      "Il tuo workspace è attualmente inattivo.<br>Per l'abbonamento, contatta il tuo fornitore di servizi.",
    daysRemaining: "restanti nella tua prova gratuita",
  },
  demoCountdownNotice: {
    demoModeFinishes: "La modalità demo termina tra <b>{time}</b>",
    startYourTrial: "Inizia la tua prova ora",
    afterExploringDemo:
      "Dopo aver esplorato la demo, <b>fai clic qui per iniziare la tua prova gratuita e sbloccare l'accesso completo.</b>",
    understood: "Capito",
  },
  mspDashboard: {
    widgets: {
      generatedTicketsWidget: {
        title: "[TRANSLATION] Generated Tickets / 90d",
        open: "[TRANSLATION] Open",
        closed: "[TRANSLATION] Closed / 90d",
        error: "[TRANSLATION] Couldn't load tickets data",
      },
      protectedUsersWidget: {
        title: "[TRANSLATION] Protected Users",
        suspended: "[TRANSLATION] Suspended",
        withIssues: "[TRANSLATION] With Issues",
        noIssues: "[TRANSLATION] No Issues",
        error: "[TRANSLATION] Couldn't load users data",
      },
      protectedDevicesWidget: {
        title: "[TRANSLATION] Protected Devices",
        isolated: "[TRANSLATION] Isolated",
        withIssues: "[TRANSLATION] With Issues",
        noIssues: "[TRANSLATION] No Issues",
        error: "[TRANSLATION] Couldn't load devices data",
      },
      ticketsBreakdownWidget: {
        ticketsPerModule: "[TRANSLATION] Tickets per module",
        ticketsPerWorkspace: "[TRANSLATION] Tickets per workspace",
        error: "[TRANSLATION] Couldn't load tickets data",
      },
      workspacesStatsWidget: {
        error: "[TRANSLATION] Couldn't load workspaces data",
        statuses: {
          [SubscriptionType.FREEZE]: "[TRANSLATION] Inactive",
          [SubscriptionType.SUBSCRIPTION]: "[TRANSLATION] Subscription",
          [SubscriptionType.TRIAL]: "[TRANSLATION] Trial",
          [SubscriptionType.INITIAL]: "[TRANSLATION] New",
          [SubscriptionType.ARCHIVED]: "[TRANSLATION] Archived",
          nearExpiration: "[TRANSLATION] Near expiration",
        },
      },
    },
  },
};
