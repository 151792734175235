export enum TicketType {
  MALWARE_IN_CLOUD_DRIVE = "malwareInCloudDrive",
  ABNORMAL_ADMIN_ACTIVITY = "abnormalAdminActivity",
  ACCESS_PERMISSIONS_VIOLATION = "accessRestrictionsViolation",
  SUSPECTED_IDENTITY_COMPROMISE = "suspectedIdentityCompromise",
  MALWARE_IN_DEVICE = "malwareInDevice",
  ENDPOINT_VULNERABILITY = "endpointVulnerability",
  USER_INACTIVITY = "userInactivity",
  /**
   *  @deprecated
   */
  MALWARE_IN_EMAIL_ATTACHMENTS = "malwareInEmailAttachment",
  EMAIL_PHISHING = "emailPhishing",
  WIFI_PHISHING = "wifiPhishing",
  ABNORMAL_DATA_MANIPULATION = "abnormalDataManipulation",
  SUSPECTED_BOT_ATTACKS = "suspectedBotAttacks",
  SUSPECTED_DATA_EXPOSURE_BY_EMAIL = "suspectedExposureOfCriticalDataByEmail",
  SUSPECTED_DATA_EXPOSURE_BY_SHARING = "suspectedExposureOfCriticalDataBySharing",
  SUSPECTED_COMPLIANCE_VIOLATION_BY_EMAIL = "suspectedDataComplianceViolationByEmail",
  SUSPECTED_COMPLIANCE_VIOLATION_BY_SHARING = "suspectedDataComplianceViolationBySharing",
  SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE = "suspectedDataComplianceViolationByDevice",
  WIFI_FORBIDDEN_NETWORK = "wifiForbiddenNetwork",
  EDR_DETECTION = "edrDetection",
  SIMULATED_EMAIL_PHISHING = "simulatedEmailPhishing",
  SECURITY_TRAINING_FAILURE = "securityTrainingFailure",
  PROXY_MISCONFIGURATION = "proxyMisconfiguration",
}

export enum TicketStatus {
  OPEN = "open",
  CLOSED = "closed",
  ALL = "all",
}

export enum TicketActionSeverity {
  LOW = "lowSeverity",
  MEDIUM = "mediumSeverity",
  CRITICAL = "criticalSeverity",
}

export enum TicketAction {
  TREAT_FILE_AS_SAFE = "treatFileAsSafe",
  TREAT_FILE_AS_MALICIOUS = "treatFileAsMalicious",
  EXCLUDE_FOLDER_FROM_SCAN = "excludeFolderFromScan",
  EXCLUDE_CERTIFICATE_FROM_SCAN = "excludeCertificateFromScan",
  EXCLUDE_FILE_PATH_AND_CERTIFICATE_FROM_SCAN = "excludeFilePathAndCertificateFromScan",
  EXCLUDE_FILE_PATH_AND_FILE_HASH_FROM_SCAN = "excludeFilePathAndFileHashFromScan",
  MARK_AS_PROCESSED = "markAsProcessed",
  EXPORT_CSV = "exportCsv",
  MARK_AS_UNPROCESSED = "markAsUnprocessed",
  SUSPEND_FROM_ALL = "suspendFromAll",
  SUSPEND_FROM_SERVICE = "suspendFromService",
  SIGN_IN_TO_ALL = "signInToAll",
  SIGN_IN_TO_SERVICE = "signInToService",
  REMOTE_SCAN = "remoteScan",
  ENABLE_FIREWALL = "enableFirewall",
  ENFORCE_UAC = "enforceUAC",
  DISABLE_DEVELOPER_MODE = "disableDevMode",
  REMOVE_EXPOSING_SHARING = "removeExposingSharing",
  CONTACT_USER = "contactUser",
  ALLOW_NO_ENCRYPTION = "allowNoEncryption",
  APPROVE_PROCESS_GROUP = "approveProcessGroup",
  DISCARD_INFECTING_FILE = "discardInfectingFile",
  APPROVE_FILE = "approveFile",
  DELETE_FILE = "deleteFile",
  LOG_FOR_AUDIT_REPORTS = "logForAuditReports",
  UN_LOG_FOR_AUDIT_REPORTS = "unLogForAuditReports",
  ENCRYPT_DRIVE = "encryptDrive",
  ADD_TO_DATA_GOVERNANCE_PERMISSIONS = "addToDataGovernancePermissions",
  ENABLE_GATEKEEPER = "enableGatekeeper",
  ENABLE_APPLE_MOBILE_FILE_INTEGRITY = "enableAppleMobileFileIntegrity",
  EXPORT_MASS_DOWNLOAD_FILES = "exportMassDownloadFiles",
  EXPORT_MASS_DELETE_FILES = "exportMassDeleteFiles",
  APPROVE_EMAILS_FROM_DOMAIN = "approveEmailsFromDomain",
  DISCARD_EMAILS_FROM_DOMAIN = "discardEmailsFromDomain",
  APPROVE_EMAILS_FROM_SENDER = "approveEmailsFromSender",
  DISCARD_EMAILS_FROM_SENDER = "discardEmailsFromSender",
  APPROVE_EMAIL = "approveEmail",
  DISCARD_EMAIL = "discardEmail",
  DOWNLOAD_EML_FILE = "downloadEmlFile",
  APPROVE_EMAIL_WITH_OPTIONS = "approveEmailWithOptions",
  DISCARD_EMAIL_WITH_OPTIONS = "discardEmailWithOptions",
  GENERAL_APPROVE = "generalApprove",
  APPROVE_IP = "approveEmailsFromSenderIp",
  DISCARD_IP = "discardEmailsFromSenderIp",
  ALLOW_PROCESS = "allowProcess",
  CONFIGURE_PROXY = "configureProxy",
  MAKE_USER_ACTIVE = "makeUserActive",
  TEST_MX_RECORD = "coroPrimaryMxRecordTest",
}

export const ticketTypeIcons: Record<TicketType, string> = {
  [TicketType.MALWARE_IN_CLOUD_DRIVE]: "malwareInCloud",
  [TicketType.ABNORMAL_ADMIN_ACTIVITY]: "abnormalAdminActivity",
  [TicketType.ACCESS_PERMISSIONS_VIOLATION]: "accessViolation",
  [TicketType.SUSPECTED_IDENTITY_COMPROMISE]: "identityCompromise",
  [TicketType.MALWARE_IN_DEVICE]: "malwareEndpoint",
  [TicketType.ENDPOINT_VULNERABILITY]: "endpointVulnerability",
  [TicketType.MALWARE_IN_EMAIL_ATTACHMENTS]: "malwareEmail",
  [TicketType.EMAIL_PHISHING]: "emailPhishing",
  [TicketType.WIFI_PHISHING]: "wifiPhishing",
  [TicketType.SUSPECTED_BOT_ATTACKS]: "multipleFailedLogin",
  [TicketType.SUSPECTED_DATA_EXPOSURE_BY_EMAIL]: "dlpEmail",
  [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_EMAIL]: "dlpEmail",
  [TicketType.SUSPECTED_DATA_EXPOSURE_BY_SHARING]: "dlpFile",
  [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_SHARING]: "dlpFile",
  [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE]: "fileDevice",
  [TicketType.WIFI_FORBIDDEN_NETWORK]: "wifiForbidden",
  [TicketType.EDR_DETECTION]: "sigmaMatch",
  [TicketType.ABNORMAL_DATA_MANIPULATION]: "",
  [TicketType.USER_INACTIVITY]: "inactiveUser",
  [TicketType.SIMULATED_EMAIL_PHISHING]: "phishing",
  [TicketType.SECURITY_TRAINING_FAILURE]: "securityTraining",
  [TicketType.PROXY_MISCONFIGURATION]: "secureMessagesSmall",
};

export enum TicketTrigger {
  // relates to malwareInCloudDrive event type
  MALWARE_IN_CLOUD_DRIVE = "malwareInCloudDrive",
  // relates to abnormalAdminActivity event type
  ABNORMAL_ADMIN_ACTIVITY = "abnormalAdminActivity",
  // relates to accessRestrictionsViolation event type
  ACCESS_PERMISSIONS_VIOLATION = "accessRestrictionsViolation",
  // relates to suspectedIdentityCompromise event type
  SUSPECTED_IDENTITY_COMPROMISE = "suspectedIdentityCompromise",
  // relates to malwareOnEndpoint event type
  MALWARE_ON_ENDPOINT = "malwareOnEndpoint",
  INFECTED_PROCESS = "infectedProcess",
  // relates to endpointVulnerability event type
  UAC_NOTIFICATIONS_MISSING = "uacNotificationMissing",
  DEVICE_PASSWORD_MISSING = "missingPinAndPasswordProtection",
  ENCRYPTION_DISABLED = "isUnencrypted",
  MOCK_LOCATION_ENABLED = "isMockLocationEnabled",
  ANTI_VIRUS_DISABLED = "antiVirusUnavailable",
  DEVELOPMENT_MODE_ENABLED = "isDeveloperModeEnabled",
  FIREWALL_DISABLED = "firewallUnavailable",
  APPS_FROM_UNKNOWN_SOURCES = "appsFromUnknownSourcesAllowed",
  NON_GENUINE_WINDOWS = "isNonGenuineWindows",
  ACTIVEX_CODE_EXECUTION_ENABLED = "activeXCodeExecutionEnabled",
  REMOTE_CODE_EXECUTION_ENABLED = "remoteCodeExecutionEnabled",
  CRITICAL_UPDATES_NOT_INSTALLED = "criticalUpdatesNotInstalled",
  EXECUTION_POLICY_ENABLED = "executionPolicyEnabled",
  VSS_BACKUP_PROTECTION = "vssBackupProtection",
  GATEKEEPER_UNAVAILABLE = "gatekeeperUnavailable",
  APPLE_MOBILE_FILE_INTEGRITY_UNAVAILABLE = "appleMobileFileIntegrityUnavailable",
  SYSTEM_INTEGRITY_PROTECTION_UNAVAILABLE = "systemIntegrityProtectionUnavailable",
  USB_LOCKDOWN = "usbLockdown",
  USER_INACTIVITY = "userInactivity",
  AUTOMATIC_UPDATE_DISABLED = "automaticUpdateDisabled",
  // relates to malwareInEmailAttachment event type
  MALWARE_IN_EMAIL_ATTACHMENTS = "malwareInEmailAttachment",
  // relates to emailPhishing event type
  /**
   *  @deprecated
   */
  EMAIL_PHISHING = "emailPhishing",
  MISSING_REQUIRED_AUTHENTICATION = "missingRequiredAuthentication",
  BLOCKLISTED_SENDER = "blocklistedSender",
  CROWDBLOCKED_SENDER = "crowdblockedSender",
  USER_IMPERSONATION = "userImpersonation",
  DOMAIN_IMPERSONATION = "domainImpersonation",
  BRAND_IMPERSONATION = "brandImpersonation",
  SUSPICIOUS_CONTENT = "suspiciousContent",
  SPAM = "spam",
  SUSPICIOUS_METADATA = "suspiciousMetadata",
  FORBIDDEN_ATTACHMENT_TYPE = "forbiddenAttachmentType",
  DOMAIN_SPOOFING = "domainSpoofing",
  REPORTED_BY_USER = "reportedByUser",
  UNVERIFIED_SENDER = "unverifiedSender",
  // relates to wifiPhishing event type
  WIFI_PHISHING = "wifiPhishing",
  // relates to abnormalDataManipulation event type
  ABNORMAL_DATA_MANIPULATION = "abnormalDataManipulation",
  MASS_DELETE = "massDelete",
  MASS_DOWNLOAD = "massDownload",
  // relates to suspectedBotAttacks event type
  SUSPECTED_BOT_ATTACKS = "suspectedBotAttacks",
  SUSPICIOUS_FILE_DOWNLOAD = "suspiciousFileDownload",
  // relates to suspectedExposureOfCriticalDataByEmail and suspectedExposureOfCriticalDataBySharing event types
  DLP_PHI = "dlpPhi",
  DLP_PCI = "dlpPci",
  DLP_PII = "dlpPii",
  DLP_NPI = "dlpNpi",
  DLP_NON_PUBLIC = "dlpNonPublicData",
  DLP_PERSONAL = "dlpPersonalData",
  DLP_HEALTH = "dlpHealthData",
  DLP_CREDIT_CARD = "dlpCreditCardData",
  DEVICE_DLP_PHI = "deviceDlpPhi",
  DEVICE_DLP_PCI = "deviceDlpPci",
  DEVICE_DLP_PII = "deviceDlpPii",
  DEVICE_DLP_NPI = "deviceDlpNpi",
  DEVICE_DLP_NON_PUBLIC = "deviceDlpNonPublicData",
  DEVICE_DLP_PERSONAL = "deviceDlpPersonalData",
  DEVICE_DLP_HEALTH = "deviceDlpHealthData",
  DEVICE_DLP_CREDIT_CARD = "deviceDlpCreditCardData",
  // relates to suspectedDataComplianceViolationByEmail and suspectedDataComplianceViolationBySharing event types
  CRITICAL_DATA_KEYWORDS = "criticalDataKeywords",
  CRITICAL_DATA_SOURCE_CODE = "criticalDataSourceCode",
  CRITICAL_DATA_CERTIFICATE = "criticalDataCertificate",
  CRITICAL_DATA_PASSWORD = "criticalDataPassword",
  CRITICAL_DATA_FILE_TYPES = "criticalDataFileTypes",
  MALWARE_DETECTION = "malwareDetection",
  // relates to wifiForbiddenNetwork event type
  FORBIDDEN_NETWORK_CONNECTION = "forbiddenNetworkConnection",
  // EDR
  EDR_DETECTION_SUSPICIOUS_PROCESS = "edrDetectionSuspiciousProcess",
  EDR_DETECTION_COLLECTION = "edrDetectionCollection",
  EDR_DETECTION_COMMAND_AND_CONTROL = "edrDetectionCommandAndControl",
  EDR_DETECTION_CREDENTIAL_ACCESS = "edrDetectionCredentialAccess",
  EDR_DETECTION_DEFENCE_EVASION = "edrDetectionDefenseEvasion",
  EDR_DETECTION_EXECUTION = "edrDetectionExecution",
  EDR_DETECTION_DISCOVERY = "edrDetectionDiscovery",
  EDR_DETECTION_EXFILTRATION = "edrDetectionExfiltration",
  EDR_DETECTION_IMPACT = "edrDetectionImpact",
  EDR_DETECTION_INITIAL_ACCESS = "edrDetectionInitialAccess",
  EDR_DETECTION_LATERAL_MOVEMENT = "edrDetectionLateralMovement",
  EDR_DETECTION_PERSISTENCE = "edrDetectionPersistence",
  EDR_DETECTION_PRIVILEGE_ESCALATION = "edrDetectionPrivilegeEscalation",
  EDR_DETECTION_RECONNAISSANCE = "edrDetectionReconnaissance",
  EDR_DETECTION_RESOURCE_DEVELOPMENT = "edrDetectionResourceDevelopment",
  // proxyMisconfiguration
  INBOUND_GATEWAY_MX_RECORD_MISCONFIGURATION = "inboundGatewayMxRecordMisconfiguration",
}

export const ticketTriggerIcons: Partial<Record<TicketTrigger, string>> = {
  [TicketTrigger.MASS_DOWNLOAD]: "massDownload",
  [TicketTrigger.MASS_DELETE]: "massDelete",
  [TicketTrigger.ABNORMAL_DATA_MANIPULATION]: "abnormalDataManipulation",
};

export enum TicketsModuleFilter {
  DEVICE_WIDGET = "deviceSecurityWidget",
  CLOUD_SECURITY_WIDGET = "cloudSecurity",
  EMAILS_WIDGET = "emailsWidget",
  USER_DATA_GOVERNANCE = "userDataGovernance",
  ENDPOINT_DATA_GOVERNANCE = "endpointDataGovernance",
  EDR = "edr",
}

export const eventTriggerFilterMap: Record<TicketsModuleFilter, TicketTrigger[]> = {
  [TicketsModuleFilter.DEVICE_WIDGET]: [
    TicketTrigger.UAC_NOTIFICATIONS_MISSING,
    TicketTrigger.DEVICE_PASSWORD_MISSING,
    TicketTrigger.ENCRYPTION_DISABLED,
    TicketTrigger.DEVELOPMENT_MODE_ENABLED,
    TicketTrigger.FIREWALL_DISABLED,
    TicketTrigger.NON_GENUINE_WINDOWS,
    TicketTrigger.MALWARE_ON_ENDPOINT,
    TicketTrigger.INFECTED_PROCESS,
    TicketTrigger.VSS_BACKUP_PROTECTION,
    TicketTrigger.FORBIDDEN_NETWORK_CONNECTION,
    TicketTrigger.GATEKEEPER_UNAVAILABLE,
    TicketTrigger.APPLE_MOBILE_FILE_INTEGRITY_UNAVAILABLE,
    TicketTrigger.SYSTEM_INTEGRITY_PROTECTION_UNAVAILABLE,
    TicketTrigger.WIFI_PHISHING,
  ],
  [TicketsModuleFilter.CLOUD_SECURITY_WIDGET]: [
    TicketTrigger.ABNORMAL_ADMIN_ACTIVITY,
    TicketTrigger.SUSPECTED_IDENTITY_COMPROMISE,
    TicketTrigger.MALWARE_IN_CLOUD_DRIVE,
    TicketTrigger.ACCESS_PERMISSIONS_VIOLATION,
    TicketTrigger.SUSPECTED_BOT_ATTACKS,
    TicketTrigger.MASS_DOWNLOAD,
    TicketTrigger.MASS_DELETE,
    TicketTrigger.USER_INACTIVITY,
  ],
  [TicketsModuleFilter.EMAILS_WIDGET]: [
    TicketTrigger.EMAIL_PHISHING,
    TicketTrigger.MISSING_REQUIRED_AUTHENTICATION,
    TicketTrigger.BLOCKLISTED_SENDER,
    TicketTrigger.CROWDBLOCKED_SENDER,
    TicketTrigger.USER_IMPERSONATION,
    TicketTrigger.DOMAIN_IMPERSONATION,
    TicketTrigger.BRAND_IMPERSONATION,
    TicketTrigger.SUSPICIOUS_CONTENT,
    TicketTrigger.SPAM,
    TicketTrigger.SUSPICIOUS_METADATA,
    TicketTrigger.FORBIDDEN_ATTACHMENT_TYPE,
    TicketTrigger.DOMAIN_SPOOFING,
    TicketTrigger.REPORTED_BY_USER,
    TicketTrigger.MALWARE_IN_EMAIL_ATTACHMENTS,
    TicketTrigger.INBOUND_GATEWAY_MX_RECORD_MISCONFIGURATION,
  ],
  [TicketsModuleFilter.USER_DATA_GOVERNANCE]: [
    TicketTrigger.DLP_PII,
    TicketTrigger.DLP_PHI,
    TicketTrigger.DLP_PCI,
    TicketTrigger.DLP_NPI,
    TicketTrigger.CRITICAL_DATA_PASSWORD,
    TicketTrigger.CRITICAL_DATA_CERTIFICATE,
    TicketTrigger.CRITICAL_DATA_SOURCE_CODE,
    TicketTrigger.CRITICAL_DATA_KEYWORDS,
    TicketTrigger.CRITICAL_DATA_FILE_TYPES,
  ],
  [TicketsModuleFilter.ENDPOINT_DATA_GOVERNANCE]: [
    TicketTrigger.DEVICE_DLP_NPI,
    TicketTrigger.DEVICE_DLP_PCI,
    TicketTrigger.DEVICE_DLP_PHI,
    TicketTrigger.DEVICE_DLP_PII,
  ],
  [TicketsModuleFilter.EDR]: [
    // EventTrigger.EDR_DETECTION_IMPACT,
    TicketTrigger.EDR_DETECTION_COMMAND_AND_CONTROL,
    // EventTrigger.EDR_DETECTION_COLLECTION,
    TicketTrigger.EDR_DETECTION_CREDENTIAL_ACCESS,
    TicketTrigger.EDR_DETECTION_DEFENCE_EVASION,
    TicketTrigger.EDR_DETECTION_DISCOVERY,
    TicketTrigger.EDR_DETECTION_EXECUTION,
    // EventTrigger.EDR_DETECTION_EXFILTRATION,
    TicketTrigger.EDR_DETECTION_INITIAL_ACCESS,
    // EventTrigger.EDR_DETECTION_LATERAL_MOVEMENT,
    TicketTrigger.EDR_DETECTION_PERSISTENCE,
    TicketTrigger.EDR_DETECTION_PRIVILEGE_ESCALATION,
    // EventTrigger.EDR_DETECTION_RECONNAISSANCE,
    // EventTrigger.EDR_DETECTION_RESOURCE_DEVELOPMENT,
  ],
};

export enum ViolationDirection {
  SHARE = "share",
  ACCESS = "access",
}

export enum CommentAction {
  ADD_COMMENT = "addNewComment",
  BULK_ADD_COMMENT = "bulkAddComment",
  MARK_AS_READ = "markAsRead",
  MARK_AS_UNREAD = "markAsUnread",
}

export enum SocStatus {
  NEW = "new",
  WIP_TIER1 = "wipTier1",
  WIP_TIER2 = "wipTier2",
  REQUIRES_TIER2 = "requiresTier2",
  NEW_COMMENTS = "newComments",
}

export const socStatusColorMap: Record<SocStatus, string> = {
  [SocStatus.NEW]: "rgb(var(--v-theme-labels-green-full))",
  [SocStatus.WIP_TIER1]: "rgb(var(--v-theme-labels-blue-full))",
  [SocStatus.WIP_TIER2]: "rgb(var(--v-theme-labels-purple-full))",
  [SocStatus.REQUIRES_TIER2]: "rgb(var(--v-theme-labels-orange-full))",
  [SocStatus.NEW_COMMENTS]: "", // empty on purpose
};

export enum TicketsModal {
  IPS_MODAL = "ipsModal",
  BSSID_MODAL = "bssidModal",
  DLP_FINDINGS_MODAL = "dlpFindings",
  SHARED_WITH = "sharedWith",
  RECIPIENTS = "recipients",
  USERS = "users",
  CONTENTS = "contents",
}

export enum AnomalyIpThreatLevel {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}
