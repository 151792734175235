<template>
  <div>
    <div class="mb-4">
      <div class="subtitle1">{{ $t("general.customer") }}</div>
      <div class="body1">
        {{ ticketDetails.workspaceId }}
      </div>
      <div class="body1 coro-link mb-4" @click="onWorkspaceNameClick()">
        {{ ticketDetails.workspaceName }}
      </div>
    </div>
    <component
      :is="generalInfoComponent"
      :ticket-details="ticketDetails"
      :is-soc="false"
    ></component>
  </div>
</template>

<script lang="ts">
import { type Component, computed, defineComponent, type PropType } from "vue";
import { TicketType } from "@/constants/tickets";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes.ts";
import SecurityAwarenessInfo from "@/components/tickets/ticket-preview-details/SecurityAwarenessInfo.vue";
import CloudAppTicketInfo from "@/components/tickets/ticket-preview-details/CloudAppTicketInfo.vue";
import DeviceTicketInfo from "@/components/tickets/ticket-preview-details/DeviceTicketInfo.vue";
import EmailTicketInfo from "@/components/tickets/ticket-preview-details/EmailTicketInfo.vue";
import UserTicketInfo from "@/components/tickets/ticket-preview-details/UserTicketInfo.vue";
import DataTicketInfo from "@/components/tickets/ticket-preview-details/DataTicketInfo.vue";
import EdrTicketInfo from "@/components/tickets/ticket-preview-details/EdrTicketInfo.vue";
import type { MspTicketDetails } from "@/_store/msp/tickets/msp-tickets.module.ts";
import MspTicketDeviceInfo from "@/components/msp/tickets/MspTicketDeviceInfo.vue";
import MspTicketEdrInfo from "@/components/msp/tickets/MspTicketEdrInfo.vue";
import ProxyMisconfigurationInfo from "@/components/tickets/ticket-preview-details/ProxyMisconfigurationInfo.vue";

const deviceTicketTypes = [
  TicketType.MALWARE_IN_DEVICE,
  TicketType.ENDPOINT_VULNERABILITY,
  TicketType.WIFI_PHISHING,
  TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE,
  TicketType.WIFI_FORBIDDEN_NETWORK,
];

const userTicketTypes = [
  TicketType.ACCESS_PERMISSIONS_VIOLATION,
  TicketType.ABNORMAL_DATA_MANIPULATION,
  TicketType.SUSPECTED_BOT_ATTACKS,
  TicketType.ABNORMAL_ADMIN_ACTIVITY,
  TicketType.SUSPECTED_IDENTITY_COMPROMISE,
  TicketType.USER_INACTIVITY,
];

const cloudAppTicketTypes = [TicketType.MALWARE_IN_CLOUD_DRIVE];

const dataTicketTypes = [
  TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_EMAIL,
  TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_SHARING,
  TicketType.SUSPECTED_DATA_EXPOSURE_BY_EMAIL,
  TicketType.SUSPECTED_DATA_EXPOSURE_BY_SHARING,
];

const securityAwarenessTypes = [
  TicketType.SIMULATED_EMAIL_PHISHING,
  TicketType.SECURITY_TRAINING_FAILURE,
];

const emailTicketTypes = [TicketType.MALWARE_IN_EMAIL_ATTACHMENTS, TicketType.EMAIL_PHISHING];

const edrTicketTypes = [TicketType.EDR_DETECTION];

const proxyMisconfigurationTicketTypes = [TicketType.PROXY_MISCONFIGURATION];

export default defineComponent({
  components: {
    CloudAppTicketInfo,
    DeviceTicketInfo,
    EmailTicketInfo,
    UserTicketInfo,
    DataTicketInfo,
    EdrTicketInfo,
    SecurityAwarenessInfo,
    ProxyMisconfigurationInfo,
  },
  props: {
    ticketDetails: {
      type: Object as PropType<MspTicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const generalInfoComponent = computed<Component | null>(() => {
      const typeMap: Array<{ types: TicketType[]; infoComponent: Component }> = [
        { types: deviceTicketTypes, infoComponent: MspTicketDeviceInfo },
        { types: emailTicketTypes, infoComponent: EmailTicketInfo },
        { types: cloudAppTicketTypes, infoComponent: CloudAppTicketInfo },
        { types: userTicketTypes, infoComponent: UserTicketInfo },
        { types: dataTicketTypes, infoComponent: DataTicketInfo },
        { types: edrTicketTypes, infoComponent: MspTicketEdrInfo },
        { types: securityAwarenessTypes, infoComponent: SecurityAwarenessInfo },
        { types: proxyMisconfigurationTicketTypes, infoComponent: ProxyMisconfigurationInfo },
      ];

      for (const { types, infoComponent } of typeMap) {
        if (types.includes(props.ticketDetails.eventType)) {
          return infoComponent;
        }
      }

      return null;
    });

    const onWorkspaceNameClick = async () => {
      await router.push({
        name: RouteName.DASHBOARD,
        query: { workspaceId: props.ticketDetails.workspaceId },
      });
    };

    return {
      generalInfoComponent,
      onWorkspaceNameClick,
    };
  },
});
</script>
