import {
  SubscriptionBundle,
  SubscriptionModule,
  SubscriptionsFilterType,
  SubscriptionType,
  WorkspaceLocale,
  WorkspaceType,
} from "@/constants/workplaces.ts";
import { useAccountStore, useSnackbarStore } from "@/_store";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment/moment";
import { createAbortController } from "@/_helpers/utils.ts";
import { defineStore } from "pinia";
import { type MspFilters, useFiltersStore } from "@/_store/filters.module.ts";
import { AxiosError, type AxiosRequestConfig } from "axios";
import { axiosInstance } from "@/plugins/https.ts";
import api from "@/_helpers/api.ts";
import { i18n } from "@/plugins/i18n.ts";
import { useWorkspacesStore, type WorkspaceListItem } from "@/_store/workspaces.module.ts";
import type { DialogDataConfig } from "@/_store/dialogs.module.ts";
import { type ModulesSettings, useSubscriptionStore } from "@/_store/subscription.module.ts";
import type { Paged, Pagination } from "@/types.ts";
import type { MspLabel } from "@/_store/msp/msp-labels.module.ts";
import isEmpty from "lodash/isEmpty";

let abortController: AbortController | undefined;

export interface MspWorkspaceDialogItem extends MspWorkspaceDetails {
  maxProtectedUsers?: number;
  image?: File;
  emailImage?: File;
  parentWorkspaceId?: string;
  endDate?: number;
  adminEmail?: string;
}

export interface MspHierarchyItem {
  workspaceId: string;
  workspaceName: string;
}

export interface MspWorkspaceListItem {
  name: string;
  displayName?: string;
  workspaceId: string;
  type: WorkspaceType;
  protectedUsers: number;
  protectableUsers: number;
  mfaRequired: boolean;
  protectedDevices: number;
  subscription: Subscription;
  subscriptionType: SubscriptionType;
  since: number;
  creationTime: number;
  canBeArchived: boolean;
  parentName?: string;
  ticketsCount: number;
  labels: MspLabel[];
}

export interface MspWorkspaceDetails extends MspWorkspaceListItem {
  assignedAdmins: AssignedAdmin[];
  domain: string;
  companyName: string;
  notificationsLevel: NotificationsLevel;
  lastActivity?: number;
  supportEnabled: boolean;
  psaEnabled?: boolean;
  scanUnprotectedUsers: boolean;
  parentScanUnprotectedUsers: boolean;
  branding: Branding;
  showDisabledModules: boolean;
  isCoroEmployeeWorkspace?: boolean;
  parentDomain?: string;
  parentPsaEnabled?: boolean;
  languageCode: WorkspaceLocale;
}

export interface AssignedAdmin {
  roleId: RoleID;
  email: string;
}

export interface RoleID {
  timestamp: number;
  date: Date;
}

export interface Branding {
  headerIconUrl?: string;
  brandColor?: string;
  alias?: string;
  noReplyEmail?: string;
  emailIconUrl?: string;
  supportEmail?: string;
  companyAddress?: string;
}

export enum NotificationsLevel {
  ALL = "all",
  NONE = "none",
  PARENT = "parent",
  /**
   * This option is applicable only to Child workspaces or Channels that have parentWorkspaceId.
   */
  LOCAL_ADMINS = "localAdmins",
}

export interface Subscription {
  modules: ModulesSettings;
  bundles: SubscriptionBundle[];
}

interface MspModuleState {
  pagination: Pagination;
  pages: number;
  total: number;
  workspaces: MspWorkspaceListItem[];
  showSkeletonLoader: boolean;
  createWorkspaceInProgress: boolean;
  createWorkspaceCompleted: boolean;
  createWorkspaceFailed: boolean;
  loading: boolean;
  hasMspAccess: boolean;
}

export type ParentWorkspaceSearchPermission =
  | "ADD_CHANNEL_ADMINS"
  | "ADD_CHANNEL_ROLES"
  | "CREATE_CHILD_WORKSPACE"
  | "CREATE_CHANNEL_WORKSPACE";

const defaultState: MspModuleState = {
  pagination: {
    page: 0,
    pageSize: 25,
  },
  pages: 0,
  total: 0,
  workspaces: [],
  showSkeletonLoader: false,
  createWorkspaceInProgress: false,
  createWorkspaceCompleted: false,
  createWorkspaceFailed: false,
  loading: false,
  hasMspAccess: false,
};

export interface EditSubscriptionData extends Subscription {
  workspaceIds: string[];
  modulesRemovedDuringEdit: Array<SubscriptionModule>;
}

export const useMspStore = defineStore("msp", {
  state: () => ({ ...defaultState }),
  actions: {
    resetPagination() {
      this.pagination = { ...defaultState.pagination };
    },
    async getMspWorkspaces(
      payload: { showSkeletonLoader?: boolean; triggeredByFilters?: boolean } = {
        showSkeletonLoader: false,
        triggeredByFilters: false,
      }
    ) {
      abortController = createAbortController(abortController);
      this.showSkeletonLoader = payload.showSkeletonLoader ?? false;
      this.loading = true;

      if (payload.triggeredByFilters) {
        this.pagination = { page: 0, pageSize: 25 };
      }
      const filtersStore = useFiltersStore();
      const { addons, types, subscriptionTypes, modules, bundles, search, labels, page, pageSize } =
        {
          ...getMspFilters(filtersStore.filters.mspFilters),
          ...this.pagination,
        };
      const request: AxiosRequestConfig = {
        ...api.mspWorkspaces(),
        params: {
          addons: addons.join(","),
          types: types.join(","),
          subscriptionTypes: subscriptionTypes.join(","),
          modules: modules.join(","),
          bundles: bundles.join(","),
          labels: labels.join(","),
          search,
          page,
          pageSize,
        },
      };

      try {
        const { data } = await axiosInstance.request({
          ...request,
          signal: abortController.signal,
        });
        this.workspaces = data.items;
        this.total = data.total;
        this.showSkeletonLoader = false;
        this.loading = false;
      } catch (error) {
        if (error && (error as AxiosError).code === AxiosError.ERR_CANCELED) {
          return;
        }
        console.error(error);
        this.showSkeletonLoader = false;
        this.loading = false;
      }
      abortController = undefined;
    },
    async exportToCsv() {
      const filtersStore = useFiltersStore();
      const request = {
        ...api.exportMspWorkspaces(),
        data: {
          ...getMspFilters(filtersStore.filters.mspFilters),
        },
      };
      try {
        this.loading = true;
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.manageWorkspaces.exportToCsv")
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async exportMspNotifications() {
      const filtersStore = useFiltersStore();
      const request = {
        ...api.exportMspNotifications(),
        data: {
          ...getMspFilters(filtersStore.filters.mspFilters),
        },
      };
      try {
        this.loading = true;
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.manageWorkspaces.exportToCsv")
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async exportMspSummary() {
      const filtersStore = useFiltersStore();
      const request = {
        ...api.exportMspSummary(),
        params: {
          type: "csv",
          resolvedFromTime: moment().subtract(90, "days").startOf("day").utc().valueOf(),
        },
        data: {
          ...getMspFilters(filtersStore.filters.mspFilters),
        },
      };
      try {
        this.loading = true;
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.manageWorkspaces.exportToCsv")
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async createWorkspace(payload: DialogDataConfig<MspWorkspaceDialogItem>) {
      const jsonData = cloneDeep(payload.item);
      const formData = generateFormData(jsonData, payload.item.image, payload.item.emailImage);

      const request = {
        ...api.mspWorkspaces(),
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
        method: "POST",
      };

      this.createWorkspaceInProgress = true;
      this.createWorkspaceCompleted = false;
      this.createWorkspaceFailed = false;

      try {
        await axiosInstance.request(request);
        await this.getMspWorkspaces();
        this.createWorkspaceInProgress = false;
        this.createWorkspaceCompleted = true;
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.manageWorkspaces.workspaceUpdated")
        );
      } catch (error) {
        this.createWorkspaceInProgress = false;
        this.createWorkspaceFailed = true;
        console.error(error);
      }
    },
    async editWorkspace(payload: DialogDataConfig<MspWorkspaceDialogItem>) {
      const {
        image,
        showDisabledModules,
        workspaceId,
        scanUnprotectedUsers,
        displayName,
        supportEnabled,
        subscription,
        maxProtectedUsers,
        companyName,
        branding,
        isCoroEmployeeWorkspace,
        emailImage,
        notificationsLevel,
        languageCode,
        labels,
      } = payload.item;
      const jsonData = {
        companyName,
        notificationsLevel,
        maxProtectedUsers,
        displayName,
        supportEnabled,
        scanUnprotectedUsers,
        psaEnabled: true, // https://gitlab.com/coronet/bugatti/tickets/roadmap/-/issues/1854
        branding: {
          ...branding,
        },
        subscription,
        showDisabledModules,
        isCoroEmployeeWorkspace,
        languageCode,
        labels,
      };
      const formData = generateFormData(jsonData, image, emailImage);
      const accountStore = useAccountStore();
      const currentWorkspace = accountStore.account.workplace;

      const request = {
        ...api.mspWorkspace(workspaceId),
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
        method: "PUT",
      };

      try {
        const { data } = await axiosInstance.request(request);
        // if we are editing workspace in which we are now, then we should update the current workspace data
        if (currentWorkspace === workspaceId) {
          useWorkspacesStore().setWorkspace({
            workspaceId: data.workspaceId,
            name: data.name,
            type: data.type,
            branding: data.branding,
            supportEnabled: data.supportEnabled,
            psaEnabled: true,
            subscription,
            showDisabledModules,
            subscriptionType: data.subscriptionType,
            betaComponents: [],
            domain: data.domain,
            isCoronetWorkspace: false,
            trialEndDate: 0,
            languageCode: data.languageCode,
          });
        }
        await this.getMspWorkspaces();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.manageWorkspaces.workspaceUpdated")
        );
      } catch (error) {
        console.error(error);
      }
    },
    async deleteWorkspace(payload: DialogDataConfig<MspWorkspaceDialogItem>) {
      const request = {
        ...api.mspWorkspace(payload.item.workspaceId),
        method: "DELETE",
      };

      try {
        await axiosInstance.request(request);
        await this.getMspWorkspaces();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.manageWorkspaces.workspaceDeleted")
        );
      } catch (error) {
        console.error(error);
      }
    },
    async convertWorkspace(
      payload: DialogDataConfig<MspWorkspaceDialogItem & { descendantConversionOption?: string }>
    ) {
      const data = {
        type: payload?.item?.type,
        parentWorkspaceId: payload?.item?.parentWorkspaceId,
        descendantConversionOption: payload?.item?.descendantConversionOption,
      };

      const request = {
        ...api.convertMspWorkspace(payload.item.workspaceId),
        data,
        method: "PUT",
      };

      try {
        await axiosInstance.request(request);
        await this.getMspWorkspaces();
      } catch (error) {
        console.error(error);
      }
    },
    async unarchiveWorkspace(payload: DialogDataConfig<MspWorkspaceDialogItem>) {
      const request = {
        ...api.unarchiveWorkspace(payload.item.workspaceId),
      };

      try {
        await axiosInstance.request(request);
        await this.getMspWorkspaces();
      } catch (error) {
        console.error(error);
      }
    },
    async archiveWorkspace(payload: DialogDataConfig<MspWorkspaceDialogItem>) {
      const request = {
        ...api.archiveWorkspace(payload.item.workspaceId),
      };

      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.manageWorkspaces.workspaceArchived")
        );
        await this.getMspWorkspaces();
      } catch (error) {
        console.error(error);
      }
    },
    async editSubscription(payload: EditSubscriptionData) {
      const request = {
        ...api.editSubscription(),
        data: {
          workspaceIds: payload.workspaceIds,
          subscriptionDto: {
            modules: payload.modules,
            bundles: payload.bundles,
          },
        },
      };

      try {
        const { data } = await axiosInstance.request(request);
        await useMspStore().getMspWorkspaces();
        useSubscriptionStore().$patch({
          subscription: {
            modules: payload.modules,
            bundles: payload.bundles,
          },
        });
        useSnackbarStore().addGenericSuccess(
          i18n.global.t(
            "snackbar.messages.manageWorkspaces.workspacesUpdated",
            { n: data.appliedActions },
            data.appliedActions
          )
        );
      } catch (error) {
        console.error(error);
      }
    },
    async bulkEditLabels(payload: { labelIds: string[]; workspaceIds: string[] }) {
      const request = {
        ...api.bulkEditLabel(),
        method: "POST",
        data: payload,
      };
      try {
        const { data } = await axiosInstance.request(request);
        await useMspStore().getMspWorkspaces();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t(
            "snackbar.messages.manageWorkspaces.workspacesUpdated",
            { n: data.appliedActions },
            data.appliedActions
          )
        );
      } catch (e) {
        console.error(e);
      }
    },
    async stopSubscription(payload: DialogDataConfig<string[]>) {
      const data = {
        workspaceIds: payload.item,
        subscriptionType: SubscriptionType.FREEZE,
      };
      await this.updateSubscriptionType(data);
    },
    async startSubscription(payload: DialogDataConfig<string[]>) {
      const data = {
        workspaceIds: payload.item,
        subscriptionType: SubscriptionType.SUBSCRIPTION,
      };
      await this.updateSubscriptionType(data);
    },
    async updateSubscriptionType({
      subscriptionType,
      workspaceIds,
    }: {
      workspaceIds: string[];
      subscriptionType: SubscriptionType;
    }) {
      const request = {
        ...api.updateSubscriptionType(),
        data: {
          workspaceIds,
          subscriptionType,
        },
      };
      try {
        const { data } = await axiosInstance.request(request);
        await useMspStore().getMspWorkspaces();
        await useWorkspacesStore().getCurrentWorkspace();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t(
            "snackbar.messages.manageWorkspaces.workspacesUpdated",
            { n: data.appliedActions },
            data.appliedActions
          )
        );
      } catch (e) {
        console.error(e);
        await useMspStore().getMspWorkspaces();
      }
    },
    async extendTrial(payload: DialogDataConfig<MspWorkspaceDialogItem>) {
      const request = {
        ...api.extendWorkspaceTrial(payload.item.workspaceId),
        data: {
          endDate: moment(payload.item.endDate).endOf("day").utc().valueOf(),
        },
      };

      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.manageWorkspaces.workspaceUpdated")
        );
        await this.getMspWorkspaces();
      } catch (error) {
        console.error(error);
      }
    },
    async checkInvitedAdminAlreadyMspAdmin(data: {
      parentWorkspaceId: string;
      invitedAdmin: string;
    }): Promise<boolean> {
      const request = {
        ...api.checkInvitedAdminAlreadyMspAdmin(),
        data,
      };

      try {
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async getRelevantHierarchy() {
      const workspacesStore = useWorkspacesStore();
      const request = {
        ...api.getRelevantHierarchy(),
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.hasMspAccess = !isEmpty(data);
        await workspacesStore.updateWorkspaceAndPermissions(data.workspaceId);
        return data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async checkMspAccess() {
      const request = {
        ...api.getRelevantHierarchy(),
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.hasMspAccess = !isEmpty(data);
      } catch (error) {
        console.error(error);
        this.hasMspAccess = false;
      }
    },
    async getHierarchies(
      payload: { search: string; pagination: Pagination } = {
        search: "",
        pagination: { page: 0, pageSize: 10 },
      }
    ) {
      const request = {
        ...api.getHierarchies(payload.search, payload.pagination.page, payload.pagination.pageSize),
      };

      return axiosInstance.request(request);
    },
    async getMspWorkspaceDescendants() {
      const request = {
        ...api.getMspWorkspaceDescendants(),
      };

      return axiosInstance.request(request);
    },
    async getWorkspaceCandidates(
      search = "",
      permission: ParentWorkspaceSearchPermission
    ): Promise<{ workspaceName: string; workspaceId: string }[]> {
      const request = {
        ...api.workspaceMspCandidates(),
        params: {
          search,
          permission,
        },
      };

      try {
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async searchMspConversionWorkplaceCandidates(query: {
      name: string;
      type?: WorkspaceType;
      channelWorkspaceIdToExclude?: string;
    }): Promise<Paged<WorkspaceListItem> | undefined> {
      const request = {
        ...api.mspConversionWorkplaceCandidates({ ...query, page: 0, pageSize: 25 }),
      };
      try {
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (e) {
        console.error(e);
      }
    },
    async getMspWorkspaceDetails(workspaceId: string): Promise<MspWorkspaceDetails | undefined> {
      const request = {
        ...api.mspWorkspaceDetails(workspaceId),
      };

      try {
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (error) {
        console.error(error);
      }
    },
  },
});

function generateFormData(jsonData: object, image?: File, emailImage?: File) {
  const formData = new FormData();
  const data = new Blob([JSON.stringify(jsonData)], {
    type: "application/json",
  });

  if (image) {
    formData.append("headerIcon", image);
  }
  if (emailImage) {
    formData.append("emailIcon", emailImage);
  }
  formData.append("payload", data);
  return formData;
}

function getMspFilters({ addons, search, subscriptionTypes, types, labels }: MspFilters) {
  return {
    subscriptionTypes,
    types,
    search,
    labels,
    addons: addons.filter((v) => v.type === SubscriptionsFilterType.ADDON).map((v) => v.name),
    modules: addons.filter((v) => v.type === SubscriptionsFilterType.MODULE).map((v) => v.name),
    bundles: addons.filter((v) => v.type === SubscriptionsFilterType.BUNDLE).map((v) => v.name),
  };
}
