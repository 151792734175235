import { defineStore } from "pinia";
import { WorkspaceType } from "@/constants/workplaces.ts";
import type { DataValue } from "@/types.ts";
import { StoreModule } from "@/constants/store.ts";
import api from "@/_helpers/api.ts";
import { axiosInstance } from "@/plugins/https.ts";
import { TicketsModuleFilter } from "@/constants/tickets.ts";

export interface GeneratedTicketsStats {
  totalGenerated: number;
  openCount: number;
  closedCount: number;
}

export type ModuleTicketsStats = {
  [moduleName in TicketsModuleFilter]: number;
};

export interface WorkspaceTicket {
  workspace: {
    name: string;
    type: WorkspaceType;
    workspaceId: string;
  };
  ticketsCount: number;
}

export interface UsersStats {
  total: number;
  suspendedCount: number;
  withIssuesCount: number;
  noIssuesCount: number;
}

export interface DevicesStats {
  total: number;
  isolatedCount: number;
  withIssuesCount: number;
  noIssuesCount: number;
}

export interface WorkspacesStats {
  total: number;
  subscriptionCount: number;
  inactiveCount: number;
  trialCount: number;
  newCount: number;
  nearExpirationCount?: number;
}

export interface MspDashboardState {
  generatedTickets: DataValue<GeneratedTicketsStats>;
  moduleTickets: DataValue<ModuleTicketsStats>;
  workspaceTickets: DataValue<WorkspaceTicket[]>;
  usersStats: DataValue<UsersStats>;
  devicesStats: DataValue<DevicesStats>;
  workspacesStats: DataValue<WorkspacesStats>;
}

export const useMspDashboardStore = defineStore(StoreModule.MSP_DASHBOARD, {
  state: (): MspDashboardState => ({
    generatedTickets: { loading: false },
    moduleTickets: { loading: false },
    workspaceTickets: { loading: false },
    usersStats: { loading: false },
    devicesStats: { loading: false },
    workspacesStats: { loading: false },
  }),

  actions: {
    async fetchGeneratedTickets() {
      try {
        this.generatedTickets = { loading: true };
        const request = {
          ...api.mspDashboardGeneratedTickets(),
        };
        const { data } = await axiosInstance.request<GeneratedTicketsStats>(request);
        this.generatedTickets.data = data;
      } catch (error: any) {
        this.generatedTickets.error = true;
        console.error("Error fetching generated tickets:", error);
      } finally {
        this.generatedTickets.loading = false;
      }
    },

    async fetchModuleTickets() {
      try {
        this.moduleTickets = { loading: true };
        const request = {
          ...api.mspDashboardModuleTickets(),
        };
        const { data } = await axiosInstance.request<{
          ticketsPerModule: {
            [moduleName in TicketsModuleFilter]: number;
          };
        }>(request);
        this.moduleTickets.data = data.ticketsPerModule;
      } catch (error: any) {
        this.moduleTickets.error = true;
        console.error("Error fetching module tickets:", error);
      } finally {
        this.moduleTickets.loading = false;
      }
    },

    async fetchWorkspaceTickets() {
      try {
        this.workspaceTickets = { loading: true };

        const request = {
          ...api.mspDashboardWorkspaceTickets(),
        };
        const { data } = await axiosInstance.request<{ ticketsPerWorkspace: WorkspaceTicket[] }>(
          request
        );
        this.workspaceTickets.data = data.ticketsPerWorkspace;
      } catch (error: any) {
        this.workspaceTickets.error = true;
        console.error("Error fetching workspace tickets:", error);
      } finally {
        this.workspaceTickets.loading = false;
      }
    },

    async fetchUsersStats() {
      try {
        this.usersStats = { loading: true };
        const request = {
          ...api.mspDashboardUsersStats(),
        };
        const { data } = await axiosInstance.request<UsersStats>(request);
        this.usersStats.data = data;
      } catch (error: any) {
        this.usersStats.error = true;
        console.error("Error fetching users stats:", error);
      } finally {
        this.usersStats.loading = false;
      }
    },

    async fetchDevicesStats() {
      try {
        this.devicesStats = { loading: true };
        const request = {
          ...api.mspDashboardDevicesStats(),
        };
        const { data } = await axiosInstance.request<DevicesStats>(request);
        this.devicesStats.data = data;
      } catch (error: any) {
        this.devicesStats.error = true;
        console.error("Error fetching devices stats:", error);
      } finally {
        this.devicesStats.loading = false;
      }
    },

    async fetchWorkspacesStats() {
      try {
        this.workspacesStats = { loading: true };
        const request = {
          ...api.mspDashboardWorkspacesStats(),
        };
        const { data } = await axiosInstance.request<WorkspacesStats>(request);
        this.workspacesStats.data = data;
      } catch (error: any) {
        this.workspacesStats.error = true;
        console.error("Error fetching workspaces stats:", error);
      } finally {
        this.workspacesStats.loading = false;
      }
    },

    async getAllWidgetsData() {
      try {
        await Promise.all([
          this.fetchGeneratedTickets(),
          this.fetchModuleTickets(),
          this.fetchWorkspaceTickets(),
          this.fetchUsersStats(),
          this.fetchDevicesStats(),
          this.fetchWorkspacesStats(),
        ]);
      } catch (error: any) {
        console.error("Error fetching all dashboard data:", error);
      }
    },
  },
});
